import { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { getSasToken } from "../../service/ReportApi";
import { read, utils } from "xlsx";
import moment from "moment";
import { encryptStorage, fileNameSlice } from "../../constant/constant";
import { jwtDecode } from "jwt-decode";
import { getConnectorsData } from "../../helpers/encryption";

export default function InfraAndCloudConnector(props: any) {
  const {
    activeConnector,
    validateConnectorInput,
    connectorFormValidationMsg,
    connectorsOnChange,
    setShowConnectorForm,
    formDataObj,
    connectorFormData,
    setConnectorFormData,
    setExcelTemplateData,
    notebookFiles,
    setNotebookFiles,
    validateNotebookInput,
    uploadTemplate,
    resetValidationMessages,
    connectorApiValidationMsg,
    verticalName,
  } = props;

  const attachment = useRef<HTMLInputElement | null>(null);
  const dropRef = useRef(null);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<any>();
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [notebookFormValidationMsg, setNotebookFormValidationMsg] = useState<any>("");
  const [activeTab, setActiveTab] = useState("Template-based");
  const [isDragging, setIsDragging] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const SolarwindsvalidationHeadings = [
    "Host Name",
    "Last Boot",
    "CPU Load",
    "Percent Memory Used",
    "Percent Used",
  ];

  const MdpwInventoryTemplate = [
    "Category",
    "Product",
    "Environment",
    "Cluster Name",
    "Configuration Details",
    "RAM-Provisioned",
    "CPU-Provisioned",
    "Storage-Provisioned",
    "RAM-Used",
    "CPU-Used",
    "Storage-Used",
    "Metrics Type (Other)",
    "Usage Metrics (Other)",
    "DR Features",
    "Database Count",
    "Schema Count",
    "Table Count",
    "ETL Pipeline Count",
    "Reports Count",
  ];

  const VmwareInventoryTemplate = [
    "Name",
    "State",
    "Host",
    "Cluster",
    "Provisioned Space",
    "Memory Size",
    "CPUs",
    "License",
    "Guest OS",
    "Purpose",
    "Compatibility",
    "Status",
    "Used Space",
    "NICs",
    "Uptime",
    "IP Address",
    "VMware Tools Version Status",
    "VMware Tools Running",
    "Host CPU",
    "Host Mem",
    "Guest Mem - %",
    "DNS Name",
    "EVC Mode",
    "UUID",
    "Notes",
    "Alarm Actions",
    "HA Protection",
    "Needs Consolidation",
    "VM Storage Policies Compliance",
    "Encryption",
    "TPM",
    "VBS",
  ];

  const DatabricksInventoryTemplate = [
    "Object",
    "ObjectType",
    "ObjectName",
    "SourceObjectType",
    "SourceObjectPath",
    "EntityCount"
  ];


  const AzureAndAWSValidationHeadings = [
    "Description",
    "Affected Resources",
    "Risk Level",
    "Control Area",
    "Sub Category",
  ];

  useEffect(() => {
    if (connectorFormData["template"]) {
      let filename = fileNameSlice(connectorFormData["template"]) || "";
      setFileName(filename);
    }
  }, []);

  const attachmentRemover = () => {
    if (attachment?.current) {
      attachment.current.files = null;
      attachment.current.value = "";
    }
  };

  /**PS_AN_54
   * This function is used to the remove the files uploaded by the user
   */

  const removeFile = () => {
    setFileName("");
    setValidationMessage("");
    attachmentRemover();
  };
  const readFile = (event: any) => {
    // attachment.current = null;

    let array: any = [];
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
      const typeArr = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      let type = typeArr.includes(file.type.split("/")[1]);
      if (type) {
        setValidationMessage("");
        let files = file;
        let f = files[0];
        let userArr = [];
        let reader = new FileReader();
        reader.onload = (e) => {
          const wb = read(e?.target?.result); //workbbok
          const sheets = wb.SheetNames; //sheetname
          const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          if (rows.length >= 1) {
            setFileName(event.target.files[0]?.name);
            setValidationMessage("");
            let columnTitle = utils.sheet_to_json(wb.Sheets[sheets[0]], {
              header: 1,
            })[0];
            if (
              JSON.stringify(
                activeConnector === "Solarwinds"
                  ? SolarwindsvalidationHeadings
                  : ""
              ) == JSON.stringify(columnTitle)
            ) {
              rows.forEach((data: any) => {
                let obj = {
                  hostName: data["Host Name"] || "",
                  lastBoot:
                    moment(data["Last Boot"]).format("MM/DD/YYYY hh:mm:ss A") ||
                    "",
                  cpuLoad: (data["CPU Load"] * 100)?.toString() + " %" || "",
                  percentMemoryUsed:
                    (data["Percent Memory Used"] * 100)?.toString() + " %" ||
                    "",
                  percentUsed:
                    (data["Percent Used"] * 100)?.toString() + " %" || "",
                };
                array.push(obj);
              });
              setExcelTemplateData(array);
            } else if (
              JSON.stringify(
                activeConnector === "Azure" ? AzureAndAWSValidationHeadings : ""
              ) == JSON.stringify(columnTitle)
            ) {
              rows.forEach((data: any) => {
                let obj = {
                  description: data.Description,
                  affectedResources: data["Affected Resources"]?.toString(),
                  riskLevel: data["Risk Level"],
                  controlArea: data["Control Area"],
                  subCategory: data["Sub Category"],
                };
                array.push(obj);
              });
              setExcelTemplateData(array);
            } else if (
              JSON.stringify(
                activeConnector === "Inventory" && verticalName === "MDPW"
                  ? MdpwInventoryTemplate
                  : ""
              ) == JSON.stringify(columnTitle)
            ) {
              rows.forEach((data: any) => {
                let obj = {
                  category: data["Category"],
                  product: data["Product"],
                  environment: data["Environment"],
                  clusterName: data["Cluster Name"],
                  configurationDetails: data["Configuration Details"],
                  ramProvisioned: data["RAM-Provisioned"],
                  cpuProvisioned: data["CPU-Provisioned"],
                  storageProvisioned: data["Storage-Provisioned"],
                  ramUsed: data["RAM-Used"],
                  cpuUsed: data["CPU-Used"],
                  storageUsed: data["Storage-Used"],
                  metricsTypeOther: data["Metrics Type (Other)"],
                  usageMetricsOther: data["Usage Metrics (Other)"],
                  drFeatures: data["DR Features"],
                  databaseCount: data["Database Count"],
                  schemaCount: data["Schema Count"],
                  tableCount: data["Table Count"],
                  etlPipelineCount: data["ETL Pipeline Count"],
                  reportsCount: data["Reports Count"],
                };
                array.push(obj);
              });
              setExcelTemplateData(array);
            } else if (
              JSON.stringify(
                activeConnector === "Inventory" && verticalName === "Databricks"
                  ? DatabricksInventoryTemplate
                  : ""
              ) === JSON.stringify(columnTitle)
            ) {
              rows.forEach((data: any) => {
                const requiredFields = [
                  "Object"
                ];
                const isValid = requiredFields.every((field) => {
                  const value = data[field];
                  return value && (typeof value === 'string' && value?.trim() !== "") || (typeof value === 'number');
                });
    
                if (!isValid) {
                  attachmentRemover();
                  setFileName("");
                  setValidationMessage(
                    "Please upload the"
                  );
                  return;
                }
    
                let obj = {
                  object: data["Object"],
                  objectType: data["ObjectType"],
                  objectName: data["ObjectName"],
                  sourceObjectType: data["SourceObjectType"],
                  sourceObjectPath: data["SourceObjectPath"],
                  entityCount: data["EntityCount"],
                };
                array.push(obj);
              });
    
              setExcelTemplateData(array);
            } else if (
              JSON.stringify(
                activeConnector === "Inventory" && verticalName === "VMware"
                  ? VmwareInventoryTemplate
                  : ""
              ) === JSON.stringify(columnTitle)
            ) {
              rows.forEach((data: any) => {
                const requiredFields = [
                  "Name",
                  "State",
                  "Host",
                  "Cluster",
                  "Provisioned Space",
                  "Purpose",
                  "License",
                  "Guest OS",
                  "Memory Size",
                  "CPUs",
                ];

                // Check if any required field is empty
                const isValid = requiredFields.every((field) => {
                  const value = data[field];
                  if (field === "State") {
                    if (value === "" || value === undefined) {
                      return false;
                    } else if ((value?.toLowerCase() !== "powered off") && (value?.toLowerCase() !== "powered on")) {
                      return false;
                    }
                  }
                  return value && (typeof value === 'string' && value?.trim() !== "") || (typeof value === 'number');
                });

                if (!isValid) {
                  // attachment.current = null;
                  attachmentRemover();
                  setFileName("");
                  setValidationMessage(
                    "Please upload the file with valid data"
                  );
                  return;
                }

                let obj = {
                  name: data["Name"],
                  state: data["State"],
                  status: data["Status"],
                  host: data["Host"],
                  cluster: data["Cluster"],
                  provisionedSpace: data["Provisioned Space"],
                  usedSpace: data["Used Space"],
                  purpose: data["Purpose"],
                  license: data["License"],
                  guestOS: data["Guest OS"],
                  compatibility: data["Compatibility"],
                  memorySize: data["Memory Size"],
                  cpus: data["CPUs"],
                  nics: data["NICs"],
                  uptime: data["Uptime"],
                  ipAddress: data["IP Address"],
                  vmwareToolsVersionStatus: data["VMware Tools Version Status"],
                  vmwareToolsRunning: data["VMware Tools Running"],
                  hostCPU: data["Host CPU"],
                  hostMem: data["Host Mem"],
                  guestMemPercent: data["Guest Mem - %"],
                  dnsName: data["DNS Name"],
                  evcMode: data["EVC Mode"],
                  uuid: data["UUID"],
                  notes: data["Notes"],
                  alarmActions: data["Alarm Actions"],
                  haProtection: data["HA Protection"],
                  needsConsolidation: data["Needs Consolidation"],
                  vmStoragePoliciesCompliance:
                    data["VM Storage Policies Compliance"],
                  encryption: data["Encryption"],
                  tpm: data["TPM"],
                  vbs: data["VBS"],
                };
                array.push(obj);
              });

              setExcelTemplateData(array);
            } else if (
              JSON.stringify(
                activeConnector === "AWS" ? AzureAndAWSValidationHeadings : ""
              ) == JSON.stringify(columnTitle)
            ) {
              rows.forEach((data: any) => {
                let obj = {
                  description: data["Description"],
                  affectedResources: data["Affected Resources"]?.toString(),
                  riskLevel: data["Risk Level"],
                  controlArea: data["Control Area"],
                  subCategory: data["Sub Category"],
                };
                array.push(obj);
              });
              setExcelTemplateData(array);
            } else {
              // attachment.current = null;
              attachmentRemover();
              setFileName("");
              setValidationMessage(
                "Please upload the file with valid columns and data"
              );
            }
          } else {
            // attachment.current = null;
            attachmentRemover();
            setFileName("");
            setValidationMessage("Please upload the file with valid data");
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        // attachment.current = null;
        attachmentRemover();
        setFileName("");
        setValidationMessage("Please upload a XLSX file");
      }
    }
  };

  /**PS_AN_63-PS_AN_68
   * This function is used to download the template file uploaded in the blob
   */
  const downloadTemplate = async () => {
    try {
      let value = encryptStorage.getItem("jwt");
      let parsedJtk: any = "";
      let storageAccountName: any, containerName: any;
      if (value) {
        parsedJtk = jwtDecode(value);
        storageAccountName = getConnectorsData({
          key: parsedJtk.ek,
          encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME!,
        });
        containerName = getConnectorsData({
          key: parsedJtk.ek,
          encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER!,
        });
      }
      const sas = await getSasToken("r");
      if (activeConnector === "Solarwinds") {
        window.open(
          `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/SolarWinds Infra Template.xlsx` +
          `?${sas.data}`
        );
      } else if (activeConnector === "Inventory") {
        switch (verticalName) {
          case "MDPW":
            window.open(
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/AWS MDPW Inventory Template.xlsx` +
              `?${sas.data}`
            );
            break;
          case "VMware":
            window.open(
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Infrastructure VMware Inventory Template.xlsx` +
              `?${sas.data}`
            );
            break;
        }
      } else if (activeConnector === "Azure") {
        window.open(
          `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Azure Template - Infra connector.xlsx` +
          `?${sas.data}`
        );
      } else {
        window.open(
          `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/AWS template - Infra connector.xlsx` +
          `?${sas.data}`
        );
      }
    } catch (error) {
      console.error("Error occurred while downloading the template.", error);
    }
  };

  const handleFiles = (files: FileList | File[]) => {
    const fileArray = Array.from(files);
    const zipFiles = fileArray.filter(file =>
      file.type === 'application/zip' || file.type === 'application/x-zip-compressed'
    );

    if (zipFiles.length === fileArray.length) {
      setNotebookFiles((prevFiles: any) => [...prevFiles, ...zipFiles]);
      setNotebookFormValidationMsg("");
    } else {
      setNotebookFormValidationMsg("Please upload only ZIP files.");
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      // Validate if all selected files are ZIP files
      const invalidFiles = filesArray.filter(file => file.type !== 'application/zip' && file.type !== 'application/x-zip-compressed');
      if (invalidFiles.length > 0) {
        setNotebookFormValidationMsg("Please upload only ZIP files.");
        return; // Exit if there are invalid files
      } else {
        setNotebookFormValidationMsg(""); // Clear any previous validation messages
      }
      setNotebookFiles((prevFiles: any) => [...prevFiles, ...filesArray]);
    }
  };

  const handleUpload = () => {
    // Implement the upload logic here
    setShowModal(false);
  };

  const handleRemoveFile = (fileName: string) => {
    setNotebookFiles((prevFiles: any) =>
      prevFiles.filter((file: any) => file.name !== fileName)
    );
  };

  const handleClear = () => {
    setNotebookFiles([]);
    if (attachment.current) {
      attachment.current.value = '';
    }
  };

  const BindTemplateForm = () => {
    return (
      <>
        <p className="font-14 font-bold color-black mb-4">Step 1</p>
        <div className="d-flex mb-3">
          <span
            onClick={() => {
              downloadTemplate();
            }}
          >
            <img
              src="images/download.svg"
              alt="download"
              title="download"
              className="me-2"
            />
            <span className="link-blue font-12 font-semibold link-blue cursor-pointer">
              Download Template
            </span>
          </span>
        </div>
        <p className="form-label font-14 font-regular color-black  mb-4">
          Download the template
        </p>
        <p className="font-14 font-bold color-black mb-4">Step 2</p>
        <p className="form-label font-14 font-regular color-black  mb-4">
          Enter valid details on the template
        </p>
        <p className="font-14 font-bold color-black mb-3">Step 3</p>
        <div className="d-flex mb-3 cursor-pointer">
          <span className="upload-btn-wrapper me-2 d-block ">
            <img
              src="images/upload.svg"
              alt="browse"
              className="me-2"
            />
            <span className="link-blue font-12 font-semibold link-blue ">
              Upload Template
            </span>
            <input
              type="file"
              name="myfile"
              ref={attachment}
              disabled={fileName !== "" ? true : false}
              className="cursor-pointer"
              onChange={(e) => {
                readFile(e);
              }}
            />
          </span>
        </div>
        <div>
          <span className="font-14 font-medium color-grey mt-1  d-block ">
            {fileName}
            <span className="cursor-pointer ms-3" title="Cancel">
              {fileName ? (
                <img
                  src="images/upload-close.svg"
                  alt="upload-close"
                  onClick={() => {
                    removeFile();
                  }}
                />
              ) : (
                <></>
              )}
            </span>
          </span>
          <p className="mt-2 mb-0 color-red font-14 font-regular">
            {validationMessage}
          </p>
        </div>
      </>
    );
  }

  const BindNotebookForm = () => {
    const dropRef = useRef(null);
    useEffect(() => {
      const dropArea: any = dropRef.current;
      if (dropArea) {
        const handleDragOver = (e: DragEvent) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(true);
        };

        const handleDragLeave = (e: DragEvent) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(false);
        };

        const handleDrop = (e: DragEvent) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(false);
          if (e.dataTransfer?.files) {
            handleFiles(e.dataTransfer.files);
          }
        };

        dropArea.addEventListener('dragover', handleDragOver);
        dropArea.addEventListener('dragleave', handleDragLeave);
        dropArea.addEventListener('drop', handleDrop);

        return () => {
          dropArea.removeEventListener('dragover', handleDragOver);
          dropArea.removeEventListener('dragleave', handleDragLeave);
          dropArea.removeEventListener('drop', handleDrop);
        };
      }
    }, []);

    return (
      <div ref={dropRef} className={`drag-drop-area ${isDragging ? 'dragging' : ''}`}>
        <div className="ribbon py-2 px-3 ms-md-4 mb-4">
          <span className="font-12 font-medium">
            Please note that some notebooks are not accessible directly. You will need to upload these manually, while the rest of the notebook codes can be accessed through the API.
          </span>
          <span className="ribbon-border" />
        </div>
        <h3 className="font-14 font-medium mt-4 mb-2 ms-md-4">Upload ZIP File <span className="mandate">*</span></h3>
        <div className="d-flex align-items-center gap-3 mb-5 ms-md-4">
          <button
            className="btn file-upload-outline rounded-3 d-flex align-items-center gap-2 font-12 font-semibold mt-2"
            onClick={() => setShowModal(true)}
          >
            <img src="../images/upload-icon-blue.svg" alt="upload-icon" />
            <span className="upload-text">Upload{notebookFiles?.length > 0 && <span>({notebookFiles?.length})</span>}</span>
          </button>
          <input
            type="file"
            ref={attachment}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".zip"
            multiple
          />
          {notebookFiles?.length > 0 && (
            <span className="font-12 font-medium color-grey">
              ({notebookFiles?.map((file: any) => file.name).join(', ')})
            </span>
          )}
        </div>
        {/* Validation Message */}
        {notebookFormValidationMsg && (
          <p className="mt-2 mb-0 color-red font-14 font-regular">
            {notebookFormValidationMsg}
          </p>
        )}
        {notebookFiles?.length > 0 && (
          <>
            <h3 className="font-18 color-black font-medium ms-md-4">Uploaded Files</h3>
            {notebookFiles?.map((file: any, index: any) => (
              <div key={index} className="d-flex align-items-center justify-content-between p-3 px-4 uploaded-file-container mb-2 gap-3 flex-wrap ms-md-4">
                <span className="d-flex align-items-center gap-3 font-14 font-medium color-black-v2">
                  <img src="../images/folder.svg" alt="doc-icon" />{file.name}
                </span>
                <button
                  type="button"
                  className="btn delete-btn font-medium font-12"
                  onClick={() => handleRemoveFile(file.name)}
                >
                  Delete
                </button>
              </div>
            ))}
          </>
        )}
        {isDragging && (
          <div className="drag-overlay">
            <p>Drop ZIP files here</p>
          </div>
        )}
        {showModal && (
          <div className="modal fade show" id="_addSessionPopup" style={{ display: 'block' }} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content custom-popup">
                <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                  <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                    Upload Notebook
                  </h2>
                  <button type="button" className="btn close-icon" onClick={() => setShowModal(false)}>
                    <img src="../images/popup-close.svg" alt="Close" />
                  </button>
                </div>

                <div className="modal-body border-0 px-4">
                  <button
                    className="btn upload-btn p-4 d-flex align-items-center justify-content-center flex-column gap-2 position-relative mb-3"
                    onClick={() => attachment.current?.click()}
                  >
                    <img src="../images/upload-icon.svg" alt="upload-icon" />
                    <span className="font-12 font-regular color-grey-v8 px-5 mx-sm-5">
                      Browse or drag and drop or click to choose files
                    </span>
                    <input
                      type="file"
                      className="form-control custom-file w-auto cust-upload"
                      onChange={handleFileChange}
                      ref={attachment}
                      multiple
                      style={{ display: 'none' }}
                    />
                  </button>
                  {notebookFiles?.map((file: any, index: any) => (
                    <div key={index} className="d-flex align-items-center justify-content-between p-3 px-4 uploaded-file-container mb-2">
                      <span className="d-flex align-items-center gap-3 font-14 font-medium color-black-v2">
                        <img src="../images/folder.svg" alt="doc-icon" />
                        {file.name}
                      </span>
                      <button
                        className="btn p-0 border-0 bg-transparent"
                        onClick={() => handleRemoveFile(file.name)}
                      >
                        <img className="close-icon" src="../images/close-icon-black.svg" alt="close-icon-black" />
                      </button>
                    </div>
                  ))}
                </div>

                <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                  <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer"
                    onClick={handleClear}>Clear</a>
                  <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold" onClick={handleUpload}>Upload</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
  const bindConnectorsForm = () => {
    const bindSaveAndCancel = () => {
      const isDisabled = () => {
        if (verticalName === "Databricks" && activeConnector === "Inventory") {
          if (activeTab === "Template-based") {
            return !fileName;
          } else {
            return notebookFiles?.length === 0;
          }
        } else {
          return !fileName;
        }
      };
      return (
        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            disabled={isDisabled()}
            onClick={() => {
              if (verticalName === "Databricks" && activeConnector === "Inventory") {
                if (activeTab === "Template-based") {
                  validateConnectorInput(file);
                } else {
                  validateNotebookInput(notebookFiles);
                }
              } else {
                validateConnectorInput(file);
              }
            }}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              setConnectorFormData({});
              resetValidationMessages(activeConnector);
              setShowConnectorForm(true);
            }}
          >
            Cancel
          </a>
        </div>
      );
    };
    switch (activeConnector) {
      case "Solarwinds":
      case "Inventory":
        return (
          <div className="col-12">
            <div className="ps-lg-4">
              <p className="font-semibold font-18 color-black mb-2">
                {activeConnector === "Solarwinds"
                  ? "Solarwinds Configurations"
                  : "Inventory Configurations"}
              </p>

              {verticalName === "Databricks" && (
                <div className="row sticky-top bg-white mb-4">
                  <div className="d-flex nav-tabs">
                    <div className="nav border-tabs flex-nowrap table-responsive nav-scroll cust-nav-detail-pills" role="tablist">
                      {["Template-based", "Notebook-based"].map((tab, index) => (
                        <button
                          key={tab}
                          className={`nav-link font-18 text-nowrap cust-nav-link ${activeTab === tab ? "active" : ""}`}
                          id={`${tab.toLowerCase().replace(/\s/g, "")}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#${tab.toLowerCase().replace(/\s/g, "")}`}
                          type="button"
                          role="tab"
                          aria-controls={tab.toLowerCase().replace(/\s/g, "")}
                          aria-selected={activeTab === tab}
                          onClick={() => setActiveTab(tab)}
                        >
                          {tab}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="tab-content" id="inventoryTabContent">
                <div
                  className={`tab-pane fade ${activeTab === "Template-based" || verticalName !== "Databricks" ? "show active" : ""}`}
                  id="template-based"
                  role="tabpanel"
                >
                  {(verticalName === "Databricks" && activeTab === "Template-based") || verticalName !== "Databricks" ?
                    <BindTemplateForm />
                    : null}

                </div>

                {verticalName === "Databricks" && (
                  <div
                    className={`tab-pane fade ${activeTab === "Notebook-based" ? "show active" : ""}`}
                    id="notebook-based"
                    role="tabpanel"
                  >
                    {(verticalName === "Databricks" && activeTab === "Notebook-based") || verticalName !== "Databricks" ?
                      <BindNotebookForm />
                      : null}
                  </div>
                )}
              </div>
              {bindSaveAndCancel()}
            </div>
          </div>
        );
      case "Azure":
        return (
          <div className="col-lg-7 col-md-12 col-12">
            <div className="ps-lg-4">
              <h3 className="font-18 font-semibold color-black mb-4">
                Azure Connector
              </h3>
              <div className="row">
                <p className="font-16 font-bold mt-3">
                  Upload Data<span className="color-red"> *</span>
                </p>
                <p className="font-14 font-bold color-black mb-4">Step 1</p>
                <div className="d-flex mb-3">
                  <span
                    onClick={() => {
                      downloadTemplate();
                    }}
                  >
                    <img
                      src="images/download.svg"
                      alt="download"
                      title="download"
                      className="me-2"
                    />
                    <span className="link-blue font-12 font-semibold link-blue cursor-pointer">
                      Download Template
                    </span>
                  </span>
                </div>
                <p className="form-label font-14 font-regular color-black  mb-4">
                  Download the template
                </p>
                <p className="font-14 font-bold color-black mb-4">Step 2</p>
                <p className="form-label font-14 font-regular color-black  mb-4">
                  Enter valid details on the template
                </p>
                <p className="font-14 font-bold color-black mb-3">Step 3</p>
                <div className="mb-3 d-md-flex align-items-center">
                  <span className="upload-btn-wrapper me-2 d-block ">
                    <button
                      type="button"
                      className="font-14 link-blue font-medium bg-white border-0 shadow-none cursor-pointer"
                    >
                      <img
                        src="images/upload.svg"
                        alt="browse"
                        className="me-2"
                      />
                      Upload Template
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      className="cursor-pointer"
                      ref={attachment}
                      onChange={(e) => {
                        readFile(e);
                      }}
                    />
                  </span>
                </div>
                <div className="mb-3">
                  <span className="font-14 font-medium color-grey mt-1   d-block ">
                    {fileName}
                    <span className="cursor-pointer ms-3" title="Cancel">
                      {fileName ? (
                        <img
                          src="images/upload-close.svg"
                          alt="upload-close"
                          onClick={() => {
                            removeFile();
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </span>
                  </span>
                  <p className="mt-2 mb-0 color-red font-14 font-regular">
                    {validationMessage}
                  </p>
                </div>
                <div
                  className="row"
                  hidden={activeConnector === "Azure" && fileName === ""}
                >
                  <h2 className="font-16 font-semibold color-black mb-4">
                    Azure Connector Details
                  </h2>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label
                        htmlFor="Connector Name"
                        className="form-label font-14 font-semibold color-black"
                      >
                        Connector Name
                      </label>
                      <span className="color-red"> *</span>
                      <input
                        type="text"
                        className="form-control theme-form font-regular font-14"
                        id="Connector Name"
                        name="Connector Name"
                        placeholder="Enter connector name"
                        value={connectorFormData["Connector Name"]}
                        defaultValue={connectorFormData["Connector Name"]}
                        onChange={(e) => {
                          connectorsOnChange(e);
                        }}
                      />
                      <p className="mt-2 mb-0 color-red font-14 font-regular">
                        {connectorFormValidationMsg["Connector Name"]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label
                        htmlFor="Azure Tenant ID"
                        className="form-label font-14 font-semibold color-black"
                      >
                        Specify Azure Tenant ID
                      </label>
                      <span className="color-red"> *</span>
                      <input
                        type="text"
                        className="form-control theme-form font-regular font-14"
                        id="Azure Tenant ID"
                        name="Azure Tenant ID"
                        placeholder="Enter tenant ID"
                        value={connectorFormData["Azure Tenant ID"]}
                        defaultValue={connectorFormData["Azure Tenant ID"]}
                        onChange={(e) => {
                          connectorsOnChange(e);
                        }}
                      />
                      <p className="mt-2 mb-0 color-red font-14 font-regular">
                        {connectorFormValidationMsg["Azure Tenant ID"]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label
                        htmlFor="Azure Subscription ID"
                        className="form-label font-14 font-semibold color-black"
                      >
                        Specify Azure Subscription ID
                      </label>
                      <span className="color-red"> *</span>
                      <input
                        type="text"
                        className="form-control theme-form font-regular font-14"
                        id="Azure Subscription ID"
                        name="Azure Subscription ID"
                        placeholder="Enter azure subscription ID"
                        value={connectorFormData["Azure Subscription ID"]}
                        defaultValue={
                          connectorFormData["Azure Subscription ID"]
                        }
                        onChange={(e) => {
                          connectorsOnChange(e);
                        }}
                      />
                      <p className="mt-2 mb-0 color-red font-14 font-regular">
                        {connectorFormValidationMsg["Azure Subscription ID"]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      className="font-14 font-semibold color-black mb-2"
                      htmlFor="Secret Key"
                    >
                      Secret Key
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Secret Key"
                      name="Secret Key"
                      placeholder="Enter secret key"
                      value={connectorFormData["Secret Key"]}
                      defaultValue={connectorFormData["Secret Key"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Secret Key"]}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label
                        htmlFor="Client ID"
                        className="form-label font-14 font-semibold color-black"
                      >
                        Client ID
                      </label>
                      <span className="color-red"> *</span>
                      <input
                        type="text"
                        className="form-control theme-form font-regular font-14"
                        id="Client ID"
                        name="Client ID"
                        placeholder="Enter client ID"
                        value={connectorFormData["Client ID"]}
                        defaultValue={connectorFormData["Client ID"]}
                        onChange={(e) => {
                          connectorsOnChange(e);
                        }}
                      />
                      <p className="mt-2 mb-0 color-red font-14 font-regular">
                        {connectorFormValidationMsg["Client ID"]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-5">
                      <label
                        className="form-label font-14 font-semibold color-black"
                        htmlFor="Description"
                      >
                        Description (optional)
                      </label>
                      <textarea
                        className="form-control theme-form"
                        placeholder="Enter description"
                        id="Description"
                        name="Description"
                        value={connectorFormData["Description"]}
                        defaultValue={connectorFormData["Description"]}
                        onChange={(e) => {
                          connectorsOnChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );
      case "AWS":
        return (
          <div className="col-lg-7 col-md-12 col-12">
            <div className="ps-lg-4">
              <h3 className="font-18 font-semibold color-black mb-3">
                AWS Connector
              </h3>
              <div className="row">
                <p className="font-16 font-bold mt-3">
                  Upload Data<span className="color-red"> *</span>
                </p>
                <p className="font-14 font-bold color-black mb-4">Step 1</p>
                <div className="d-flex mb-3">
                  <span
                    onClick={() => {
                      downloadTemplate();
                    }}
                  >
                    <img
                      src="images/download.svg"
                      alt="download"
                      title="download"
                      className="me-2"
                    />
                    <span className="link-blue font-12 font-semibold link-blue cursor-pointer">
                      Download Template
                    </span>
                  </span>
                </div>
                <p className="form-label font-14 font-regular color-black  mb-4">
                  Download the template
                </p>
                <p className="font-14 font-bold color-black mb-4">Step 2</p>
                <p className="form-label font-14 font-regular color-black  mb-4">
                  Enter valid details on the template
                </p>
                <p className="font-14 font-bold color-black mb-3">Step 3</p>
                <div className="mb-3 d-md-flex align-items-center">
                  <span className="upload-btn-wrapper me-2 d-block ">
                    <button
                      type="button"
                      className="font-14 link-blue font-medium bg-white border-0 shadow-none cursor-pointer"
                    >
                      <img
                        src="images/upload.svg"
                        alt="browse"
                        className="me-2 cursor-pointer"
                      />
                      Upload Template
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      className="cursor-pointer"
                      ref={attachment}
                      onChange={(e) => {
                        readFile(e);
                      }}
                    />
                  </span>
                </div>
                <div className="mb-5">
                  <span className="font-14 font-medium color-grey mt-1   d-block ">
                    {fileName}
                    <span className="cursor-pointer ms-3" title="Cancel">
                      {fileName ? (
                        <img
                          src="images/upload-close.svg"
                          alt="upload-close"
                          onClick={() => {
                            removeFile();
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </span>
                  </span>
                  <p className="mt-2 mb-0 color-red font-14 font-regular">
                    {validationMessage}
                  </p>
                </div>
                <div
                  className="row"
                  hidden={activeConnector === "AWS" && fileName === ""}
                >
                  <h2 className="font-16 font-semibold color-black mb-4 ">
                    AWS Connector Details
                  </h2>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label
                        htmlFor="Connector Name"
                        className="form-label font-14 font-semibold color-black"
                      >
                        Connector Name
                      </label>
                      <span className="color-red"> *</span>
                      <input
                        type="text"
                        className="form-control theme-form font-regular font-14"
                        id="Connector Name"
                        name="Connector Name"
                        value={connectorFormData["Connector Name"]}
                        placeholder="Enter connector name"
                        onChange={(e) => {
                          connectorsOnChange(e);
                        }}
                      />
                      <p className="mt-2 mb-0 color-red font-14 font-regular">
                        {connectorFormValidationMsg["Connector Name"]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label
                        htmlFor="Account ID"
                        className="form-label font-14 font-semibold color-black"
                      >
                        Account ID
                      </label>
                      <span className="color-red"> *</span>
                      <input
                        type="text"
                        className="form-control theme-form font-regular font-14"
                        id="Account ID"
                        name="Account ID"
                        value={connectorFormData["Account ID"]}
                        placeholder="Enter account ID"
                        onChange={(e) => {
                          connectorsOnChange(e);
                        }}
                      />
                      <p className="mt-2 mb-0 color-red font-14 font-regular">
                        {connectorFormValidationMsg["Account ID"]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label
                        htmlFor="Access ID"
                        className="form-label font-14 font-semibold color-black"
                      >
                        Access ID
                      </label>
                      <span className="color-red"> *</span>
                      <input
                        type="text"
                        className="form-control theme-form font-regular font-14"
                        id="Access ID"
                        name="Access ID"
                        placeholder="Enter access ID"
                        value={connectorFormData["Access ID"]}
                        onChange={(e) => {
                          connectorsOnChange(e);
                        }}
                      />
                      <p className="mt-2 mb-0 color-red font-14 font-regular">
                        {connectorFormValidationMsg["Access ID"]}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      className="font-14 font-semibold color-black mb-2"
                      htmlFor="Secret Key"
                    >
                      Secret Key
                    </label>
                    <span className="color-red"> *</span>
                    <input
                      type="text"
                      className="form-control theme-form font-regular font-14"
                      id="Secret Key"
                      name="Secret Key"
                      placeholder="Enter secret key"
                      value={connectorFormData["Secret Key"]}
                      onChange={(e) => {
                        connectorsOnChange(e);
                      }}
                    />
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Secret Key"]}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-5">
                      <label
                        className="form-label font-14 font-semibold color-black"
                        htmlFor="Description"
                      >
                        Description (optional)
                      </label>
                      <textarea
                        className="form-control theme-form"
                        placeholder="Enter description"
                        id="Description"
                        name="Description"
                        value={connectorFormData["Description"]}
                        onChange={(e) => {
                          connectorsOnChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );
    }
  };

  return <>{bindConnectorsForm()}</>;
}