import React, { useEffect, useRef, useState } from 'react';
import { getProjectReportData } from '../../service/ReportApi';
import { Navigate, useNavigate } from 'react-router-dom';
import { DownloadReportModalProps } from '../../interface/GenerateReportModel';

//PS_Report_Category_Selection_04
const DownloadReportModal: React.FC<DownloadReportModalProps> = ({
  isOpen,
  onClose,
  onProceed,
  selectedOption,
  selectedCategory,
  reportData,
  handleOptionChange,
  handleCategoryChange,
  removeCategory,
  isDropdownOpen,
  toggleDropdown
}) => {
  //PS_Report_Category_Selection_05
  if (!isOpen) return null;

  return (
    <div className="modal fade show" id="DownloadReport" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="accept-promptLabel" aria-modal="true" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content custom-popup">
          <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-3 pb-2">
            <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
              Download Report
            </h2>
            <button type="button" className="btn close-icon" onClick={onClose}>
              <img src="../images/popup-close.svg" alt="Close" />
            </button>
          </div>

          <div className="modal-body border-0 px-4">
            <p className="font-14 font-medium color-black">How would you like to download the File ?</p>
            <div className="d-flex align-items-center gap-4">
              <div className="d-flex align-items-center gap-2">
                <input
                  className="form-check-input theme-black-radio"
                  type="radio"
                  name="downloadOption"
                  id="allCategories"
                  value="all"
                  checked={selectedOption === 'all'}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label font-14 font-regular color-black" htmlFor="allCategories">
                  All Categories
                </label>
              </div>
              <div className="d-flex align-items-center gap-2">
                <input
                  className="form-check-input theme-black-radio"
                  type="radio"
                  name="downloadOption"
                  id="specificCategories"
                  value="specific"
                  checked={selectedOption === 'specific'}
                  onChange={handleOptionChange}
                />
                <label className="form-check-label font-14 font-regular color-black" htmlFor="specificCategories">
                  Specific Categories
                </label>
              </div>
            </div>

            {selectedOption === 'specific' && (
              <>
                <label className="font-14 font-medium color-black mt-5">Categories</label>
                <div className="dropdown">
                  <div
                    className="cursor-pointer p-2 px-3 border-grey w-100 rounded-3 d-flex align-items-center gap-1 position-relative"
                    onClick={toggleDropdown}
                  >
                    {selectedCategory.length > 0 ? (
                      <>
                        {selectedCategory.slice(0, 4).map((category, index) => (
                          <span key={index} className="cust-keywords font-12 font-medium p-1 px-3 align-items-center d-flex justify-content-between gap-2">
                            {category}
                            <button
                              className="border-0 bg-transparent p-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeCategory(category);
                              }}
                            >
                              <img className="close-icon" src="../images/close-icon-black.svg" alt="close-icon" />
                            </button>
                          </span>
                        ))}
                        {selectedCategory.length > 4 && (
                          <span className="keywords-count font-12 font-medium p-1 px-3">+{selectedCategory.length - 4}</span>
                        )}
                      </>
                    ) : (
                      'Select Category'
                    )}
                  </div>
                  {isDropdownOpen && (
                    <ul className="dropdown-menu download-report-popup ps-3 pe-2 py-2 show">
                      <ul className="drp-scroll ps-1 pe-2 list-unstyled">
                        {reportData.menus.map((menu: any) => (
                          <li key={menu.menuId}>
                            <div className="form-check">
                              <input
                                className="form-check-input custom-form-checkbox me-2"
                                type="checkbox"
                                id={`category-${menu.menuId}`}
                                checked={selectedCategory.includes(menu.menuName)}
                                onChange={() => handleCategoryChange(menu.menuName)}
                              />
                              <label className="form-check-label font-14 font-medium color-grey-v9" htmlFor={`category-${menu.menuId}`}>
                                {menu.menuName}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </ul>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="modal-footer px-4 border-0 justify-content-end pb-3 pt-3 gap-1">
            <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={onClose}>
              Cancel
            </a>
            <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold" onClick={() => onProceed(selectedOption, selectedCategory)}>
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadReportModal;