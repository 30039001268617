// PS_challengesRecommendation_01, PS_challengesRecommendation_02
import { useEffect, useState } from "react";
import { DeleteChallengesRecommendations, GetChallengesRecommendations, PostChallengesRecommendations } from "../../service/QuestionnaireApi";
import { ChallengeRecommendation, ChallengesRecommendationsProps, EditRecommendationPopupProps } from "../../interface/ChallengesRecommendations";
import ToastComponent from "../ToastComponent";
import DeletePopup from "../DeletePopup";
import { useDatabricksContextObject } from "./DatabricksContextObject";

const ChallengesRecommendations: React.FC<ChallengesRecommendationsProps> = ({ title, state, setIsLoading }) => {

    const { setIsChallengesRecommendationsDataUpdated } = useDatabricksContextObject();

    const [challengesRecommendationsData, setChallengesRecommendationsData] = useState<ChallengeRecommendation[]>([]);
    const [toast, setToast] = useState<{ toastType: 'error' | 'success', toastBodyMessage: string } | null>(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<ChallengeRecommendation | null>(null);
    const [editRecommendation, setEditRecommendation] = useState<ChallengeRecommendation | null>(null);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [newRecommendation, setNewRecommendation] = useState({
        title: '',
        challenge: '',
        recommendation: ''
    });
    const [newErrors, setNewErrors] = useState({
        title: '',
        challenge: '',
        recommendation: ''
    });
    const [showSavingPopup, setShowSavingPopup] = useState(false);



    // PS_challengesRecommendation_03, PS_challengesRecommendation_04
    const getInitialPageData = async () => {
        setIsLoading(true);
        // PS_challengesRecommendation_05
        const payload = {
            organizationId: state.organizationId,
            verticalId: state.verticalId,
            projectId: state.projectId,
            connectorId: state.connectorId
        }

        try {
            const response = await GetChallengesRecommendations(payload);
            if (response.status != 200) {
                setToast({ toastType: 'error', toastBodyMessage: 'API Failed' });
            } else {
                setIsLoading(false);
                setChallengesRecommendationsData(response.data.challengesRecommendations || []); // PS_challengesRecommendation_10
            }
        } catch (error) {
            setToast({ toastType: 'error', toastBodyMessage: 'API Failed' }); // PS_challengesRecommendation_12
        } finally {
            setIsLoading(false);  // Handle loading state (PS_challengesRecommendation_13)
        }
    };

    useEffect(() => {
        getInitialPageData();  // PS_challengesRecommendation_02 
    }, []);


    useEffect(() => {
        if (toast) {
            const timer = setTimeout(() => {
                setToast(null);
            }, 3000); // Clear toast after 3 seconds

            return () => clearTimeout(timer);
        }
    }, [toast]);

    const validateNewRecommendation = () => {
        let isValid = true;
        const errors = {
            title: '',
            challenge: '',
            recommendation: ''
        };
        if (!newRecommendation.title.trim()) {
            errors.title = 'Title is required';
            isValid = false;
        }
        if (!newRecommendation.challenge.trim()) {
            errors.challenge = 'Challenges are required';
            isValid = false;
        }
        if (!newRecommendation.recommendation.trim()) {
            errors.recommendation = 'Recommendation is required';
            isValid = false;
        }
        setNewErrors(errors);
        return isValid;
    };// PS_challengesRecommendation_17, PS_challengesRecommendation_31

    const handleSaveRecommendation = async (isRegenerated: boolean) => {
        if (validateNewRecommendation()) {// PS_challengesRecommendation_17
            const data = {
                organizationId: state.organizationId,
                verticalId: state.verticalId,
                projectId: state.projectId,
                connectorId: state.connectorId,
                ...newRecommendation,
                isRegenerated
            };
            try {
                if (!isRegenerated) {
                    setShowSavingPopup(true);
                }
                // PS_challengesRecommendation_18 - 21, 
                const response = await PostChallengesRecommendations(data);

                if (response.status === 200) {
                    if (isRegenerated) {
                        const regeneratedData = response.data.challengesAndRecommendations;

                        // Update the state with regenerated data
                        setNewRecommendation({
                            title: regeneratedData.title || newRecommendation.title,
                            challenge: regeneratedData.challenge || newRecommendation.challenge,
                            recommendation: regeneratedData.recommendation || newRecommendation.recommendation
                        });

                    } else {
                        setToast({ toastType: 'success', toastBodyMessage: 'Recommendation added successfully' });
                        await getInitialPageData()
                        setNewRecommendation({ title: '', challenge: '', recommendation: '' }); // Clear the form
                    }
                    setIsChallengesRecommendationsDataUpdated(true);

                } else {
                    setToast({ toastType: 'error', toastBodyMessage: `Failed to ${isRegenerated ? 'regenerate' : 'add'} recommendation` });
                }
            } catch (error) {
                setToast({ toastType: 'error', toastBodyMessage: `An error occurred while ${isRegenerated ? 'regenerating' : 'adding'}` });
            } finally {
                setShowSavingPopup(false);
                setIsLoading(false);
            }
        }
    };

    const handleNewRecommendationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setNewRecommendation(prevForm => ({ ...prevForm, [name]: value }));
        setNewErrors(prevErrors => ({ ...prevErrors, [name]: '' })); // Clear errors if any
    };

    const handleClearNewRecommendationForm = () => {
        setNewRecommendation({ title: '', challenge: '', recommendation: '' });
        setNewErrors({ title: '', challenge: '', recommendation: '' });
    };

    // PS_challengesRecommendation_40 -42
    const handleDelete = (item: ChallengeRecommendation) => {
        setItemToDelete(item);
        setShowDeletePopup(true);
    };
    // PS_challengesRecommendation_44
    const confirmDelete = async () => {
        setIsLoading(true);
        if (itemToDelete) {
            const payload = {
                challengesRecommendationId: itemToDelete.challengesRecommendationId
            }

            try {
                const response = await DeleteChallengesRecommendations(payload); // PS_challengesRecommendation_45-48
                if (response.status === 200) {
                    setChallengesRecommendationsData(prevData =>
                        prevData.filter(item => item.challengesRecommendationId !== itemToDelete.challengesRecommendationId)
                    );// PS_challengesRecommendation_49
                    setIsChallengesRecommendationsDataUpdated(true);
                    setToast({ toastType: 'success', toastBodyMessage: 'Item deleted successfully' }); // PS_challengesRecommendation_50
                } else {
                    setToast({ toastType: 'error', toastBodyMessage: 'Failed to delete item' }); // PS_challengesRecommendation_50
                }
            } catch (error) {
                setToast({ toastType: 'error', toastBodyMessage: 'An error occurred while deleting' });// PS_challengesRecommendation_51
            } finally {
                setIsLoading(false);
            }
        }
        setShowDeletePopup(false);
        setItemToDelete(null);
    };

    const handleCloseDeletePopup = () => {
        setShowDeletePopup(false); // This function will close the delete popup
    };

    // PS_challengesRecommendation_26-28
    const handleEditClick = (challenge: ChallengeRecommendation) => {
        setEditRecommendation(challenge);
        setShowEditPopup(true);
    };

    const EditRecommendationPopup: React.FC<EditRecommendationPopupProps> = ({ isVisible, onClose }) => {
        const [editForm, setEditForm] = useState({
            title: editRecommendation?.title || '',
            challenge: editRecommendation?.challenge || '',
            recommendation: editRecommendation?.recommendation || ''
        });

        const [userEdits, setUserEdits] = useState({
            title: '',
            challenge: '',
            recommendation: ''
        });

        const [editFormErrors, setEditFormErrors] = useState({
            title: '',
            challenge: '',
            recommendation: ''
        });

        useEffect(() => {
            if (editRecommendation) {
                setEditForm({
                    title: editRecommendation.title || '',
                    challenge: editRecommendation.challenge || '',
                    recommendation: editRecommendation.recommendation || ''
                });
                setUserEdits({
                    title: '',
                    challenge: '',
                    recommendation: ''
                });
            }
        }, [editRecommendation]);

        const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target;
            setEditForm(prevForm => ({
                ...prevForm,
                [name]: value
            }));
            setUserEdits(prevEdits => ({
                ...prevEdits,
                [name]: value
            }));
        };

        const handleClearEditForm = () => {
            setEditForm({
                title: '',
                challenge: '',
                recommendation: ''
            });
            setEditFormErrors({
                title: '',
                challenge: '',
                recommendation: ''
            });
        };


        const validateEditForm = () => {
            let isValid = true;
            const errors = { title: '', challenge: '', recommendation: '' };

            if (!editForm.title.trim()) {
                errors.title = 'Title is required';
                isValid = false;
            }
            if (!editForm.challenge.trim()) {
                errors.challenge = 'Challenges are required';
                isValid = false;
            }
            if (!editForm.recommendation.trim()) {
                errors.recommendation = 'Recommendation is required';
                isValid = false;
            }

            setEditFormErrors(errors);
            return isValid;
        };

        // PS_challengesRecommendation_30

        const handleEditRecommendation = async (isRegenerated: boolean) => {
            if (validateEditForm()) {
                const data = {
                    organizationId: state.organizationId,
                    verticalId: state.verticalId,
                    projectId: state.projectId,
                    connectorId: state.connectorId,
                    challengesRecommendationId: editRecommendation?.challengesRecommendationId,
                    // Use the data from userEdits if available, otherwise fall back to editForm.
                    title: userEdits.title !== '' ? userEdits.title : editForm.title,
                    challenge: userEdits.challenge !== '' ? userEdits.challenge : editForm.challenge,
                    recommendation: userEdits.recommendation !== '' ? userEdits.recommendation : editForm.recommendation,
                    isRegenerated
                };

                if (!isRegenerated) {
                    onClose(); // Close the popup only when adding (not regenerating)
                }

                try {
                    if (!isRegenerated) {
                        setShowSavingPopup(true);
                    }
                    const response = await PostChallengesRecommendations(data);

                    if (response.status === 200) {
                        if (isRegenerated) {
                            const regeneratedData = response.data.challengesAndRecommendations;
                            setEditRecommendation({
                                ...regeneratedData,
                                challengesRecommendationId: editRecommendation?.challengesRecommendationId // Preserve the original ID
                            });
                            setEditForm({
                                title: regeneratedData.title || '',
                                challenge: regeneratedData.challenge || '',
                                recommendation: regeneratedData.recommendation || ''
                            });
                            setIsChallengesRecommendationsDataUpdated(true);

                        } else {
                            setToast({ toastType: 'success', toastBodyMessage: 'Recommendation updated successfully' });
                            await getInitialPageData();
                            onClose();
                        }
                    } else {
                        setToast({ toastType: 'error', toastBodyMessage: `Failed to ${isRegenerated ? 'regenerate' : 'update'} recommendation` });
                    }
                } catch (error) {
                    setToast({ toastType: 'error', toastBodyMessage: `An error occurred while ${isRegenerated ? 'regenerating' : 'updating'}` });
                } finally {
                    setShowSavingPopup(false);
                    setIsLoading(false);
                }
            }
        };

        if (!isVisible) return null;

        return (
            <>
                <div className="modal-backdrop fade show"></div>
                <div className="modal fade show" id="_editRecommendation" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content custom-popup w-650">
                            <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pb-2 pt-3">
                                <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor">
                                    Edit Recommendation
                                </h2>
                                <button type="button" className="btn" onClick={onClose}>
                                    <img src="images/popup-close.svg" alt="Close" />
                                </button>
                            </div>
                            <div className="modal-body border-0 px-4 pt-0">
                                <div className="row row-gap-4">
                                    <div className="col-12">
                                        <label htmlFor="_title" className="form-label color-black font-16 font-medium mb-2">Title <span className="mandate">*</span></label>
                                        <input
                                            type="text"
                                            id="_title"
                                            className={`form-control rounded-1 shadow-none`}
                                            placeholder="Enter Title"
                                            name="title"
                                            value={editForm.title}
                                            onChange={handleEditFormChange}
                                        />
                                        {editFormErrors.title && <div className="text-danger font-12 mt-1">{editFormErrors.title}</div>}
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="_challenges" className="form-label color-black font-16 font-medium mb-2">Challenges <span className="mandate">*</span></label>
                                        <textarea
                                            id="_challenges"
                                            className={`form-control rounded-1 shadow-none`}
                                            placeholder="Enter Challenges"
                                            name="challenge"
                                            value={editForm.challenge}
                                            onChange={handleEditFormChange}
                                        />
                                        {editFormErrors.challenge && <div className="text-danger font-12 mt-1">{editFormErrors.challenge}</div>}
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="_Recommendation" className="form-label color-black font-16 font-medium mb-2">Recommendation <span className="mandate">*</span></label>
                                        <textarea
                                            id="_Recommendation"
                                            className={`form-control rounded-1 shadow-none`}
                                            placeholder="Enter Recommendation"
                                            name="recommendation"
                                            value={editForm.recommendation}
                                            onChange={handleEditFormChange}
                                        />
                                        {editFormErrors.recommendation && <div className="text-danger font-12 mt-1">{editFormErrors.recommendation}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                                <button type="button" className="btn btn-dark theme-outline-btn regenerate-btn d-flex gap-2 align-items-center font-14 font-semibold " onClick={() => handleEditRecommendation(true)}><img src="../images/regenerate-icon.svg" alt="Regenerate-icon" />Regenerate</button>
                                <div className="d-flex justify-content-end">
                                    <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer " onClick={handleClearEditForm}>Clear</a>
                                    <button type="button" className="btn btn-dark theme-primary-btn-md border-0 font-semibold " onClick={() => handleEditRecommendation(false)} >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="ms-lg-4">
            <div className="rounded-3 p-4 mb-5 bg-grey">
                <span className="d-flex align-items-center font-16 font-semibold color-black">
                    <img src="images/chevron-down.svg" alt="accordion-arrow" className="cursor-pointer accordion-arrow me-2 collapsed" data-bs-toggle="collapse" data-bs-target="#crm_Dev" aria-expanded="false" />
                    Add New {title}
                </span>
                <div className="pt-4 collapse" id="crm_Dev" style={{}}>
                    <div className="row mt-1 row-gap-4">
                        <div className="col-12">
                            <label htmlFor="_newTitle" className="form-label color-black font-16 font-medium mb-2">Title <span className="mandate">*</span></label>
                            <input
                                type="text"
                                id="_newTitle"
                                className="form-control rounded-1 shadow-none bg-grey"
                                value={newRecommendation.title}
                                onChange={handleNewRecommendationChange}
                                name="title"
                            />
                            {newErrors.title && <div className="text-danger font-12 mt-1">{newErrors.title}</div>}
                        </div>
                        <div className="col-12">
                            <label htmlFor="_newChallenges" className="form-label color-black font-16 font-medium mb-2">Challenges <span className="mandate">*</span></label>
                            <textarea
                                id="_newChallenges"
                                className="resize-none form-control bg-grey shadow-none rounded-1 h-115px"
                                value={newRecommendation.challenge}
                                onChange={handleNewRecommendationChange}
                                name="challenge"
                            />
                            {newErrors.challenge && <div className="text-danger font-12 mt-1">{newErrors.challenge}</div>}
                        </div>
                        <div className="col-12">
                            <label htmlFor="_newRecommendation" className="form-label color-black font-16 font-medium mb-2">Recommendation <span className="mandate">*</span></label>
                            <textarea
                                id="_newRecommendation"
                                className="resize-none form-control bg-grey shadow-none rounded-1 h-115px"
                                value={newRecommendation.recommendation}
                                onChange={handleNewRecommendationChange}
                                name="recommendation"
                            />
                            {newErrors.recommendation && <div className="text-danger font-12 mt-1">{newErrors.recommendation}</div>}
                        </div>

                        <div className="col-12 d-flex align-items-center justify-content-between">
                            <button type="button" className="btn btn-dark theme-outline-btn regenerate-btn d-flex gap-2 align-items-center font-14 font-semibold bg-transparent" onClick={() => handleSaveRecommendation(true)}><img src="../images/regenerate-icon.svg" alt="Regenerate-icon" />Regenerate</button>
                            <div className="d-flex justify-content-end">
                                <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer py-2" onClick={handleClearNewRecommendationForm}>Clear</a>
                                <button type="button" className="btn btn-dark theme-primary-btn-md border-0 font-semibold py-2" onClick={() => handleSaveRecommendation(false)} >Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {challengesRecommendationsData.map((item, index) => {
                return (

                    <div key={`${item.challengesRecommendationId}-${index}`}>
                        <div className="d-flex align-items-center mt-3 mb-4 justify-content-between">
                            <p className="font-16 font-bold m-0 color-black">{item.title}</p>
                            <div className="d-flex align-items-center">
                                <button type="button" className="btn edit-btn custom-btn font-medium" onClick={() => handleEditClick(item)}>Edit</button>
                                <button type="button" className="btn btn-outline-danger custom-btn ms-3 font-medium" onClick={() => handleDelete(item)}>Delete</button>
                            </div>
                        </div>
                        <p className="font-16 font-semibold my-3 color-black">Challenges:</p>
                        <p className="font-regular font-16 color-black mt-3 mb-4">
                            {item.challenge}
                        </p>
                        <p className="font-16 font-semibold my-3 color-black">Recommendation:</p>
                        <p className="font-regular font-16 color-black mt-3 mb-4">
                            {item.recommendation}
                        </p>
                    </div>
                )
            })}
            {toast && <ToastComponent name={toast} />}

            {showDeletePopup && (
                <DeletePopup
                    setHideDeletePopup={handleCloseDeletePopup}
                    popupHeading="Delete Confirmation"
                    popupMessage={`Are you sure you want to delete "${itemToDelete?.title}"?`}
                    deleteCall={confirmDelete}
                />
            )}

            {showDeletePopup ? <div className="modal-backdrop fade show"></div> : <></>}

            {showEditPopup && editRecommendation && (
                <EditRecommendationPopup
                    isVisible={showEditPopup}
                    onClose={() => setShowEditPopup(false)}
                />
            )}


            {showSavingPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saving...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            )
            }
        </div>

    );
}

export default ChallengesRecommendations;