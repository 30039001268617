// // src/useSections.js
// import { useState, useEffect } from 'react';
// import { getSectionsApi, updateSectionsApi } from '../services/api';

// export const useSections = () => {
//   const [sectionsData, setSectionsData] = useState({ sections: [] });
//   const [selectedSection, setSelectedSection] = useState(null);

//   useEffect(() => {
//     const fetchSections = async () => {
//       try {
//         const data = await getSectionsApi();
//         setSectionsData(data);
//         if (data.sections && data.sections.length > 0) {
//           setSelectedSection(data.sections[0]);
//         }
//       } catch (error) {
//         console.error('Failed to fetch sections:', error);
//       }
//     };

//     fetchSections();
//   }, []);

//   const updateSectionContent = async (sectionId, templateId, updatedContent) => {
//     console.log("inside update sections content useSections:", sectionId, templateId, updatedContent);

//     // Common function to update sections
//     const updateSection = (updater) => {
//       const updatedSections = sectionsData.sections.map(section => {
//         if (section.id === sectionId) {
//           return updater(section);
//         }
//         return section;
//       });

//       console.log("inside update section", { ...sectionsData, sections: updatedSections });

//       setSectionsData({ ...sectionsData, sections: updatedSections });
//       return updatedSections;
//     };

//     // Template-specific updaters
//     const templateUpdaters = {
//       "FlowComponents": (section) => ({
//         ...section,
//         content: {
//           ...section.content,
//           content: updatedContent
//         }
//       }),
//       "TextEditor": (section) => ({
//         ...section,
//         content: updatedContent
//       }),
//       "TableComponent": (section) => ({
//         ...section,
//         content: {
//           ...section.content,
//           table: updatedContent
//         }
//       }),
//       "SessionContentEditor": (section) => ({
//         ...section,
//         content: updatedContent
//       })
//     };

//     // Default updater if templateId doesn't match any known template
//     const defaultUpdater = (section) => section;

//     // Get the appropriate updater function
//     const updater = templateUpdaters[templateId] || defaultUpdater;

//     // Update sections
//     const updatedSections = updateSection(updater);

//     // Persist updated sections
//     try {
//       let payload = {
//         ...sectionsData,
//         sections: updatedSections
//       }
//       console.log(payload, "Payloaddd here")
//       let res = await updateSectionsApi(payload);
//       console.log(res, "Response")
//     } catch (error) {
//       console.error('Error updating sections:', error);
//     }
//   };

//   const addSection = async (templateId, content) => {
//     const newSection = {
//       id: String(Date.now()),
//       templateId,
//       content,
//     };
//     const updatedSections = [...sectionsData.sections, newSection];
//     setSectionsData({ ...sectionsData, sections: updatedSections });

//     try {
//       await updateSectionsApi({ ...sectionsData, sections: updatedSections });
//     } catch (error) {
//       console.error('Error adding section:', error);
//     }
//   };

//   return {
//     sectionsData,
//     selectedSection,
//     setSelectedSection,
//     updateSectionContent,
//     addSection
//   };
// };



// src/useSections.js
import { useState, useEffect } from 'react';
import { getSectionsApi, htmlToPdf, updateSectionsApi } from '../../Service/api';
import { composeHTMLReport } from './reportUtils';


export const useSections = () => {
  const [sectionsData, setSectionsData] = useState({ sections: [] });
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        // const data = await getSectionsApi();
        // setSectionsData(data);
        // if (data.sections && data.sections.length > 0) {
        //   setSelectedSection(data.sections[0]);
        // }
      } catch (error) {
        console.error('Failed to fetch sections:', error);
      }
    };

    fetchSections();
  }, []);

  const updateSectionContent = async (sectionId, templateId, updatedContent) => {
    console.log("inside update sections content useSections:", sectionId, templateId, updatedContent);

    // Common function to update sections
    const updateSection = (updater) => {
      const updatedSections = sectionsData.sections.map(section => {
        if (section.id === sectionId) {
          return updater(section);
        }
        return section;
      });

      console.log("inside update section", { ...sectionsData, sections: updatedSections });

      setSectionsData({ ...sectionsData, sections: updatedSections });
      return updatedSections;
    };

    // Template-specific updaters
    const templateUpdaters = {
      "FlowComponents": (section) => ({
        ...section,
        content: {
          ...section.content,
          content: updatedContent
        }
      }),
      "TextEditor": (section) => ({
        ...section,
        content: updatedContent
      }),
      "TableComponent": (section) => ({
        ...section,
        content: {
          ...section.content,
          table: updatedContent
        }
      }),
      "SessionContentEditor": (section) => ({
        ...section,
        content: updatedContent
      }),
      "ChallengesImages": (section) => ({
        ...section,
        content: updatedContent
      }),
      "Recommendations": (section) => ({
        ...section,
        content: {
          ...section.content,
          content: updatedContent
        }
      })
    };

    // Default updater if templateId doesn't match any known template
    const defaultUpdater = (section) => section;

    // Get the appropriate updater function
    const updater = templateUpdaters[templateId] || defaultUpdater;

    // Update sections
    const updatedSections = updateSection(updater);

    // Persist updated sections
    try {
      let payload = {
        ...sectionsData,
        sections: updatedSections
      }
      console.log(payload, "Payloaddd here")
      // let res = await updateSectionsApi(payload);
      // console.log(res, "Response")
    } catch (error) {
      console.error('Error updating sections:', error);
    }
  };

  const addSection = async (templateId, content) => {
    const newSection = {
      id: String(Date.now()),
      templateId,
      content,
    };
    const updatedSections = [...sectionsData.sections, newSection];
    setSectionsData({ ...sectionsData, sections: updatedSections });

    try {
      // await updateSectionsApi({ ...sectionsData, sections: updatedSections });
    } catch (error) {
      console.error('Error adding section:', error);
    }
  };

  const  handleGenerateReport = async () => {
    const htmlReport = composeHTMLReport(sectionsData.sections);
    try {
      // const response = await htmlToPdf(htmlReport);
      // if (response && response.link) {
      //   alert(`Report generated! View PDF at: ${response.link}`);
      // } else {
      //   alert('Failed to generate report.');
      // }
    } catch (error) {
      console.error('Error generating report:', error);
      alert('There was an error generating the report.');
    }
  };

  return {
    sectionsData,
    selectedSection,
    setSelectedSection,
    updateSectionContent,
    addSection,
    handleGenerateReport
  };
};