import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import WorkflowNav from "./WorkflowNav";
import WorkflowLeftNav from "./WorkflowLefNav";
import WorkflowMain from "./WorkflowMain";
import { useEffect, useState } from "react";
import { getActionItemApi, getAssessmentStatusApi, getScopeApi, getSessionDataApi, postErrorLog } from "../../Service/api";
import { useOrgContext } from "../Context/Context";


function WorkflowWrapper() {

    const navigate = useNavigate();
    const location = useLocation();
    const { assessmentId, status, assessmentName } = location.state;
    const { deviation, setDeviation } = useOrgContext();

    // State variables
    const [workloadId, setWorkloadId] = useState('');
    const [reportId, setReportId] = useState('');
    const [sessionData, setSessionData] = useState([]);
    const [scopeData, setScopeData] = useState([]);
    const [preProcess, setPreProcess] = useState([]);
    const [postProcess, setPostProcess] = useState([]);
    const [progress, setProgress] = useState(0);
    const [selectedSessionId, setSelectedSessionId] = useState(sessionData && sessionData.length ? sessionData[0].session_id : null);
    const userId = localStorage.getItem('userId')




    console.log(location.state, "locationData");
    //--------------------------------------------------
    const sampleSession = [
        {
            "session_id": "1",
            "session": "Day 0",
            "toggle": {
                "process": "preprocess",
                "value": "true"
            }
        },
        {
            "session_id": "2",
            "session": "Day 1",
            "toggle": {
                "process": "preprocess",
                "value": "true"
            }
        },
        {
            "session_id": "3",
            "session": "Day 2",
            "toggle": {
                "process": "preprocess",
                "value": "true"
            }
        }
    ]

    const sampleScope =
    {
        "scope": {
            "inscope": "Full Recommendations Document A detailed summary from the discovery sessions including justification ",
            "outscope": "Recommendations will be high-level and won't include detailed data schema designs or low-level artifacts."
        },
        "progress": "40",
        "deviation": "There is some deviation in the scope of the assessment"
    }


    const sampleActionItems = 
        {
            "pre_process":
            [
                {
                    "attribute_id": "865",
                    "attribute_name": "POW",
                    "checklist_id": "2",
                    "checklist": [
                        {
                            "checklist_item": "The sow should have proper alignment and should be able to define the business",
                            "is_checked": "false",
                            "is_skip": "true",
                            "reason": "Pending"
                        },
                        {
                            "checklist_item": "The sow should have proper alignment and should be able to define the business",
                            "is_checked": "true",
                            "is_skip": "false",
                            "reason": ""
                        }
                    ],
                    "is_default": "True",
                    "is_automated": "False",
                    "file_name": "row.pdf",
                    "file_size": ""
                },
                {
                    "attribute_id": "413",
                    "attribute_name": "SOW",
                    "checklist_id": "8",
                    "checklist": [
                        {
                            "checklist_item": "The sow should have proper alignment and should be able to define the business",
                            "is_checked": "false",
                            "is_skip": "false",
                            "reason": ""
                        }
                    ],
                    "is_default": "False",
                    "is_automated": "True",
                    "file_name": "row.pdf",
                    "file_size": "15mb"
                },
                {
                    "attribute_id": "689",
                    "attribute_name": "Business Flow",
                    "checklist_id": "7",
                    "checklist": [
                        {
                            "checklist_item": "The sow should have proper alignment and should be able to define the business",
                            "is_checked": "true",
                            "is_skip": "false",
                            "reason": "Applicable"
                        }
                    ],
                    "is_default": "False",
                    "is_automated": "True",
                    "file_name": "",
                    "file_size": ""
                },
                {
                    "attribute_id": "870",
                    "attribute_name": "Architecture",
                    "checklist_id": "3",
                    "checklist": [
                        {
                            "checklist_item": "The sow should have proper alignment and should be able to define the business",
                            "is_checked": "true",
                            "is_skip": true,
                            "reason": 'Pending'
                        }
                    ],
                    "file_name":"", 
                    "file_size":"", 
                    "is_default":"False", 
                    "is_automated":"False",
                },
            ],

            "post_process": [
                {
                    "attribute_id": "456",
                    "attribute_name": "transcript",
                    "checklist_id": "2",
                    "checklist": [
                        {
                            "checklist_item": "The transcirpt should be in document format",
                            "is_checked": "false",
                            "is_skip": "false",
                            "reason": ""
                        }
                    ],
                    "is_default": "True",
                    "is_automated": "True",
                    "file_name": "sow.pdf"
                }
            ]

        }
    

    const sampleattributes =
        [{
            "attribute_id": "1",
            "attribute_name": "Architecture diagram"
        },
        {
            "attribute_id": "2",
            "attribute_name": "Current state infrastructure"
        }]

    useEffect(() => {
        setSessionData(sampleSession);
        setScopeData(sampleScope.scope);
        setProgress(sampleScope.progress);
        setDeviation(sampleScope.deviation);
        setPreProcess(sampleActionItems.pre_process);
        setPostProcess(sampleActionItems.post_process);

    }, []);


    //-------------------------------------------------
    // SQ_NO_1.0 - Fetch initial assessment status
    useEffect(() => {
        getAssessmentStatus();
    }, []);

    // SQ_NO_2.0 - Fetch scope data
    useEffect(() => {
        if (workloadId) {
            getScopeData();
        }
    }, [workloadId]);

    // SQ_NO_3.0 - Fetch action items
    useEffect(() => {
        if (workloadId && reportId) {
            getActionItem();
        }
    }, [workloadId, reportId]);

    // SQ_NO_1.0 - Get assessment status and session data
    const getAssessmentStatus = async () => {
        try {
            const payload = {
                user_id: userId,
                assessment_id: assessmentId,
                status: status
            };
            // const response = await getAssessmentStatusApi(payload);
            // if (response.status === 200) {
            //     setWorkloadId(response.data.workflow_id);
            //     setReportId(response.data.report_id);
            //     const sessionResponse = await getSessionDataApi(response.data.workflow_id, response.data.report_id);
            //     if (sessionResponse.status === 200) {
            //         setSessionData(sessionResponse.data);
            //     }
            // }

            //setSessionData(sampleSession)
        } catch (error) {
            handleError(error, 'getAssessmentStatus');
        }
    };

    // SQ_NO_2.0 - Get scope data
    const getScopeData = async () => {
        try {
            const payload = {
                user_id: 'UUID',
                assessment_id: assessmentId,
                workflow_id: workloadId
            };
            // const response = await getScopeApi(payload);
            // if (response.status === 200) {
            //     setScopeData(response.data.scope);
            //     setProgress(response.data.progress);
            //     setDeviation(response.data.deviation);
            // }    
            // setScopeData(sampleScope.scope)
            // setProgress(sampleScope.progress)
            // setDeviation(sampleScope.deviation)
        } catch (error) {
            handleError(error, 'getScopeData');
        }
    };

    // SQ_NO_3.0 - Get action items
    const getActionItem = async () => {
        try {
            const payload = {
                user_id: 'UUID',
                assessment_id: assessmentId,
                workflow_id: workloadId,
                session_id: sessionData?.session_id
            };
            // const response = await getActionItemApi(payload);
            // if (response.status === 200) {
            //     setPreProcess(response.data.pre_process);
            //     setPostProcess(response.data.post_process);
            // }
            setPreProcess(sampleActionItems.pre_process)
            setPostProcess(sampleActionItems.post_process)
        } catch (error) {
            handleError(error, 'getActionItem');
        }
    };

    // SQ_NO_2.2 - Handle Error
    const handleError = (error, functionName) => {
        const errorData = {
            user_id: userId,
            type: "error",
            error_module_name: functionName,
            error_description: error?.message + " ," + error?.response?.data?.body?.message
        };
        postErrorLog(errorData);
    };

    const handleSessionSelect = (sessionId) => {
        setSelectedSessionId(sessionId);
    };

    console.log(sessionData, "sessionDatain Wrapper");
    console.log(progress, "progress");
    console.log(scopeData, "scopeData");
    console.log(preProcess, "preProcessdkd");

    useEffect(()=>{
        console.log(preProcess,"Preprocessin Wrapper");

        
    },[preProcess])
    








    return (
        <>

            {/* <Header/> */}
            <div className="container-fluid p-4 content-container">
                <div className="row">
                    <Breadcrumbs assessmentName={assessmentName} />
                </div>
                <WorkflowNav
                    assessmentName={assessmentName}
                    scopeData={scopeData}
                    reportId={reportId}
                />

                <div className="row">
                    <WorkflowLeftNav
                        sessionData={sessionData}
                        reportId={reportId}
                        workloadId={workloadId}
                        scopeData={scopeData}
                        progress={progress}
                        deviation={deviation}
                        onSessionSelect={handleSessionSelect}
                    />

                    <WorkflowMain
                        preProcess={preProcess}
                        postProcess={postProcess}
                        getActionItems={getActionItem}
                        assessmentId={assessmentId}
                        workloadId={workloadId}
                        selectedSessionId={selectedSessionId}
                        getActionItem={getActionItem}
                        assessmentName={assessmentName}
                    />
                </div>
            </div>
        </>

    );
}
export default WorkflowWrapper;