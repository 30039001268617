import { createContext } from 'react';
import { EncryptStorage } from 'encrypt-storage';
import { getConnectorsData, getReportData } from '../helpers/encryption';
import { jwtDecode } from 'jwt-decode';
import { getInfraInventoryDetails } from '../service/InfraAssessmentApi';

export const UserDetails: any = createContext(undefined);

export const encryptStorage = new EncryptStorage(process.env.REACT_APP_ENC_STORAGE_KEY!, JSON.parse(getReportData(process.env.REACT_APP_ENC_ALG!)));

export const baseUrl: string = "https://npapi.zebpulse.com";
export const baseUrl2: string = "http://localhost:8080";
export const baseUrl3: string = "http://localhost:8081";

let value = encryptStorage.getItem("jwt");
let parsedJtk: any = ""
let storageAccountName: any;
let containerName: any;
if (value) {
  parsedJtk = jwtDecode(value)
  storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
  containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
}

// assessment endpoints 
export const assesmentEndpoints = {
  getAllOrganization: "/assessments/list",
  getOrganization: "/assessments/editlist",
  getIndustryDropDown: "/assessments/industrytypes",
  getEmployeeRange: "/assessments/employees",
  getRegionDropDown: "/assessments/regions",
  fetchProgressDetails: "/connectors/trackAiQuestionnarieProgress",
  getVerticalDropDown: "/assessments/verticals",
  postOrganization: "/assessments/startnew",
  deleteOrganization: "/assessments/deleteOrganization",
  deleteVerticals: "/assessments/deleteVerticals",
  sasToken: "/assessments/sasToken",
};

// initiative and roadmap endpoints
export const initiaitveEndpoints = {
  getAllMilestone: "/assessments/organization/roadmap/initiative/milestone/list",
  cancelInitiative: "/assessments/organization/roadmap/initiative/milestone/cancel",
  postInitiative: "/assessments/organization/roadmap/initiative",
  deleteMilestone: "/assessments/organization/roadmap/initiative/milestone/delete",
  postMilestone: "/assessments/organization/roadmap/initiative/milestone/create",
  getRoles: "/assessments/milestone/roles",
  getRoadMapData: "/assessments/organization/roadmap",
  getInitiativeData: "/assessments/organization/roadmap/sequencing",
  getInitiative: "/assessments/initiative/edit"
}


export const reportEndpoints = {
  getReport: "/report",
  deleteReport: "/connectors/project/delete",
  downloadReport: "/report/downloadReport",
  downloadPdf: "/Zebpulse-Cloudgen-llm-api/converthtmltopdf"
};

// admin endpoints 
export const AdminEndpoints = {
  getNavbar: "/admin/lateral/list",
  getQuestions: "/admin/questions/list",
  postQuestions: "/admin/questions/upload",
  updateQuestions: "/admin/questions/upload",
  deleteQuestions: "/admin/questions/delete"
}

export const QuestionnaireEndpoints = {
  getLeftNav: "/connectors/list/vertical",
  getControlArea: "/connectors/controlareas/list",
  getQuestions: "/connectors/questionnaire",
  getProject: "/connectors/projects",
  getConnectedConnector: "/connectors/list",
  postConnector: "/connectors/add",
  postQuestions: "/connectors/controlareas/questions",
  deleteConnector: "/connectors/delete",
  addProject: "/connectors/project/add",
  deleteProject: "/connectors/project/delete",
  postQuestionsReport: "/connectors/questionnaire/report",
  getGenerateReportButton: "/connectors/generateReportButton",
  postReassess: "/assessments/reassess",
  getSalesforce: "/connectors/getSalesforceCloud",
  postSalesforce: "/connectors/postSalesforceCloud",
  getServiceNow: "/connectors/getServiceNowDropdowns",
}

export const authEndpoints = {
  generateToken: "/auth/generatetoken",
  logoutApi: "/auth/deletetoken",
}

export const infraAssessmentEndpoints = {
  getInfraAssessmentMenus: "/connectors/infraAssessment/getInfraAssessmentMenus",
  getInfraAssessmentSubmenus: "/connectors/infraAssessment/getInfraAssessmentSubmenus",
  getInfraInventoryDetails: "/connectors/infraAssessment/getInventoryDetails",
  postInfraInventoryDetails: "/connectors/infraAssessment/postInventoryDetails",
  postInfraQuestionDetails: "/connectors/infraAssessment/postInfraQuestions",
  postInfraApplicationDetails: "/connectors/infraAssessment/postApplicationDetails",
  getInfraQuestions: "/connectors/infraAssessment/getInfraAssessmentQuestions",
  deleteInfraInventoryDetails: "/connectors/infraAssessment/deleteInventoryDetails",
  deleteInfraApplicationDetails: "/connectors/infraAssessment/deleteApplicationDetails",
  getInfraAssessmentApplications: "/connectors/infraAssessment/getApplicationDetails",
  getMigrationPhaseDropdown: "/assessments/infraAssessment/getAllMigrationPhases",
  postMigrationPlanDetails: "/assessments/infraAssessment/postMigrationPlanDetails",
  getMigrationPlans: "/assessments/infraAssessment/getMigrationPlans",
  getInfraAssessmentQuestions: "/connectors/infraAssessment/getInfraAssessmentQuestions",
  postMigrationStrategyAnswers: "/connectors/infraAssessment/postMigrationStrategyAnswers",
  DeleteMigrationPlanDetails: "/assessments/infraAssessment/deleteMigrationPlan",
  reportGenerationButton: "/connectors/infraAssessment/reportGenerationButton",
  getMigrationProcessDropdown: "/assessments/infraAssessment/getAllMigrationProcess",
  getMigrationStrategyDropdown: "/report/strategyList",
  UpdateMigrationStrategy: "/connectors/updateMigrationStrategy"
}

export const fileNameSlice = (value: any) => {
  if (typeof value !== 'string') return
  let urlName;
  if (value?.includes('/')) {
    if (value.includes('?')) {
      urlName = value.includes('-iiiii-') ?
        value.split('/')[value.split('/').length - 1].split("?")[0].split('-iiiii-')[1]
        : value.split('/')[value.split('/').length - 1].split("?")[0]
    }
    else {
      urlName =
        value.includes('-iiiii-') ?
          value.split('/')[value.split('/').length - 1].split('-iiiii-')[1]
          : value.split('/')[value.split('/').length - 1]
    }
  }
  else {
    urlName = value
  }
  return decodeURI(urlName)
}


// Grouped all the connector template urls
export const templateUrls = {
  //Connector templates starts here
  dsConnectorSolarwinds: `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Solarwinds Orion-Template.xlsx`,
  infraConnectorSolarwinds: `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/SolarWinds Infra Template.xlsx`,
  infraConnectorAzure: `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Azure Template - Infra connector.xlsx`,
  infraConnectorAws: `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/AWS template - Infra connector.xlsx`,
  snConnectorItam: `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/ITAM - Template.xlsx`,
  snConnectorEnv: `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Environment - Template.xlsx`,
  //Connector templates ends here
}
