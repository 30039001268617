// // ContentDisplay.jsx
// import React from 'react';
// import FlowComponents from './reportComponents/FlowComponents';
// import ChallengesRecommendations from './reportComponents/ChallengesRecommendations';
// import DynamicTable from './reportComponents/DynamicTable';
// import TextEditorTemplate from './reportComponents/TextEditorTemplate';
// import SessionContentEditor from './reportComponents/SessionContentEditor'; // Adjust the path as necessary

// const ContentDisplay = ({ section, updateSectionContent }) => {
//   console.log(section, "Section IDD");
//   if (!section) {
//     return <div className="text-gray-500">Select a section to see its content</div>;
//   }

//   const handleUpdateSectionContent = (updatedContent) => {
//     console.log("inside handleupdatesectioncontent contentDisplay.js update section is called for ", section.id, section.templateId, updatedContent);
//     updateSectionContent(section.id, section.templateId, updatedContent);
//   };

//   return (
//     <div>
//       <h2 className="text-2xl font-bold mb-4">{section.content.title}</h2>
//       {section.templateId === 'FlowComponents' ? (
//         <FlowComponents
//           sectionName={section.content.title}
//           title={section.content.flowName}
//           content={section.content.content}
//           updateSectionContent={handleUpdateSectionContent}
//         />
//       ) : section.templateId === 'ChallengesRecommendations' ? (
//         <ChallengesRecommendations
//           title={section.content.title}
//           content={section.content.content}
//           updateSectionContent={handleUpdateSectionContent}
//         />
//       ) : section.templateId === 'TableComponent' ? (
//         <DynamicTable
//           tableData={section.content.table}
//           updateSectionContent={handleUpdateSectionContent}
//           templateId={section.templateId}
//           sectionId={section.id}
//         />
//       ) : section.templateId === 'TextEditor' ? (
//         <TextEditorTemplate
//           initialContent={section.content.content}
//           onContentChange={(updatedContent) =>
//             handleUpdateSectionContent({ ...section.content, content: updatedContent })
//           }
//         />
//       ) : section.templateId === 'SessionContentEditor' ? (
//         <SessionContentEditor
//           initialSections={section.content}
//           onSectionsChange={handleUpdateSectionContent}
//         />
//       ) : (
//         <p>{section.content.content}</p>
//       )}
//     </div>
//   );
// };

// export default ContentDisplay;



// ContentDisplay.jsx
import React from 'react';
import FlowComponents from './FlowComponents';
import ChallengesRecommendations from './ChallengesRecommendations';
import Recommendations from './Recommendations';
import DynamicTable from './DynamicTable';
import TextEditorTemplate from './TextEditorTemplate';
import SessionContentEditor from './SessionContentEditor';

const ContentDisplay = ({ section, updateSectionContent }) => {
  console.log(section, "Section IDD");
  if (!section) {
    return <div className="text-gray-500">Select a section to see its content</div>;
  }

  const handleUpdateSectionContent = (updatedContent) => {
    console.log("inside handleupdatesectioncontent contentDisplay.js update section is called for ", section.id, section.templateId, updatedContent);
    updateSectionContent(section.id, section.templateId, updatedContent);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">{section.content.title}</h2>
      {section.templateId === 'FlowComponents' ? (
        <FlowComponents
          sectionName={section.content.title}
          title={section.content.flowName}
          content={section.content.content}
          updateSectionContent={handleUpdateSectionContent}
        />
      ) : section.templateId === 'ChallengesRecommendations' ? (
        <ChallengesRecommendations
          title={section.content.title}
          content={section.content.content}
          updateSectionContent={handleUpdateSectionContent}
        />
      ) : section.templateId === 'ChallengesImages' ? (
        // <ChallengesImages
        //   title={section.content.title}
        //   content={section.content.content}
        //   updateSectionContent={handleUpdateSectionContent}
        // />
        <>
        </>
      ) : section.templateId === 'Recommendations' ? (
        <Recommendations
          title={section.content.title}
          content={section.content.content}
          updateSectionContent={handleUpdateSectionContent}
        />
      ) : section.templateId === 'TableComponent' ? (
        <DynamicTable
          tableData={section.content.table}
          updateSectionContent={handleUpdateSectionContent}
          templateId={section.templateId}
          sectionId={section.id}
        />
      ) : section.templateId === 'TextEditor' ? (
        <TextEditorTemplate
          initialContent={section.content.content}
          onContentChange={(updatedContent) =>
            handleUpdateSectionContent({ ...section.content, content: updatedContent })
          }
        />
      ) : section.templateId === 'SessionContentEditor' ? (
        <SessionContentEditor
          initialSections={section.content}
          onSectionsChange={handleUpdateSectionContent}
        />
      ) : (
        <p>{section.content.content}</p>
      )}
    </div>
  );
};

export default ContentDisplay;