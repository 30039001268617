import react, { useState, useEffect, useContext, useRef } from "react";
import TextEditor from "./TextEditor";
import SavingToast from "../SavingComponent";
import StarRating from "./StarRating";
import { Assessment, AssessmentItem, Module, ModulesResponse } from "../../interface/DatabricksReportModel";
import { AddDatawarehouseArchitecture, DeleteDatawarehouseArchitecture, GetDatawarehouseArchitecture, PostDatawarehouseArchitecture } from "../../service/QuestionnaireApi";
import ToastComponent from "../ToastComponent";
import DeletePopup from "../DeletePopup";
import Loader from "../Loader";

const ExecutiveSummaryRating = (props: any) => {
    const {
        verticalName,
        summaryName,
        summary,
        postSummary,
        showToasterPopup,
        reportData,
        setIsLoading
    } = props;

    //PS_DatawarehouseArchitecture_69 - PS_DatawarehouseArchitecture_70

    const [isEditing, setIsEditing] = useState<boolean>(summary?.length === 0);
    const [isUpload, setIsUpload] = useState<boolean>(summary?.length === 0);
    const [value, setValue] = useState<string>(summary);
    const [modulesData, setModulesData] = useState<ModulesResponse | null>(null);
    const [newAssessments, setNewAssessments] = useState<{ [key: string]: Assessment }>({});
    const [editingAssessment, setEditingAssessment] = useState<{ [key: string]: Assessment }>({});
    const [newArchitectureName, setNewArchitectureName] = useState("");
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: { assessmentName: string, rating: string, risk: string, description: string } }>({});
    const [isAddDataWarehouseArchitectureModalOpen, setIsAddDataWarehouseArchitectureModalOpen] = useState(false);
    const [editingDataWarehouseAssessmentId, setEditingDataWarehouseAssessmentId] = useState<string | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<{ datawarehouseArchitectureId: string; architectureModuleId?: string } | null>(null);
    const [showToast, setShowToast] = useState(false);
    const [toastProps, setToastProps] = useState({ toastType: '', toastBodyMessage: '' });
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [showDeletingToasterLoading, setShowDeletingToasterLoading] = useState(false);


    const shouldShowMandatoryAsterisk = (
        summaryName: string,
        verticalName: string
    ): boolean => {
        return true; // Asterisk for all other cases
    };

    const isMandatory = shouldShowMandatoryAsterisk(summaryName, verticalName);

    useEffect(() => {
        setValue(summary);
        setIsEditing(summary?.length === 0);
        setIsUpload(summary?.length === 0);
    }, [summary]);

    useEffect(() => {
        fetchArchitectureAssessment();
    }, []);


    const openAddDataWarehouseArchitectureModal = () => {
        setIsAddDataWarehouseArchitectureModalOpen(true);
    };



    const onSave = (value: string, summaryType: string) => {
        postSummary(value);
        setIsEditing(false);
    };


    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const fetchArchitectureAssessment = async () => {
        const payload = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId

        };
        try {
            setIsLoading(true)
            const response = await GetDatawarehouseArchitecture(payload);
            if (response.status === 200) {
                setModulesData(response.data);
                setIsLoading(false)
            }
            else {
                setToastProps({
                    toastType: 'error',
                    toastBodyMessage: 'Api failed'
                });
            }
        } catch (error) {
            setIsLoading(false)
            setToastProps({
                toastType: 'error',
                toastBodyMessage: 'Api failed'
            });

        }
    };

    const validateAssessment = (moduleId: string, assessment: Assessment) => {
        const errors = {
            assessmentName: "",
            rating: "",
            risk: "",
            description: "",
        };

        if (!assessment.assessmentName) errors.assessmentName = "Assessment name is required";
        if (!assessment.rating) errors.rating = "Rating is required";
        if (!assessment.risk) errors.risk = "Risk is required";
        if (!assessment.description) errors.description = "Description is required";

        setValidationErrors(prev => ({
            ...prev,
            [moduleId]: errors
        }));

        return Object.values(errors).every(error => error === "");
    };

    //PS_DatawarehouseArchitecture_102 - PS_DatawarehouseArchitecture_116
    const handlePostDatawareArchitecture = async (moduleId: string, subModuleId: string = "") => {
        const assessment = editingDataWarehouseAssessmentId
            ? editingAssessment[moduleId]
            : newAssessments[moduleId] || {};

        if (!validateAssessment(moduleId, assessment)) return;
        const payload = {
            datawarehouseArchitectureId: assessment.datawarehouseArchitectureId || "",
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            architectureModuleId: moduleId,
            architectureSubModuleId: subModuleId,
            assessmentName: assessment.assessmentName,
            rating: assessment.rating,
            risk: assessment.risk,
            description: assessment.description,
        };
        try {

            setShowToasterLoading(true);
            const response = await PostDatawarehouseArchitecture(payload);
            if (response.status === 200) {
                setToastProps({
                    toastType: 'success',
                    toastBodyMessage: 'Architecture Details are saved successfully'
                });
            } else {
                setToastProps({
                    toastType: 'error',
                    toastBodyMessage: 'Api failed'
                });
            }
            setNewAssessments(prev => {
                const newState = { ...prev };
                delete newState[moduleId];
                return newState;
            });
            setValidationErrors(prev => {
                const newState = { ...prev };
                delete newState[moduleId];
                return newState;
            });
            setEditingDataWarehouseAssessmentId(null);
            fetchArchitectureAssessment();

        } catch (error) {
            setToastProps({
                toastType: 'error',
                toastBodyMessage: 'Api failed'
            });
        } finally {
            setShowToasterLoading(false); // Hide the saving toast
            setShowToast(true);
            setTimeout(() => setShowToast(false), 3000);
        }
    };

    //PS_DatawarehouseArchitecture_80 - PS_DatawarehouseArchitecture_96
    const handleAddDataWarehouseArchitecture = async () => {
        if (!newArchitectureName.trim()) {
            alert("Please enter an architecture name");
            return;
        }

        try {
            setIsAddDataWarehouseArchitectureModalOpen(false); // Close the modal
            setShowToasterLoading(true); // Show the saving toast

            const payload = {
                moduleName: newArchitectureName,
                organizationId: reportData.organizationId,
                verticalId: reportData.verticalId,
                projectId: reportData.projectId
            };
            const response = await AddDatawarehouseArchitecture(payload);
            if (response.status === 200) {

                setToastProps({
                    toastType: 'success',
                    toastBodyMessage: 'Architecture Details are saved successfully'
                });
            } else {
                setToastProps({
                    toastType: 'error',
                    toastBodyMessage: 'Api failed'
                });
            }

            setNewArchitectureName("");
            fetchArchitectureAssessment();
        } catch (error) {
            setToastProps({
                toastType: 'error',
                toastBodyMessage: 'Api failed'
            });


        } finally {
            setShowToasterLoading(false); // Hide the saving toast
            setShowToast(true);
            setTimeout(() => setShowToast(false), 3000);
        }


    };

    const handleEditDataWarehouseAssessment = (assessment: AssessmentItem, moduleId: string) => {
        setEditingDataWarehouseAssessmentId(assessment.datawarehouseArchitectureId);
        setEditingAssessment(prev => ({
            ...prev,
            [moduleId]: {
                datawarehouseArchitectureId: assessment.datawarehouseArchitectureId,
                assessmentName: assessment.assessmentName,
                rating: assessment.rating,
                risk: assessment.risk,
                description: assessment.description,
            }
        }));
    };

    //PS_DatawarehouseArchitecture_117 - PS_DatawarehouseArchitecture_134
    const handleDeleteDatawareArchitecture = (datawarehouseArchitectureId: string, architectureModuleId?: string) => {
        setItemToDelete({ datawarehouseArchitectureId, architectureModuleId });
        setShowDeletePopup(true);
        setShowDeleteModal(true);
    };

    const confirmDeleteDatawareArchitecture = async () => {
        if (!itemToDelete) return;

        const payload = {
            datawarehouseArchitectureId: itemToDelete.datawarehouseArchitectureId,
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            architectureModuleId: itemToDelete.architectureModuleId || ""
        };
        try {
            setShowDeletingToasterLoading(true)

            const response = await DeleteDatawarehouseArchitecture(payload);

            if (response.status === 200) {
                fetchArchitectureAssessment();

                setToastProps({
                    toastType: 'success',
                    toastBodyMessage: 'Architecture deleted successfully'
                });
            } else {
                setToastProps({
                    toastType: 'error',
                    toastBodyMessage: 'Api failed'
                });
            }
        } catch (error) {
            setToastProps({
                toastType: 'error',
                toastBodyMessage: 'Failed to delete architecture'
            });
        } finally {
            setShowDeletingToasterLoading(false);
            setShowToast(true);
            setTimeout(() => setShowToast(false), 3000);
            closeDeletePopup();
        }
    };

    const closeDeletePopup = () => {
        setShowDeletePopup(false);
        setShowDeleteModal(false);
        setItemToDelete(null);
    };

    //PS_DatawarehouseArchitecture_97- PS_DatawarehouseArchitecture_101
    const handleCancelDataWarehouseAssessmentEdit = (moduleId: string) => {
        setEditingDataWarehouseAssessmentId(null);
        setEditingAssessment(prev => {
            const newState = { ...prev };
            delete newState[moduleId];
            return newState;
        });
        setValidationErrors(prev => {
            const newState = { ...prev };
            delete newState[moduleId];
            return newState;
        });
    };

    //PS_DatawarehouseArchitecture_99 
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, moduleId: string) => {
        const { name, value } = e.target;
        setNewAssessments(prev => ({
            ...prev,
            [moduleId]: {
                ...prev[moduleId],
                [name]: name === 'rating' ? parseInt(value) : value
            }
        }));
    };

    //PS_DatawarehouseArchitecture_135 - PS_DatawarehouseArchitecture_142
    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, moduleId: string) => {
        const { name, value } = e.target;
        setEditingAssessment(prev => ({
            ...prev,
            [moduleId]: {
                ...prev[moduleId],
                [name]: name === 'rating' ? parseInt(value) : value
            }
        }));
    };


    return (
        <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
                <div className="ps-lg-4">

                    <div className="d-flex justify-content-between mt-4">
                        <h3 className="font-18 font-semibold color-black mb-4">
                            {summaryName}
                            {isMandatory && <span className="mandate"> *</span>}
                        </h3>
                        <div>
                            <button
                                type="button"
                                className="btn edit-btn custom-btn font-medium px-4"
                                onClick={() => {
                                    setIsEditing(!isEditing);
                                }}
                            >
                                Edit
                            </button>
                        </div>

                    </div>

                    {isEditing ? (
                        <TextEditor
                            value={value}
                            onSave={onSave}
                            onCancel={handleEditClick}
                            summaryType={summaryName}
                            verticalName={verticalName}
                        />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: value }} />
                    )}

                    {verticalName === 'Databricks' && summaryName === 'Executive Summary' && (
                        <>
                            <div>
                                <div className="d-flex justify-content-between align-items-center my-3">
                                    <h3 className="font-18 font-semibold color-black mb-0">Modules</h3>
                                    <button type="button" className="btn edit-btn font-12 font-medium" onClick={openAddDataWarehouseArchitectureModal}>Add</button>
                                </div>
                                {modulesData?.modules?.map((module: Module) => (
                                    <div key={module.architectureModuleId} className="mb-4 custom-border-bottom">
                                        <div className="d-flex align-items-center justify-content-between font-16 font-medium color-black-v1 pb-3">
                                            <div className="d-flex align-items-center">
                                                <img src="../images/accordion-arrow-grey.svg" alt="accordion-arrow"
                                                    className="cursor-pointer accordion-arrow me-2" data-bs-toggle="collapse"
                                                    data-bs-target={`#module${module.architectureModuleId}`} aria-expanded="false" />
                                                <span className="color-black font-16 font-semibold">{module.moduleName}</span>
                                            </div>
                                            <button type="button" className="btn delete-btn font-medium font-12" onClick={() => handleDeleteDatawareArchitecture("", module.architectureModuleId)}>Delete</button>
                                        </div>
                                        <div className="collapse show" id={`module${module.architectureModuleId}`}>
                                            <div className="table-responsive mb-4 mt-3">
                                                <table className="table table-bordered themeTable alter-typo">
                                                    <thead className="font-medium">
                                                        <tr>
                                                            <th className="fw-unset">Assessment</th>
                                                            <th className="fw-unset">Rating</th>
                                                            <th className="fw-unset">Risk</th>
                                                            <th className="fw-unset">Description</th>
                                                            <th className="fw-unset text-center">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="font-regular">
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    aria-label="services"
                                                                    name="assessmentName"
                                                                    value={newAssessments[module.architectureModuleId]?.assessmentName || ''}
                                                                    onChange={(e) => handleInputChange(e, module.architectureModuleId)}
                                                                    className="form-control font-14 font-regular color-black shadow-none"
                                                                    placeholder="Enter" />
                                                                {validationErrors[module.architectureModuleId]?.assessmentName &&
                                                                    <div className="text-danger">{validationErrors[module.architectureModuleId].assessmentName}</div>}
                                                            </td>
                                                            <td>
                                                                <StarRating
                                                                    rating={newAssessments[module.architectureModuleId]?.rating || 0}
                                                                    editable={true}
                                                                    onChange={(rating: { toString: () => any; }) => handleInputChange({ target: { name: 'rating', value: rating.toString() } } as React.ChangeEvent<HTMLInputElement>, module.architectureModuleId)}
                                                                    error={validationErrors[module.architectureModuleId]?.rating}
                                                                />
                                                            </td>
                                                            <td>
                                                                <select
                                                                    name="risk"
                                                                    value={newAssessments[module.architectureModuleId]?.risk || ''}
                                                                    onChange={(e) => handleInputChange(e, module.architectureModuleId)}
                                                                    className="form-select form-control custom-form rounded-3 w-110px"
                                                                >
                                                                    <option value="" >Select</option>
                                                                    <option value="Low" style={{ color: 'green' }}>Low</option>
                                                                    <option value="Medium" style={{ color: 'orange' }}>Medium</option>
                                                                    <option value="High" style={{ color: 'red' }}>High</option>
                                                                </select>
                                                                {validationErrors[module.architectureModuleId]?.risk &&
                                                                    <div className="text-danger">{validationErrors[module.architectureModuleId].risk}</div>}

                                                            </td>
                                                            <td className="text-wrap">

                                                                <textarea
                                                                    name="description"
                                                                    value={newAssessments[module.architectureModuleId]?.description || ''}
                                                                    onChange={(e) => handleInputChange(e, module.architectureModuleId)}
                                                                    className="form-control resize-none font-14 font-regular color-black shadow-none h-60px"
                                                                    placeholder="Enter Description"
                                                                ></textarea>
                                                                {validationErrors[module.architectureModuleId]?.description &&
                                                                    <div className="text-danger">{validationErrors[module.architectureModuleId].description}</div>}

                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    type="button"
                                                                    className="border-0 add-btn d-inline-flex align-items-center justify-content-center p-0"
                                                                    onClick={() => handlePostDatawareArchitecture(module.architectureModuleId, "")}
                                                                >
                                                                    <img src="../images/plus.svg" alt="add" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        {Object.entries(module.subModules).map(([subModuleName, assessments]) => {

                                                            const validAssessments = assessments.filter((assessment: AssessmentItem) =>
                                                                assessment.datawarehouseArchitectureId &&
                                                                assessment.assessmentName
                                                            );

                                                            // Only render submodule if there are valid assessments
                                                            if (validAssessments.length === 0) return null;
                                                            return validAssessments.map((assessment: AssessmentItem) => (
                                                                <tr key={assessment.datawarehouseArchitectureId}>
                                                                    {editingDataWarehouseAssessmentId === assessment.datawarehouseArchitectureId ? (
                                                                        // Editing mode
                                                                        <>
                                                                            <td> <input
                                                                                type="text"
                                                                                name="assessmentName"
                                                                                value={editingAssessment[module.architectureModuleId]?.assessmentName || ''}
                                                                                onChange={(e) => handleEditInputChange(e, module.architectureModuleId)}
                                                                                className="form-control font-14 font-regular color-black shadow-none"
                                                                                placeholder="Enter Assessment Name"
                                                                            /></td>
                                                                            <td>
                                                                                <StarRating
                                                                                    rating={editingAssessment[module.architectureModuleId]?.rating || 0}
                                                                                    editable={true}
                                                                                    onChange={(rating: { toString: () => any; }) => handleEditInputChange({ target: { name: 'rating', value: rating.toString() } } as React.ChangeEvent<HTMLInputElement>, module.architectureModuleId)}
                                                                                    error={validationErrors[module.architectureModuleId]?.rating}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    name="risk"
                                                                                    value={editingAssessment[module.architectureModuleId]?.risk || ''}
                                                                                    onChange={(e) => handleEditInputChange(e, module.architectureModuleId)}
                                                                                    className="form-select form-control custom-form rounded-3 w-110px"
                                                                                    defaultValue={assessment.risk.toLowerCase()}
                                                                                >
                                                                                    <option value="">Select</option>
                                                                                    <option value="Low" style={{ color: 'green' }}>Low</option>
                                                                                    <option value="Medium" style={{ color: 'orange' }}>Medium</option>
                                                                                    <option value="High" style={{ color: 'red' }}>High</option>
                                                                                </select>
                                                                            </td>
                                                                            <td className="text-wrap">
                                                                                <textarea
                                                                                    name="description"
                                                                                    value={editingAssessment[module.architectureModuleId]?.description || ''}
                                                                                    onChange={(e) => handleEditInputChange(e, module.architectureModuleId)}
                                                                                    aria-label="comments"
                                                                                    className="form-control resize-none font-14 font-regular color-black shadow-none h-115px"
                                                                                    placeholder="Enter Description"

                                                                                ></textarea>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <span className="cursor-pointer" onClick={() => handlePostDatawareArchitecture(module.architectureModuleId, assessment.architectureSubModuleId)}>
                                                                                    <img src="../images/check.svg" alt="saveIcon" title="Save" />
                                                                                </span>
                                                                                <span className="ms-3 cursor-pointer" onClick={() => handleCancelDataWarehouseAssessmentEdit(module.architectureModuleId)}>

                                                                                    <img src="../images/cancel-icon.svg" alt="cancelIcon" title="Cancel" />
                                                                                </span>
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        // Display mode
                                                                        <>
                                                                            <td>{assessment.assessmentName}</td>
                                                                            <td>
                                                                                <StarRating
                                                                                    rating={assessment.rating}
                                                                                    editable={false}
                                                                                />
                                                                            </td>
                                                                            <td className={`font-medium ${assessment.risk.toLowerCase() === 'low'
                                                                                ? 'text-success'
                                                                                : assessment.risk.toLowerCase() === 'medium'
                                                                                    ? 'text-warning'
                                                                                    : 'text-danger'
                                                                                }`}>
                                                                                {assessment.risk}
                                                                            </td>
                                                                            <td className="text-wrap">{assessment.description}</td>
                                                                            <td className="text-center">
                                                                                <span className="cursor-pointer" onClick={() => handleEditDataWarehouseAssessment(assessment, module.architectureModuleId)}>

                                                                                    <img src="../images/editIcon.svg" alt="editIcon" title="Edit" />
                                                                                </span>
                                                                                <span className="ms-3 cursor-pointer" onClick={() => handleDeleteDatawareArchitecture(assessment.datawarehouseArchitectureId)}>
                                                                                    <img src="../images/deleteIcon.svg" alt="deleteIcon" title="Delete" />
                                                                                </span>
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            ));
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {showToasterLoading && <SavingToast text="Saving" />}
                    {showDeletingToasterLoading && <SavingToast text="Deleting" />}


                    {showToasterPopup && (
                        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                            <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                <div className="toast-body d-flex align-items-center ">
                                    <div className="d-block me-3">
                                        <img src="images/toast-success.svg" alt="toast-success" />
                                    </div>
                                    <div className="d-block">
                                        <span className="font-medium font-16 color-grey">
                                            `${summaryName} have been generated successfully`
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                    {isAddDataWarehouseArchitectureModalOpen && (
                        <div className="modal fade show" id="_addDataWarehouseArchitecturePopup" data-bs-backdrop="static" data-bs-keyboard="false"
                            tabIndex={-1} aria-labelledby="accept-promptLabel" aria-modal="true" role="dialog" style={{ display: 'block' }}>
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content custom-popup">
                                    <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                                        <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                                            Add Data Warehouse Architecture</h2>
                                        <button type="button" className="btn" onClick={() => setIsAddDataWarehouseArchitectureModalOpen(false)} aria-label="Close">
                                            <img src="images/popup-close.svg" alt="Close" />
                                        </button>
                                    </div>
                                    <div className="modal-body border-0 px-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="form-label font-14 font-medium color-black" htmlFor="architectureName">Architecture Name</label>
                                                <input
                                                    type="text"
                                                    id="architectureName"
                                                    className="form-control custom-form color-black"
                                                    value={newArchitectureName}
                                                    onChange={(e) => setNewArchitectureName(e.target.value)}
                                                    placeholder="Enter Architecture Name"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                                        <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={() => setIsAddDataWarehouseArchitectureModalOpen(false)} >Cancel</a>
                                        <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold" onClick={handleAddDataWarehouseArchitecture}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {isAddDataWarehouseArchitectureModalOpen && <div className="modal-backdrop fade show"></div>}
                    {showToast && <ToastComponent name={toastProps} />}
                    {showDeletePopup && (
                        <DeletePopup
                            popupHeading="Delete Architecture"
                            popupMessage="Are you sure you want to delete this architecture?"
                            setHideDeletePopup={closeDeletePopup}
                            deleteCall={confirmDeleteDatawareArchitecture}
                        />
                    )}

                    {showDeleteModal && <div className="modal-backdrop fade show"></div>}

                </div>

            </div>
        </div >
    );
};

export default ExecutiveSummaryRating;
