import { StarRatingProps } from "../../interface/DatabricksReportModel";

const StarRating: React.FC<StarRatingProps> = ({ rating, editable, onChange, error }) => {
    return (
        <div>
            <div className="star-rating">
                {[1, 2, 3, 4, 5].map((star) => (
                    <span
                        key={star}
                        className={`star ${star <= rating ? 'active' : ''} ${editable ? 'editable' : ''}`}
                        onClick={() => editable && onChange && onChange(star)}
                    >
                        ★
                    </span>
                ))}
            </div>
            {error && <div className="text-danger">{error}</div>}
        </div>
    );
};

export default StarRating;