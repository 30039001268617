// src/components/MainLayout.js
import React, { useState } from 'react';
import NavBar from './Navbar';
import LeftNav from './LeftNav';
import ContentDisplay from './ContentDisplay';
import SectionModal from './SectionModal';

export const MainLayout = ({
  sections,
  selectedSection,
  setSelectedSection,
  updateSectionContent,
  addSection,
  handleGenerateReport
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // console.log(sections , "Sectionsss Herererere")
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row flex-grow">
        <NavBar onGenerateReport={handleGenerateReport} />
        <div className="w-1/5 bg-gray-200 p-4">
          <LeftNav
            sections={sections}
            setActiveSectionId={(id) => {
              const section = sections.find(section => section.id === id);
              if (section) {
                setSelectedSection(section);
              }
            }}
            onAddSection={() => setIsModalOpen(true)}
          />
        </div>
        <div className="flex-grow w-4/5 p-4 overflow-y-auto">
          <ContentDisplay
            section={selectedSection}
            updateSectionContent={updateSectionContent}
          />
        </div>
      </div>

      {isModalOpen && (
        <SectionModal
          closeModal={() => setIsModalOpen(false)}
          onSectionSubmit={(templateId, content) => {
            addSection(templateId, content);
            setIsModalOpen(false);
          }}
        />
      )}
    </div>
  );
};