// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas-container {
    height: 80vh;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .error-message {
    padding: 1rem;
    background: #ffe6e6;
    color: #cc0000;
    border-radius: 4px;
    margin: 1rem;
    pre {
      white-space: pre-wrap;
      font-family: monospace;
    }
  }
  
  .loading {
    padding: 2rem;
    text-align: center;
    font-style: italic;
    color: #666;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Excalidraw/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ;MACE,qBAAqB;MACrB,sBAAsB;IACxB;EACF;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;EACb","sourcesContent":[".canvas-container {\n    height: 80vh;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    overflow: hidden;\n  }\n  \n  .error-message {\n    padding: 1rem;\n    background: #ffe6e6;\n    color: #cc0000;\n    border-radius: 4px;\n    margin: 1rem;\n    pre {\n      white-space: pre-wrap;\n      font-family: monospace;\n    }\n  }\n  \n  .loading {\n    padding: 2rem;\n    text-align: center;\n    font-style: italic;\n    color: #666;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
