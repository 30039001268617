import React, { useEffect, useState, useRef } from 'react';
import TextEditor from "./reportComponents/TextEditor";
import { fetchChallengesAndRecommendation, generateConclusionDetails, getAttributeDetails } from "../service/QuestionnaireApi";
import { useDatabricksContextObject } from './reportComponents/DatabricksContextObject';
import { getProjectReportData } from '../service/ReportApi';


//PS_Databricks_conclusion_03 - PS_Databricks_conclusion_19
const Conclusion = (props: any) => {

    const { isExecutiveSummaryUpdated, setIsExecutiveSummaryUpdated } = useDatabricksContextObject()
    const{businessOverview} = useDatabricksContextObject();
    const { isChallengesRecommendationsDataUpdated, setIsChallengesRecommendationsDataUpdated } = useDatabricksContextObject()
    const { reportData, businessOverviewData, setIsLoading } = props;
    const initialConclusionData = reportData.conclusion || [];
    const hasBusinessOverview = businessOverview && businessOverview.trim().length > 0;
    const [conclusionDescription, setConclusionDescription] = useState(initialConclusionData[0]?.[0]?.attributeValue || '');
    const [isEdit, setIsEdit] = useState(false);
    const [showGenerateToaster, setShowGenerateToaster] = useState(false);
    const [showSaveToaster, setShowSaveToaster] = useState(false);
    const [showGenerateMessage, setShowGenerateMessage] = useState(false);
    const [showSaveMessage, setShowSaveMessage] = useState(false);
    const [isGenerated, setIsGenerated] = useState(!!(initialConclusionData[0]?.[0]?.attributeValue));
    const [conclusionData, setConclusionData] = useState<any>(reportData.conclusion || []);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [challengesAndRecommendation, setChallengesAndRecommendation] = useState<any>();

    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [isChallengesEmpty, setIsChallengesEmpty] = useState(false); // Initialize as true
    const [shouldShowGenerateButton, setShouldShowGenerateButton] = useState(hasBusinessOverview && !isChallengesEmpty && !isGenerated || isExecutiveSummaryUpdated || isChallengesRecommendationsDataUpdated);

    useEffect(() => {
        setIsChallengesEmpty(challengesAndRecommendation?.length === 0);
    }, [challengesAndRecommendation]);

    // const shouldShowGenerateButton = hasBusinessOverview && hasChallenges && !isGenerated || isSummaryModified;
    const regex = /"|[*]/g;

    useEffect(() => {
        getChallengesAndRecommendation();
        getInitialPageData();
    }, []);

    useEffect(() => {
        setShouldShowGenerateButton(hasBusinessOverview && !isChallengesEmpty && !isGenerated || isExecutiveSummaryUpdated || isChallengesRecommendationsDataUpdated);
    }, [hasBusinessOverview, !isChallengesEmpty, isGenerated, isExecutiveSummaryUpdated, isChallengesRecommendationsDataUpdated]);

    //PS_Databricks_conclusion_20 - PS_Databricks_conclusion_38
    const getInitialPageData = async () => {
        // setIsLoading(true);
        const payload = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            connectorId: reportData.connectors.find((c: any) => c.connectorName === "Conclusion")?.connectorId,
        };

        try {
            const response = await getAttributeDetails(payload);
            if (response.status === 200) {
                setConclusionData(response?.data || []);
                setIsGenerated(!!(response.data[0]?.attributeValue));
                setConclusionDescription(response.data[0]?.attributeValue || '');

            } else {
                // setIsLoading(false);
                setShowToasterFailedPopup(true);
            }
        } catch (error) {
            console.error('Failed to fetch flow components:', error);
        }
    };
    const getChallengesAndRecommendation = async () => {
        setIsLoading(true);
        let payload = {
            organizationId: reportData.organizationId,
            projectId: reportData.projectId,
            verticalId: reportData.verticalId,
        };

        try {
            let response = await fetchChallengesAndRecommendation(payload);
            setIsLoading(false);
            if (response.status === 200) {
                setChallengesAndRecommendation(response.data)
            } else {
                setIsLoading(false);
                console.error('API call failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    //PS_Databricks_conclusion_40 - PS_Databricks_conclusion_69
    const getConclusionDescription = async () => {
        setShowGenerateToaster(true);
        setButtonDisabled(true);
        const challenges = challengesAndRecommendation ? Object.values(props?.challengesData)?.flat()?.map((item: any) => ({
            title: item.title,
            challenges: item.challenges,
            recommendations: item.recommendation
        })) : [];

        const payload = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            VerticalName: reportData.verticalName,
            projectId: reportData.projectId,
            connectorId: reportData.connectors.find((c: any) => c.connectorName === "Conclusion")?.connectorId,
            connectorName: 'Conclusion',
            attributeMasterId: '',
            attributeId: '',
            attributeName: 'Conclusion Description',
            attributeValue: '',
            businessOverview: businessOverviewData,
            challenges: challenges
        };

        try {
            const generateConclusionAPIResponse = await generateConclusionDetails(payload);
            await Promise.all([
                new Promise(resolve => setTimeout(resolve, 5000)),
                generateConclusionAPIResponse
            ]);

            if (generateConclusionAPIResponse.data && generateConclusionAPIResponse.status === 200) {
                setShowGenerateToaster(false);
                setShowGenerateMessage(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                setIsGenerated(true);
                setShouldShowGenerateButton(false);


                const newConclusionData = generateConclusionAPIResponse.data[0];
                setIsExecutiveSummaryUpdated(false)
                setIsChallengesRecommendationsDataUpdated(false)
                setConclusionData([newConclusionData]);
                setConclusionDescription(newConclusionData.attributeValue);
                reportData((prev: any) => ({
                    ...prev,
                    conclusion: [newConclusionData]
                }));
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
        } catch (error) {
            setShowToasterFailedPopup(true);
            console.error('Failed to fetch flow components:', error);
        } finally {
            setShowGenerateMessage(false);
            setShowGenerateToaster(false);
            setShowSaveMessage(false);
            setShowToasterFailedPopup(false);
            setButtonDisabled(false);

        }
    };

    //PS_Databricks_conclusion_71 - PS_Databricks_conclusion_98
    const handleSave = async (newValue: string) => {
        const updatedConclusionData = conclusionData[0] || {};
        const challenges = challengesAndRecommendation? Object?.values(props?.challengesData)?.flat()?.map((item: any) => ({
            title: item.title,
            challenges: item.challenges,
            recommendations: item.recommendation
        })) : [];

        const payload = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            connectorId: reportData.connectors.find((c: any) => c.connectorName === "Conclusion")?.connectorId,
            connectorName: 'Conclusion',
            attributeMasterId: updatedConclusionData.attributeMasterId,
            attributeId: updatedConclusionData.attributeId,
            attributeName: 'Conclusion Description',
            attributeValue: newValue.trim(),
            businessOverview: props.businessOverviewData,
            challenges: challenges
        };

        setShowSaveToaster(true);

        try {
            const updatedGenerateConclusionApiResponse = await generateConclusionDetails(payload);
            if (updatedGenerateConclusionApiResponse.data && updatedGenerateConclusionApiResponse.status === 200) {
                setShowSaveMessage(true);
                setIsEdit(!isEdit);
                await new Promise(resolve => setTimeout(resolve, 1000));
                setShowSaveMessage(false);
                await new Promise(resolve => setTimeout(resolve, 1000));

                const newConclusionData = updatedGenerateConclusionApiResponse.data[0];
                setConclusionData([newConclusionData]);
                setConclusionDescription(newConclusionData.attributeValue);
                reportData((prev: any) => ({
                    ...prev,
                    conclusion: [newConclusionData]
                }));
                await new Promise(resolve => setTimeout(resolve, 2000));
            } 
        } catch (error) {
            console.error('Error updating conclusion:', error);
        } finally {
            // setIsLoading(false);
            setShowToasterFailedPopup(false);
            setShowSaveMessage(false);
            setShowSaveToaster(false);
        }
    };

    //PS_Databricks_conclusion_99 - PS_Databricks_conclusion_101
    const toggleEdit = () => {
        setIsEdit(!isEdit);
    };

    return (
        <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mb-3">
                <div className="ribbon py-2 px-3 ms-md-4">
                    <span className="font-12 font-medium">Please click Generate Conclusion again if there are any additions or changes in Challenges and Recommendations or Business Overview.</span>
                    <span className="ribbon-border" />
                </div>
            </div>

            <div className="col-lg-12 col-md-12 col-12">
                <div className="ps-lg-4">
                    <div className="d-flex justify-content-between align-items-center my-2 mb-4">
                        <h3 className="font-18 font-semibold color-black mb-0">
                            Conclusion
                        </h3>
                        {isGenerated && !isEdit && !shouldShowGenerateButton && (
                            <button
                                type="button"
                                className={`btn edit-btn custom-btn font-medium px-4`}
                                onClick={toggleEdit}
                            >
                                Edit
                            </button>
                        )}
                    </div>
                    {(!hasBusinessOverview && isChallengesEmpty) && (
                        <p>Please Upload a transcript and provide challenges and recommendation to generate conclusion.</p>
                    )}
                    {(!hasBusinessOverview && !isChallengesEmpty) && (
                        <p>Please upload a transcript to generate conclusion.</p>
                    )}
                    {(hasBusinessOverview && isChallengesEmpty) && (
                        <p>Please add challenges and recommendation to generate conclusion.</p>
                    )}

                    {/* Only show the button if no messages are displayed */}
                    {!hasBusinessOverview && isChallengesEmpty || shouldShowGenerateButton && (
                        <button
                            type="button"
                            className="btn btn-dark theme-outline-btn regenerate-btn d-flex gap-2 align-items-center font-14 font-semibold lh-1 mb-4"
                            onClick={getConclusionDescription}
                            disabled={buttonDisabled}
                        >
                            Generate Conclusion
                        </button>
                    )}

                    { isGenerated && !shouldShowGenerateButton ? (
                        <div className="mt-3">
                            {isEdit ? (
                                <TextEditor
                                    value={conclusionDescription}
                                    onSave={handleSave}
                                    onCancel={toggleEdit}
                                    summaryType={"conclusion"}
                                    verticalName={reportData.verticalName}
                                />
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: conclusionDescription }} />
                            )}
                        </div>
                    ) : null}
                    {/* {shouldShowGenerateButton && (
                        <button
                            type="button"
                            className="btn btn-dark theme-outline-btn regenerate-btn d-flex gap-2 align-items-center font-14 font-semibold lh-1 mb-4"
                            onClick={getConclusionDescription}
                            disabled={buttonDisabled}
                        >
                            Generate Conclusion
                        </button>
                    )}

                    {isGenerated ? (
                        <div className="mt-3">
                            {isEdit ? (
                                <TextEditor
                                    value={conclusionDescription}
                                    onSave={handleSave}
                                    onCancel={toggleEdit}
                                    summaryType={"conclusion"}
                                    verticalName= {reportData.verticalName}
                                />
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: conclusionDescription }} />
                            )}
                        </div>
                    ) : (
                        <div className="mt-3">
                            {!hasBusinessOverview && !hasChallenges && (
                                <p>Please add business overview and challenges to generate the conclusion.</p>
                            )}
                            {!hasBusinessOverview && hasChallenges && (
                                <p>Please add business overview to generate the conclusion.</p>
                            )}
                            {hasBusinessOverview && !hasChallenges && (
                                <p>Please add challenges and recommendations to generate the conclusion.</p>
                            )}
                        </div>
                    )} */}

                    {showGenerateToaster && (
                        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                            <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                <div className="toast-body d-flex align-items-center ">
                                    <div className="d-block me-3">
                                        <img
                                            src="images/loading.gif"
                                            alt="toast-success"
                                            style={{ height: "15px", width: "15px" }}
                                        />
                                    </div>
                                    <div className="d-block">
                                        <span className="font-medium font-16 color-grey">
                                            Generating...
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}

                    {showSaveToaster && (
                        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                            <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                <div className="toast-body d-flex align-items-center ">
                                    <div className="d-block me-3">
                                        <img
                                            src="images/loading.gif"
                                            alt="toast-success"
                                            style={{ height: "15px", width: "15px" }}
                                        />
                                    </div>
                                    <div className="d-block">
                                        <span className="font-medium font-16 color-grey">
                                            Saving...
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                    }

                    {showSaveMessage && (
                        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                            <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                <div className="toast-body d-flex align-items-center ">
                                    <div className="d-block me-3">
                                        <img src="images/toast-success.svg" alt="toast-success" />
                                    </div>
                                    <div className="d-block">
                                        <span className="font-medium font-16 color-grey">
                                            Conclusion has been saved successfully
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showGenerateMessage && (
                        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                            <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                <div className="toast-body d-flex align-items-center ">
                                    <div className="d-block me-3">
                                        <img src="images/toast-success.svg" alt="toast-success" />
                                    </div>
                                    <div className="d-block">
                                        <span className="font-medium font-16 color-grey">
                                            Conclusion has been generated successfully
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {showToasterFailedPopup && (
                        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                            <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                                <div className="toast-body d-flex align-items-center ">
                                    <div className="d-block me-3">
                                        <img src="images/fail-icon.svg" alt="toast-fail" />
                                    </div>
                                    <div className="d-block">
                                        <span className="font-medium font-16 color-grey">
                                            Api failed.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Conclusion;