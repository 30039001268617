

//SQ_0.1
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getNotificationApi, logOutUser, postErrorLog, updateDarkThemeApi } from "../../Service/api";
import { useEffect, useState } from "react";
import SessionTimeOutPopup from "./SessionTimeOutPopup";
import { useOrgContext } from "../Context/Context";
import useSessionTimeout from "./useSessionTimout";

function Header({ isDarkMode, setIsDarkMode }) {
    //SQ_0.2
    const navigate = useNavigate();
    const userName = localStorage?.getItem('userName');
    const [logoImage, setLogoImage] = useState('');
    const userImage = localStorage?.getItem('userImage');
    const [notification, setNotification] = useState([])
    const userId = localStorage.getItem('userId');
    const { setSessionTimeoutPopup, sessionTimeoutPopup,triggerRefresh,colorMode,setColorMode } = useOrgContext();
    const[isAdmin,setIsAdmin] = useState(localStorage.getItem('isAdmin'))
    const location = useLocation();


    // console.log(isAdmin,"isAdmin");
    
    
        //SQ_1.14
    useEffect(() => {
        getNotification();
        createLogoImage()
    }, []);

    const getNotification = async () => {
        const payload = {
            "user_id": userId,

        }
        try {
            const response = await getNotificationApi(payload);
            if (response.status === 200) {
                // console.log(response, "respowksww");
                // console.log(response.data.body?.data, "mesg");


                setNotification(response.data.body?.data);


            }
        } catch (error) {
            handleError(error, "logout")
        }
    }



    // Handles user logout by making an API call and clearing session data.

    // Details:
    // --------
    // Constructs a payload with the user ID and a unique socket ID, then makes an API call to log out the user using `logOutUser`. Upon successful logout (status 200), clears local and session storage and navigates to the login page. If the logout fails or an error occurs, logs the error, clears local and session storage, and navigates to the login page.

    // Formula:
    // --------
    // payload = {
    //     user_id: userId,
    //     unique_socket_id: "jdjdkdj"
    // }

    // API Call:
    // --------
    // response = await logOutUser(payload)

    // If response.status == 200:
    //   clear local and session storage,navigate to login page

    // Error Handling:
    // try {} catch(error) {
    //     handleError(error, "logout")
    //     localStorage.clear()
    //     sessionStorage.clear()
    //     navigate('/')
    // } 
    //SQ_2.1-2.14: Handles Logout
    const handleLogout = async () => {
        try {

            // Prepare payload
            const payload = { user_id: userId, "unique_socket_id": "jdjdkdj" };

            // Make API call
            const response = await logOutUser(payload);

            console.log(response, "resp");

            if (response.status === 200) {
                localStorage.clear();
                sessionStorage.clear()

                navigate('/'); // Navigate to login page
            } else {
                console.error("Logout failed:", response.statusText);
            }
        } catch (error) {
            handleError(error, "logout")
            localStorage.clear();
            sessionStorage.clear()

            navigate('/'); // Navigate to login page

        }
    };

    // Logs and handles errors that occur in API calls.
    // Parameters:
    // -----------
    // error : object
    //     The error object received from a failed API call.
    // functionName : string
    //     The name of the function where the error occurred.

    // Details:
    // --------
    // Constructs an error data object including user ID, error type, module name, and error description. Sends this data to `postErrorLog`.

    const handleError = (error, functionName) => {
        const errorData = {
            user_id: userId,
            type: "error",
            error_module_name: functionName,
            error_description: error?.message + " ," + error?.response?.data?.body?.message

        };
        postErrorLog(errorData);
    };

    // Creates a logo image based on the user's initials.
    // Details:
    // --------
    // Splits the user's name into parts and extracts the first letters of the first and last names to form initials (logo letters). Draws a circle on a canvas, then draws the initials in the center of the circle. Converts the canvas to a base64 image URL and sets it as the logo image.

    // Formula:
    // --------
    // nameParts = userName?.split(' ')
    // firstName = nameParts[0] || ''
    // lastName = nameParts[nameParts.length - 1] || ''
    // logoLetters = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase()

    // Canvas Setup:
    // canvasSize = 200
    // displaySize = 40
    // canvas = document.createElement('canvas')
    // canvas.width = canvasSize
    // canvas.height = canvasSize

    // Drawing Circle:
    // context.fillStyle = '#f0f0f0'
    // context.beginPath()
    // context.arc(canvasSize / 2, canvasSize / 2, canvasSize / 2, 0, Math.PI * 2, true)
    // context.closePath()
    // context.fill()

    // Drawing Text:
    // fontSize = Math.round(canvasSize / 2.5)
    // context.font = `bold ${fontSize}px Arial`
    // context.fillStyle = '#333'
    // context.textAlign = 'center'
    // context.textBaseline = 'middle'
    // context.imageSmoothingEnabled = true
    // context.imageSmoothingQuality = 'high'
    // context.fillText(logoLetters, canvasSize / 2, canvasSize / 2)

    // Convert to Base64:
    // dataUrl = canvas.toDataURL('image/png')
    // setLogoImage(dataUrl)

    //SQ_3.0 -3.7 : To create logo images based on user name
    function createLogoImage() {
        let nameParts = userName?.split(' ');
        let firstName = nameParts[0] || '';
        let lastName = nameParts[nameParts.length - 1] || '';
        let logoLetters = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();

        // Increase canvas size for better quality
        const canvasSize = 200; // Larger canvas size for better resolution
        const displaySize = 40; // Size to display the image
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = canvasSize;
        canvas.height = canvasSize;

        // Draw circle
        context.fillStyle = '#f0f0f0'; // Circle color
        context.beginPath();
        context.arc(canvasSize / 2, canvasSize / 2, canvasSize / 2, 0, Math.PI * 2, true);
        context.closePath();
        context.fill();

        // Calculate font size based on canvas size
        const fontSize = Math.round(canvasSize / 2.5); // Adjust font size dynamically
        context.font = `bold ${fontSize}px Arial`; // Set font size and family
        context.fillStyle = '#333'; // Text color
        context.textAlign = 'center';
        context.textBaseline = 'middle';


        // Enable anti-aliasing for smoother text
        context.imageSmoothingEnabled = true;
        context.imageSmoothingQuality = 'high';

        // Draw text
        //context.fillText(logoLetters, canvasSize / 2, canvasSize / 2);
        const textYPosition = canvasSize / 2 + 6; // Use this for middle alignment
        context.fillText(logoLetters, canvasSize / 2, textYPosition);

        // Convert to base64
        const dataUrl = canvas.toDataURL('image/png');
        setLogoImage(dataUrl);
    }

    // Handles the click event on a notification and navigates to the appropriate page.

    // Parameters:
    // -----------
    // notification : object
    //     The notification object that contains details such as `approval_id`.

    // Details:
    // --------
    // If the notification contains an `approval_id`, navigates to the Approvals page and passes the `approval_id` as part of the state. This allows the Approvals page to fetch and display the relevant approval details.

    // Formula:
    // --------
    // if notification.approval_id,then navigate to Approvals page with approval_id in state

    // SQ_8.6 : Handle navigation
    const handleNotificationClick = (notification) => {
        if (notification.approval_id) {
            // Navigate to Approvals with approval_id in state
            navigate("/Approvals", {
                state: {
                    approval_id: notification.approval_id,
                },
            });
        }
    };

    // SQ_8.7 Handle session timeout
    const handleSessionTimeout = () => {
        setSessionTimeoutPopup(true);
    };

    // SQ_8.8Use the custom session timeout hook
    useSessionTimeout(1800000, handleSessionTimeout);

 

    const handleLogoClick = () => {
        if (location.pathname === '/Client-onboarding') {
          triggerRefresh(); // Trigger the data refresh
        } else {
          navigate('/Client-onboarding');
        }
      };

      const toggleDarkMode = () => {
        const newDarkMode = !isDarkMode; // Calculate new dark mode state
        setIsDarkMode(newDarkMode); // Update state immediately
        handleUpdateDarkTheme(newDarkMode); // Pass new state to API function
      };

      const handleUpdateDarkTheme = async (newDarkMode) => {
        const payload ={
            "user_id": userId,
            "is_theme_dark": newDarkMode ? "true" : "false"
        }
        //PS_15
        try {
            
          const response = await updateDarkThemeApi(payload); // Call the API function
          if (response.status === 200) {
            localStorage.setItem('darkTheme', newDarkMode ? 'true' : 'false'); // Update local storage
            setColorMode(newDarkMode ? 'dark' : 'light')
          } else {
            console.error('Error updating dark theme preference');
          }
        } catch (error) {
         handleError(error,"handleUpdateDarkTheme");
        }
      };
    

    return (
        <>
            {sessionTimeoutPopup &&
                <SessionTimeOutPopup />
            }
            {/* Header */}
            <div className="container-fluid p-0 position-sticky top-0 z-index-999" >
                <nav className="navbar navbar-expand-lg custom-navbar">
                    <div className="container-fluid d-flex justify-content-between align-items-center">
                        <a className="navbar-brand"  style={{cursor:'pointer'}} onClick={handleLogoClick}>
                            <img src="images/logo.svg" alt="logo" />
                        </a>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink
                                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                                    aria-current="page"
                                    to="/Client-onboarding"
                                    state={{ fromNav: true }}
                                   

                                >
                                    Clients
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                                    to="/AssessmentsGrid"
                                >
                                    Assessments
                                </NavLink>
                            </li>
                            {isAdmin == "true" && (
                                <>
                              
                            <li className="nav-item">
                                <NavLink
                                    className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                                    to="/AttributesMain"
                                >
                                    Attributes
                                </NavLink>
                            </li>

                                <li className="nav-item">
                                    <NavLink
                                        className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                                        to="/Approvals"
                                    >
                                        Approvals
                                    </NavLink>
                                </li>
                                </>
                            )}

                        </ul>
                        <div className="right-container">
                            <ul className="list-unstyled mb-0 d-lg-flex gap-4 d-grid ms-0 align-items-center">
                                <li>
                                    <div className="dropdown">
                                        <span
                                            className="nav-link p-0 position-relative cursor-pointer"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img
                                                className="notif-icon rounded-circle"
                                                src="images/notification-bell.svg"
                                                alt="profile-image"
                                            />
                                            <span className="notif-count rounded-circle font-bold d-flex align-items-center justify-content-center">
                                                2
                                            </span>
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-end border-0 custom-box-shadow mt-2 p-0 notif-dropdown">
                                            <div className="d-flex justify-content-between align-items-center mx-3 py-3 border-bottom">
                                                <h2 className="mb-0 font-semibold font-18">Notifications</h2>
                                                <button className="border-0 bg-transparent">
                                                    <img src="images/close-icon.svg" alt="close-icon" />
                                                </button>
                                            </div>
                                            <ul className="px-0 notif-panel list-unstyled py-2">
                                                {notification?.map((notification, index) => (
                                                    <li key={index}>
                                                        <a
                                                            href="#"
                                                            className="d-flex gap-2 black-color text-decoration-none unread-notification"
                                                            onClick={(e) => {
                                                                e.preventDefault(); // Prevent default link behavior
                                                                handleNotificationClick(notification); // Handle navigation
                                                            }}
                                                        >
                                                            <img
                                                                src={notification?.imageUrl || "images/profile-photo.svg"}
                                                                alt="profile-pic"
                                                                className="rounded-circle"
                                                            />
                                                            <span className="font-14 font-regular">
                                                                {notification?.message}
                                                            </span>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="dropdown">
                                        <span
                                            className="nav-link p-0"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img
                                                className="profile-pic"
                                                src={logoImage}
                                                alt="profile-image"
                                            />
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-end border-0 custom-box-shadow mt-2 p-4 z-index-999">
                                            <div className="d-flex align-items-center gap-3 pb-4 border-bottom">


                                                {userImage && userImage !== 'null' && userImage !== 'undefined' ? (
                                                    <img
                                                        src={`data:image/jpeg;base64,${localStorage.getItem('userImage')}`}
                                                        alt='profile-pic'
                                                        className='profile-img-header rounded-circle'
                                                    />
                                                ) : (
                                                    <img
                                                        src={logoImage}
                                                        alt="profile-pic"
                                                        className="profile-img-header rounded-circle"
                                                    />
                                                )}
                                                <div className="d-flex flex-column gap-2">
                                                    <p className="font-16 font-medium black-color mb-0">
                                                        {localStorage.getItem('userName')}
                                                    </p>
                                                    <p className="font-9 font-medium light-grey-v1 mb-0">
                                                        {localStorage.getItem('emailId')}
                                                    </p>
                                                </div>
                                                <button className="border-0 bg-transparent p-0" onClick={handleLogout}>
                                                    <img src="images/logout.svg" alt="logout-icon" />
                                                </button>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center pt-4">
                                                <div className="d-flex gap-2">
                                                    <img src="images/dark-light-theme.svg" alt="dark-theme-icon" />
                                                    <span className="primary-color font-9 font-medium"  
                                                   
                                                    style={{ cursor: 'pointer' }}
                                                    >
                                                        Dark Theme
                                                    </span>
                                                </div>
                                                <div className="form-check form-switch d-inline-flex align-items-center">
                                                    <input
                                                        className="form-check-input custom-switcher cursor-pointer shadow-none"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked-1"
                                                        onClick={toggleDarkMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </nav>
            </div>
            {/* Header */}
        </>
    );
}

export default Header;