//SQ_-0.1 and 0.2
import { useEffect, useState } from "react";
import Select from "react-select";
import { getProviderApi, postErrorLog, saveAssessmentDetailsApi } from "../../Service/api";

//SQ_0.3-0.12: Declare styling for select package 
const styles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
    },
};

function AssessmentsPopup(props) {
    let { isOpen, onClose, assessmentEditData, editMode, setIsPopupOpen, refreshAssessmentData } = props

    console.log(setIsPopupOpen, "popupstate");
    console.log(onClose, "onCLose");



    // SQ_NO_0.13 - State declarations
    const [assessmentFieldData, setAssessmentFieldData] = useState({
        assessmentsName: '',
        domain: '',
        sowDoc: null,
        type: '',
        scope: '',
        connector: [],
        iamAccessKey: '',
        secretKey: '',
        accountId: '',
        region: ''
    });
    const [connectorPage, setConnectorPage] = useState(false);
    const [providerData, setProviderData] = useState([])

    const awsProvider = providerData.find(provider => provider.label === "AWS");
    const azureProvider = providerData.find(provider => provider.label === "Azure");
    const defaultConnector = awsProvider ? [{ value: awsProvider.id, label: awsProvider.label }] : [];
    const [selectedConnectors, setSelectedConnectors] = useState(defaultConnector);
    const userId = localStorage.getItem('userId')

    console.log(assessmentFieldData.scope, "scopefield");
    console.log(assessmentEditData.scope, "scope");


    // SQ_NO_1.32 - 1.37: Prepopulate assessement data and provider data
    useEffect(() => {
        if (assessmentEditData) {
            const editedConnectors = providerData.filter(provider =>
                assessmentEditData.connectors.some(
                    connector => connector.service_provider_id === provider.id
                )
            );

            const selected = editedConnectors.length > 0 ? editedConnectors.map(connector => ({
                value: connector.id,
                label: connector.label
            })) : defaultConnector;

            const updatedFieldData = {
                assessmentsName: assessmentEditData.assessment_name || '',
                domain: assessmentEditData.domain || '',
                sowDoc: assessmentEditData.sow_url || null,
                type: assessmentEditData.assessment_type || '',
                scope: assessmentEditData.scope.return || '',
                connector: selected.map(connector => connector.value),
                iamAccessKey: '',
                secretKey: '',
                accountId: '',
                region: '',
                tenantId: '',
                clientId: '',
                clientSecret: '',
                subscriptionId: ''
            };

            setSelectedConnectors(selected);
            setAssessmentFieldData(updatedFieldData);

            prepopulateConnectorFields(assessmentEditData.connectors, updatedFieldData);
        }
    }, [assessmentEditData, providerData]);

    //SQ_1.38 : Get the provider data 
    useEffect(() => {
        getProvider()
    }, [])

    //SQ_1.38 - 1.51 
    //     ### `getProvider`

    // Fetches provider data and updates the state.

    //     Details:
    //     --------
    //     Constructs a payload with the user ID and a dropdown type for connectors. Fetches provider data using `getProviderApi` and updates the state with the fetched provider data. Handles errors by logging them.

    //     Formula:
    //     --------
    //     payload = {
    //         dropdown_type: "connectors",
    //         user_id: userId
    //     }

    //     API Call:
    //     --------
    //     response = await getProviderApi(payload)

    //     If response.status == 200:
    //         set provider data with response.data.body.data

    //     Error Handling:
    //     catch(error) {
    //        call  handleError(error, "getProvider")
    //     }
    //SQ_1.39-1.51: Fetches the provider data
    const getProvider = async () => {
        try {
            const payload = {
                "dropdown_type": "connectors",
                "user_id": userId
            }
            const response = await getProviderApi(payload);
            console.log(response, "response");
            if (response.status == 200) {
                setProviderData(response.data.body.data)

            }

        } catch (error) {
            handleError(error, "getProvider")
        }
    };

    //SQ_1.52 - 1.54: Prepopulate connector fields
    //     ### `prepopulateConnectorFields`

    // Prepopulates connector fields based on provided connector data.

    //     Parameters:
    //     -----------
    //     connectors : array
    //         The array of connector objects.
    //     updatedFieldData : object
    //         Data used to update the fields.

    //     Details:
    //     --------
    //     Iterates through the connectors array and checks for AWS and Azure connectors. For each connector, updates the state with the corresponding fields (like IAM keys, tenant ID, etc.) for AWS and Azure.

    //     Formula:
    //     --------
    //     awsProvider = providerData.find(provider => provider.label === "AWS")
    //     azureProvider = providerData.find(provider => provider.label === "Azure")

    //     connectors.forEach(connector => {
    //         if (awsProvider && connector.service_provider_id === awsProvider.id) {
    //             setAssessmentFieldData(prevState => ({
    //                 ...prevState,
    //                 iamAccessKey: connector.iamAccessKey || '',
    //                 secretKey: connector.secretKey || '',
    //                 accountId: connector.accountId || '',
    //                 region: connector.region || ''
    //             }))
    //         } else if (azureProvider && connector.service_provider_id === azureProvider.id) {
    //             setAssessmentFieldData(prevState => ({
    //                 ...prevState,
    //                 tenantId: connector.tenantId || '',
    //                 clientId: connector.clientId || '',
    //                 clientSecret: connector.clientSecret || '',
    //                 subscriptionId: connector.subscriptionId || ''
    //             }))
    //         }
    //     })

    //SQ_1.52-1.54: Prepopulate connector fields
    const prepopulateConnectorFields = (connectors, updatedFieldData) => {

        console.log(connectors, "connectorlog");

        connectors.forEach(connector => {
            const awsProvider = providerData.find(provider => provider.label === "AWS");
            const azureProvider = providerData.find(provider => provider.label === "Azure");

            if (awsProvider && connector.service_provider_id === awsProvider.id) {
                setAssessmentFieldData(prevState => ({
                    ...prevState,
                    iamAccessKey: connector.iam_access_key || '',
                    secretKey: connector.secret_key || '',
                    accountId: connector.account_id || '',
                    region: connector.region || ''
                }));
            } else if (azureProvider && connector.service_provider_id === azureProvider.id) {
                setAssessmentFieldData(prevState => ({
                    ...prevState,
                    tenantId: connector.tenant_id || '',
                    clientId: connector.client_id || '',
                    clientSecret: connector.client_secret || '',
                    subscriptionId: connector.subscription_id || ''
                }));
            }
        });
    };

    //SQ_1.55-1.56:
    //     ### `handleConnectorChange`

    // Handles changes in the connector selection.

    //     Parameters:
    //     -----------
    //     selectedOptions : array
    //         The selected connector options.

    //     Details:
    //     --------
    //     Updates the state with the selected connector options or the default connector if no option is selected.

    //     Formula:
    //     --------
    //     setSelectedConnectors(selectedOptions.length > 0 ? selectedOptions : defaultConnector)
    //     set assessmentFieldData with the selected connector options
    //SQ_1.55-1.56:
    const handleConnectorChange = (selectedOptions) => {
        setSelectedConnectors(selectedOptions.length > 0 ? selectedOptions : defaultConnector);

        // Clear out corresponding fields for the deselected connector
        const connectorIds = selectedOptions.map(option => option.value);
        let newData = { ...assessmentFieldData };

        if (!connectorIds.includes(awsProvider.id)) {
            newData = {
                ...newData,
                iamAccessKey: '',
                secretKey: '',
                accountId: '',
                region: ''
            };
        }

        if (!connectorIds.includes(azureProvider.id)) {
            newData = {
                ...newData,
                tenantId: '',
                clientId: '',
                clientSecret: '',
                subscriptionId: ''
            };
        }

        setAssessmentFieldData({
            ...newData,
            connector: selectedOptions.map(option => option.value)
        });
    };

    // SQ_1.57
    //     ### `handleFileUpload`

    // Handles the upload of a file.

    //     Parameters:
    //     -----------
    //     event : object
    //         The event object for the file input change.

    //     Details:
    //     --------
    //     Retrieves the selected file from the event and updates the state with the file.

    //     Formula:
    //     --------
    //     const file = event.target.files[0]
    //     set assessmentFieldData with the file
    // //SQ_1.57
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setAssessmentFieldData(prev => ({ ...prev, sowDoc: file }));
    };

    //     ### `handleFieldChange`

    // Handles changes in input fields and updates the assessment field data state.
    //     Parameters:
    //     -----------
    //     field : string
    //         The name of the field to update.
    //     value : any
    //         The new value of the field.

    //     Details:
    //     --------
    //     Updates the `assessmentFieldData` state with the new value for the specified field.

    //     Formula:
    //     --------
    //    set assessmentFieldData with the updated field value

    // SQ_NO_1.4 - 1.5 - Handle field changes
    const handleFieldChange = (field, value) => {
        setAssessmentFieldData(prev => ({ ...prev, [field]: value }));
    };

    //SQ_1.59
    //     ### `handleSaveOrUpdate`

    // Handles the save or update operation for assessment data.

    //     Details:
    //     --------
    //     Constructs a payload with user ID, assessment details, scope, and connector information. Makes an API call using `saveAssessmentDetailsApi` to save the assessment data. Handles errors by logging them.

    //     Formula:
    //     --------
    //     const payload = {
    //         user_id: userId,
    //         assessment_id: assessmentEditData.assessment_id,
    //         client_id: assessmentEditData.client_id,
    //         assessment_name: assessmentFieldData.assessmentsName,
    //         assessment_type_id: assessmentEditData.assessment_type,
    //         scope: assessmentFieldData.scope,
    //         connectors: selectedConnectors.map(connector => {
    //             const id = connector.value
    //             if (id === "AWS") {
    //                 return {
    //                     service_provider_id: id,
    //                     iam_access_key: assessmentFieldData.iamAccessKey,
    //                     secret_key: assessmentFieldData.secretKey,
    //                     account_id: assessmentFieldData.accountId,
    //                     region: assessmentFieldData.region,
    //                 }
    //             } else if (id === "Azure") {
    //                 return {
    //                     service_provider_id: id,
    //                     tenant_id: assessmentFieldData.tenantId,
    //                     client_id: assessmentFieldData.clientId,
    //                     client_secret: assessmentFieldData.clientSecret,
    //                     subscription_id: assessmentFieldData.subscriptionId,
    //                 }
    //             }
    //             return null
    //         }).filter(Boolean),
    //     }

    //     API Call:
    //     --------
    //     response = await saveAssessmentDetailsApi(payload)

    //     Error Handling:
    //     catch(error) {
    //         handleError(error, "saveAssessments")
    //     }

    // SQ_NO_1.13 to SQ_NO_1.25 - Handle save or update button click
    const handleSaveOrUpdate = async () => {

        try {
            debugger
            const payload = {
                user_id: userId,
                assessment_id: assessmentEditData.assessment_id,
                client_id: assessmentEditData.client_id,
                assessment_name: assessmentFieldData.assessmentsName,
                assessment_type_id: assessmentEditData.assessment_type,
                scope: {
                    ...assessmentEditData.scope,
                    return: assessmentFieldData.scope, // Updating the return part with assessmentFieldData.scope
                },
                connectors: selectedConnectors.map((connector) => {
                    const id = connector.value;
                    if (id === awsProvider.id) {
                        return {
                            service_provider_id: id,
                            iam_access_key: assessmentFieldData.iamAccessKey,
                            secret_key: assessmentFieldData.secretKey,
                            account_id: assessmentFieldData.accountId,
                            region: assessmentFieldData.region,
                            connector_id: assessmentEditData.connectors[0]?.connector_id,
                            connection_details: assessmentEditData.connectors[0]?.connection_details,
                        };
                    } else if (id === azureProvider.id) {
                        return {
                            service_provider_id: id,
                            tenant_id: assessmentFieldData.tenantId,
                            client_id: assessmentFieldData.clientId,
                            client_secret: assessmentFieldData.clientSecret,
                            subscription_id: assessmentFieldData.subscriptionId,
                            region: assessmentFieldData.region,
                            connector_id: assessmentEditData.connectors[1]?.connector_id,
                            connection_details: assessmentEditData.connectors[1]?.connection_details,
                        };
                    }
                    return null;
                }).filter(Boolean),
            };


            console.log(payload, "assess save payload"); // Replace this with the actual save function call
            const response = await saveAssessmentDetailsApi(payload)
            console.log(response, "assess save");
            onClose()
            refreshAssessmentData();

        } catch (error) {
            handleError(error, "saveAssessments")
        }
    };

    //SQ_1.0 - 1.3: Handle error posting
    //     ### `handleError`

    // Logs and handles errors that occur in API calls.

    //     Parameters:
    //     -----------
    //     error : object
    //         The error object received from a failed API call.
    //     functionName : string
    //         The name of the function where the error occurred.

    //     Details:
    //     --------
    //     Constructs an error data object including user ID, error type, module name, and error description. Sends this data to `postErrorLog`.

    //     Formula:
    //     --------
    //     object of errorData with user ID, type, error module name, and error description
    //SQ_1.0 -1.3: Handle error posting
    const handleError = (error, functionName) => {
        const errorData = {
            user_id: userId,
            type: "error",
            error_module_name: functionName,
            error_description: error?.message + " ," + error?.response?.data?.body?.message

        };
        postErrorLog(errorData);
    };

    //SQ_1.58  
    //     ### `renderAssessmentContent`

    // Renders the assessment form content.

    //     Returns:
    //     --------
    //     JSX Elements:
    //         Rendered assessment form fields including name, domain, SOW upload, type, and scope.

    //     Details:
    //     --------
    //     Renders input fields for assessment name, domain, SOW upload, assessment type, and assessment scope. Includes Cancel and Next buttons to handle form navigation and data submission.

    //     Formula:
    //     --------
    //     render the fiedlds for assessment name, domain, SOW upload, assessment type, and assessment scope
    //         <!-- Render Cancel and Next buttons -->
    //     </div>
    //SQ_1.58 : Assessment fields
    const renderAssessmentContent = () => (
        <div className="row">
            <div className="col-md-6 mb-3">
                <label className="form-label font-14 font-medium" htmlFor="assessment-name">
                    Assessment Name <span className="input-improtent-section">*</span>
                </label>
                <input
                    type="text"
                    className="form-control w-100 mb-4 mt-2"
                    id="assessment-name"
                    value={assessmentFieldData.assessmentsName}
                    onChange={(e) => handleFieldChange('assessmentsName', e.target.value)}
                    placeholder="Enter Assessment Name"
                    disabled
                />
            </div>
            <div className="col-md-6 mb-3">
                <label className="form-label font-14 font-medium" htmlFor="assessment-domain">
                    Domain <span className="input-improtent-section">*</span>
                </label>
                <select
                    className="form-select migrate-form-ui mb-4 mt-2"
                    id="assessment-domain"
                    value={assessmentFieldData.domain}
                    onChange={(e) => handleFieldChange('domain', e.target.value)}
                    disabled
                >
                    <option value="">Select Domain</option>
                    <option value="Data Science">Data Science</option>
                    <option value="Cyber Security">Cyber Security</option>

                </select>
            </div>
            <div className="col-md-6 mb-3">
                <label className="form-label font-14 font-medium" htmlFor="industry-type">
                    SOW <span className="input-improtent-section">*</span>
                </label>
                <div className="mt-2">
                    <button className=" btn secondary-btn file-upload-chat-area link-color" disabled>
                        <img src="images/upload-icon.svg" alt="" /> Upload
                        <input type="file" onChange={handleFileUpload} />
                    </button>
                    <span className="font-12 text-grey-v1 ms-4">
                        {assessmentFieldData.sowDoc ? assessmentFieldData.sowDoc : "No file chosen"}
                    </span>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <label className="form-label font-14 font-medium" htmlFor="industry-type">
                    Type <span className="input-improtent-section">*</span>
                </label>
                <select
                    className="form-select migrate-form-ui mb-4 mt-2"
                    id="industry-type"
                    value={assessmentFieldData.type}
                    onChange={(e) => handleFieldChange('type', e.target.value)}
                    disabled
                >
                    <option value="">Select Type</option>
                    {/* Example types, replace with actual options */}
                    <option value="MDPW">MDPW</option>
                    <option value="WAFR">WAFR</option>
                </select>
            </div>
            <div className="col-md-12 mb-3">
                <label className="form-label font-14 font-medium" htmlFor="scope">
                    Scope <span className="input-improtent-section">*</span>
                </label>
                <textarea
                    className="form-control w-100 mt-2 resize-none custom-textarea shadow-none"
                    placeholder="Describe Scope"
                    value={assessmentFieldData.scope}
                    //onChange={(e) => handleFieldChange('scope', e.target.value)}
                    onChange={(e) => {
                        if (e.target.value.length <= 200) {
                            handleFieldChange('scope', e.target.value);
                        }
                    }}
                    maxLength={200}
                />
            </div>
            <div className="col-md-12">
                <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                    <button className="btn outline-button py-2 px-4" onClick={(() => { onClose() })}>Cancel</button>
                    <button className="btn primary-button py-2 px-4" onClick={(() => { setConnectorPage(true); })}
                        disabled={!assessmentFieldData.assessmentsName || !assessmentFieldData.domain || !assessmentFieldData.type || !assessmentFieldData.scope}>
                        Next</button>
                </div>
            </div>
        </div>
    );

    //SQ_1.59: Connector fields
    //     ### `renderConnectorContent`

    // Renders the connector form content.

    //     Returns:
    //     --------
    //     JSX Elements:
    //         Rendered connector form fields based on selected connectors, including AWS and Azure.

    //     Details:
    //     --------
    //     Renders a multi-select dropdown for selecting connectors and renders corresponding input fields for selected connectors (like IAM keys, tenant ID, etc.). Includes Cancel, Previous, and Save buttons to handle form navigation and data submission.

    //     Formula:
    //     --------
    //     render the  connector form content based on selected connectors, including AWS and Azure.
    //SQ_1.59: Connector fields
    const renderConnectorContent = () => (
        <div className="row">
            <div className="col-md-6 mb-3">
                <label className="form-label font-14 font-medium" htmlFor="assessment-connector">
                    Connector
                </label>
                <Select
                    value={selectedConnectors}
                    isMulti
                    styles={styles}
                    name="connectors"
                    options={providerData.map(provider => ({
                        value: provider.id,
                        label: provider.label
                    }))}
                    onChange={handleConnectorChange}
                />
            </div>

            <div className="col-md-12 p-0">
                <div className="bg-white rounded-2 tab-container p-3">
                    <ul className="nav nav-tabs custom-tabs border-0 d-flex gap-3" role="tablist">
                        {selectedConnectors.map((connector, idx) => (
                            <li className="nav-item" role="presentation" key={idx}>
                                <a
                                    className={`nav-link text-grey font-16 ${idx === 0 ? "active" : ""}`}
                                    data-bs-toggle="tab"
                                    href={`#${connector.value}`}
                                    aria-selected={idx === 0}
                                >
                                    {connector.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div className="tab-content">
                        {selectedConnectors.map((connector, idx) => (
                            <div
                                key={idx}
                                id={connector.value}
                                className={`tab-pane fade ${idx === 0 ? "show active" : ""} tab-section mt-4`}
                            >
                                {connector.label === "AWS" ? (
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label font-14 font-medium" htmlFor="assessment-IAM-access-key">
                                                IAM Access Key
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-4 mt-2"
                                                id="assessment-IAM-access-key"
                                                placeholder="Enter IAM Access Key"
                                                value={assessmentFieldData.iamAccessKey}
                                                onChange={(e) => handleFieldChange("iamAccessKey", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label font-14 font-medium" htmlFor="assessment-secret-key">
                                                Secret Key
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-4 mt-2"
                                                id="assessment-secret-key"
                                                placeholder="Enter Secret Key"
                                                value={assessmentFieldData.secretKey}
                                                onChange={(e) => handleFieldChange("secretKey", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label font-14 font-medium" htmlFor="assessment-account-id">
                                                Account ID
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-4 mt-2"
                                                id="assessment-account-id"
                                                placeholder="Enter Account ID"
                                                value={assessmentFieldData.accountId}
                                                onChange={(e) => handleFieldChange("accountId", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label font-14 font-medium" htmlFor="assessment-region">
                                                Region
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-4 mt-2"
                                                id="assessment-region"
                                                placeholder="Enter Region"
                                                value={assessmentFieldData.region}
                                                onChange={(e) => handleFieldChange("region", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                ) : connector.label === "Azure" ? (
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label font-14 font-medium" htmlFor="assessment-tenant-id">
                                                Tenant ID
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-4 mt-2"
                                                id="assessment-tenant-id"
                                                placeholder="Enter Tenant ID"
                                                value={assessmentFieldData.tenantId}
                                                onChange={(e) => handleFieldChange("tenantId", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label font-14 font-medium" htmlFor="assessment-client-id">
                                                Client ID
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-4 mt-2"
                                                id="assessment-client-id"
                                                placeholder="Enter Client ID"
                                                value={assessmentFieldData.clientId}
                                                onChange={(e) => handleFieldChange("clientId", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label font-14 font-medium" htmlFor="assessment-client-secret">
                                                Client Secret
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-4 mt-2"
                                                id="assessment-client-secret"
                                                placeholder="Enter Client Secret"
                                                value={assessmentFieldData.clientSecret}
                                                onChange={(e) => handleFieldChange("clientSecret", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label font-14 font-medium" htmlFor="assessment-subscription-id">
                                                Subscription ID
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control w-100 mb-4 mt-2"
                                                id="assessment-subscription-id"
                                                placeholder="Enter Subscription ID"
                                                value={assessmentFieldData.subscriptionId}
                                                onChange={(e) => handleFieldChange("subscriptionId", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                            <button className="btn outlined-button py-2 px-4 me-auto font-14 primary-color font-semibold" onClick={(() => { setConnectorPage(false); })}>Previous</button>
                            <button className="btn outline-button py-2 px-4 font-semibold" onClick={(() => { onClose() })}>Cancel</button>
                            <button className="btn primary-button py-2 px-4 font-semibold" onClick={handleSaveOrUpdate} disabled={!areConnectorFieldsValid()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const areConnectorFieldsValid = () => {
        const awsFieldsFilled = selectedConnectors.some(c => c.label === "AWS")
            ? !!assessmentFieldData.iamAccessKey && !!assessmentFieldData.secretKey && !!assessmentFieldData.accountId && !!assessmentFieldData.region
            : true;

        const azureFieldsFilled = selectedConnectors.some(c => c.label === "Azure")
            ? !!assessmentFieldData.tenantId && !!assessmentFieldData.clientId && !!assessmentFieldData.clientSecret && !!assessmentFieldData.subscriptionId
            : true;

        return awsFieldsFilled && azureFieldsFilled;
    };

    return (

        <div className="container-fluid">
            <div className={`modal fade modal-lg-only loader-popup-container configure-limit-container ${isOpen ? 'show' : ''}`}
                id={connectorPage ? "Connectors" : "Assessments"}
                tabIndex={-1}
                aria-labelledby="loaderModalLabel"
                aria-hidden="true"
                style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)", zIndex: "10000" }}
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content vh-75 overflow-hidden radius-6 border-0">
                        <div className="modal-header border-0 p-4">
                            <img src="images/add-assessment-icon.svg" alt="" className="me-3" />
                            <h4 className="mb-0 font-18 font-semibold text-grey-v1">Add Assessment</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(() => { onClose() })} />
                        </div>
                        <div className="p-4 pt-1">
                            <div className="d-flex justify-content-between align-items-center mb-5 step-wizard m-auto">
                                <div className="d-flex flex-column justify-content-between position-relative align-items-center gap-1 ">
                                    <div className={`wizard-step-icon d-flex justify-content-center align-items-center font-bold font-14 ${!connectorPage ? 'active' : ''}`}>
                                        <img src="images/file-icon-white.svg" alt="file-icon" />
                                        <span className="active-dot" />
                                    </div>
                                    <span className={`bg-white px-1 font-14 font-medium wizard-step-text position-absolute text-center text-nowrap ${!connectorPage ? 'active' : ''}`}>Assessments</span>
                                </div>
                                <div className="d-flex flex-column justify-content-between position-relative align-items-center gap-1">
                                    <div className={`wizard-step-icon d-flex justify-content-center align-items-center font-bold font-14 ${connectorPage ? 'active' : ''}`}>
                                        <img src="images/gear-icon-white.svg" alt="gear-icon" />
                                        <span className="active-dot" />
                                    </div>
                                    <span className={`bg-white px-1 font-14 font-medium wizard-step-text position-absolute text-center text-nowrap ${connectorPage ? 'active' : ''}`}>Connectors</span>
                                </div>
                            </div>

                            {connectorPage ? renderConnectorContent() : renderAssessmentContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AssessmentsPopup;