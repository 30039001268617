
import React, { useEffect, useState, useRef } from 'react';
import { convertToExcalidrawElements, Excalidraw, MainMenu, WelcomeScreen } from '@excalidraw/excalidraw';
import { parseMermaidToExcalidraw } from '@excalidraw/mermaid-to-excalidraw';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { getWorkbenchDataApi, postErrorLog, saveWorkbenchContentApi } from '../../Service/api';


const Workbench = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const excalidrawRef = useRef(null);

  const [state, setState] = useState({
    elements: [],
    loading: false,
    error: null,
});
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exportedImage, setExportedImage] = useState(null);
  const [mermaidCode, setMermaidCode] = useState('');
  const [files, setFiles] = useState({});
  const userId = localStorage.getItem('userId');

  const { attribute_name,assessmentId, workflowId, attribute_id,assessmentName } = location.state || {};

  const staticMermaidDiagram = `
  %%{init: {'theme': 'base', 'themeVariables': { 'primaryColor': '#1f77b4', 'edgeLabelBackground':'#cccccc', 'clusterBkg':'#d3d3d3', 'tertiaryColor': '#ffffff'}} }%%
  graph TD
      subgraph Large_Scale_AWS_Architecture_Diagram
          user[Account]

          subgraph Region_1
              direction LR
              vpc1[VPC 1]
          end

          subgraph Region_2
             direction LR
              vpc2[VPC 2]
          end
          user -->|Access| vpc1
          user -->|Access| vpc2
      end `;

      const staticElements = [];

  
  // Fetching workbench data
  useEffect(() => {
    fetchData();
  }, [assessmentId, workflowId, attribute_id]);

  const fetchData = async () => {
    try {
    //   const response = await getWorkbenchDataApi(assessmentId, workflowId, attribute_id);
    //   if (response.status === 200) {
    //     setMermaidCode(response.data.mermaid_code);
    //   }
    setMermaidCode(staticMermaidDiagram);
    } catch (error) {
      handleError(error, 'getWorkbenchData');
      setError('Failed to fetch workbench data');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (mermaidCode) {
      generateElementsFromMermaid(mermaidCode);
      setFiles(mermaidCode);
    }
  }, [mermaidCode]);

  const generateElementsFromMermaid = async (mermaidCode) => {
    setState((prev) => ({
        ...prev,
        elements: [],
        loading: true,
        error: null,
    }));
    if (!mermaidCode.trim()) {
        setState({
            elements: staticElements,
            loading: false,
            error: null,
        });
        return;
    }
    try {
        const { elements } = await parseMermaidToExcalidraw(mermaidCode, { fontSize: 16 });
        let excalidrawElements = convertToExcalidrawElements(elements);
        excalidrawElements = excalidrawElements.map(el => ({ ...el, fontFamily: 2, roughness: 0, fontSize: 20 }));
        setState({
            elements: excalidrawElements,
            loading: false,
            error: null,
        });
    } catch (error) {
        console.error('Error generating elements from Mermaid:', error);
        setState({
            elements: [],
            loading: false,
            error: error.message,
        });
    }
};

console.log(state.elements,"elements");


  // Handle Download
  const handleDownload = async () => {
    try {
      const blob = await excalidrawRef.current.exportToBlob({
        quality: 1,
        padding: 10,
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'workbench-export.png';
      link.click();
    } catch (error) {
      handleError(error, 'handleDownload');
      setError('Failed to download image');
    }
  };

  // Handle Save
  const handleSave = async () => {
    try {
      const imageBase64 = await excalidrawRef.current.exportToBlob({
        quality: 1,
        padding: 10,
      }).then(blob => new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      }));

      const response = await saveWorkbenchContentApi(
        assessmentId,
        workflowId,
        attribute_id,
        JSON.stringify(elements),
        imageBase64
      );

      if (response.status === 200) {
        navigate('/Workflow');
      }
    } catch (error) {
      handleError(error, 'handleSave');
      setError('Failed to save workbench content');
    }
  };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

  const handleError = (error, functionName) => {
    const errorData = {
      user_id: userId,
      type: "error",
      error_module_name: functionName,
      error_description: error?.message + " ," + error?.response?.data?.body?.message
    };
    postErrorLog(errorData);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 my-3">
          <div className="d-flex justify-content-between flex-md-row flex-column align-items-center">
            <h1 className="font-24 font-semibold black-color page-title mb-0">
              <Link to="/Workflow"  state={{assessmentName,assessmentId}} >
                <img
                  src="images/back-arrow.svg"
                  alt="back-arrow"
                  className="me-2"
                 
                />
              </Link>
              Business Flow
            </h1>
            <div className="d-flex">
              {/* <button className="btn primary-button me-2" onClick={handleDownload}>Download</button> */}
              <button className="btn primary-button" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mb-3 business-flow">
        <Excalidraw
          ref={excalidrawRef}
          initialData={{
            elements: elements,
            files: files,
          }}
          onChange={(elements) => {
            console.log(elements, 'elementsData');
          }}
        >
          <MainMenu>
            <MainMenu.DefaultItems.ClearCanvas />
            <MainMenu.DefaultItems.SaveAsImage />
            <MainMenu.DefaultItems.ToggleTheme />
            <MainMenu.DefaultItems.Export />
            <MainMenu.DefaultItems.SaveToActiveFile />
            <MainMenu.DefaultItems.ClearCanvas />
            <MainMenu.DefaultItems.LiveCollaborationTrigger />
            <MainMenu.DefaultItems.Help />
            <MainMenu.DefaultItems.LoadScene />
            <MainMenu.DefaultItems.ChangeCanvasBackground />
          </MainMenu>
          <WelcomeScreen>
            <WelcomeScreen.Center>
              <WelcomeScreen.Center.Logo />
              <WelcomeScreen.Center.Heading>
                Welcome to zeb pulse
              </WelcomeScreen.Center.Heading>
              <WelcomeScreen.Center.Menu />
            </WelcomeScreen.Center>
          </WelcomeScreen>
        </Excalidraw>
      </div>
      {exportedImage && (
        <div className="row">
          <div className="col-md-12">
            <h3>Exported Image:</h3>
            <img src={exportedImage} alt="Exported Workbench" className="img-fluid" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Workbench;