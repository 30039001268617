// Components/ChallengesRecommendations.jsx
import React, { useState } from 'react';

const ChallengesRecommendations = ({ title, content, updateSectionContent }) => {
  const [challengesRecommendationsData, setChallengesRecommendationsData] = useState(content || []);
  const [newRecommendation, setNewRecommendation] = useState({
    title: '',
    challenge: '',
    recommendation: ''
  });
  const [newErrors, setNewErrors] = useState({
    title: '',
    challenge: '',
    recommendation: ''
  });
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const validateNewRecommendation = () => {
    let isValid = true;
    const errors = {
      title: '',
      challenge: '',
      recommendation: ''
    };
    if (!newRecommendation.title.trim()) {
      errors.title = 'Title is required';
      isValid = false;
    }
    if (!newRecommendation.challenge.trim()) {
      errors.challenge = 'Challenges are required';
      isValid = false;
    }
    if (!newRecommendation.recommendation.trim()) {
      errors.recommendation = 'Recommendation is required';
      isValid = false;
    }
    setNewErrors(errors);
    return isValid;
  };

  const handleSaveRecommendation = () => {
    if (validateNewRecommendation()) {
      const newEntry = {
        ...newRecommendation
      };
      const updatedData = [...challengesRecommendationsData, newEntry];
      setChallengesRecommendationsData(updatedData);
      updateSectionContent(updatedData);
      setNewRecommendation({ title: '', challenge: '', recommendation: '' });
    }
  };

  const handleNewRecommendationChange = (e) => {
    const { name, value } = e.target;
    setNewRecommendation(prevForm => ({ ...prevForm, [name]: value }));
    setNewErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };

  const handleClearNewRecommendationForm = () => {
    setNewRecommendation({ title: '', challenge: '', recommendation: '' });
    setNewErrors({ title: '', challenge: '', recommendation: '' });
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="p-4">
      <div className="bg-gray-100 p-4 rounded-lg mb-5">
        <div className="flex justify-between items-center cursor-pointer" onClick={toggleAccordion}>
          <h2 className="text-xl font-semibold mb-4">Add New {title}</h2>
          <svg
            className={`w-6 h-6 transform transition-transform ${isAccordionOpen ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
        {isAccordionOpen && (
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div>
              <label className="block text-sm font-semibold mb-2">Title <span className="text-red-500">*</span></label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={newRecommendation.title}
                onChange={handleNewRecommendationChange}
                name="title"
              />
              {newErrors.title && <div className="text-red-500 text-sm mt-1">{newErrors.title}</div>}
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2">Challenges <span className="text-red-500">*</span></label>
              <textarea
                className="w-full p-2 border rounded"
                value={newRecommendation.challenge}
                onChange={handleNewRecommendationChange}
                name="challenge"
              />
              {newErrors.challenge && <div className="text-red-500 text-sm mt-1">{newErrors.challenge}</div>}
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2">Recommendation <span className="text-red-500">*</span></label>
              <textarea
                className="w-full p-2 border rounded"
                value={newRecommendation.recommendation}
                onChange={handleNewRecommendationChange}
                name="recommendation"
              />
              {newErrors.recommendation && <div className="text-red-500 text-sm mt-1">{newErrors.recommendation}</div>}
            </div>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-gray-300 text-black py-2 px-4 rounded"
                onClick={handleClearNewRecommendationForm}
              >
                Clear
              </button>
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded"
                onClick={handleSaveRecommendation}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>

      {challengesRecommendationsData.map((item, index) => (
        <div key={index} className="border rounded p-4 mb-4">
          <h3 className="text-lg font-semibold">{item.title}</h3>
          <p className="mt-2"><strong>Challenges:</strong> {item.challenge}</p>
          <p className="mt-2"><strong>Recommendation:</strong> {item.recommendation}</p>
        </div>
      ))}
    </div>
  );
};

export default ChallengesRecommendations;