// import { StaticComponent } from './templates/StaticComponent';
// import { FlowComponents } from './templates/FlowComponent';
// import { ChallengesRecommendations } from './templates/ChallengesRecommendations';
// import { TableComponent } from './templates/TableComponent';
// import { TextEditor } from './templates/TextEditor';

import { StaticComponent } from "../templates/StaticComponent";
import { TableComponent } from "../templates/TableComponent";
import ChallengesRecommendations from "./ChallengesRecommendations";
import FlowComponents from "./FlowComponents";
import Recommendations from "./Recommendations";
import SessionContentEditor from "./SessionContentEditor";
import TextEditorTemplate from "./TextEditorTemplate";

// export const convertToHTML = (sections) => {
//   return sections.map(section => {
//     switch (section.templateId) {
//       case 'StaticComponent':
//         return StaticComponent(section.content);
//       case 'FlowComponents':
//         return FlowComponents(section.content);
//       case 'ChallengesRecommendations':
//         return ChallengesRecommendations(section.content);
//       case 'TableComponent':
//         return TableComponent(section.content);
//       case 'TextEditor':
//         return TextEditor(section.content);
//       default:
//         return '';
//     }
//   }).join('');
// };

// export const composeHTMLReport = (sections) => {
//   const content = convertToHTML(sections);
//   return `
//     <!DOCTYPE html>
//     <html lang="en">
//       <head>
//         <meta charset="UTF-8">
//         <meta name="viewport" content="width=device-width, initial-scale=1.0">
//       </head>
//       <body>
//         ${content}
//       </body>
//     </html>
//   `;
// };


// src/convert.js


export const convertToHTML = (sections) => {
  return sections.map(section => {
    switch (section.templateId) {
      case 'StaticComponent':
        return StaticComponent(section.content);
      case 'FlowComponents':
        return FlowComponents(section.content);
      case 'ChallengesRecommendations':
        return ChallengesRecommendations(section.content);
      case 'TableComponent':
        return TableComponent(section.content);
      case 'TextEditor':
        return TextEditorTemplate(section.content);
      case 'SessionContentEditor':
        return SessionContentEditor(section.content);
      case 'Recommendations':
        return Recommendations(section.content);
      default:
        return '';
    }
  }).join('');
};

export const composeHTMLReport = (sections) => {
  const content = convertToHTML(sections);

  console.log(content);



  return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>
      <body>
        ${content}
      </body>
    </html>
  `;
};