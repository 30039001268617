import React from 'react';

const NavBar = ({ onGenerateReport }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between items-center">
      <button 
        onClick={onGenerateReport} 
        className="px-6 py-2 bg-green-600 rounded hover:bg-green-700 transition">
        Generate Report
      </button>
    </div>
  );
};

export default NavBar;