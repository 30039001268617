import React, { useState, useEffect } from 'react';
import { getRegions } from '../../service/AssesmentApi';
import { regionsModel } from '../../interface/questionnaireInterface';
export default function WafrConnector(props: any) {
  const {
    activeConnector,
    validateConnectorInput,
    connectorsOnChange,
    setShowConnectorForm,
    connectorFormData,
    setConnectorFormData,
    connectorFormValidationMsg,
    connectorApiValidationMsg,
    resetValidationMessages,
    isLoading,
    verticalName
  } = props;

  const [isRegionDropdownOpen, setIsRegionDropdownOpen] = useState(false);

  const [regions, setRegions] = useState<regionsModel[]>([]);

  const [showPasswords, setShowPasswords] = useState({
    "IAM Access Key": false,
    "Secret Key": false,
    "Account ID": false,
    "Databricks Secret Key": false

  });
  type PasswordFields = "IAM Access Key" | "Secret Key" | "Account ID" | "Databricks Secret Key";


  useEffect(() => {
    fetchRegions();
  }, []);


  const togglePasswordVisibility = (field: PasswordFields) => {
    setShowPasswords(prev => ({ ...prev, [field]: !prev[field] }));
  };


  const fetchRegions = async () => {
    try {
      isLoading(true);
      const response = await getRegions();

      if (response.status === 200) {
        const formattedRegions = response.data.map((region: any) => ({
          regionId: region.aws_region_details_id,
          regionName: region.region_name
        }));
        setRegions(formattedRegions);
      }
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
    isLoading(false)
  };

  const handleRegionChange = (regionName: string) => {
    const updatedRegions = connectorFormData["Region"] ? connectorFormData["Region"].split(',') : [];
    if (updatedRegions.includes(regionName)) {
      updatedRegions.splice(updatedRegions.indexOf(regionName), 1);
    } else {
      updatedRegions.push(regionName);
    }
    updateRegions(updatedRegions);
  };


  const updateRegions = (updatedRegions: string[]) => {
    connectorsOnChange({
      target: {
        name: "Region",
        value: updatedRegions.join(','),
      },
    });
  };

  const removeRegion = (regionName: string) => {
    const updatedRegions = connectorFormData["Region"].split(',').filter((r: string) => r !== regionName);
    updateRegions(updatedRegions);
  };

  const bindConnectorsForm = () => {
    const bindSaveAndCancel = () => {
      return (
        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            onClick={() => {
              validateConnectorInput(null);
            }}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              setConnectorFormData({});
              resetValidationMessages(activeConnector);
              setShowConnectorForm(true);
            }}
          >
            Cancel
          </a>
        </div>
      );
    };
    switch (activeConnector) {
      case "AWS":
        return (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <h3 className="font-18 font-semibold color-black mt-4 mb-4">
              AWS Connector
            </h3>
            <div className="col-lg-8 col-md-12 col-12 mb-4">
              <div className="row">
                {(["IAM Access Key", "Secret Key"] as const).map((field) => (
                  <div className="col-12 col-md-12 col-lg-6" key={field}>
                    <div className="mb-5">
                      <label
                        htmlFor={field}
                        className="form-label font-14 font-semibold color-black"
                      >
                        {field}
                      </label><span className="color-red"> *</span>
                      <div className="position-relative">
                        <input
                          type={showPasswords[field] ? "text" : "password"}
                          className="form-control theme-form font-regular font-14 pe-5 hide-password-reveal"
                          id={field}
                          name={field}
                          placeholder={`Enter ${field}`}
                          value={connectorFormData[field]}
                          onChange={connectorsOnChange}
                        />
                        <button
                          type="button"
                          className="btn btn-link position-absolute end-0 top-50 translate-middle-y"
                          onClick={() => togglePasswordVisibility(field)}
                          style={{ backgroundColor: 'transparent', border: 'none' }}
                        >
                          <img
                            src={showPasswords[field] ? "images/eye-show.svg" : "images/eye-hide.svg"}
                            alt={showPasswords[field] ? "Hide" : "Show"}
                            width="20"
                            height="20"
                          />
                        </button>
                      </div>
                      <p className="mt-2 mb-0 color-red font-14 font-regular">
                        {connectorFormValidationMsg[field]}
                      </p>
                    </div>
                  </div>
                ))}

                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Region"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Region
                    </label><span className="color-red"> *</span>
                    <div className="dropdown">
                      <button
                        className="multiselect-control form-control theme-form font-regular font-14 text-start color-fade d-flex justify-content-between align-items-center"
                        type="button"
                        id="regionDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded={isRegionDropdownOpen}
                        onClick={() => setIsRegionDropdownOpen(!isRegionDropdownOpen)}
                      >
                        <span>
                          {connectorFormData["Region"] ?
                            <div className="d-flex flex-wrap gap-2">
                              {connectorFormData["Region"].split(',').map((region: string) => (
                                <span key={region} className="badge font-regular font-12 bg-secondary">
                                  {region}
                                  <button
                                    type="button"
                                    className="btn-close btn-close-white ms-2"
                                    aria-label="Close"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeRegion(region);
                                    }}
                                  ></button>
                                </span>
                              ))}
                            </div>
                            :
                            'Select Regions'
                          }
                        </span>
                        <img src="images/arrow.png" alt="Dropdown arrow" height={15} width={15} className="ms-3" />
                      </button>
                      <ul className={`dropdown-menu w-100 overflow-auto dropdown-menu-scroll ${isRegionDropdownOpen ? 'show' : ''}`} aria-labelledby="regionDropdown">
                        {regions.map((region: regionsModel) => (
                          <li key={region.regionId}>
                            <div className="dropdown-item">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`region-${region.regionId}`}
                                  checked={connectorFormData["Region"] && connectorFormData["Region"].includes(region.regionName)}
                                  onChange={() => handleRegionChange(region.regionName)}
                                />
                                <label className="form-check-label" htmlFor={`region-${region.regionId}`}>
                                  {region.regionName}
                                </label>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Region"]}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="mb-5">
                    <label
                      htmlFor="Account ID"
                      className="form-label font-14 font-semibold color-black"
                    >
                      Account ID
                    </label><span className="color-red"> *</span>
                    <div className="position-relative">
                      <input
                        type={showPasswords["Account ID"] ? "text" : "password"}
                        className="form-control theme-form font-regular font-14 pe-5 hide-password-reveal"
                        id="Account ID"
                        name="Account ID"
                        placeholder="Enter Account ID"
                        value={connectorFormData["Account ID"]}
                        onChange={connectorsOnChange}
                      />
                      <button
                        type="button"
                        className="btn btn-link position-absolute end-0 top-50 translate-middle-y"
                        onClick={() => togglePasswordVisibility("Account ID")}
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                      >
                        <img
                          src={showPasswords["Account ID"] ? "images/eye-show.svg" : "images/eye-hide.svg"}
                          alt={showPasswords["Account ID"] ? "Hide" : "Show"}
                          width="20"
                          height="20"
                        />
                      </button>
                    </div>
                    <p className="mt-2 mb-0 color-red font-14 font-regular">
                      {connectorFormValidationMsg["Account ID"]}
                    </p>
                  </div>
                </div>

                {verticalName === 'Databricks' && (
                  <>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="mb-5">
                        <label htmlFor="Databricks Client ID" className="form-label font-14 font-semibold color-black">
                          Databricks Client ID
                        </label>
                        <input
                          type="text"
                          className="form-control theme-form font-regular font-14"
                          id="Databricks Client ID"
                          name="Databricks Client ID"
                          placeholder="Enter Databricks Client ID"
                          value={connectorFormData["Databricks Client ID"]}
                          onChange={connectorsOnChange}
                        />
                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg["Databricks Client ID"]}
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="mb-5">
                        <label htmlFor="Databricks Secret Key" className="form-label font-14 font-semibold color-black">
                          Databricks Secret Key
                        </label>
                        <div className="position-relative">
                          <input
                            type={showPasswords["Databricks Secret Key"] ? "text" : "password"}
                            className="form-control theme-form font-regular font-14 pe-5 hide-password-reveal"
                            id="Databricks Secret Key"
                            name="Databricks Secret Key"
                            placeholder="Enter Databricks Secret Key"
                            value={connectorFormData["Databricks Secret Key"]}
                            onChange={connectorsOnChange}
                          />
                          <button
                            type="button"
                            className="btn btn-link position-absolute end-0 top-50 translate-middle-y"
                            onClick={() => togglePasswordVisibility("Databricks Secret Key")}
                            style={{ backgroundColor: 'transparent', border: 'none' }}
                          >
                            <img
                              src={showPasswords["Databricks Secret Key"] ? "images/eye-show.svg" : "images/eye-hide.svg"}
                              alt={showPasswords["Databricks Secret Key"] ? "Hide" : "Show"}
                              width="20"
                              height="20"
                            />
                          </button>
                        </div>
                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg["Databricks Secret Key"]}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="mb-5">
                        <label htmlFor="Resource Group Name" className="form-label font-14 font-semibold color-black">
                          Resource Group Name
                        </label>
                        <input
                          type="text"
                          className="form-control theme-form font-regular font-14"
                          id="Resource Group Name"
                          name="Resource Group Name"
                          placeholder="Enter Resource Group Name"
                          value={connectorFormData["Resource Group Name"]}
                          onChange={connectorsOnChange}
                        />
                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg["Resource Group Name"]}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="mb-5">
                        <label htmlFor="Host Name" className="form-label font-14 font-semibold color-black">
                          Host Name
                        </label>
                        <input
                          type="text"
                          className="form-control theme-form font-regular font-14"
                          id="Host Name"
                          name="Host Name"
                          placeholder="Enter Host Name"
                          value={connectorFormData["Host Name"]}
                          onChange={connectorsOnChange}
                        />
                        <p className="mt-2 mb-0 color-red font-14 font-regular">
                          {connectorFormValidationMsg["Host Name"]}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <p className="mt-1 mb-0 color-red font-14 font-regular">
                  {connectorApiValidationMsg}
                </p>
                {bindSaveAndCancel()}
              </div>
            </div>
          </div>
        );
    }
  };

  return <>{bindConnectorsForm()}</>;
}