// SQ_2.0-2.2: Import statements
import React, { useState, useEffect } from 'react';
import PreProcessComponent from './PreProcessComponent';
import PostProcessComponent from './PostProcessComponent';
import { getAttributesDataApi, postErrorLog } from '../../Service/api';
import Loader from '../Loader/Loader';



// SQ_NO_2.0 - SQ_NO_2.13: Wrapper component for attribute handling
const AttributeWorkbenchWrapper = ({ domainId, assessmentId, assessmentName }) => {

  const [attributesData, setAttributesData] = useState([]);
  const [preProcessData, setPreProcessData] = useState([]);
  const [postProcessData, setPostProcessData] = useState([]);
  const [activeTab, setActiveTab] = useState("Pre Processing");
  const [assessmentTypes, setAssessmentTypes] = useState([]);
  const [showLoader, setShowLoader] = useState(false)

  const userId = localStorage.getItem('userId')


  // SQ_NO_2.6: Fetch initial data and handle data segregation
  useEffect(() => {
    fetchData();
  }, [domainId, assessmentId]);

  // Fetches attribute data based on the user ID and updates relevant state variables.

  //   Details:
  //   --------
  //   Constructs a payload with the user ID, then fetches attribute data using `getAttributesDataApi`. Upon successful response, updates the state to store the fetched attribute data, pre-process data, post-process data, and assessment types. Handles errors by logging them and ensures the loading state is properly managed.

  //   Formula:
  //   --------
  //   payload = {
  //       user_id: userId
  //   }

  //   API Call:
  //   --------
  //   response = await getAttributesDataApi(payload)
    
  //   If response is successful:
  //      set preprocess data,post process data, assessment types

  //   Error Handling:
  //   catch the error and call handleError function
    
  // SQ_NO_2.6- 2.22: Get attributes data
  const fetchData = async () => {
    try {
      const payload = {
        "user_id": userId
      }
      setShowLoader(true)
      const response = await getAttributesDataApi(payload);
      setShowLoader(false)
      console.log(response, "response");

      setAttributesData(response.data.data)
      setPreProcessData(response.data.data.pre_process)
      setPostProcessData(response.data.data.post_process)
      setAssessmentTypes(response.data.data.assessment_types)

    } catch (error) {
      setShowLoader(false)
      handleError(error, "fetchAttributesData")
    }
  };

  // Logs and handles errors that occur in API calls.

  //   Parameters:
  //   -----------
  //   error : object
  //       The error object received from a failed API call.
  //   functionName : string
  //       The name of the function where the error occurred.

  //   Details:
  //   --------
  //   Constructs an error data object including user ID, error type, module name, and error description. Sends this data to `postErrorLog`.

  //   Formula:
  //   --------
  //   errorData = {
  //      userid,message,module_name,type
  //   }
  //SQ_1.0 - 1.3: Handles post error log
  const handleError = (error, functionName) => {
    const errorData = {
      user_id: userId,
      type: "error",
      error_module_name: functionName,
      error_description: error?.message + " ," + error?.response?.data?.message
    };
    postErrorLog(errorData);
  };

  //### `handleTabClick`

// Handles the click event on a tab to switch between different tabs.

//     Parameters:
//     -----------
//     tabName : string
//         The name of the tab to activate.

//     Details:
//     --------
//     Sets the active tab name in the state, which can be used to determine which tab's content should be displayed.

//     Formula:
//     --------
//     setActiveTab(tabName) 
  //SQ_2.23: Handle tab switch
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  console.log(preProcessData, postProcessData, "fdlnhsbvjdfkl;");


  // SQ_NO_2.25 - SQ_NO_2.27: Render components
  return (
    <>
        {showLoader ? (
            <Loader />
        ) : (
            <>
                <h2 className="font-24 font-semibold heading-txt-color">
                    Attributes
                </h2>

                <div className="col-md-12">
                    <div className="bg-white rounded-2 tab-container p-3">
                        {/* Tab Section */}
                        <ul
                            className="nav nav-tabs custom-tabs border-0 d-flex gap-3"
                            role="tablist"
                        >
                            <li className="nav-item" role="presentation">
                                <a
                                    className={`nav-link ${activeTab === "Pre Processing" ? "active" : ""} text-grey font-16`}
                                    data-bs-toggle="tab"
                                    aria-selected={activeTab === "Pre Processing"}
                                    role="tab"
                                    id="pre"
                                    onClick={() => handleTabClick("Pre Processing")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Pre Processing
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className={`nav-link ${activeTab === "Post Processing" ? "active" : ""} text-grey font-16`}
                                    data-bs-toggle="tab"
                                    aria-selected={activeTab === "Post Processing"}
                                    role="tab"
                                    id="post"
                                    onClick={() => handleTabClick("Post Processing")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Post Processing
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            {activeTab === "Pre Processing" && (
                                <div
                                    className="tab-pane fade show active tab-section mt-4"
                                    id="pre"
                                    role="tabpanel"
                                >
                                    <PreProcessComponent
                                        data={preProcessData}
                                        refreshAttributes={fetchData}
                                        domainId={domainId}
                                        assessmentId={assessmentId}
                                        assessmentTypes={assessmentTypes}
                                    />
                                </div>
                            )}
                            {activeTab === "Post Processing" && (
                                <div
                                    className="tab-pane fade show active tab-section mt-4"
                                    id="post"
                                    role="tabpanel"
                                >
                                    <PostProcessComponent
                                        data={postProcessData}
                                        refreshAttributes={fetchData}
                                        domainId={domainId}
                                        assessmentId={assessmentId}
                                        assessmentTypes={assessmentTypes}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )}
    </>
);
};

      export default AttributeWorkbenchWrapper;


