import React from 'react';

function Loader() {
  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-center loader-container text-center flex-column">
          <div className="spinner-border custom-spinner-loader" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="text-white font-semibold">Loading</p>
        </div>
      </div>
    </>
  );
}

export default Loader;