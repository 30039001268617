import React, { useEffect, useState, useRef } from 'react';
import { Excalidraw, MainMenu, WelcomeScreen } from '@excalidraw/excalidraw';
import { parseMermaidToExcalidraw } from '@excalidraw/mermaid-to-excalidraw';
import { convertToExcalidrawElements } from '@excalidraw/excalidraw';

import './style.css';

const initialData = {
    libraryItems: [],
};

const Canvas = () => {
    const excalidrawRef = useRef(null);
    const [state, setState] = useState({
        elements: [],
        loading: false,
        error: null,
    });

    const [files, setFiles] = useState({});
    const [exportedImage, setExportedImage] = useState(null);

    const staticMermaidDiagram = `
    %%{init: {'theme': 'base', 'themeVariables': { 'primaryColor': '#1f77b4', 'edgeLabelBackground':'#cccccc', 'clusterBkg':'#d3d3d3', 'tertiaryColor': '#ffffff'}} }%%
    graph TD
        subgraph Large_Scale_AWS_Architecture_Diagram
            user[Account]

            subgraph Region_1
                direction LR
                vpc1[VPC 1]
            end

            subgraph Region_2
               direction LR
                vpc2[VPC 2]
            end
            user -->|Access| vpc1
            user -->|Access| vpc2
        end `;

    useEffect(() => {
        setFiles('dmkedm');
    }, [staticMermaidDiagram]);

    const staticElements = [];

    const generateElementsFromMermaid = async (mermaidCode) => {
        setState((prev) => ({
            ...prev,
            elements: [],
            loading: true,
            error: null,
        }));
        if (!mermaidCode.trim()) {
            setState({
                elements: staticElements,
                loading: false,
                error: null,
            });
            return;
        }
        try {
            const { elements } = await parseMermaidToExcalidraw(mermaidCode, { fontSize: 16 });
            let excalidrawElements = convertToExcalidrawElements(elements);
            excalidrawElements = excalidrawElements.map(el => ({ ...el, fontFamily: 2, roughness: 0, fontSize: 20 }));
            setState({
                elements: excalidrawElements,
                loading: false,
                error: null,
            });
        } catch (error) {
            console.error('Error generating elements from Mermaid:', error);
            setState({
                elements: [],
                loading: false,
                error: error.message,
            });
        }
    };

    useEffect(() => {
        if (Object.keys(files).length > 0) {
            generateElementsFromMermaid(staticMermaidDiagram);
        }
    }, [files]);

    const exportImage = async () => {
        if (excalidrawRef.current) {
            const scene = excalidrawRef.current.getSceneElements();
            const appState = excalidrawRef.current.getAppState();

            const { data } = await excalidrawRef.current.exportToBlob({ mimeType: 'image/png', quality: 1 });
            const url = URL.createObjectURL(data);
            setExportedImage(url);
        } else {
            console.error("Excalidraw ref is not populated");
        }
    };

    useEffect(() => {
        if (excalidrawRef) {
            console.log("currentValue", excalidrawRef);
        }
    }, [excalidrawRef]);

    return (
        <div className="canvas-container">
            {state.error && (
                <div className="error-message">
                    <h3>Error:</h3>
                    <pre>{state.error}</pre>
                </div>
            )}

            {state.loading ? (
                <div className="loading">Rendering Diagram...</div>
            ) : (
                <>
                    <Excalidraw
                        ref={excalidrawRef}
                        initialData={{
                            elements: state.elements,
                            files: files,
                        }}
                        onChange={(elements) => {
                            console.log(elements, 'elementsData');
                        }}
                    >
                        <MainMenu>
                            <MainMenu.DefaultItems.ClearCanvas />
                            <MainMenu.DefaultItems.SaveAsImage />
                            <MainMenu.DefaultItems.ToggleTheme />
                            <MainMenu.DefaultItems.Export />
                            <MainMenu.DefaultItems.SaveToActiveFile />
                            <MainMenu.DefaultItems.ClearCanvas />
                            <MainMenu.DefaultItems.LiveCollaborationTrigger />
                            <MainMenu.DefaultItems.Help />
                            <MainMenu.DefaultItems.LoadScene />
                            <MainMenu.DefaultItems.ChangeCanvasBackground />
                        </MainMenu>
                        <WelcomeScreen>
                            <WelcomeScreen.Center>
                                <WelcomeScreen.Center.Logo />
                                <WelcomeScreen.Center.Heading>
                                    Welcome to zeb pulse
                                </WelcomeScreen.Center.Heading>
                                <WelcomeScreen.Center.Menu />
                            </WelcomeScreen.Center>
                        </WelcomeScreen>
                    </Excalidraw>

                    <button onClick={exportImage}>Export and Store Image</button>
                    {exportedImage && <img src={exportedImage} alt="Exported Diagram" />}
                </>
            )}
        </div>
    );
};

export default Canvas;