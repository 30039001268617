import React, { createContext, ReactNode, SetStateAction, useContext, useState } from "react"

const DatabricksContext = createContext<DataContextType | undefined>(undefined);

interface DataContextType {
    businessOverview: string;
    setBusinessOverview: React.Dispatch<SetStateAction<string>>;
    isExecutiveSummaryUpdated: boolean;
    setIsExecutiveSummaryUpdated: React.Dispatch<SetStateAction<boolean>>;
    isChallengesRecommendationsDataUpdated: boolean;
    setIsChallengesRecommendationsDataUpdated: React.Dispatch<SetStateAction<boolean>>;
}


interface DatabricksDataProviderType {
    children: ReactNode
}


const DatabricksContextObject: React.FC<DatabricksDataProviderType> = ({ children }) => {
    const [businessOverview, setBusinessOverview] = useState<string>("");
    const [isExecutiveSummaryUpdated, setIsExecutiveSummaryUpdated] = useState<boolean>(false);
    const [isChallengesRecommendationsDataUpdated, setIsChallengesRecommendationsDataUpdated] = useState<boolean>(false);

    const dataContextObject: DataContextType = {
        businessOverview,
        setBusinessOverview,
        isExecutiveSummaryUpdated,
        setIsExecutiveSummaryUpdated,
        isChallengesRecommendationsDataUpdated,
        setIsChallengesRecommendationsDataUpdated,
    }

    return (
        <DatabricksContext.Provider value={dataContextObject}>
            {children}
        </DatabricksContext.Provider>
    )
}


export const useDatabricksContextObject = () => {
    
    const context = useContext(DatabricksContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context

}


export default DatabricksContextObject;