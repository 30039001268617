// Components/LeftNav.jsx
import React from 'react';

const LeftNav = ({ sections, setActiveSectionId, onAddSection }) => {
  console.log("sections in left nav", sections);

  // Check if sections is an object and has a sections property
  const sectionsArray = Array.isArray(sections) ? sections : (sections && sections.sections) || [];

  return (
    <div className="w-full space-y-4">
      <button 
        className="w-full px-4 py-2 bg-blue-500 text-white rounded"
        onClick={onAddSection}
      >
        + Add Section
      </button>
      <ul className="w-full space-y-2">
        {sectionsArray.map((section, index) => (
          <li key={section.id || index} className="w-full">
            <button
              className="w-full px-4 py-2 text-left bg-gray-200 rounded hover:bg-gray-300"
              onClick={() => setActiveSectionId(section.id)}
            >
              {
              section?.content?.navTitle|| section?.content?.title || 'Unnamed Section'}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftNav;