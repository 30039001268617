
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import { MainLayout } from './Components/ReportGeneration/MainLayout';
import { useSections } from './Components/ReportGeneration/useSections';
import Login from './Components/Login/Login';
import ClientCards from './Components/Clients/ClientCards';
import Canvas from './Components/Excalidraw/Canvas';
import AssessmentsGrid from './Components/Assessments/AssessmentsGrid';
import Approvals from './Components/Admin/Approvals';
import MainReportComponent from './Components/Report/MainReportComponent';

import { MsalProvider } from '@azure/msal-react';
import AttributesMain from './Components/Attributes/AttributesMain';
import {  useEffect, useState } from 'react';
import { ContextProvider, useOrgContext } from './Components/Context/Context';
import SessionTimeOutPopup from './Components/Header/SessionTimeOutPopup';
import WorkflowWrapper from './Components/Workflow/WorkflowWrapper';
import Header from './Components/Header/Header';
import Workbench from './Components/Workflow/Workbench';
import ArchitectureWorkbench from './Components/Workflow/ArchitectureWorkbench';

const msalInstance = new PublicClientApplication(msalConfig)

function AppContent() {

  const location=useLocation();
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('darkTheme') === 'true');
  const { sessionTimeoutPopup } = useOrgContext();
  const navigate = useNavigate();
  const hideHeaderRoutes = ['/', '/forgetPassword', '/oops', '/signUp', '/signUpStepTwo'];
	const shouldShowHeader = !hideHeaderRoutes.includes(location.pathname);

  

  console.log(sessionTimeoutPopup,"log");
 

  const {
    sectionsData,
    selectedSection,
    setSelectedSection,
    updateSectionContent,
    addSection,
    handleGenerateReport
  } = useSections();

  useEffect(()=>{
		if(!(localStorage.getItem('userName') && localStorage.getItem('userId') && localStorage.getItem('jwt'))){
			navigate('/')
		}
	},[])

  useEffect(() => {
    localStorage.setItem('darkTheme', isDarkMode);
}, [isDarkMode]);


console.log(isDarkMode,"isDarkMode");


  return (
    (localStorage.getItem('userName') && localStorage.getItem('userId') && localStorage.getItem('jwt')) ?
    <div  className={isDarkMode ? 'dark-theme' : ''}>
    {sessionTimeoutPopup && 
				<SessionTimeOutPopup  />
			}

{shouldShowHeader && 
				(
					window.location.pathname !== '/Login' && 
					window.location.pathname !== '/unauthorizedAccess')
           && 
				<Header isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />}
      <Routes >

        <Route path='/' element={<Login isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />} />
        <Route path="/Client-onboarding" element={<ClientCards />} />
        <Route path="/Canvas" element={<Canvas />} />
        <Route path="/AssessmentsGrid" element={<AssessmentsGrid />} />
        <Route path="/AttributesMain" element={<AttributesMain />} />
        <Route path="/Report" element={<MainReportComponent />} />

        <Route path="/Workflow" element={<WorkflowWrapper/>} />
        <Route path="/Workbench" element={<Workbench/>} />
        <Route path="/ArchitectureWorkbench" element={<ArchitectureWorkbench/>} />
        


        <Route path="/Approvals" element={<Approvals />} />
        <Route path="/MainLayout" element={
          <MainLayout sections={sectionsData.sections}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            updateSectionContent={updateSectionContent}
            addSection={addSection}
            handleGenerateReport={handleGenerateReport} />} />


      </Routes>

    </div>
    : <Routes>
		<Route path='/' element={<Login  isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/>}/>
		</Routes>
  );
}

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <ContextProvider>
            <AppContent />
        </ContextProvider>

      </Router>
    </MsalProvider>
  );
}

export default App;



