import { useEffect, useState } from "react";
import { postErrorLog, saveClientInfoApi } from "../../Service/api";

function ClientInfoPopup({ getAllClientData, setClientInfoPopup, editMode = false, setEditMode, clientDataToEdit, industryData }) {

    //SQ_5.3 - 5.4: 
    const [clientData, setClientData] = useState({
        clientName: '',
        clientsLogo: null,
        logoName: '',
        industryType: '',
        employeeCount: '',
        websiteUrl: ''
    });
    const userId = localStorage.getItem('userId');
    const[error,setError]=useState('')

    //5.18 -5.19: Initial useEffect when edit client
    useEffect(() => {
        editClientDataById();
    }, [clientDataToEdit]);


    // Initializes the client data form with the data of the client being edited.

    //         Parameters:
    //     -----------
    //         clientDataToEdit : object
    //         The data of the client that is to be edited.

    //         Details:
    //     --------
    //         If`clientDataToEdit` is available, extracts the client's logo URL, logo filename, and various client information (name, industry type, employee count, revenue, website URL) from `clientDataToEdit`. It then updates the `clientData` state with this information.

    //     Formula:
    //     --------
    //         logoUrl = clientDataToEdit.client_logo
    //     fileName = logoUrl.split('/').pop()

    //     setClientData() to replace the current client data with the edited data.
    //5.19 -5.22:  Prepopulate the fields 
    const editClientDataById = () => {
        if (clientDataToEdit) {

            const fileName = clientDataToEdit.client_logo
            ? `${clientDataToEdit.client_name}_logo`
            : null;

            setClientData({
                clientName: clientDataToEdit.client_name,
                clientsLogo: clientDataToEdit.client_logo,
                logoName: fileName,
                industryType: clientDataToEdit.industry_type,
                employeeCount: mapEmployeeCount(clientDataToEdit.employee_count),
                websiteUrl: clientDataToEdit.website_url
            });
        }
    };

    // Maps an employee count to a corresponding string range.

    // Parameters:
    // -----------
    // count : int
    //     The number of employees.

    // Returns:
    // --------
    // string
    //     The employee range that corresponds to `count`.

    // Formula:
    // --------
    // If count <= 50: return '1-50'
    // If count <= 200: return '51-200'
    // If count <= 500: return '201-500'
    // If count <= 1000: return '501-1000'
    // Else: return '1000+'
    //SQ_5.23 - 5.24 : Maps employee count to a readable format
    const mapEmployeeCount = (count) => {
        if (count <= 50) return '1-50';
        if (count <= 200) return '51-200';
        if (count <= 500) return '201-500';
        if (count <= 1000) return '501-1000';
        return '1000+';
    };

    // Handles changes in input fields and updates the appropriate state.

    // Parameters:
    // -----------
    // event : object
    //     The event object corresponding to the input change.

    // Details:
    // --------
    // When the input type is 'file' and the file size is within a 5MB limit, reads the file as a Data URL and updates the `clientsLogo` and `logoName` in the state. For other input types, updates the state with the new input value, using the input's `name` attribute to identify which part of the state to update.

    // Formula:
    // --------
    // If type == 'file' AND file.size <= 5MB:
    //     set the clientsLogo to the Data URL of the file
    //     set the logoName to the name of the file

    // Else:
    //   push the new value to the state using the input's name as the key
    //SQ_5.7 - 5.17 : Handles the input field change
    const handleInputChange = (event) => {
        const { name, value, type, files } = event.target;
    
        if (type === 'file' && files.length > 0) {
            const file = files[0];
            const maxSize = 5 * 1024 * 1024; // 5MB
    
            // Validate file type
            if (file.type !== "image/jpeg" && file.type !== "image/jpg") {
                setError("Only .jpg and .jpeg files are allowed.");
                event.target.value = ""; // Clear the file input
                return;
            }
    
            // Validate file size
            if (file.size > maxSize) {
                setError("File size exceeds 5MB limit.");
                event.target.value = ""; // Clear the file input
                return;
            }
    
            // If valid, process the file
            setError(""); // Clear any previous error
            const reader = new FileReader();
            reader.onloadend = () => {
                setClientData(prevData => ({
                    ...prevData,
                    clientsLogo: reader.result,
                    logoName: file.name,
                }));
            };
            reader.readAsDataURL(file);
        } else {
            setClientData(prevData => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    // Handles form submission to save or update client information.

    // Parameters:
    // -----------
    // event : object
    //     The event object for form submission.

    // Details:
    // --------
    // Prevents the default form submission behavior. Constructs a payload with user's ID, action type ('update'), client ID, updated client logo URL, industry type, and website URL. Sends this payload to the `saveClientInfoApi`. Upon successful response, retrieves all client data afresh and closes the form.

    // Formula:
    // --------
    // payload = userr_id, action, client_id, client_logo, industry_type, website_url

    // Response Handling:
    // If response.status == 200:
    //  refreshClientData() and set popup Open(false)
    //SQ_5.26 -5.51: Handle the client submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = {
            "user_id": userId,
            "action": "update",  // Admin actions are limited to update and delete 
            "client_id": clientDataToEdit.client_id,  // Required for identifying the client to be updated or deleted
            "client_logo": clientData.clientsLogo,  // Updated logo URL
            "industry_type": clientData.industryType,  // Updated industry type
            "website_url": clientData.websiteUrl
        };

        try {
            const response = await saveClientInfoApi(payload);
            if (response.status === 200) {
                await getAllClientData();
                setClientInfoPopup(false);
            }
        } catch (error) {
            handleError(error, "saveCLientInfo");
        }
    };

    // Logs error information by sending it to `postErrorLog`.

    // Parameters:
    // -----------
    // error : object
    //     The error object received from a failed API call.
    // functionName : string
    //     The name of the function where the error occurred.

    // Details:
    // --------
    // Constructs an error data object including user's ID, error type, name of the module where the error occurred, and error description. Sends this data to `postErrorLog`.

    // Formula:
    // --------
    // errorData = {
    //     user_id: userId,
    //     type: "error",
    //     error_module_name: functionName,
    //     error_description: error.message + " ," + error.response.data.body.message
    // }
    //SQ_1.0- 1.3: Handles error posting
    const handleError = (error, functionName) => {
        const errorData = {
            user_id: userId,
            type: "error",
            error_module_name: functionName,
            error_description: error?.message + " ," + error?.response?.data?.body?.message
        };
        postErrorLog(errorData);
    };


    // Resets the client data to an initial state and closes the form.

    // Details:
    // --------
    // Resets `clientData` to its initial state with default values. Closes the client information popup and disables edit mode.

    // Formula:
    // --------
    // setClientData to initial state
    // setClientInfoPopup to false
    // setEditMode to false
    //SQ_5.53-5.54
    const handleCancel = () => {
        setClientData({
            clientName: '',
            clientsLogo: null,
            logoName: '',
            industryType: '',
            employeeCount: '',
            websiteUrl: ''
        });
        setClientInfoPopup(false);
        setEditMode(false);
    };

    // Removes the currently selected logo and clears the logo file input.

    // Details:
    // --------
    // Updates the `clientData` state to remove the `clientsLogo` and `logoName`. Also, clears the file input value to ensure no file is selected.

    // Formula:
    // --------
    // setClientData by removing clientsLogo and logoName
    // clear the file input

    //SQ_10.0 -10.1
    const handleRemoveLogo = () => {
        setClientData(prevData => ({
            ...prevData,
            clientsLogo: null,
            logoName: ''
        }));
        setError("");

        const fileInput = document.getElementById('logo-upload');
        if (fileInput) {
            fileInput.value = '';
        }
    };
    console.log(clientData,"clientData");
    

    return (
        <div
            className="modal fade show modal-lg-only loader-popup-container configure-limit-container"
            style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" , zIndex: '10000' }}
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content vh-75 overflow-hidden radius-6 border-0">
                    <div className="modal-header border-0 p-4">
                        <img src="images/Client-icon.svg" alt="" className="me-3" />
                        <h4 className="mb-0 font-18 font-semibold text-grey-v1">
                            {editMode ? "Edit Client" : "Add Client"}
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={handleCancel}
                        />
                    </div>
                    <div className="p-4 pt-1">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label font-14 font-medium" htmlFor="Client">
                                    Client Name <span className="input-improtent-section">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control w-100 mb-4 mt-2"
                                    id="Client"
                                    name="clientName"
                                    value={clientData.clientName}
                                    onChange={handleInputChange}
                                    disabled={editMode}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label
                                    className="form-label font-14 font-medium"
                                    htmlFor="industry-type"
                                >
                                    Client Logo <span className="input-improtent-section">*</span>
                                </label>
                                <div className="mt-2">
                                    <button className="btn secondary-btn file-upload-chat-area link-color">
                                        <img src="images/uplode-icon.svg" alt="" /> Upload
                                        <input
                                            type="file"
                                            name="clientsLogo"
                                            onChange={handleInputChange}
                                            accept=".jpg, .jpeg"
                                            id="logo-upload"
                                        />
                                    </button>
                                    <span className="font-12 text-grey-v1 ms-4">
                                        {clientData.logoName || 'No file chosen'}
                                        {clientData.logoName && (
                                            <a href="#" onClick={handleRemoveLogo}>
                                                <img
                                                    className="ms-2"
                                                    src="images/file-close-icon.svg"
                                                    alt=""
                                                />
                                            </a>
                                        )}
                                    </span>
                                </div>
                                {error && <div className="text-danger font-12 mt-2">{error}</div>}
                            </div>
                          
                            <div className="col-md-6 mb-3">
                                <label
                                    className="form-label font-14 font-medium"
                                    htmlFor="industry-type"
                                >
                                    Industry Type <span className="input-improtent-section">*</span>
                                </label>
                                <select
                                    className="form-select migrate-form-ui mb-4 mt-2"
                                    id="industryType"
                                    name="industryType"
                                    value={clientData.industryType}
                                    onChange={handleInputChange}
                                >
                                    <option value='' >Select Industry</option>
                                    {industryData?.map((industry) => (
                                        <option key={industry.id} value={industry.id}>
                                            {industry.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label
                                    className="form-label font-14 font-medium"
                                    htmlFor="employee"
                                >
                                    No. of Employees <span className="input-improtent-section">*</span>
                                </label>
                                <select
                                    className="form-select migrate-form-ui mb-4 mt-2"
                                    id="employee"
                                    name="employeeCount"
                                    value={clientData.employeeCount}
                                    onChange={handleInputChange}
                                    disabled={editMode}
                                >
                                    <option value="">Select Employee Count</option>
                                    <option value="1-50">1-50</option>
                                    <option value="51-200">51-200</option>
                                    <option value="201-500">201-500</option>
                                    <option value="501-1000">501-1000</option>
                                    <option value="1000+">1000+</option>
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label
                                    className="form-label font-14 font-medium"
                                    htmlFor="Website-type"
                                >
                                    Website URL <span className="input-improtent-section">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="Website-type"
                                    className="form-control w-100 mb-4 mt-2"
                                    name="websiteUrl"
                                    value={clientData.websiteUrl}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-12">
                                <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                                    <button className="btn outline-button py-2 px-4" onClick={handleCancel}>Cancel</button>
                                    <button className="btn primary-button py-2 px-4" onClick={handleSubmit}
                                    disabled={
                                        !clientData.clientName?.trim() ||
                                        !clientData.industryType?.trim() ||
                                        !clientData.employeeCount || // Ensure it's not 0 or NaN
                                        !clientData.websiteUrl?.trim() ||
                                        !clientData.clientsLogo 
                                    }
                                    >
                                        {editMode ? "Update" : "Add"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ClientInfoPopup;