
import { useState, useEffect } from 'react';

//SQ_8.9-8.18:
const useSessionTimeout = (timeout = 1800000, onTimeout) => { // 30 minutes
    const [lastActivity, setLastActivity] = useState(Date.now());

    useEffect(() => {
        const handleActivity = () => {
            setLastActivity(Date.now());
        };

        const activities = ['click', 'mousemove', 'keypress'];

        activities.forEach(event => window.addEventListener(event, handleActivity));

        const interval = setInterval(() => {
            if (Date.now() - lastActivity > timeout) {
                clearInterval(interval);
                onTimeout();
            }
        }, 1000);

        return () => {
            activities.forEach(event => window.removeEventListener(event, handleActivity));
            clearInterval(interval);
        };
    }, [lastActivity, timeout, onTimeout]);

    return lastActivity;
};

export default useSessionTimeout;