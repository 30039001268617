import { useEffect, useState } from "react";
import WorkflowPreprocess from "./WorkflowPreprocess";
import { addAttributeApi, getLockStatusApi, getWorkflowAttributesApi, postErrorLog } from "../../Service/api";
import PopupComponent from "./PopupComponent";
import WorkflowPostprocess from "./WorkflowPostprocess";

function WorkflowMain(props) {

let { preProcess, postProcess, getActionItems,assessmentId,workflowId,selectedSessionId,getActionItem,assessmentName  }=props

const [activeTab, setActiveTab] = useState('PreProcessing');
const [attributeData, setAttributeData] = useState([]);
const [selectedAttributeId, setSelectedAttributeId] = useState('');
const [isCompleted, setIsCompleted] = useState(false);
const [isLock, setIsLock] = useState(false);
const [isPopupOpen, setIsPopupOpen] = useState(false);
const [popupCase, setPopupCase] = useState('');

const userId=localStorage.getItem('userId');

//------------------------------------------------------
const sampleattributes= 
[{
    "attribute_id":"1",
    "attribute_name":"Architecture diagram"
  },
  {
    "attribute_id":"2",
    "attribute_name":"Current state infrastructure"
  }]

  useEffect(() => {
    setAttributeData(sampleattributes);
    
}, []);

  //-------------------------------------------------------


  // SQ_NO_10 - Initialize state and fetch initial attributes
  useEffect(() => {
    getAttributes();
  }, [activeTab]);

    // SQ_NO_10 - Fetch attributes based on active tab
    const getAttributes = async () => {
        try {
            const availablePreprocessActionItem = preProcess.map(item => item.id);
            const availablePostprocessActionItem = postProcess.map(item => item.id);
    
            const payload = {
              user_id: userId,
              assessment_id: assessmentId,
              workflow_id: workflowId,
              process: activeTab === "PreProcessing" ? preProcess : postProcess,
              avaliable_attributes: activeTab === "PreProcessing" ? availablePreprocessActionItem : availablePostprocessActionItem
            };
        //   const response = await getWorkflowAttributesApi(payload);
        //   if (response.status === 200) {
        //     setAttributeData(response.data.body.data);
        //   }
        } catch (error) {
          handleError(error,'getAttributes');
        }
      };

        // SQ_NO_13 - Handle adding a new attribute
  const handleAddAttribute = async () => {
    try {
      const payload = {
        user_id: userId,
        assessment_id: assessmentId,
        workflow_id: workflowId,
        session_id: selectedSessionId,
        attribute_id: selectedAttributeId
      };
    //   const response = await addAttributeApi(payload);
    //   if (response.status === 200) {
    //     getActionItems();
    //   }
    } catch (error) {
      handleError(error,'handleAddAttribute');
    }
  };

    // SQ_NO_26 - Handle locking/unlocking the workflow
    const handleLock = async () => {
        const newLockState = !isLock;
        setIsLock(newLockState);
    
        if (newLockState) {
          setIsPopupOpen(true);
          setPopupCase('unlockPopup');
        } else {
          await postLockStatus(newLockState);
        }
      };
    
      
  // SQ_NO_26 - Post lock status to the server
  const postLockStatus = async (lockState) => {
    try {
      const payload = { locked: lockState };
    //   const response = await getLockStatusApi(payload);
    //   if (response.status === 200) {
    //     setIsLock(lockState);
    //     if (lockState) {
    //       await callAgents();
    //     }
    //   }
    } catch (error) {
      handleError(error,'postLockStatus');
    }
  };

  // SQ_NO_26 - Call agents after locking
  const callAgents = async () => {
    // Implementation for calling agents
    // This would include API calls for session, checklist, and action item agents
  };

  // Check completion status
//   useEffect(() => {
//     checkCompletionStatus();
//   }, [preProcess, postProcess]);

//   const checkCompletionStatus = () => {
//     const process = activeTab === 'PreProcessing' ? preProcess : postProcess;
//     const allCompleted = process.each(attribute => 
//       attribute.checklist.every(item => item.is_checked || item.is_skip)
//     );
//     setIsCompleted(allCompleted);
//   };

   // SQ_NO_2.2 - Handle Error
   const handleError = (error, functionName) => {
    const errorData = {
        user_id: userId,
        type: "error",
        error_module_name: functionName,
        error_description: error?.message + " ," + error?.response?.data?.body?.message
    };
    postErrorLog(errorData);
};

console.log(preProcess,"pre");



      return (
        <div className="d-flex col-md-9">
          <div className="bg-white rounded-2 w-100 px-3">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="font-24 font-semibold heading-txt-color border-bottom py-3 mb-4">
                Day 0
              </h2>
              <button 
                className="border-0 p-0 action-icons locking-icon rounded-circle d-flex justify-content-center align-items-center"
                onClick={handleLock}
              >
                <img src={isLock ? "images/lock-day.svg" : "images/unlock-day.svg"} alt="lock-icon" />
              </button>
            </div>
            <div className="d-flex flex-column tab-container">
              <div className="d-flex justify-content-between flex-md-row flex-column align-items-center">

                <div className="d-flex">
                  <ul className="nav nav-tabs custom-tabs border-0 d-flex gap-3" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${activeTab === 'PreProcessing' ? 'active' : ''} text-grey font-16`}
                        onClick={() => setActiveTab('PreProcessing')}
                        style={{ cursor: 'pointer' }}                                                                                                                                                                                                                           
                        // href="#pre"
                        // role="tab"
                      >
                        Pre Processing
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link ${activeTab === 'PostProcessing' ? 'active' : ''} text-grey font-16`}
                        onClick={() => setActiveTab('PostProcessing')}
                        style={{ cursor: 'pointer' }}   
                        // href="#post"
                        // role="tab"
                      >
                        Post Processing
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  {isCompleted && (
                    <span className="p-2 gap-2 d-flex align-items-center badge custom-badge approved">
                      <span className="dot-indicator" />
                      Completed
                    </span>
                  )}
                  <button
                    className="d-flex align-items-center gap-1 align-items-center font-14 outlined-button px-3"
                    onClick={() => {
                      setPopupCase('Add Attribute');
                      setIsPopupOpen(true);
                    }}
                  >
                    <img src="images/add_attribute.svg" alt="add-icon" />
                    Add Attribute
                  </button>
                </div>
              </div>
              <div className="tab-content">
                <div
                  id="pre"
                  className={`tab-pane fade ${activeTab === 'PreProcessing' ? 'show active' : ''} tab-section mt-4`}
                  role="tabpanel"
                >
                  <div className="p-2 custom-card mb-3 strip">
                    <span className="font-12 font-medium d-flex align-items-center">
                      <img className="me-2" src="images/out_of_scope.svg" alt="" />
                      The Scope has gone out of scope as the infrastructure costs
                      more than the estimates as the resource has been changed
                      from S3 to ECS
                    </span>
                  </div>
                  {activeTab === "PreProcessing" && 
                  <WorkflowPreprocess 
                  preProcess={preProcess} 
                  assessmentId={assessmentId}
                  workflowId={workflowId}
                  sessionId={selectedSessionId}
                  getActionItem={getActionItem}
                  assessmentName={assessmentName}
                  />}
                </div>
                <div
                  id="post"
                  className={`tab-pane fade ${activeTab === 'PostProcessing' ? 'show active' : ''} tab-section mt-4`}
                  role="tabpanel"
                >
                   {activeTab === "PostProcessing" && <WorkflowPostprocess postProcess={postProcess} />}
                </div>
              </div>
            </div>
          </div>
          {isPopupOpen && (
            <PopupComponent
            case={popupCase}
            onClose={() => setIsPopupOpen(false)}
            attributeData={attributeData}
            selectedAttributeId={selectedAttributeId}
            setSelectedAttributeId={setSelectedAttributeId}

          />
          )}
        </div>
      )
}
export default WorkflowMain;