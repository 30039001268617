import React, { useState, useEffect } from 'react';
import { saveReportContentApi, postErrorLog } from '../../../Service/api';

const ChallengesRecommendationsTemplate = ({ reportData, getReportData }) => {
  console.log("ChallengesRecommendationsTemplate rendering with data:", reportData);

  const [content, setContent] = useState(reportData.body.content || []);
  const [newTitle, setNewTitle] = useState('');
  const [newChallenge, setNewChallenge] = useState('');
  const [newRecommendation, setNewRecommendation] = useState('');
  const [openedAccordions, setOpenedAccordions] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [editChallenge, setEditChallenge] = useState('');
  const [editRecommendation, setEditRecommendation] = useState('');
  const [errorData, setErrorData] = useState(null);
  const [titleExistsError, setTitleExistsError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    console.log("useEffect triggered for initial state setup");
    setOpenedAccordions(content.map((_, index) => index));
  }, [content]);

  const toggleAccordion = (index) => {
    console.log("toggleAccordion called with index:", index);
    setOpenedAccordions(prevState =>
      prevState.includes(index) ? prevState.filter(i => i !== index) : [...prevState, index]
    );
  };

  const validateNewChallenge = () => {
    let validationErrors = {};
    let isValid = true;

    if (newTitle.trim() === '') {
      validationErrors.title = "Title is required";
      isValid = false;
    }
    if (newChallenge.trim() === '') {
      validationErrors.challenge = "Challenge is required";
      isValid = false;
    }
    if (newRecommendation.trim() === '') {
      validationErrors.recommendation = "Recommendation is required";
      isValid = false;
    }
    if (content.some(item => item.title === newTitle)) {
      validationErrors.title = "The Title name already exists";
      isValid = false;
    }

    setValidationErrors(validationErrors);
    setIsValid(isValid);
  };

  const addChallenge = async () => {
    console.log("addChallenge called");
    validateNewChallenge();
    if (!isValid) {
      return;
    }
    
    const newItem = { title: newTitle, challenge: newChallenge, recommendation: newRecommendation };
    const updatedContent = [...content, newItem];
    setContent(updatedContent);
    setNewTitle('');
    setNewChallenge('');
    setNewRecommendation('');

    try {
      console.log("Saving new content to the server", updatedContent);
      const payload = {
        report_id: reportData.report_data_id,
        message: { ...reportData, body: { ...reportData.body, content: updatedContent } }
      };
      console.log("logging payload inside addChallenge", payload);
      
      await saveReportContentApi(payload);
      getReportData(); // Refresh data after save
    } catch (error) {
      setErrorData('Error saving content.');
      await postErrorLog({ error });
    }
  };

  const deleteChallenge = async (index) => {
    console.log("deleteChallenge called with index:", index);
    const updatedContent = content.filter((_, i) => i !== index);
    setContent(updatedContent);
    try {
      const payload = {
        report_id: reportData.report_data_id,
        message: { ...reportData, body: { ...reportData.body, content: updatedContent } }
      };
      console.log("logging payload inside delete challenge", payload);
      
      await saveReportContentApi(payload);
      getReportData(); // Refresh data after deletion
    } catch (error) {
      setErrorData('Error deleting content.');
      await postErrorLog({ error });
    }
  };

  const saveChanges = async () => {
    console.log("saveChanges called");
    const updatedContent = content;

    try {
      const payload = {
        report_id: reportData.report_data_id,
        message: { ...reportData, body: { ...reportData.body, content: updatedContent } }
      };
      console.log("Saving changes to the server", updatedContent);
    //   await saveReportContentApi(payload);
      setIsEditing(null);
    } catch (error) {
      setErrorData('Error saving content.');
    //   await postErrorLog({ error });
    }
  };

  const handleEdit = (index) => {
    console.log("handleEdit called with index:", index);
    setIsEditing(index);
    setEditTitle(content[index].title);
    setEditChallenge(content[index].challenge);
    setEditRecommendation(content[index].recommendation);
  };

  const handleUpdate = async () => {
    console.log("handleUpdate called");
    const updatedContent = [...content];
    updatedContent[isEditing] = {
      title: editTitle,
      challenge: editChallenge,
      recommendation: editRecommendation,
    };
    setContent(updatedContent);

    try {
      const payload = {
        report_id: reportData.report_data_id,
        message: { ...reportData, body: { ...reportData.body, content: updatedContent } }
      };
      console.log("Logging payload for update", updatedContent);
    //   await saveReportContentApi(payload);
      setIsEditing(null);
    } catch (error) {
      setErrorData('Error updating content.');
    //   await postErrorLog({ error });
    }
  };

  const handleCancelEdit = () => {
    console.log("handleCancelEdit called");
    setIsEditing(null);
  };

  return (
    <div className="d-flex col-md-9">
      <div className="bg-white rounded-2 w-100 px-3">
        <h2 className="font-24 font-semibold heading-txt-color border-bottom py-3 mb-4">Challenges & Recommendations</h2>
        <div className="d-flex flex-column tab-container">
          <div className="accordion" id="rec-accordion">
            <div className="accordion-item recommendation-accordion custom-accordion card py-1 px-3 custom-card mb-3">
              <div className="d-flex justify-content-between align-items-center accordion-btn-parent">
                <button className="accordion-button ps-2 shadow-none bg-transparent collapsed focusable" type="button" data-bs-toggle="collapse"
                  data-bs-target="#rec-collapse" aria-expanded="true" aria-controls="rec-collapse">
                  <img className="accordion-arrow me-3" src="images/accordion.svg" alt="accordion-arrow" />
                  <h2 className="accordion-header font-14 font-medium text-dark mb-0">
                    Add New Data Challenges & Recommendations
                  </h2>
                </button>
              </div>
              <div id="rec-collapse" className="accordion-collapse collapse" data-bs-parent="#rec-accordion">
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label className="form-label text-dark font-14 font-medium" htmlFor="Client">Title<span className="input-improtent-section">*</span></label>
                      <input type="text" className="font-14 form-control w-100 mt-2" id="Client" placeholder="Enter Title" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />
                      {validationErrors.title && <div className="error-message">{validationErrors.title}</div>}
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label text-dark font-14 font-medium" htmlFor="Challenges">Challenges<span className="input-improtent-section">*</span></label>
                      <textarea id="Challenges" className="font-14 form-control w-100 mt-2 resize-none custom-textarea shadow-none" placeholder="Enter Challenges" value={newChallenge} onChange={(e) => setNewChallenge(e.target.value)}></textarea>
                      {validationErrors.challenge && <div className="error-message">{validationErrors.challenge}</div>}
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label text-dark font-14 font-medium" htmlFor="Recommendations">Recommendation<span className="input-improtent-section">*</span></label>
                      <textarea id="Recommendations" className="font-14 form-control w-100 mt-2 resize-none custom-textarea shadow-none" placeholder="Enter Recommendations" value={newRecommendation} onChange={(e) => setNewRecommendation(e.target.value)}></textarea>
                      {validationErrors.recommendation && <div className="error-message">{validationErrors.recommendation}</div>}
                    </div>

                    <div className="col-md-12">
                      <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                        <button className="btn outline-button py-2 px-4 font-semibold focusable" onClick={() => { setNewTitle(''); setNewChallenge(''); setNewRecommendation(''); }}>Clear</button>
                        <button className="btn primary-button py-2 px-4 font-semibold focusable" onClick={addChallenge}>Add</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {content.map((item, index) => (
              <div className="accordion-item custom-accordion card bg-transparent py-1 px-3 custom-card mb-3" key={index}>
                <div className="d-flex justify-content-between align-items-center accordion-btn-parent">
                  <button className="accordion-button ps-2 shadow-none bg-transparent collapsed focusable" type="button" data-bs-toggle="collapse"
                    data-bs-target={`#rec-collapse-${index}`} aria-expanded="true" aria-controls={`rec-collapse-${index}`}>
                    <img className="accordion-arrow me-3" src="images/accordion.svg" alt="accordion-arrow" />
                    <h2 className="accordion-header font-14 font-medium text-dark mb-0">
                      {item.title}
                    </h2>
                  </button>
                  <div className="btn-container d-flex gap-3">
                    <button className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center" onClick={() => handleEdit(index)}>
                      <img src="images/edit.svg" alt="edit-icon" />
                    </button>
                    <button className="border-0 p-0 action-icons delete-icon rounded-circle d-flex justify-content-center align-items-center" onClick={() => deleteChallenge(index)}>
                      <img src="images/cancel-red.svg" alt="delete" />
                    </button>
                  </div>
                </div>
                <div id={`rec-collapse-${index}`} className="accordion-collapse collapse" data-bs-parent="#rec-accordion">
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h3 className="font-semibold font-16 text-dark">Challenges</h3>
                        {isEditing === index ? (
                          <textarea className="font-14 form-control w-100 mt-2 resize-none custom-textarea shadow-none" placeholder="Edit Challenge" value={editChallenge} onChange={(e) => setEditChallenge(e.target.value)}></textarea>
                        ) : (
                          <p className="font-14 font-regular text-dark">{item.challenge}</p>
                        )}
                      </div>

                      <div className="col-md-12 mb-3">
                        <h3 className="font-semibold font-16 text-dark">Recommendations</h3>
                        {isEditing === index ? (
                          <textarea className="font-14 form-control w-100 mt-2 resize-none custom-textarea shadow-none" placeholder="Edit Recommendation" value={editRecommendation} onChange={(e) => setEditRecommendation(e.target.value)}></textarea>
                        ) : (
                          <p className="font-14 font-regular text-dark">{item.recommendation}</p>
                        )}
                      </div>

                      {isEditing === index && (
                        <div className="d-flex justify-content-end gap-2">
                          <button className="btn outline-button py-2 px-4 font-semibold focusable" onClick={handleCancelEdit}>Cancel</button>
                          <button className="btn primary-button py-2 px-4 font-semibold focusable" onClick={handleUpdate}>Save</button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengesRecommendationsTemplate;