
import React, { useEffect, useState } from 'react';
import { getChatHistoryApi, getWorkbenchDataApi, handleQuerySubmitApi, postErrorLog } from '../../Service/api';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactFlow, { Background } from 'reactflow';
import 'reactflow/dist/style.css';

function ArchitectureWorkbench() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sourceUrl, setSourceUrl] = useState(null);
    const [initialChatData, setInitialChatData] = useState([]);
    const [query, setQuery] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const userId = localStorage.getItem('userId'); 
    const location = useLocation();
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const navigate=useNavigate()

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // SQ_NO_1.0 - Data Fetching
    useEffect(() => {
        getWorkbenchData();
        getChatHistory();
    }, [location.state]);

    const getWorkbenchData = async () => {
        try {
            const payload = {
                user_id: location.state.user_id,
                assessment_id: location.state.assessment_id,
                workflow_id: location.state.workflow_id,
                action_item_id: location.state.action_item_id,
            };

            //const response = await getWorkbenchDataApi(payload);

            // if (response.status === 200) {
            //   setSourceUrl(response.data.imageUrl);
            // }
            setSourceUrl('https://avaeuszebpulsestgdev.blob.core.windows.net/zebpulserevamp/56842663-4603-4691-b66d-c2fd0dc65324.jpg');
        } catch (error) {
            handleError(error, 'getWorkbenchData');
        }
    };

    // SQ_NO_2.0 - Image Loading
    useEffect(() => {
        if (sourceUrl) {
            loadImage();
        }
    }, [sourceUrl]);

    const loadImage = async () => {
        try {
            setLoading(true);
            const response = await fetch(sourceUrl);
            if (!response.ok) throw new Error('Network response was not ok');
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            setImageUrl(url);
            setLoading(false);
        } catch (err) {
            setError(`Failed to load image: ${err.message}`);
            setLoading(false);
        }
    };

    // SQ_NO_3.0 - Image Download
    const handleDownload = () => {
        if (!imageUrl) return;

        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'architecture_diagram.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // SQ_NO_4.0 - Initial Chat History Fetching
    const getChatHistory = async () => {
        try {
            const payload = {
                user_id: location.state.user_id,
                assessment_id: location.state.assessment_id,
                workflow_id: location.state.workflow_id,
                report_id: location.state.report_id,
            };

            // const response = await getChatHistoryApi(payload);

            // if (response.status === 200) {
            //     setInitialChatData(response.data);
            // }
        } catch (error) {
            handleError(error, 'getChatHistory');
        }
    };

    // SQ_NO_5.0 - Interactive Chat
    const handleQuerySubmit = async () => {
        try {
            const payload = {
                user_id: location.state.user_id,
                assessment_id: location.state.assessment_id,
                workflow_id: location.state.workflow_id,
                report_id: location.state.report_id,
                query: query,
            };

            const response = await handleQuerySubmitApi(payload);

            if (response.status === 200 || response.status === 201) {
                const newChatData = {
                    user_query: query,
                    system_query: response.data.system_query,
                };

                setInitialChatData(prevData => [...prevData, newChatData]);

                if (response.status === 201) {
                    await getWorkbenchData();
                }
            }
        } catch (error) {
            handleError(error, 'handleQuerySubmit');
        }
    };

    const handleError = (error, functionName) => {
        const errorData = {
            user_id: userId,
            type: "error",
            error_module_name: functionName,
            error_description: error?.message + " ," + error?.response?.data?.body?.message
        };
        postErrorLog(errorData);
    };

    return (
        <>
            <div className="container-fluid p-4 content-container">
                {/* Tab Heading */}
                <div className="row">
                    <div className="col-md-12 my-3">
                        <div className="d-flex justify-content-between flex-md-row flex-column align-items-center">
                            <h1 className="font-24 font-semibold black-color page-title mb-0">
                                <a  onClick={(()=>{navigate('/Workflow',{
                                  state:{
                                   
                                      assessment_id: location.state.assessment_id,
                                      assessmentName:location.state.assessmentName
                                      // workflow_id: location.state.workflow_id,
                                      // action_item_id: location.state.action_item_id,
                                  }
                                })})}>
                                    <img src="images/back-arrow.svg" alt="back-arrow" className="me-2" />
                                </a>
                                Architecture
                            </h1>
                            <div className="d-flex gap-2">
                                <button
                                    className="d-flex gap-2 align-items-center outlined-button px-3 bg-transparent"
                                    onClick={handleDownload}
                                    disabled={!imageUrl || loading}
                                >
                                    <img src="images/DOWNLOAD-GREEN.svg" alt="scope-icon" />
                                    <span>{loading ? 'Loading...' : 'Download'}</span>
                                </button>
                                <button className="btn primary-button">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* chat and image container */}
                <div className="col-md-12 mb-3 business-flow">
                    <div className="d-flex">
                        {/* Chat Box */}
                        <div className="d-flex flex-column chat-window col-md-3">
                            <h4 className="font-semibold font-14">AI Assist</h4>
                            <div className="flex-grow-1">
                                <div className="my-2 flex-1 overflow-auto">
                                    {initialChatData.map((chat, index) => (
                                        <React.Fragment key={index}>
                                            <div className="d-flex justify-content-end gap-4 align-items-end mb-3">
                                                <div className="chat-content-user font-12 font-regular">
                                                    {chat.user_query}
                                                </div>
                                            </div>
                                            <div className="mb-3 d-flex justify-content-start align-items-end">
                                                <span className="d-inline-block me-2">
                                                    <img src="images/zeb-Pulse_chat.svg" alt="chat-icon" className="user-img" />
                                                </span>
                                                <div className="chat-content font-14 font-regular pe-5 ps-4 w-70">
                                                    <p className="mb-2">{chat.system_query}</p>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-auto input-group chat-input">
                                <input
                                    type="text"
                                    className="chat-input form-control font-14 font-regular"
                                    placeholder="Enter your requirement"
                                    aria-label="Input group example"
                                    aria-describedby="btnGroupAddon"
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                />
                                <button
                                    type="button"
                                    className="py-3 input-group-text bg-white shadow-none"
                                    id="btnGroupAddon"
                                    onClick={handleQuerySubmit}
                                >
                                    <img src="images/send-icon.svg" alt="search-icon" />
                                </button>
                            </div>
                        </div>
                        {/* Image Box */}
                        <div className="col-md-9 d-flex justify-content-center align-items-center" style={{ position: 'relative', height: dimensions.height - 60 }}>
                            <div className="react-flow-wrapper" style={{ height: '100%', width: '100%', position: 'relative' }}>
                                <ReactFlow
                                    nodes={[]}
                                    edges={[]}
                                    fitView
                                >
                                    <Background 
                                        variant="dots" 
                                        gap={12} 
                                        size={1} 
                                        color="#cccccc"
                                    />
                                     {imageUrl && !loading && (
                                    <div className="image-overlay">
                                        <img
                                            src={imageUrl}
                                            alt="Diagram"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </div>
                                )}
                                </ReactFlow>

                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ArchitectureWorkbench;