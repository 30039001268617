import React, { useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import SunEditor's CSS

const TextEditorTemplate = ({ initialContent = "Default text", onContentChange }) => {

  // console.log(initialContent);




  const editorRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(initialContent);

  useEffect(() => {
    setContent(initialContent)
  }, [initialContent])


  const handleEditorChange = (content) => {
    setContent(content);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    if (onContentChange) {
      onContentChange(content);
    }
  };

  const monitorImages = () => {
    console.log('Image upload complete');
  };
  
  return (
    <div className="mx-auto p-2 border border-gray-300 rounded shadow-sm">
      {isEditing ? (
        <>
          <SunEditor
            ref={editorRef}
            setContents={content}
            onChange={handleEditorChange}
            setOptions={{
              height: 400,
              width: '100%',
              buttonList: [
                ['bold', 'italic', 'underline'],
                ['font', 'fontSize'],
                ['fontColor', 'hiliteColor'],
                ['align', 'list', 'indent', 'outdent'],
                ['table', 'link', 'image'],
              ],
            }}
            onImageUpload={(_, __, ___, ____, remainingFilesCount) => {
              if (remainingFilesCount === 0) monitorImages();
            }}
            className="border-2 border-gray-300 rounded mb-4"
          />
          <button
            onClick={handleSaveClick}
            className=" mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
            Save
          </button>
        </>
      ) : (
        <>
          <div className="prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: content }}></div>
          <button
            onClick={handleEditClick}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
            Edit
          </button>
        </>
      )}
    </div>
  );
};

export default TextEditorTemplate;