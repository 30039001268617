import replaceSpacesWithUnderscores from "../ReportGeneration/helper/modifyTitle.js";

{/* <h2 style="color: #333; font-size: 24px; margin-bottom: 10px;">${content.title}</h2> */ }

export const StaticComponent = (content) => {
  let modified_title = replaceSpacesWithUnderscores(content.title)
  return `
  <div class="new-page" id=${modified_title} style="padding: 20px; border: 1px solid #ccc; margin-bottom: 20px;  background-color: #f9f9f9;">
    <p style="color: #555; line-height: 1.6;">${content.content}</p>
  </div>
`};