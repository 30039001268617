
// Import statements: SQ_1.1 to 1.12
import { useEffect, useState } from "react";

import ClientInfoPopup from './ClientInfoPopup';

import { fetchClientsDataApi, fetchIndustryType, handleDeleteClientApi, postErrorLog } from "../../Service/api";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { useOrgContext } from "../Context/Context";


function ClientCards() {

    //SQ_1.3 :State declarations
    const [clientsData, setClientsData] = useState([]);
    const [industryData, setIndustryData] = useState([]);
    const [clientInfoPopup, setClientInfoPopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedIndustryType, setSelectedIndustryType] = useState('')
    const [loadCount, setLoadCount] = useState(12);
    const [clientDataToEdit, setClientDataToEdit] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [totalClientCount, setTotalClientData] = useState('')
    const [showLoader, setShowLoader] = useState(false)
    const [filteredClients, setFilteredClients] = useState([]);
    const navigate = useNavigate()
    const isAdmin = localStorage.getItem('isAdmin')
    const { refreshTrigger} = useOrgContext();

    const userId = localStorage.getItem('userId')

    useEffect(() => {       
        getAllClientData();
        setSearchTerm('')
      }, [refreshTrigger])


    // Executes side-effects upon component mount to fetch client and industry data.

    // Details:
    // --------
    // Calls `getAllClientData` and `getIndustryType` when the component is initially mounted.
    //Executes side-effects when the selected industry type changes to refresh client data.

    // Formula:
    // --------
    // useEffect(() => {
    //     getAllClientData();
    //     getIndustryType();
    // }, []);

    // SQ_NO_1.4 - Initial useEffect
    useEffect(() => {
        getAllClientData();
        getIndustryType();
    }, []);

    //SQ_1.5
    useEffect(() => {
        getAllClientData()

    }, [selectedIndustryType, loadCount,searchTerm])

    useEffect(() => {
        bindClientData();
        console.log(clientsData, "renderedlogo after update");

    }, [clientsData])

    // Filters the clients based on the search term.
    // Returns:
    // --------
    // Array:
    //     A filtered list of clients whose names include the current search term (case insensitive).

    // Formula:
    // --------
    // filteredClients = clientsData.filter(client =>
    //     client.client_name.toLowerCase().includes(searchTerm.toLowerCase())
    // )

    // Executes side-effects when the search term changes.

    // Parameters:
    // -----------
    // searchTerm : string
    //     The currently entered search term.

    // Details:
    // --------
    // Debounces the search by delaying the API call by 300ms after the search term changes. Calls `getAllClientData` to refresh the client list based on the new search term.

    // Formula:
    // --------
    // useEffect triggers when searchTerm changes

    // SQ_3.3 -3.6: Filter the clients based on search term 
    // const filteredClients = clientsData.filter(client =>
    //     client.client_name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    // Fetches and sets all client data, applying filters as necessary.

    // Details:
    // --------
    // Constructs a payload with user ID, filters (including selected industry type and static load value), and search key. Fetches client data using `fetchClientsDataApi` and updates the state with the fetched client data and total client count. Handles errors by logging them.

    // Formula:
    // --------
    // payload = {
    //     user_id: userId,
    //     filters: {
    //         industry_name: selectedIndustryType,
    //         load: 12
    //     },
    //     search_key: searchTerm
    // }

    // API Call:
    // --------
    // response = await fetchClientsDataApi(payload)

    // If response is successful:
    //   set clientsData with response.data.body.data.client_data
    // SQ_NO_1.6-1.20:  Fetch all client data
    const getAllClientData = async () => {
        try {
            const payload =
            {
                user_id: userId,
                filters: {
                    industry_name: selectedIndustryType,
                    load: 12

                },
                search_key: searchTerm,
                load: loadCount
            };
            if (!searchTerm) {
                setShowLoader(true);
            }
            const response = await fetchClientsDataApi(payload);
            if (!searchTerm) {
                setShowLoader(false);
            }
            console.log(response, "responsess")
            setClientsData(response.data.body.data.client_data);

            setTotalClientData(response.data.body.data.total_client_count)
            setFilteredClients(response.data.body.data.client_data);


        } catch (error) {
            setShowLoader(false)
            handleError(error, 'getAllClientData');
        }
    };

    // Fetches and sets the industry type data.

    // Details:
    // --------
    // Constructs a payload with user ID and a dropdown type for industry type. Fetches industry type data using `fetchIndustryType` and updates the state with the fetched industry data. Handles errors by logging them.

    // Formula:
    // --------
    // payload = {
    //     dropdown_type: "industry_type",
    //     is_admin: "True",
    //     user_id: userId
    // }

    // API Call:
    // --------
    // response = await fetchIndustryType(payload)

    // If response is successful:
    //   set industryData with response.data.body.data

    // SQ_NO_1.20 -1.30 - Fetch industry type data
    const getIndustryType = async () => {
        try {
            const payload =
            {
                "dropdown_type": "industry_type",  // Specific to the special structured output
                "is_admin": "True",
                "user_id": userId
            }

            const response = await fetchIndustryType(payload);
            setIndustryData(response.data.body.data);
        } catch (error) {
            handleError(error, 'getIndustryType');
        }
    };

    // useEffect(() => {
    //     bindClientData()
    //     console.log(clientsData,"logocheckwhen render");
    // }, [clientsData])


    // Renders the client data or a message indicating no clients were found.

    // Returns:
    // --------
    // JSX Elements:
    //     Rendered client cards if `filteredClients` has data; Otherwise, an "Oops! No Clients Found" message.

    // Details:
    // --------
    // Maps through `filteredClients` to create individual client cards. Each card displays client details like logo, name, website URL, industry type, and assessments count. Includes an edit button for each client.

    // Formula:
    // --------
    // If filteredClients.length == 0:
    //     return "Oops! No Clients Found" JSX

    // Else:
    //     filteredClients.map(client => { ... render client card ... })

    // SQ_NO_2.0 -2.2: Bind client data to client cards
    const bindClientData = () => {
        if (filteredClients.length === 0) {
            return (
                <div className="row mt-4">
                    <div className="d-grid justify-content-center text-center my-5 pt-5">
                        <img src="images/norecord-img.svg" alt="norecord-img" />
                        <p className="font-semibold font-30 mt-5 mb-2">Oops!</p>
                        <p className="font-regular font-16">No Clients Found</p>
                    </div>
                </div>
            );
        }

        return (
            <div className="row mt-4">
                {filteredClients.map((client, index) => (
                    <div key={index}
                        className="col-md-6 col-lg-4 mb-4"

                    >
                        <div className="client-card">
                            <div className="d-flex justify-content-between">
                                <div className="client-details d-flex gap-3">
                                    <img
                                        // src={client.client_logo || "images/client-logo-1.svg"}
                                        src={`${client.client_logo}?${new Date().getTime()}` || "images/client-logo-1.svg"}
                                        className="Client-card-logo"
                                        alt="client-logo"
                                        width="32"
                                        height="32"
                                        style={{ borderRadius: "50%", objectFit: "cover", cursor: "pointer" }}
                                        onClick={(() => {
                                            navigate("/AssessmentsGrid", {
                                                state: {
                                                    clientId: client.client_id,
                                                    clientName: client.client_name,
                                                },
                                            });
                                        })}
                                    />
                                    <span className="d-flex flex-column">
                                        <span className="font-16 font-semibold heading-txt-color"
                                            onClick={(() => {
                                                navigate("/AssessmentsGrid", {
                                                    state: {
                                                        clientId: client.client_id,
                                                        clientName: client.client_name,
                                                    },
                                                });
                                            })}
                                            style={{ cursor: "pointer" }}
                                            title={client.client_name}

                                        >
                                            {client.client_name.length > 30
                                                ? `${client.client_name.slice(0, 30)}...` // Slice to 40 characters and add ellipsis
                                                : client.client_name}

                                        </span>
                                        <span className="font-12 font-regular grey-v2">{client.website_url}</span>
                                    </span>
                                </div>
                                <div className="dropdown">
                                    {isAdmin == "true" && (
                                        <button
                                            className="btn"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src="images/three-dots-dropdown-icon.svg"
                                                alt="dropdown-icon"
                                            />
                                        </button>
                                    )}
                                    <ul
                                        className="dropdown-menu font-14 font-regular action-dropdown colo"

                                    >
                                        <li>
                                            <button className="dropdown-item" type="button" onClick={() => handleEditClient(client)}>
                                                <img
                                                    height={10}
                                                    width={12}
                                                    src="images/edit.svg"
                                                    className="me-2 mb-1"
                                                />
                                                Edit Client
                                            </button>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-4 pt-3 card-alignment">
                                <span className="font-14 font-medium grey-v3">
                                    <img
                                        src="images/industry-type-icon-grey.svg"

                                        className="client-card-icons me-2 mb-1"
                                        alt="file-icon"
                                    />
                                    Type{" "}
                                    <span className="font-14 font-semibold ms-4">{client.industry_name}</span>
                                </span>
                                {/* <span className="client-card-vertical-divider"></span> */}
                                <span className="font-14 font-medium grey-v3">
                                    <img
                                        src="images/file-icon-grey.svg"
                                        className="client-card-icons me-2 mb-1"
                                        alt="file-icon"
                                    />
                                    Assessments{" "}
                                    <span className="blue-text font-14 font-semibold ms-4"
                                        style={{ cursor: "pointer" }}
                                        onClick={(() => {
                                            navigate("/AssessmentsGrid", {
                                                state: {
                                                    clientId: client.client_id,
                                                    clientName: client.client_name,
                                                },
                                            });
                                        })} >
                                        {client.assessmentsCount}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    // Handles the update of the search term input and triggers re-fetching of client data.
    // Parameters:
    // -----------
    // e : object
    //     The event object for the search input change.

    // Details:
    // --------
    // Updates the `searchTerm` state with the input value from the event.

    // Formula:
    // --------
    // setSearchTerm to e.target.value   
    // SQ_NO_3.0 -3.3 : Handle search input
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Handles the loading of more client data upon user request.
    // Details:
    // --------
    // Increments `loadCount` by 12 to fetch the next set of clients. Calls `getAllClientData` to fetch additional client data based on the new `loadCount`.

    // Formula:
    // --------
    // setLoadCount to  prevCount + 12
    // getAllClientData()

    // Handle load more: SQ_ 9.0 - 9.3
    const handleLoadMore = () => {
        setLoadCount(prevCount => prevCount + 12);
    };

    // Logs and handles errors that occur in API calls.

    // Parameters:
    // -----------
    // error : object
    //     The error object received from a failed API call.
    // functionName : string
    //     The name of the function where the error occurred.
    // Details:
    // --------
    // Constructs an error data object including user ID, error type, module name, and error description. Sends this data to `postErrorLog`.

    // Formula:
    // --------
    // errorData holds the error data

    //  SQ_1.0 -1.3
    const handleError = (error, functionName) => {
        const errorData = {
            user_id: userId,
            type: "error",
            error_module_name: functionName,
            error_description: error?.message + " ," + error?.response?.data?.body?.message

        };
        postErrorLog(errorData);
    };

    // Prepares the client data for editing by setting the appropriate states.

    // Parameters:
    // -----------
    // clientData : object
    //     The data of the client to be edited.

    // Details:
    // --------
    // Sets the edit mode to true, shows the client information popup, and updates the state with the client data to be edited.

    // Formula:
    // --------
    // setEditMode(true)
    // setClientInfoPopup(true)
    // setClientDataToEdit(clientData) 

    // SQ_4.0 - 4.3 :  function to handle edit client
    const handleEditClient = (clientData) => {
        setEditMode(true)
        setClientInfoPopup(true);
        setClientDataToEdit(clientData)
    };


    return (
        <>
            {/* <Header /> */}
            {showLoader ?
                <Loader /> :
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between flex-md-row flex-column">
                                <span className="font-24 font-semibold black-color page-title">
                                    Clients ({totalClientCount})
                                </span>
                                <div className="d-flex gap-2">



                                    {/* Client dropdown */}
                                    <div class="d-flex gap-2 align-items-center">
                                        <label class="text-nowrap font-medium">Industry Type</label>
                                        <select
                                            class="form-select industry-dropdown"
                                            value={selectedIndustryType}
                                            onChange={(e) => {

                                                setSelectedIndustryType(e.target.value)                                        
                                            }}

                                        >

                                            <option value='' >Select Industry</option>
                                            {industryData?.map((industry) => (
                                                <option key={industry.id} value={industry.id}>
                                                    {industry.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* Client dropdown */}

                                    {/* Search */}
                                    <div className="input-group custom-search">
                                        <input
                                            type="text"
                                            className="form-control border-0 input-search"
                                            placeholder="Search"
                                            value={searchTerm}
                                            onChange={handleSearch}
                                        />
                                        <button className="input-group-text input-field border-0 bg-white migrate-form-ui">
                                            <img src="images/search.svg" alt="search-icon" />
                                        </button>
                                    </div>
                                     {/* Search */}


                                </div>
                            </div>
                        </div>
                    </div>
                    {bindClientData()}
                    {clientInfoPopup && (
                        <ClientInfoPopup
                            getAllClientData={getAllClientData}
                            setClientInfoPopup={setClientInfoPopup}
                            clientInfoPopup={clientInfoPopup}
                            editMode={editMode}
                            setEditMode={setEditMode}
                            clientDataToEdit={clientDataToEdit}
                            industryData={industryData}
                        />
                    )}

                    {filteredClients.length > 0 && (
                        <div className="col-md-12">
                            <span className="font-14 font-medium table-records-color">
                                Showing <span>{filteredClients?.length}</span> of <span>{totalClientCount}</span> records
                            </span>
                            {filteredClients.length < totalClientCount && (
                                <div className="text-center">
                                    <button className="btn primary-button text-center" onClick={handleLoadMore}>Load More</button>
                                </div>
                            )}
                        </div>
                    )}



                </div>
            }

        </>

    )
}
export default ClientCards