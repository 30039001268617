import React, { useState, useEffect } from 'react';
import TextEditorTemplate from './TextEditorTemplate'; // Adjust the path as necessary

const SessionContentEditor = ({ initialSections = {}, onSectionsChange }) => {
  const [sessions, setSessions] = useState(initialSections.sessions || []);
  const [title, setTitle] = useState(initialSections.title);

  useEffect(() => {
    setSessions(initialSections.sessions || []);
    setTitle(initialSections.title);
  }, [initialSections]);

  const handleContentChange = (index, newContent) => {
    const updatedSessions = sessions.map((session, i) =>
      i === index ? { ...session, content: newContent } : session
    );
    setSessions(updatedSessions);
    onSectionsChange({ ...initialSections, sessions: updatedSessions });
  };

  const handleTitleChange = (index, newTitle) => {
    const updatedSessions = sessions.map((session, i) =>
      i === index ? { ...session, title: newTitle } : session
    );
    setSessions(updatedSessions);
    onSectionsChange({ ...initialSections, sessions: updatedSessions });
  };

  const handleAddSession = () => {
    const newSession = { title: 'New Session Title', content: 'New session content' };
    const updatedSessions = [...sessions, newSession];
    setSessions(updatedSessions);
    onSectionsChange({ ...initialSections, sessions: updatedSessions });
  };

  return (
    <div>
      {/* <h2 className="text-2xl font-bold mb-4">{title}</h2> */}
      {sessions.map((session, index) => (
        <div key={index} className="mb-8">
          <input
            type="text"
            value={session.title}
            onChange={(e) => handleTitleChange(index, e.target.value)}
            className="w-full p-2 border-b-2 mb-4 text-xl font-bold"
            placeholder="Session Title"
          />
          <TextEditorTemplate
            initialContent={session.content}
            onContentChange={(newContent) => handleContentChange(index, newContent)}
          />
        </div>
      ))}
      <button
        onClick={handleAddSession}
        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition">
        Add New Session
      </button>
    </div>
  );
};

export default SessionContentEditor;