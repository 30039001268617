import React, { useState, useEffect, useRef } from 'react';
import { postArchitectureDetails, deleteAttributeRecords } from "../service/QuestionnaireApi";
import { getSasToken } from "../service/ReportApi";
import uploadFileToBlob1 from "../helpers/BlobUpload";
import { FutureStateArchitectureProps } from '../interface/GenerateReportModel';

//PS_FutureStateArchitecture_03 - PS_FutureStateArchitecture_06
const FutureStateArchitecture: React.FC<FutureStateArchitectureProps> = ({
    reportData,
    setReportData,
    setIsLoading

}) => {
    const [architectureData, setArchitectureData] = useState<any[]>(reportData.futureStateArchitecture || []);
    const [architectureName, setArchitectureName] = useState<string>("");
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState<string>("");
    const [nameError, setNameError] = useState<string>("");
    const [fileError, setFileError] = useState<string>("");
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [editingItem, setEditingItem] = useState<any>(null);
    const [editArchitectureName, setEditArchitectureName] = useState<string>("");
    const [editFile, setEditFile] = useState<any>(null);
    const [editFileName, setEditFileName] = useState<string>("");
    const [editNameError, setEditNameError] = useState<string>("");
    const [editFileError, setEditFileError] = useState<string>("");
    const [sasToken, setSasToken] = useState<string>('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingItemId, setDeletingItemId] = useState<string | null>(null);
    const fileInputRef = useRef(null);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showEditSavePopup, setShowEditSavePopup] = useState(false)

    const [hideToast, setHideToast] = useState(true);

    const [toastProp, setToastProp] = useState({
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    });


    //PS_FutureStateArchitecture_07
    useEffect(() => {
        setArchitectureData(reportData.futureStateArchitecture || []);
    }, [reportData.futureStateArchitecture]);

    //PS_FutureStateArchitecture_08 - PS_FutureStateArchitecture_21
    useEffect(() => {
        const fetchSasToken = async () => {
            try {
                const sas = await getSasToken('r');
                setSasToken(sas?.data);
            } catch (error) {
                console.error("Error fetching SAS token:", error);
            }
        };

        fetchSasToken();
    }, []);

    const generateImageUrl = (blobUrl: string, sasToken: string) => {
        if (!blobUrl || !sasToken) return '';
        return `${blobUrl}?${sasToken}`;
    };

    //PS_FutureStateArchitecture_91 - PS_FutureStateArchitecture_95
    const closeModal = () => {
        setShowDeleteModal(false);
        setDeletingItemId(null)
    };

   //PS_FutureStateArchitecture_23 - PS_FutureStateArchitecture_30
    const handleArchitectureNameChange = (e: React.ChangeEvent<HTMLInputElement>, isEdit: boolean = false) => {
        const value = e.target.value;
        if (isEdit) {
            setEditArchitectureName(value);
            setEditNameError('');
        } else {
            setArchitectureName(value);
            setNameError('');
        }
    };
    
    //PS_FutureStateArchitecture_32 - PS_FutureStateArchitecture_76
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, isEdit = false) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

            if (!allowedTypes.includes(selectedFile.type)) {
                if (isEdit) {
                    setEditFileError('File should be PNG, JPG, or JPEG');
                    setEditFile(null);
                    setEditFileName('');
                } else {
                    setFileError('File should be PNG, JPG, or JPEG');
                    setFile(null);
                    setFileName('');
                }
                resetFileInput();
                return;
            }

            if (selectedFile.size > 20 * 1024 * 1024) {
                if (isEdit) {
                    setEditFileError('File size should be less than 20 MB');
                    setEditFile(null);
                    setEditFileName('');
                } else {
                    setFileError('File size should be less than 20 MB');
                    setFile(null);
                    setFileName('');
                }
                resetFileInput();
                return;
            }

            validateImage(selectedFile, 1000, 1500, 300)
                .then((result: any) => {
                    if (result.isValid) {
                        if (isEdit) {
                            setEditFileError('');
                            setEditFile(selectedFile);
                            setEditFileName(selectedFile.name);
                        } else {
                            setFileError('');
                            setFile(selectedFile);
                            setFileName(selectedFile.name);
                        }
                    } else {
                        if (isEdit) {
                            setEditFileError(result.errorMessage);
                            setEditFile(null);
                            setEditFileName('');
                        } else {
                            setFileError(result.errorMessage);
                            setFile(null);
                            setFileName('');
                        }
                        resetFileInput();
                    }
                })
                .catch((error) => {
                    if (isEdit) {
                        setEditFileError(error);
                        setEditFile(null);
                        setEditFileName('');
                    } else {
                        setFileError(error);
                        setFile(null);
                        setFileName('');
                    }
                    resetFileInput();
                });
        } else {
            if (isEdit) {
                setEditFile(null);
                setEditFileName('');
                setEditFileError('');
            } else {
                setFile(null);
                setFileName('');
                setFileError('');
            }
        }
    };

    //PS_FutureStateArchitecture_42 - PS_FutureStateArchitecture_44
    const resetFileInput = () => {
        if (fileInputRef.current) {
            (fileInputRef.current as HTMLInputElement).value = '';
        }
    };

    //PS_FutureStateArchitecture_78 - PS_FutureStateArchitecture_83
    const handleCloseEditModal = () => {
        setEditingItem(null);
        setEditNameError('');
        setEditFileError('');
    };

    //PS_FutureStateArchitecture_51 -PS_FutureStateArchitecture_64
    const validateImage = (file: any, minWidth = 1000, minHeight = 1500, minDPI = 300) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                resolve({ isValid: false, errorMessage: 'No file selected.' });
                return;
            }

            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {

                    const dpiX = (img.width / (file.width / 25.4));
                    const dpiY = (img.height / (file.height / 25.4));

                    if (img.width < minWidth || img.height < minHeight || dpiX < minDPI || dpiY < minDPI) {
                        reject(`Upload an image over 1000 x 1500 pixels`);
                    } else {
                        resolve({ isValid: true, errorMessage: '' });
                    }
                };
                img.onerror = () => {
                    reject('Error loading image. Please try again.');
                };
                img.src = (event?.target?.result as string);
            };

            reader.onerror = () => {
                reject('Error reading file. Please try again.');
            };

            reader.readAsDataURL(file);
        });
    };

    const handleAdd = async () => {
        let isValid = true;

        if (!architectureName.trim()) {
            setNameError('Please enter an Architecture Name');
            isValid = false;
        } else if (architectureName.length > 40) {
            setNameError('Please enter a valid Architecture Name');
            isValid = false;
        } else {
            setNameError('');
        }

        if (!file) {
            setFileError('Please select a file to upload');
            isValid = false;
        } else {
            setFileError('');
        }

        if (!isValid) return;

        setError('');
        setShowToasterLoading(true);


        try {
            const blobUrl = await uploadFileToBlob1(file, 'futureStateArchitecture');

            const payload = [
                {
                    organizationId: reportData.organizationId,
                    verticalId: reportData.verticalId,
                    projectId: reportData.projectId,
                    connectorId: reportData.connectors.find((c: any) => c.connectorName === "Future State Architecture")?.connectorId,
                    connectorName: 'Future State Architecture',
                    attributeMasterId: '',
                    attributeId: '',
                    attributeName: 'Architecture Name',
                    attributeValue: architectureName,
                },
                {
                    organizationId: reportData.organizationId,
                    verticalId: reportData.verticalId,
                    projectId: reportData.projectId,
                    connectorId: reportData.connectors.find((c: any) => c.connectorName === "Future State Architecture")?.connectorId,
                    connectorName: 'Future State Architecture',
                    attributeMasterId: '',
                    attributeId: '',
                    attributeName: 'Architecture Blob URL',
                    attributeValue: blobUrl,
                },
            ];
            const response = await postArchitectureDetails(payload);
            if (response.data && response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true);
                await new Promise(resolve => setTimeout(resolve, 1000));

                setArchitectureData((prevData: any) => [response.data, ...prevData]);
                setReportData((prevState: any) => ({
                    ...prevState,
                    futureStateArchitecture: Array.isArray(prevState.futureStateArchitecture)
                        ? [response.data, ...prevState.futureStateArchitecture]
                        : [response.data]
                }));
                setArchitectureName('');
                setFile(null);
                setFileName('');
            } else {
                setFileError("Error: " + response.statusText);
                setIsLoading(false);
                setError("Error: " + response.statusText);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        } catch (error) {
            setShowToasterFailedPopup(true);
            console.error('Error adding architecture details:', error);
            setFileError("An error occurred while adding the architecture details.");
        } finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    };
    //PS_FutureStateArchitecture_96 - PS_FutureStateArchitecture_99
    const handleDeleteArchitecture = (item: any) => {
        setDeletingItemId(item[0].attributeMasterId);
        setShowDeleteModal(true);
    };
    
    //PS_FutureStateArchitecture_101 - PS_FutureStateArchitecture_130
    const confirmDeleteArchitecture = async () => {
        try {
            const payload = { attributeMasterId: deletingItemId };
            const response = await deleteAttributeRecords(payload);
            if (response.status === 200) {
                setShowDeletePopup(true);
                setIsLoading(true);
                closeModal();
                await new Promise(resolve => setTimeout(resolve, 1000));
                setArchitectureData((prevData: any) => prevData.filter((dataItem: any) => dataItem[0].attributeMasterId !== deletingItemId));
                setReportData((prevState: any) => ({
                    ...prevState,
                    futureStateArchitecture: Array.isArray(prevState.futureStateArchitecture)
                        ? prevState.futureStateArchitecture.filter((dataItem: any) => dataItem[0].attributeMasterId !== deletingItemId)
                        : []
                }));
            } else {
                setFileError("Error: " + response.statusText);
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                };
                setToastProp(toastData);
                setHideToast(false);
                setError("Error: " + response.statusText);
            }
        } catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
            console.error('Error deleting architecture details:', error);
            setFileError("An error occurred while deleting the architecture details.");
        } finally {
            setIsLoading(false)
            setDeletingItemId(null)
            setTimeout(() => {
                setShowDeletePopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }

    }
     
    //PS_FutureStateArchitecture_131 - 199
    const handleEdit = (item: any) => {
        setEditingItem(item);
        const nameItem = item.find((attr: any) => attr.attributeName === 'Architecture Name');
        const blobItem = item.find((attr: any) => attr.attributeName === 'Architecture Blob URL');
        setEditArchitectureName(nameItem ? nameItem.attributeValue : '');

        if (blobItem && blobItem.attributeValue) {
            const fileName = extractFileNameFromBlobUrl(blobItem.attributeValue);
            setEditFileName(fileName);
        } else {
            setEditFileName('');
        }

        setEditNameError('');
        setEditFileError('');
    };

    const handleEditSave = async () => {
        let isValid = true;

        if (!editArchitectureName.trim()) {
            setEditNameError('Please enter an Architecture Name');
            isValid = false;
        } else if (editArchitectureName.length > 40) {
            setEditNameError('Please enter a valid Architecture Name');
            isValid = false;
        } else {
            setEditNameError('');
        }

        if (!editFile && !editFileName) {
            setEditFileError('Please select a file to upload');
            isValid = false;
        } else {
            setEditFileError('');
        }

        if (!isValid) return;
        setShowToasterLoading(true);

        try {
            let blobUrl = editingItem.find((attr: any) => attr.attributeName === 'Architecture Blob URL')?.attributeValue;
            if (editFile) {
                blobUrl = await uploadFileToBlob1(editFile, 'futureStateArchitecture');
            }

            const payload = editingItem.map((attr: any) => {
                if (attr.attributeName === 'Architecture Name') {
                    return {
                        ...attr,
                        attributeValue: editArchitectureName,
                    };
                } else if (attr.attributeName === 'Architecture Blob URL') {
                    return {
                        ...attr,
                        attributeValue: blobUrl,
                    };
                }
                return attr;
            });
            handleCloseEditModal()
            const response = await postArchitectureDetails(payload);

            if (response.data && response.status === 200) {
                setShowEditSavePopup(true);
                setIsLoading(true);
                await new Promise(resolve => setTimeout(resolve, 1000));

                setArchitectureData((prevData) => prevData.map((item) =>
                    item[0].attributeMasterId === editingItem[0].attributeMasterId ? response.data : item
                ));
                setReportData((prevState: any) => ({
                    ...prevState,
                    futureStateArchitecture: Array.isArray(prevState.futureStateArchitecture)
                        ? prevState.futureStateArchitecture.map((item: any) =>
                            item[0].attributeMasterId === editingItem[0].attributeMasterId ? response.data : item
                        )
                        : [response.data]
                }));
                setEditingItem(null);
            } else {
                setIsLoading(false);
                setFileError("Error: " + response.statusText);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
                setError("Error: " + response.statusText);
            }
        } catch (error) {
            console.error('Error editing architecture details:', error);
            setFileError("An error occurred while editing the architecture details.");
            setError("An error occurred while editing the architecture details.");
            setShowToasterFailedPopup(true);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowEditSavePopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    };

    const extractFileNameFromBlobUrl = (blobUrl: string) => {
        const urlParts = blobUrl.split('/');
        let fileName = urlParts[urlParts.length - 1];

        if (!fileName.includes('.')) {
            const dashParts = blobUrl.split('-');
            fileName = dashParts[dashParts.length - 1];
        }

        fileName = fileName.split('?')[0];

        const iiiiiIndex = fileName.indexOf('iiiii-');
        if (iiiiiIndex !== -1) {
            fileName = fileName.substring(iiiiiIndex + 6);
        }

        fileName = decodeURIComponent(fileName);
        return fileName || 'Unknown File';
    };

    //PS_FutureStateArchitecture_84 - PS_FutureStateArchitecture_90
    const handleRemoveFile = () => {
        setFile(null);
        setFileName('');
        setError('');
        resetFileInput();
    };


    return (
        <div className="ms-lg-4">
            <h3 className="font-18 font-semibold color-black m-0 mt-4 mt-lg-0">Future State Architecture</h3>
            <div className="border-bottom my-4 pt-2 pb-4">
                <div className="row row-gap-4">
                    <div className="col-12 col-md-6 col-lg-3">
                        <div>
                            <label htmlFor="architectureName" className="form-label font-14 font-semibold color-black">
                                Architecture Name <span className="mandate">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control theme-form font-regular font-14"
                                id="architectureName"
                                placeholder="Architecture Name"
                                value={architectureName}
                                onChange={(e) => handleArchitectureNameChange(e)}
                            />
                            {nameError && <div className="text-danger font-12 mt-1">{nameError}</div>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                        <div className="d-flex align-items-end justify-content-between">
                            <div>
                                <label htmlFor="architectureUpload" className="form-label font-14 font-semibold color-black">
                                    Upload Architecture <span className="mandate">*</span>
                                </label>

                                <div className="position-relative d-flex align-items-center gap-3">
                                    <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold pd-12">
                                        <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control custom-file p-0"
                                        id="architectureUpload"
                                        aria-label="upload"
                                        onChange={handleFileChange}
                                        accept=".png,.jpg,.jpeg"
                                        style={{ opacity: 0 }}
                                        ref={fileInputRef}
                                    />
                                    {fileName && (
                                        <span className="font-12 font-medium color-grey">
                                            {fileName}
                                            <img
                                                src="images/close-icon-grey.svg"
                                                alt="close icon"
                                                className="ms-2 cursor-pointer"
                                                onClick={handleRemoveFile}
                                            />
                                        </span>
                                    )}
                                </div>
                                {fileError && <div className="text-danger font-12 mt-1">{fileError}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 ms-auto ms-md-0 mt-0 mt-4 pb-3">
                        <button
                            type="button"
                            className="btn btn-dark theme-primary-btn-md d-flex gap-2 align-items-center border-0 font-14 font-semibold"
                            onClick={handleAdd}
                            disabled={isSaving}
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
            {saveSuccess && <div className="alert alert-success mt-2">Architecture details has been saved successfully!</div>}

            {/* Display existing architecture data */}
            {architectureData && architectureData.length > 0 && (
                <div>
                    {architectureData.map((item: any, index: number) => {
                        const nameItem = item.find((attr: any) => attr.attributeName === 'Architecture Name');
                        const blobItem = item.find((attr: any) => attr.attributeName === 'Architecture Blob URL');

                        return (
                            <div key={index} className="border rounded-3 p-3 mb-4">
                                <div className="d-flex align-items-center font-16 font-medium color-black-v1">
                                    <div className="d-flex align-items-center">
                                        <img
                                            src="images/accordion-arrow-grey.svg"
                                            alt="accordion-arrow"
                                            className="cursor-pointer accordion-arrow me-2"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#architecture${index}`}
                                            aria-expanded={index === 0 ? "true" : "false"}
                                        />
                                        <span className="color-black font-16 font-semibold">{nameItem ? nameItem.attributeValue : 'Unnamed Architecture'}</span>
                                    </div>
                                    <div className="d-flex align-items-center ms-auto">
                                        <button type="button" className="btn edit-btn custom-btn font-medium" onClick={() => handleEdit(item)}>Edit</button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger custom-btn ms-3 font-medium"
                                            onClick={() => {
                                                handleDeleteArchitecture(item)
                                            }
                                            }
                                            disabled={deletingItemId === item[0].attributeMasterId}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <div className={`px-3 collapse ${index === 0 ? 'show' : ''}`} id={`architecture${index}`}>
                                    <div className="text-center pt-3">
                                        {blobItem && blobItem.attributeValue && (
                                            <img src={generateImageUrl(blobItem.attributeValue, sasToken)} alt="architecture diagram" className="w-100" />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            {/* Edit Modal */}
            {editingItem && (
                <>
                    <div className="modal-backdrop fade show"></div>
                    <div className="modal fade show" id="start-new-assessment" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="accept-promptLabel" aria-modal="true" role="dialog" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content custom-popup w-650">
                                <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                                    <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                                        Edit Architecture
                                    </h2>
                                    <button type="button" className="btn" onClick={handleCloseEditModal} aria-label="Close">
                                        <img src="images/popup-close.svg" alt="Close" />
                                    </button>
                                </div>
                                <div className="modal-body border-0 px-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label font-14 font-medium color-black" htmlFor="editArchitectureName">Architecture Name</label>
                                            <input
                                                type="text"
                                                id="editArchitectureName"
                                                className="form-control custom-form color-black"
                                                value={editArchitectureName}
                                                onChange={(e) => handleArchitectureNameChange(e, true)}
                                            />
                                            {editNameError && <div className="text-danger font-12 mt-1">{editNameError}</div>}
                                        </div>
                                        <div className="col-md-12 mt-4">
                                            <label className="form-label font-14 font-medium color-black" htmlFor="editArchitectureUpload">Upload Architecture</label>
                                            <div className="position-relative d-flex align-items-center edit-architecture-popup gap-3">
                                                <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-12 font-semibold">
                                                    <img src="images/upload-icon-blue.svg" alt="upload-icon" />Upload
                                                </label>
                                                <input
                                                    type="file"
                                                    className="form-control custom-file mb-4"
                                                    id="editArchitectureUpload"
                                                    onChange={(e) => handleFileChange(e, true)}
                                                    accept=".png,.jpg,.jpeg"
                                                    style={{ opacity: 0 }}
                                                    ref={fileInputRef}
                                                />
                                                {editFileName && (
                                                    <span className="font-12 font-medium color-grey">
                                                        {editFileName}
                                                        <img
                                                            src="images/close-icon-grey.svg"
                                                            alt="close icon"
                                                            className="ms-3 cursor-pointer"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setEditFile(null);
                                                                setEditFileName('');
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                            {editFileError && <div className="text-danger font-12 mt-1">{editFileError}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                                    <a
                                        className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer"
                                        onClick={() => {
                                            setEditArchitectureName('');
                                            setEditFileName('');
                                            setEditFile(null);
                                            setEditNameError('');
                                            setEditFileError('');
                                        }}
                                    >
                                        Clear
                                    </a>
                                    <button
                                        type="button"
                                        className="btn btn-dark theme-primary-btn border-0 font-semibold"
                                        onClick={handleEditSave}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            )}
            {showDeleteModal && (
                <div
                    className="modal fade show"
                    id="delete"
                    tabIndex={-1}
                    aria-hidden="true"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    style={{ display: 'block' }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-3">
                            <div className="modal-header pb-0 border-0">

                                <button
                                    type="button"
                                    className="btn-close cursor-pointer"
                                    onClick={closeModal}
                                    aria-label="Close"
                                ></button>
                            </div>

                            <div className="modal-body">
                                <div className="w-100 text-center mb-3">
                                    <img src="images/delete-icon.svg" alt="delete" />
                                </div>

                                <p className="font-semibold font-24 text-center mb-2 red-400">
                                    Delete Confirmation
                                </p>

                                <p className="font-semibold font-16 text-center mb-5">
                                    Are you sure you want to delete?
                                </p>

                                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                    <a
                                        className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4"
                                        onClick={confirmDeleteArchitecture}
                                    >
                                        Yes
                                    </a>

                                    <button
                                        type="button"
                                        className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold"
                                        onClick={closeModal}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showDeleteModal && <div className="modal-backdrop fade show"></div>}
            {showToasterLoading && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img
                                    src="images/loading.gif"
                                    alt="toast-success"
                                    style={{ height: "15px", width: "15px" }}
                                />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Saving...
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showToasterPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Architecture has been added successfully
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showEditSavePopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Architecture has been saved successfully
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showToasterFailedPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/fail-icon.svg" alt="toast-fail" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Api failed.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showDeletePopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Architecture has been Deleted successfully.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FutureStateArchitecture;