
import AttributeWorkbenchWrapper from "./AttributesWorkbenchWrapper";
import Header from "../Header/Header";

function AttributesMain(){



  return (
    <>
    {/* <Header/> */}
    <div className="container-fluid p-4">
    <div className="row h-100">

        <AttributeWorkbenchWrapper

        />
      {/* )} */}
    </div>
    </div>
    </>
  );

}

export default AttributesMain