import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import SideNavComponent from "./SideNav";
import ReportWorkbenchComponent from "./ReportWorkbench";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import { fetchReportDataApi } from "../../Service/api";
import Breadcrumbs from "../Breadcrumbs";


const MainReportComponent = () => {
  console.log("MainReportComponent render start");

  // Use useLocation hook to access navigation state data
  const location = useLocation();
//   const [workflowId] = useState(location.state.workflowId);
//   const [reportId] = useState(location.state.reportId);

  const [workflowId] = useState("b6279741-68d9-47a7-9b23-1a0cf81e8e02");
  const [reportId] = useState("b6279741-68d9-47a7-9b23-1a0cf81e8e02");  


  const [navItems, setNavItems] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getReportData = useCallback(async () => {
    console.log("getReportData called");
    setLoading(true);
    setError(null);
    try {
      const data = await fetchReportDataApi({ report_id: reportId });
      console.log("API response:", data);
      if (data.statusCode === 200) {
        const sortedItems = data.body.message.sort(
          (a, b) => parseInt(a.sort_id) - parseInt(b.sort_id)
        );
        console.log("Sorted items:", sortedItems);
        console.log("Report ID: ", data.body);

        setNavItems(sortedItems);
        setActiveTab(sortedItems[0]?.report_data_id || null);
      } else {
        throw new Error(data.statusText || "Failed to fetch report data");
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
      setError("Failed to load report data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [reportId]);

  useEffect(() => {
    console.log("MainReportComponent useEffect: calling getReportData");
    getReportData();
  }, [getReportData]);

  const handleTabChange = useCallback(
    (tabId) => {
      console.log("Tab changed to:", tabId);
      if (!navItems.some((item) => item.report_data_id === tabId)) {
        console.error("Invalid tabId selected:", tabId);
      }
      setActiveTab(tabId);
    },
    [navItems]
  );

  console.log("MainReportComponent rendering with:", {
    navItems,
    activeTab,
    loading,
    error,
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container-fluid p-4 content-container">
       <Breadcrumbs/>
        {error && <div className="error-message">{error}</div>}

        <div className="row">
          <div className="col-md-12 my-3">
            <div className="d-flex justify-content-between flex-md-row flex-column align-items-center">
              <h1 className="font-24 font-semibold black-color page-title mb-0">
                <a href="#">
                  <img
                    src="../images/back-arrow.svg"
                    alt="back-arrow"
                    className="me-2"
                  />
                </a>
                Reports
              </h1>
              <div className="d-flex">
                <button
                  className="btn primary-button focusable"
                  onClick={() => console.log("Download button clicked")}
                  disabled={loading}
                >
                  <img
                    src="../images/download-report.svg"
                    alt="file-icon"
                    className="mb-1 me-1"
                  />
                  Download Report
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <SideNavComponent
            initialNavItems={navItems}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            workflowId={workflowId}
            reportId={reportId}
          />
          <ReportWorkbenchComponent
            templates={navItems}
            activeTemplateId={activeTab}
            reportId={workflowId}
            getReportData={getReportData}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(MainReportComponent, (prevProps, nextProps) => {
  return prevProps.workflowId === nextProps.workflowId;
});