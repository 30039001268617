// import React, { useState, useEffect } from 'react';

// const DynamicTable = ({ tableData, updateSectionContent }) => {
//   const [rows, setRows] = useState(tableData.rows);
//   const [editingIndex, setEditingIndex] = useState(null);
//   const [editedRow, setEditedRow] = useState([]);
//   const [newRow, setNewRow] = useState(Array(tableData.columns.length).fill(''));

//   useEffect(() => {
//     console.log('Rendering DynamicTable with tableData:', tableData);
//     setRows(tableData.rows); // Ensure rows are updated when tableData changes
//   }, [tableData]);

//   const handleInputChange = (e, colIndex, isEditing) => {
//     const { value } = e.target;
//     if (isEditing) {
//       const updatedRow = [...editedRow];
//       updatedRow[colIndex] = value;
//       setEditedRow(updatedRow);
//       console.log('Editing row data:', updatedRow); // Debugging edited row data
//     } else {
//       const updatedNewRow = [...newRow];
//       updatedNewRow[colIndex] = value;
//       setNewRow(updatedNewRow);
//       console.log('Adding new row data:', updatedNewRow); // Debugging new row data
//     }
//   };

//   const handleEditClick = (index) => {
//     setEditingIndex(index);
//     setEditedRow(rows[index]);
//     console.log('Editing row at index:', index, 'with data:', rows[index]); // Debugging
//   };

//   const handleDeleteClick = (index) => {
//     const updatedRows = rows.filter((_, i) => i !== index);
//     console.log('Deleting row at index:', index); // Debugging
//     setRows(updatedRows);
//     updateSectionContent({ ...tableData, rows: updatedRows });
//   };

//   const handleSaveClick = (index) => {
//     const updatedRows = [...rows];
//     updatedRows[index] = editedRow;
//     console.log('Saving row at index:', index, 'with updated data:', editedRow); // Debugging
//     setRows(updatedRows);
//     updateSectionContent({ ...tableData, rows: updatedRows });
//     setEditingIndex(null);
//   };

//   const handleCancelClick = () => {
//     console.log('Canceling edit'); // Debugging
//     setEditingIndex(null);
//   };

//   const handleAddRow = () => {
//     const updatedRows = [...rows, newRow];
//     console.log('Adding new row:', newRow); // Debugging
//     setRows(updatedRows);
//     updateSectionContent({ ...tableData, rows: updatedRows });
//     setNewRow(Array(tableData.columns.length).fill(''));
//   };

//   console.log('Current rows state:', rows); // Debugging current rows state

//   return (
//     <div className="p-6">
//       <h2 className="text-xl font-semibold mb-4">{tableData.title}</h2>
//       <table className="min-w-full divide-y divide-gray-200">
//         <thead className="bg-gray-50">
//           <tr>
//             {tableData.columns.map((column, index) => (
//               <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
//                 {column}
//               </th>
//             ))}
//             <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
//               Actions
//             </th>
//           </tr>
//         </thead>
//         <tbody className="bg-white divide-y divide-gray-200">
//           <tr>
//             {newRow.map((cell, colIndex) => (
//               <td key={colIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                 <input
//                   type="text"
//                   value={cell}
//                   onChange={(e) => handleInputChange(e, colIndex, false)}
//                   className="form-input block w-full sm:text-sm border-gray-300 rounded-md"
//                   placeholder={`Enter ${tableData.columns[colIndex]}`}
//                 />
//               </td>
//             ))}
//             <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
//               <button
//                 onClick={handleAddRow}
//                 className="text-green-600 hover:text-green-900"
//               >
//                 Add
//               </button>
//             </td>
//           </tr>

//           {rows.map((row, index) => (
//             <tr key={index}>
//               {row.map((cell, colIndex) => (
//                 <td key={colIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                   {editingIndex === index ? (
//                     <input
//                       type="text"
//                       value={editedRow[colIndex] || ''}
//                       onChange={(e) => handleInputChange(e, colIndex, true)}
//                       className="form-input block w-full sm:text-sm border-gray-300 rounded-md"
//                     />
//                   ) : (
//                     cell || '-'
//                   )}
//                 </td>
//               ))}
//               <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
//                 {editingIndex === index ? (
//                   <>
//                     <button
//                       onClick={() => handleSaveClick(index)}
//                       className="text-indigo-600 hover:text-indigo-900"
//                     >
//                       Save
//                     </button>
//                     <button
//                       onClick={handleCancelClick}
//                       className="ml-2 text-red-600 hover:text-red-900"
//                     >
//                       Cancel
//                     </button>
//                   </>
//                 ) : (
//                   <>
//                     <button
//                       onClick={() => handleEditClick(index)}
//                       className="text-indigo-600 hover:text-indigo-900"
//                     >
//                       Edit
//                     </button>
//                     <button
//                       onClick={() => handleDeleteClick(index)}
//                       className="ml-2 text-red-600 hover:text-red-900"
//                     >
//                       Delete
//                     </button>
//                   </>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default DynamicTable;

import React, { useState, useEffect } from 'react';

const DynamicTable = (props) => {
  console.log(props, "Dynamic Table")
  let { sectionId, tableData, templateId, updateSectionContent } = props
  console.log(templateId, "Template ID in the Dynamic Table!!")
  const [rows, setRows] = useState(tableData.rows);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedRow, setEditedRow] = useState([]);
  const [newRow, setNewRow] = useState(Array(tableData.columns.length).fill(''));

  useEffect(() => {
    console.log('Rendering DynamicTable with tableData:', tableData);
    setRows(tableData.rows); // Ensure rows are updated when tableData changes
  }, [tableData]);

  const handleInputChange = (e, colIndex, isEditing) => {
    const { value } = e.target;
    if (isEditing) {
      const updatedRow = [...editedRow];
      updatedRow[colIndex] = value;
      setEditedRow(updatedRow);
      console.log('Editing row data:', updatedRow); // Debugging edited row data
    } else {
      const updatedNewRow = [...newRow];
      updatedNewRow[colIndex] = value;
      setNewRow(updatedNewRow);
      console.log('Adding new row data:', updatedNewRow); // Debugging new row data
    }
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedRow(rows[index]);
    console.log('Editing row at index:', index, 'with data:', rows[index]); // Debugging
  };

  const handleDeleteClick = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    console.log('Deleting row at index:', index);
    setRows(updatedRows);

    let data = { ...tableData, rows: updatedRows }
    console.log("updated rows", data, templateId, sectionId);

    updateSectionContent(data);
  };

  const handleSaveClick = (index) => {
    const updatedRows = [...rows];
    updatedRows[index] = editedRow;
    console.log('Saving row at index:', index, 'with updated data:', editedRow); // Debugging
    setRows(updatedRows);
    updateSectionContent({ ...tableData, rows: updatedRows });
    setEditingIndex(null);
  };

  const handleCancelClick = () => {
    console.log('Canceling edit'); // Debugging
    setEditingIndex(null);
  };

  const handleAddRow = () => {
    const updatedRows = [...rows, newRow];
    // console.log('Adding new row:', newRow); // Debugging
    // console.log("after adding", updatedRows);
    
    setRows(updatedRows);
    // updateSectionContent(sectionId, templateId, { ...tableData, rows: updatedRows });
    let data = { ...tableData, rows: updatedRows }
    console.log("updated rows", data, templateId, sectionId);

    updateSectionContent(data);
    setNewRow(Array(tableData.columns.length).fill(''));
  };

  console.log('Current rows state:', rows); // Debugging current rows state

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-4">{tableData.title}</h2>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {column}
              </th>
            ))}
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            {newRow.map((cell, colIndex) => (
              <td key={colIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <input
                  type="text"
                  value={cell}
                  onChange={(e) => handleInputChange(e, colIndex, false)}
                  className="form-input block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder={`Enter ${tableData.columns[colIndex]}`}
                />
              </td>
            ))}
            <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
              <button
                onClick={handleAddRow}
                className="text-green-600 hover:text-green-900"
              >
                Add
              </button>
            </td>
          </tr>

          {rows.map((row, index) => (
            <tr key={index}>
              {row.map((cell, colIndex) => (
                <td key={colIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {editingIndex === index ? (
                    <input
                      type="text"
                      value={editedRow[colIndex] || ''}
                      onChange={(e) => handleInputChange(e, colIndex, true)}
                      className="form-input block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  ) : (
                    cell || '-'
                  )}
                </td>
              ))}
              <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                {editingIndex === index ? (
                  <>
                    <button
                      onClick={() => handleSaveClick(index)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancelClick}
                      className="ml-2 text-red-600 hover:text-red-900"
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => handleEditClick(index)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteClick(index)}
                      className="ml-2 text-red-600 hover:text-red-900"
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTable;