
// Approvals.js (or Approvals component file)
import React, { useState, useEffect, use } from 'react';
import Header from '../Header/Header';
import { fetchClientsDataApi, getApprovalData, getAssessmentTypeData, postErrorLog } from '../../Service/api';
import Loader from '../Loader/Loader';


const Approvals = () => {

    //State declarations: 0.1
    const [approvalData, setApprovalData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loadCount, setLoadCount] = useState(10);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [sortColumnName, setSortColumnName] = useState("");
    const [filterData, setFilterData] = useState({
        type: "",
        domain: "",
        status: "",
        client: "",
        start_date: "",
        end_date: ""
    });
    const [clientsData, setClientsData] = useState([])
    const [assessmentTypeData, setAssessmentTypeData] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false); // State to control dropdown visibility
    const [filteredApprovals, setFilteredApprovals] = useState([])




    const userId = localStorage.getItem('userId')


    // Executes side-effects when sort order, sort column name, or search term changes to fetch updated approval data.

    // Parameters:
    // -----------
    // sortOrder : string
    //     The order for sorting ('ASC' or 'DESC').
    // sortColumnName : string
    //     The name of the column to sort by.
    // search : string
    //     The search term.

    // Details:
    // --------
    // Calls `fetchApprovalData` with the updated filter data to refresh the approval list based on the current sorting and search conditions.

    // Formula:
    // --------
    // useEffect  calls fetchApprovalData(filterData)
    //------------------------

    //SQ_0.3- 0.5: Inital fetch approval data
    useEffect(() => {
        fetchApprovalData(filterData);
    }, [sortOrder, sortColumnName, loadCount,search]);

    // Executes side-effects upon component mount to fetch client and assessment type data.

    // Details:
    // --------
    // Calls `getAllClientData` and `fetchAssessmentTypeData` when the component is initially mounted.

    // Formula:
    // --------
    // useEffect triggers getAllClientData and fetchAssessmentTypeData
    //SQ_5.14 and 5.29
    useEffect(() => {
        getAllClientData()
        fetchAssessmentTypeData()
    }, [])

    // Fetches and sets the approval data based on provided filters and search criteria.

    // Parameters:
    // -----------
    // filterData : object
    //     The filter data to apply to the approval data fetch.

    // Details:
    // --------
    // Constructs a payload with user ID, filters, search key, and sort information. Fetches approval data using `getApprovalData` and updates the state with the fetched approval data and total approval count. Handles errors by logging them and ensures the loading state is properly managed.
    // //SQ_1.1 -1.27: Fetch apporval data
    const fetchApprovalData = async (filterData) => {

        try {
            const payload = {
                user_id: userId,
                filters: filterData,
                search_key: search,
                sort: {
                    columnName: sortColumnName || "status",
                    order: sortOrder || "ASC"
                },
                load: loadCount
            };
            if(!search){
            setShowLoader(true)
        }
            const response = await getApprovalData(payload);
            if(!search){
                setShowLoader(false)
            }
            if (response?.status === 200) {
                const data = response.data.body.data.approvals;
                setApprovalData(data);
                // let filteredData = data;
                // if (search) {
                //     filteredData = data.filter(approval =>
                //         approval.client_name.toLowerCase().includes(search.toLowerCase())
                //     );
                // }
                setFilteredApprovals(data);
                setTotalCount(response.data.body.data.total_count);
            }
        } catch (error) {
            setShowLoader(false)
            handleError(error, "getApprovalData")
        } finally {
            setLoading(false);
        }
    };

    console.log(search,totalCount,"searchVALUE");
    
    // Logs and handles errors that occur in API calls.

    // Parameters:
    // -----------
    // error : object
    //     The error object received from a failed API call.
    // functionName : string
    //     The name of the function where the error occurred.

    // Details:
    // --------
    // Constructs an error data object including user ID, error type, module name, and error description. Sends this data to `postErrorLog`.
    //
    //SQ_1.0 -1.3
    const handleError = (error, functionName) => {
        const errorData = {
            user_id: userId,
            type: "error",
            error_module_name: functionName,
            error_description: error?.message + " ," + error?.response?.data?.body?.message

        };
        postErrorLog(errorData);
    };

    // Applies the current filter settings.

    // Details:
    // --------
    // Calls `fetchApprovalData` with the provided filter data and logs the applied filters to the console.

    // Formula:
    // --------
    // call fetchApprovalData(filterData)

    //SQ_3.1-3.3
    const handleApply = () => {
        fetchApprovalData(filterData);
        closeFilter();
    };

    // Handles input changes in the search field and triggers data fetch.

    // Parameters:
    // -----------
    // e : object
    //     The event object for the search input change.

    // Details:
    // --------
    // Updates the state with the new input value and calls `fetchApprovalData` with the current filter data.

    // Formula:
    // --------
    // set the search with the new value and call fetchApprovalData(filterData)
    //SQ_3.5-3.7

    // };
    const handleSearch = (e) => {
        const term = e.target.value;
        setSearch(term);
    };

    // Toggles the sort order and sets the column name to sort by.

    // Parameters:
    // -----------
    // columnName : string
    //     The name of the column to be sorted.

    // Details:
    // --------
    // Toggles the sort order between 'ASC' and 'DESC' and sets the sort column name to the provided column name.

    // Formula:
    // --------
    // set sortOrder with the opposite value of the current sortOrder and set sortColumnName with the provided columnName
    //SQ_3.9-3.11
    const toggleSortOrder = (columnName) => {

        let newSortOrder;

        if (sortColumnName === columnName) {
            // If the same column is clicked, toggle the sort order
            newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
        } else {
            // If a different column is clicked, set the default sort order (ASC)
            newSortOrder = 'ASC';
        }


        setSortOrder(newSortOrder);
        setSortColumnName(columnName);
    };

    // Validates and sets filter data.

    // Parameters:
    // -----------
    // e : object
    //     The event object for the filter input change.

    // Details:
    // --------
    // Retrieves the input ID and value from the event, then updates the `filterData` state.

    // Formula:
    // --------
    // const { id, value } = e.target;
    // set the filterData with the new value
    //SQ_5.1 -5.3
    const filterValidation = (e) => {
        const { id, value } = e.target;
        setFilterData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    // Clears all filter data and fetches the approval data with reset filters.

    // Details:
    // --------
    // Sets filter data to an initial state with empty values and calls `fetchApprovalData` with the reset filter data.

    // Formula:
    // --------
    // resetValue = {
    //     type: "",
    //     domain: "",
    //     status: "",
    //     client: "",
    //     start_date: "",
    //     end_date: ""
    // }

    //reset the filterData with resetValue and call fetchApprovalData(resetValue)
    //SQ_5.4-5.7
    const handleClear = () => {

        const resetValue = {
            type: "",
            domain: "",
            status: "",
            client: "",
            start_date: "",
            end_date: ""
        }
        setFilterData(resetValue);
        fetchApprovalData(resetValue);
        closeFilter()
    };


    // Renders the approval data in a table format, including sorting functionality.

    // Returns:
    // --------
    // JSX Elements:
    //     Rendered approval table with data rows and sorting headers.

    // Details:
    // --------
    // Maps through `approvalData` to create table rows for each approval entry, including client name, assessment name, type, request date, requester, status, and an action button.

    // Formula:
    // --------
    // If approvalData.length == 0:
    //     return "Oops! No Attributes Found" JSX

    // Else:
    //     approvalData.map(item => { ... render approval row ... })

    //SQ_2.0-2.2
    const renderApprovalData = () => {
        return (
            <div className="table-responsive bg-white zp-table custom-spacing">
                <table className="table m-0 align-middle">
                    <thead>
                        <tr className="alter">
                            <th>
                                Client
                                {filteredApprovals?.length > 0 && (
                                    <span className="cursor-pointer ms-1" onClick={() => toggleSortOrder('client_name')}>
                                        <img
                                            src="images/sort-down-arrow.svg"
                                            alt="sort-arrow-down"
                                            style={{
                                                transform: sortColumnName === "client_name" && sortOrder === "ASC"
                                                    ? "scaleY(-1)"
                                                    : "scaleY(1)",
                                                transition: "transform 0.2s ease-in-out",
                                            }}
                                        />
                                    </span>
                                )}
                            </th>
                            <th>
                                Assessment Name
                                {filteredApprovals?.length > 0 && (
                                    <span className="cursor-pointer ms-1" onClick={() => toggleSortOrder('assessment_name')}>
                                        <img
                                            src="images/sort-down-arrow.svg"
                                            alt="sort-arrow-down"
                                            style={{
                                                transform: sortColumnName === "assessment_name" && sortOrder === "ASC"
                                                    ? "scaleY(-1)"
                                                    : "scaleY(1)",
                                                transition: "transform 0.2s ease-in-out",
                                            }}
                                        />
                                    </span>
                                )}
                            </th>
                            <th>
                                Type
                                {filteredApprovals?.length > 0 && (
                                    <span className="cursor-pointer ms-1" onClick={() => toggleSortOrder('type')}>
                                        <img
                                            src="images/sort-down-arrow.svg"
                                            alt="sort-arrow-down"
                                            style={{
                                                transform: sortColumnName === "type" && sortOrder === "ASC"
                                                    ? "scaleY(-1)"
                                                    : "scaleY(1)",
                                                transition: "transform 0.2s ease-in-out",
                                            }}
                                        />
                                    </span>
                                )}
                            </th>
                            <th>
                                Requested on
                                {filteredApprovals?.length > 0 && (
                                    <span className="cursor-pointer ms-1" onClick={() => toggleSortOrder('request_date_time')}>
                                        <img
                                            src="images/sort-down-arrow.svg"
                                            alt="sort-arrow-down"
                                            style={{
                                                transform: sortColumnName === "request_date_time" && sortOrder === "ASC"
                                                    ? "scaleY(-1)"
                                                    : "scaleY(1)",
                                                transition: "transform 0.2s ease-in-out",
                                            }}
                                        />
                                    </span>
                                )}
                            </th>
                            <th className="">
                                Requested by
                                {filteredApprovals?.length > 0 && (
                                    <span className="cursor-pointer ms-1" onClick={() => toggleSortOrder('requested_by')}>
                                        <img
                                            src="images/sort-down-arrow.svg"
                                            alt="sort-arrow-down"
                                            style={{
                                                transform: sortColumnName === "requested_by" && sortOrder === "ASC"
                                                    ? "scaleY(-1)"
                                                    : "scaleY(1)",
                                                transition: "transform 0.2s ease-in-out",
                                            }}
                                        />
                                    </span>
                                )}
                            </th>
                            <th className="text-center">
                                Status
                                {filteredApprovals?.length > 0 && (
                                    <span className="cursor-pointer ms-1" onClick={() => toggleSortOrder('status')}>
                                        <img
                                            src="images/sort-down-arrow.svg"
                                            alt="sort-arrow-down"
                                            style={{
                                                transform: sortColumnName === "status" && sortOrder === "ASC"
                                                    ? "scaleY(-1)"
                                                    : "scaleY(1)",
                                                transition: "transform 0.2s ease-in-out",
                                            }}
                                        />
                                    </span>
                                )}
                            </th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        {filteredApprovals?.length === 0 && (
                            <div className="row mt-4" style={{ paddingLeft: "190%" }}>
                                <div className="d-grid justify-content-center text-center my-5 pt-5">
                                    <img src="images/norecord-img.svg" alt="norecord-img" />
                                    <p className="font-semibold font-30 mt-5 mb-2">Oops!</p>
                                    <p className="font-regular font-16">No Attributes Found</p>
                                </div>
                            </div>
                        )}


                        {filteredApprovals?.map((item) => (
                            <tr key={item.id}>
                                <td>
                                    <a href="#" className="w-150px text-truncate text-decoration-none blue-text d-block">
                                        {item?.client_name}
                                    </a>
                                </td>
                                <td>{item?.assessment_name}</td>
                                <td>{item?.type_of_request}</td>
                                <td>{item?.request_date_time?.slice(0, 10)}</td>
                                <td>{item?.requested_by}</td>
                                <td className="text-center">
                                    <span className={`table-status  ${item.status === 'approved' ? 'completed-color' : 'in-progress-color'}`}>
                                        <span
                                            className={`status-indicator ${item.status === 'approved'
                                                ? 'status-completed'
                                                : item.status === 'pending'
                                                    ? 'status-in-progress'
                                                    : ''
                                                }`}
                                        />
                                        {capitalizeFirstLetter(item?.status)}

                                    </span>
                                </td>
                                <td className='text-center'>

                                    <button className='btn'>
                                        <img src="images/eye-icon.svg" alt="kebab-icon" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // Fetches and sets the client data without any applied filters or search keys.

    // Details:
    // --------
    // Constructs a payload with user ID and default filter and search settings. Fetches client data using `fetchClientsDataApi` and updates the state with the fetched client data. Handles errors by logging them.

    // Formula:
    // --------
    // payload = {
    //     user_id: userId,
    //     filters: {
    //         industry_name: "",
    //         load: ""
    //     },
    //     search_key: ""
    // }

    // API Call:
    // --------
    // response = await fetchClientsDataApi(payload)

    // If response is successful:
    //     set the clientsData state with the response data

    //SQ_5.15-5.28
    const getAllClientData = async () => {
        try {
            const payload =
            {
                user_id: userId,
                filters: {
                    industry_name: "",
                    load: ""

                },
                search_key: "",
                //load: loadCount
            };

            const response = await fetchClientsDataApi(payload);
            console.log(response, "responsess")
            setClientsData(response.data.body.data.client_data);

        } catch (error) {

        }
    };

    // Fetches and sets the assessment type data.

    // Details:
    // --------
    // Constructs a payload with user ID and dropdown type for assessment types. Fetches assessment type data using `getAssessmentTypeData` and updates the state with the fetched assessment type data. Handles errors by logging them.

    // Formula:
    // --------
    // payload = {
    //     dropdown_type: 'assessment_type',
    //     user_id: userId
    // }

    // API Call:
    // --------
    // response = await getAssessmentTypeData(payload)

    // If response is successful:
    //     set the assessmentTypeData state with the response data

    //SQ_5.30-5.41
    const fetchAssessmentTypeData = async () => {
        try {
            const payload = {
                dropdown_type: 'assessment_type',
                // is_admin: false,
                user_id: userId
            };
            const response = await getAssessmentTypeData(payload);
            if (response.status === 200) {
                setAssessmentTypeData(response.data.body.data);
            }
        } catch (error) {
            handleError(error, "fetchAssessmentTypeData")
        }
    };

    //SQ_5.42-5.43
    // Function to toggle filter dropdown
    const toggleFilter = () => {
        setIsFilterOpen((prev) => !prev);
    };
    //SQ_5.44-5.45
    // Function to close filter dropdown
    const closeFilter = () => {
        setIsFilterOpen(false);
    };

    const isFilterDataEmpty = () => {
        return Object.values(filterData).every(value => value === '');
    };

    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
    };


    return (
        <>

            {/* <Header /> */}
            {showLoader ? (
                <Loader />
            ) : (
                <>

                    {/* Parent Organization Grid starts here */}
                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-md-12"></div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12 mb-3">
                                <div className="d-flex justify-content-between flex-md-row flex-column">
                                    <span className="font-24 font-semibold black-color page-title">
                                        Approvals ({totalCount})
                                    </span>
                                    <div className="d-flex gap-2">
                                        <div className="input-group custom-search">
                                            <input
                                                type="text"
                                                className="form-control border-0 input-search"
                                                placeholder="Search"
                                                value={search}
                                                onChange={((e)=>{handleSearch(e)})}
                                            />
                                            <button className="input-group-text input-field border-0 bg-white migrate-form-ui" onClick={handleSearch}>
                                                <img src="images/search.svg" alt="search-icon" />
                                            </button>
                                        </div>
                                        {/* Filter Section Starts */}
                                        <div className='position-relative'>
                                        <button
                                            type="button"
                                            className="filter-btn bg-white"
                                            data-bs-toggle="dropdown"
                                            id="dropdownMenuLink"
                                            //data-bs-auto-close="outside"
                                            onClick={toggleFilter}
                                            aria-expanded={isFilterOpen}
                                        >
                                            <img src="images/filter.svg" alt="filter-icon" />
                                        </button>
                                        <div
                                            className={`dropdown-menu filter-dropdown dropdown-menu-end border-0 ${isFilterOpen ? "show" : ""}`} // Add "show" class conditionally
                                            aria-labelledby="dropdownMenuLink"
                                        >
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <h5 className="font-16 font-semibold black-color mb-0">
                                                    Filter
                                                </h5>

                                                <button type="button" class="btn-close" onClick={closeFilter} data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="row">
                                                {/* Client */}
                                                <div className="col-md-6 mb-3">
                                                    <label
                                                        className="form-label font-14 font-medium"
                                                        htmlFor="client"
                                                    >
                                                        Client
                                                    </label>
                                                    <select
                                                        className="form-select migrate-form-ui"
                                                        id="client"
                                                        value={filterData.client}
                                                        onChange={filterValidation}
                                                    >
                                                        <option value="" >Select Client</option>
                                                        {clientsData?.map((clients) => (
                                                            <option key={clients.client_id} value={clients.client_id}>
                                                                {clients.client_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {/* Client */}
                                                {/* Type */}
                                                <div className="col-md-6 mb-3">
                                                    <label
                                                        className="form-label font-14 font-medium"
                                                        htmlFor="type"
                                                    >
                                                        Type
                                                    </label>
                                                    <select
                                                        className="form-select migrate-form-ui"
                                                        id="type"
                                                        value={filterData.type}
                                                        onChange={filterValidation}
                                                    >
                                                        <option value="" >Select type</option>
                                                        {assessmentTypeData?.map((type) => (
                                                            <option key={type.id} value={type.id}>
                                                                {type.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {/* Type */}

                                                {/* Domain */}
                                                <div className="col-md-6 mb-3">
                                                    <label
                                                        className="form-label font-14 font-medium"
                                                        htmlFor="domain"
                                                    >
                                                        Domain
                                                    </label>
                                                    <select
                                                        className="form-select migrate-form-ui"
                                                        id="domain"
                                                        value={filterData.domain}
                                                        onChange={filterValidation}
                                                    >
                                                        <option value="">Select Domain</option>
                                                        <option value="Data Science">Data Science</option>
                                                        <option value="AI">AI</option>
                                                        <option value="App Dev">App Dev</option>
                                                    </select>
                                                </div>
                                                {/* Domain */}

                                                {/* Status */}
                                                <div className="col-md-6 mb-3">
                                                    <label
                                                        className="form-label font-14 font-medium"
                                                        htmlFor="status"
                                                    >
                                                        Status
                                                    </label>
                                                    <select
                                                        className="form-select migrate-form-ui"
                                                        id="status"
                                                        value={filterData.status}
                                                        onChange={filterValidation}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="approved">Approved</option>
                                                        <option value="pending">Pending</option>
                                                    </select>
                                                </div>
                                                {/* Status */}

                                                {/* Date Range */}
                                                <div className="row mt-3">
                                                    <h5 className="font-14 font-semibold black-color mb-2">
                                                        Date Range
                                                    </h5>
                                                    <div className="col-md-6 mb-3">
                                                        <label
                                                            className="form-label font-14 font-medium"
                                                            htmlFor="start-date"
                                                        >
                                                            Start Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control migrate-form-ui"
                                                            id="start_date"
                                                            onChange={filterValidation}
                                                            value={filterData.start_date ? new Date(filterData.start_date).toISOString().split('T')[0] : ''}
                                                            max={getCurrentDate()}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label
                                                            className="form-label font-14 font-medium"
                                                            htmlFor="end-date"
                                                        >
                                                            End Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control migrate-form-ui"
                                                            id="end_date"
                                                            onChange={filterValidation}
                                                            value={filterData.end_date ? new Date(filterData.end_date).toISOString().split('T')[0] : ''}
                                                            min={filterData.start_date} // Ensure end date is after start date
                                                            max={getCurrentDate()}
                                                        />
                                                    </div>
                                                </div>
                                                {/* Status */}

                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                                                        <button className="btn outline-button" onClick={handleClear}>Clear</button>
                                                        <button className="btn primary-button" onClick={handleApply} disabled={isFilterDataEmpty()}> Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        {/* Filter Section Ends */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4">
                                {renderApprovalData()}
                            </div>


                            {filteredApprovals.length > 0 && (
                                <div className="col-md-12">
                                    <span className="font-14 font-medium table-records-color">
                                        Showing <span>{filteredApprovals?.length}</span> of <span>{totalCount}</span> records
                                    </span>
                                    {filteredApprovals.length < totalCount && (
                                        <div className="text-center">
                                            <button className="btn primary-button text-center" onClick={(() => {
                                                setLoadCount(prevCount => prevCount + 10);
                                                // fetchApprovalData();
                                            })}>Load More</button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
            {/* Parent Organization Grid ends here */}
        </>
    );
};

export default Approvals;