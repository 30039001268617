import DatabricksContextObject from "./DatabricksContextObject"
import DataBricksReport from "../DataBricksReport";

const DataBricksRootComponent = ({ initialReportData }: any) => {
    return (
        <DatabricksContextObject>
            <DataBricksReport initialReportData={initialReportData} />
        </DatabricksContextObject>
    )
}

export default DataBricksRootComponent;