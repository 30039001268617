import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { reportSortSaveApi } from "../../Service/api";

const SideNavComponent = ({
  initialNavItems,
  activeTab,
  setActiveTab,
  workflowId,
  reportId,
}) => {
  const [navItems, setNavItems] = useState(initialNavItems);
  const [error, setError] = useState(null);

  useEffect(() => {
    const invalidItems = navItems.filter((item) => !item.report_data_id);
    if (invalidItems.length > 0) {
      console.error("Invalid navItems detected:", invalidItems);
    }
    console.log(
      "SideNavComponent Rendered - NavItems:",
      navItems.map((i) => i.report_data_id)
    );
  }, [navItems]);

  const onReorder = useCallback(
    async (updatedItems) => {
      console.log("Reordered Items:", updatedItems.map((i) => i.report_data_id));

      const reorderedItems = updatedItems.map((item, index) => ({
        ...item,
        sort_id: index.toString(),
      }));

      setNavItems(reorderedItems);
      setError(null);

      try {
        const payload = {
          report_id: reportId,
          message: reorderedItems,
        };

        await reportSortSaveApi(payload);
      } catch (error) {
        console.error("Error saving order:", error);
        setNavItems(initialNavItems);
        setError("Failed to save the new order. Please try again.");
      }
    },
    [reportId, initialNavItems]
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      const items = Array.from(navItems);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      onReorder(items);
    },
    [navItems, onReorder]
  );

  return (
    <div className="col-md-3">
      <div className="bg-white ps-2 rounded-2 d-flex flex-column position-sticky top-0">
        <div className="flex-grow-1 overflow-auto left-nav-container pt-3 ps-2">
          <ul className="list-unstyled">
            <li>
              <div className="d-flex align-items-center justify-content-between me-3">
                <span className="text-dark font-14 font-semibold">
                  Sections
                </span>
                <button
                  className="btn focusable rounded-circle d-flex justify-content-center align-content-center"
                  style={{ width: "20px", height: "20px", padding: "0" }}
                >
                  <img src="../images/section-add.svg" alt="Add Section" />
                </button>
              </div>
            </li>

            {error && <li className="text-danger">{error}</li>}

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="sideNav">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {navItems.map((item, index) => {
                      if (!item.report_data_id) {
                        console.error("Item missing report_data_id:", item);
                        return null;
                      }
                      const itemId = String(item.report_data_id);
                      return (
                        <Draggable
                          key={itemId}
                          draggableId={itemId}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`mt-3 ${
                                activeTab === itemId ? "active-menu" : ""
                              }`}
                              style={{
                                ...provided.draggableProps.style,
                                background: snapshot.isDragging
                                  ? "lightblue"
                                  : "white",
                              }}
                            >
                              <a
                                className={`${
                                  activeTab === itemId
                                    ? "d-flex justify-content-between align-items-center active-text nav-link font-14 font-semibold active"
                                    : "d-flex justify-content-between align-items-center inactive-text nav-link font-14 font-medium text-dark active"
                                }`}
                                onClick={() => setActiveTab(itemId)}
                                href="#!"
                                role="tab"
                                aria-selected={activeTab === itemId}
                              >
                                {item.navTitle}
                              <span class="draggable-icon me-3"><img src="../../images/draggable.svg" alt=""/></span>
                              </a>
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="d-flex gap-2 font-12 grey-v3 font-medium border-top me-2 py-4">
              <img src="../images/template-note.svg" alt="" />
              represents template sections
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SideNavComponent, (prevProps, nextProps) => {
  return (
    prevProps.initialNavItems === nextProps.initialNavItems &&
    prevProps.activeTab === nextProps.activeTab
  );
});