import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { saveReportContentApi, postErrorLog } from "../../../Service/api";

const SingleRecordTableTemplate = ({ reportData, getReportData }) => {
  const initialContent = reportData.body.content;

  const [content, setContent] = useState(initialContent);
  const [newRow, setNewRow] = useState(["", "", "", ""]);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editingTableId, setEditingTableId] = useState(null);
  const [tempRowData, setTempRowData] = useState(null);
  const [errorData, setErrorData] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    console.log("logging: Data received from Outer Component", content);
  }, [content]);

  const validateInput = (row, isEditing) => {
    console.log("debug: Validating inputs", { row, isEditing });
    let errors = {};
    let valid = true;

    row.forEach((value, index) => {
      if (index === 1 || index === 2) {
        if (isNaN(value) || parseFloat(value) < 0) {
          errors[index] = "Invalid cost value";
          valid = false;
        }
      } else if (value.trim() === "" && index !== 3) {
        errors[index] = "Field cannot be empty";
        valid = false;
      }
    });

    if (!isEditing) {
      const duplicate = content[0].rows.some(
        (existingRow) => existingRow[0] === row[0]
      );
      if (duplicate) {
        errors[0] = "Duplicate service name";
        valid = false;
      }
    }

    setErrorData(errors);
    setIsValid(valid);
    console.log("debug: Validation results", { valid, errors });
  };

  const handleInputChange = (index, value) => {
    console.log("logging: Handling input change", { index, value });

    if (isEditing) {
      setTempRowData((prev) => {
        const newData = [...prev];
        newData[index] = value;
        return newData;
      });
      validateInput(tempRowData, true);
    } else {
      const updatedRow = [...newRow];
      updatedRow[index] = value;
      setNewRow(updatedRow);
      validateInput(updatedRow, false);
    }
  };

  const handleAddRowApiCall = async (updatedContent) => {
    console.log("logging: Attempting to save new row via API");

    const payload = {
      report_id: reportData.report_data_id, // Assuming report_data_id is the identifier
      message: {
        ...reportData,
        body: { ...reportData.body, content: updatedContent },
      },
    };

    try {
      console.log("debug: Calling saveReportContentApi with payload", payload);
      const response = await saveReportContentApi(payload);
      console.log("logging: Save API response", response);

      if (response.status === 200) {
        console.log("logging: Save successful");
        setContent(updatedContent);
        getReportData(); // Refresh data by calling the passed prop function
      }
    } catch (error) {
      console.error("error: Save API call failed", error);
      await postErrorLog({ error });
      setErrorData({ apiError: "Failed to save data. Please try again!" });
    }
  };

  const handleAddRow = () => {
    console.log("logging: Attempting to add new row", newRow);

    // Check if the fourth element (last column) of the newRow is empty and fill it with _
    if (newRow[3] === "") {
      console.log("info: Last column is empty, filling it with underscore _");
      newRow[3] = "_";
    }

    // if there are no floating values then add two decimal places to the values
    if (newRow[1] && !String(newRow[1]).includes(".")) {
      newRow[1] = parseFloat(newRow[1]).toFixed(2);
    }
    if (newRow[2] && !String(newRow[2]).includes(".")) {
      newRow[2] = parseFloat(newRow[2]).toFixed(2);
    }

    if (!isValid) {
      console.error("error: Cannot add row, invalid input", errorData);
      return;
    }

    const updatedContent = [...content];
    const tableRows = updatedContent[0].rows;
    tableRows.splice(-1, 0, newRow);
    recalculateTotals(updatedContent, 0);
    setNewRow(["", "", "", ""]);
    setIsValid(false);

    handleAddRowApiCall(updatedContent);
  };

  const handleEditRowApiCall = async (updatedContent) => {
    console.log("logging: Attempting to save edited row via API");

    const payload = {
      report_id: reportData.report_data_id, // Assuming report_data_id is the identifier
      message: {
        ...reportData,
        body: { ...reportData.body, content: updatedContent },
      },
    };

    try {
      console.log("debug: Calling saveReportContentApi with payload", payload);
      const response = await saveReportContentApi(payload);
      console.log("logging: Save API response", response);

      if (response.status === 200) {
        console.log("logging: Save successful");
        setContent(updatedContent);
        getReportData(); // Refresh data by calling the passed prop function
      }
    } catch (error) {
      console.error("error: Save API call failed", error);
      await postErrorLog({ error });
      setErrorData({ apiError: "Failed to save data. Please try again!" });
    }
  };

  const saveEditedRow = () => {
    console.log("logging: Attempting to save edited row", {
      editingTableId,
      editingRowIndex,
      tempRowData,
    });

    if (!isValid) {
      console.error("error: Cannot save row, invalid input", errorData);
      return;
    }

    const updatedContent = [...content];
    updatedContent[editingTableId].rows[editingRowIndex] = tempRowData;
    recalculateTotals(updatedContent, editingTableId);
    setEditingRowIndex(null);
    setEditingTableId(null);
    setTempRowData(null);
    setIsValid(false);
    setIsEditing(false);

    handleEditRowApiCall(updatedContent);
  };

  const cancelEditing = () => {
    console.log("logging: Canceling editing");

    setEditingRowIndex(null);
    setEditingTableId(null);
    setTempRowData(null);
    setIsValid(false);
    setIsEditing(false);
  };

  const startEditingRow = (tableIndex, rowIndex, row) => {
    console.log("logging: Starting to edit row", { tableIndex, rowIndex, row });

    setEditingRowIndex(rowIndex);
    setEditingTableId(tableIndex);
    setTempRowData([...row]);
    setIsValid(true);
    setIsEditing(true);
  };

  const handleDeleteRowApiCall = async (updatedContent) => {
    console.log("logging: Attempting to delete row via API");

    const payload = {
      report_id: reportData.report_data_id, // Assuming report_data_id is the identifier
      message: {
        ...reportData,
        body: { ...reportData.body, content: updatedContent },
      },
    };

    try {
      console.log("debug: Calling saveReportContentApi with payload", payload);
      const response = await saveReportContentApi(payload);
      console.log("logging: Save API response", response);

      if (response.status === 200) {
        console.log("logging: Save successful");
        setContent(updatedContent);
        getReportData(); // Refresh data by calling the passed prop function
      }
    } catch (error) {
      console.error("error: Save API call failed", error);
      await postErrorLog({ error });
      setErrorData({ apiError: "Failed to delete data. Please try again!" });
    }
  };

  const handleDeleteRow = (tableIndex, rowIndex) => {
    console.log("logging: Deleting row", { tableIndex, rowIndex });

    const updatedContent = [...content];
    updatedContent[tableIndex].rows.splice(rowIndex, 1);
    recalculateTotals(updatedContent, tableIndex);

    handleDeleteRowApiCall(updatedContent);
  };

  const recalculateTotals = (updatedContent, tableIndex) => {
    console.log("debug: Recalculating totals", { tableIndex });

    const rows = updatedContent[tableIndex].rows;
    let monthlyTotal = 0;
    let yearlyTotal = 0;
    rows.forEach((row) => {
      if (row[0] !== "Total") {
        monthlyTotal += parseFloat(row[1]) || 0;
        yearlyTotal += parseFloat(row[2]) || 0;
      }
    });

    const totalRow = rows.find((row) => row[0] === "Total") || [
      "Total",
      "0",
      "0",
      "",
    ];
    totalRow[1] = monthlyTotal.toFixed(2);
    totalRow[2] = yearlyTotal.toFixed(2);
    rows[rows.length - 1] = totalRow;
    console.log("debug: Totals recalculated", { monthlyTotal, yearlyTotal });
  };

  return (
    <div className="d-flex col-md-9">
      <div className="bg-white rounded-2 w-100 px-3">
        <h2 className="font-24 font-semibold heading-txt-color border-bottom py-3 mb-4">
          {reportData.navTitle}
        </h2>

        <div className="table-responsive bg-white zp-table custom-spacing">
          {content.map((table, tableIndex) => (
            <table key={tableIndex} className="table m-0 align-middle">
              <thead>
                <tr className="alter">
                  {table.columns.map((col, index) => (
                    <th key={index} className={index > 0 ? "text-end" : ""}>
                      {col}
                    </th>
                  ))}
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {newRow.map((cell, index) => (
                    <td key={index} className={index > 0 ? "text-end" : ""}>
                      {index === 1 || index === 2 ? (
                        <div className="input-group mb-3">
                          <span className="input-group-text">$</span>
                          <NumericFormat
                            value={cell}
                            onValueChange={(values) =>
                              handleInputChange(index, values.floatValue || 0)
                            }
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            allowNegative={false}
                            className="form-control font-14"
                            placeholder="0.00"
                            disabled={isEditing}
                          />
                        </div>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          placeholder={table.columns[index]}
                          value={cell}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          disabled={isEditing}
                        />
                      )}
                    </td>
                  ))}
                  <td className="text-center">
                    <button
                      className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center"
                      onClick={handleAddRow}
                      disabled={
                        isEditing ||
                        !isValid ||
                        Object.keys(errorData).length > 0
                      }
                    >
                      <img src="images/add-row.svg" alt="Add" />
                    </button>
                  </td>
                </tr>
                {table.rows.map((row, rowIndex) => {
                  const isRowEditing =
                    editingRowIndex === rowIndex &&
                    editingTableId === tableIndex;
                  const isTotalRow = row[0] === "Total";

                  return (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          className={cellIndex > 0 ? "text-end" : ""}
                        >
                          {isTotalRow ? (
                            <>
                              {cellIndex > 0 && cellIndex < 3 ? (
                                <strong>${cell}</strong>
                              ) : (
                                <strong>{cell}</strong>
                              )}
                            </>
                          ) : (
                            <>
                              {isRowEditing ? (
                                cellIndex === 1 || cellIndex === 2 ? (
                                  <div className="input-group mb-3">
                                    <span className="input-group-text">$</span>
                                    <NumericFormat
                                      value={tempRowData[cellIndex]}
                                      onValueChange={(values) =>
                                        handleInputChange(
                                          cellIndex,
                                          values.floatValue || 0
                                        )
                                      }
                                      thousandSeparator
                                      decimalScale={2}
                                      fixedDecimalScale
                                      allowNegative={false}
                                      className="form-control font-14"
                                      placeholder="0.00"
                                    />
                                  </div>
                                ) : (
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={tempRowData[cellIndex]}
                                    onChange={(e) =>
                                      handleInputChange(
                                        cellIndex,
                                        e.target.value
                                      )
                                    }
                                  />
                                )
                              ) : (
                                cell
                              )}
                            </>
                          )}
                        </td>
                      ))}
                      {!isTotalRow && (
                        <td className="text-center">
                          {isRowEditing ? (
                            <div className="d-flex gap-3 justify-content-center">
                              <button
                                className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center"
                                onClick={saveEditedRow}
                                disabled={!isValid}
                              >
                                <img src="images/add-row.svg" alt="Save" />
                              </button>
                              <button
                                className="border-0 p-0 action-icons delete-icon rounded-circle d-flex justify-content-center align-items-center"
                                onClick={cancelEditing}
                              >
                                <img src="images/cancel-red.svg" alt="Cancel" />
                              </button>
                            </div>
                          ) : (
                            <div className="d-flex gap-3 justify-content-center">
                              <button
                                className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center"
                                onClick={() =>
                                  startEditingRow(tableIndex, rowIndex, row)
                                }
                                disabled={isEditing}
                              >
                                <img src="images/edit.svg" alt="Edit" />
                              </button>
                              <button
                                className="border-0 p-0 action-icons delete-icon rounded-circle d-flex justify-content-center align-items-center"
                                onClick={() =>
                                  handleDeleteRow(tableIndex, rowIndex)
                                }
                                disabled={isEditing}
                              >
                                <img src="images/cancel-red.svg" alt="Delete" />
                              </button>
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleRecordTableTemplate;
