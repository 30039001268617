
// SectionModal.jsx
import React, { useState } from 'react';

const SectionModal = ({ closeModal, onSectionSubmit }) => {
  const [templateId, setTemplateId] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [navTitle, setNavTitle] = useState('');

  const handleSave = () => {
    if (!templateId || !navTitle || !sectionName) {
      alert('Please fill in all fields.');
      return;
    }

    const content = templateId === 'FlowComponents'
      ? { title: sectionName, flowName: sectionName, navTitle: navTitle, content: [] }
      : templateId === 'ChallengesRecommendations'
        ? { title: sectionName, navTitle: navTitle, content: [] }
        : templateId === 'TableComponent'
          ? {
              title: sectionName,
              navTitle: navTitle,
              table: {
                title: sectionName,
                columns: ["Service/Software", "Monthly Cost", "Yearly Cost", "Comments"],
                rows: [] // Initialize with empty rows
              }
            }
          : templateId === 'TextEditor'
            ? { title: sectionName, navTitle: navTitle, content: "Default text" } // Default text for text editor
            : { title: sectionName, navTitle: navTitle, content: '' };

    onSectionSubmit(templateId, content);
    closeModal();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white w-1/2 p-8 rounded shadow-lg">
        <h2 className="text-xl mb-4">Select Template</h2>
        <select
          className="w-full p-2 mb-4 border rounded"
          value={templateId}
          onChange={(e) => setTemplateId(e.target.value)}
        >
          <option value="">-- Choose a template --</option>
          <option value="FlowComponents">Flow Components</option>
          <option value="ChallengesRecommendations">Challenges & Recommendations</option>
          <option value="TableComponent">Table Component</option>
          <option value="TextEditor">Text Editor</option>
        </select>

        <h3 className="text-lg mb-2">Enter Section Name</h3>
        <input
          className="w-full p-2 mb-4 border rounded"
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          placeholder="Enter section name"
        />

        <h3 className="text-lg mb-2">Enter Navigation Bar Name</h3>
        <input
          className="w-full p-2 mb-4 border rounded"
          value={navTitle}
          onChange={(e) => setNavTitle(e.target.value)}
          placeholder="Enter nav bar name"
        />

        <div className="flex justify-end space-x-4">
          <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={handleSave}>
            Save
          </button>
          <button className="px-4 py-2 bg-gray-300 rounded" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionModal;