
import React, { useState, useEffect } from "react";

function WorkflowLeftNav(props) {
    const { sessionData, progress, deviation,onSessionSelect  } = props;
    const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);  // Default to Day 0
    const [deviationMessage, setDeviationMessage] = useState('');
    const [selectedSessionId, setSelectedSessionId] = useState(sessionData && sessionData.length ? sessionData[0].session_id : null); 

    useEffect(() => {
        if (deviation) {
            setDeviationMessage(deviation);
        }
    }, [deviation]);

    const handleSessionClick = (index,sessionId) => {
        setSelectedSessionIndex(index);
        onSessionSelect(sessionId);
    };

    const renderSessionItems = () => {
        if (!sessionData || sessionData.length === 0) {
            return <li className="font-12 font-medium grey-v3">No sessions available</li>;
        }

        return sessionData.map((session, index) => (
            <li key={session.session_id}
                onClick={() => handleSessionClick(index,session.session_id)}
                className={`nav-item ${index === selectedSessionIndex ? "active-day" : ""}`}
                style={{cursor:'pointer'}}>
                <div className={`nav-link ${index === selectedSessionIndex ? "active text-dark" : "inactive-text text-dark"} font-14 font-medium`}>
                    <div className="d-flex justify-content-between current-day">
                        <p className="mb-0 font-14 font-semibold">{session.session}</p>
                        {(deviationMessage && index === selectedSessionIndex) && (
                            <span className="font-12 font-semibold d-flex align-items-center day-status">
                                <img className="me-2" src="images/out_of_scope.svg" alt=""/> Out of Scope
                            </span>
                        )}
                    </div>
                </div>
                {index === selectedSessionIndex && (
                    <div className="active-text">
                        <div className="container custom-detail">
                            <div className="row mt-3">
                                <div className="col-7 font-12 font-medium grey-v3">Status:</div>
                                <div className="col-5 font-12 font-semibold text-dark">In progress</div>
                            </div>
                        </div>
                        <div className="container custom-detail">
                            <div className="row">
                                <div className="col-7 font-12 font-medium grey-v3">Assessment Progress:</div>
                                <div className="col-5 font-12 font-semibold text-dark">{progress}%</div>
                            </div>
                        </div>
                    </div>
                )}
            </li>
        ));
    };

    return (
        <div className="col-md-3">
            <div className="bg-white ps-2 rounded-2 d-flex flex-column position-sticky top-0">
                <div className="flex-grow-1 overflow-auto left-nav-container pt-3 ps-2">
                    <ul className="list-unstyled">
                        {renderSessionItems()}
                    </ul>
                </div>
            </div>
            
        </div>
    );
}

export default WorkflowLeftNav;