import React, { createContext, useState, useContext, useEffect } from "react";

export const Context = createContext();

export function ContextProvider({ children }) {
  const [sessionTimeoutPopup, setSessionTimeoutPopup] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [colorMode, setColorMode] = useState("light");
  const [deviation, setDeviation] = useState('');

  
  const triggerRefresh = () => {
    setRefreshTrigger(prev => prev + 1);
  };


  return (
    <Context.Provider
      value={{
        sessionTimeoutPopup,
        setSessionTimeoutPopup,
        refreshTrigger, triggerRefresh ,
        colorMode, setColorMode,
        deviation, setDeviation
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useOrgContext() {
  return useContext(Context);
}