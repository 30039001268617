import { updateDarkThemeApi } from "./api";

//SQ_NO_1.0 -1.10
const CURRENT_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'dev';

const BASE_URLS = {
	dev: 'https://npapi.zebpulse.com',

};

const ENDPOINTS = {
  // Editor: {
  //   loadEditor: '/editor/load',
  //   sections: '/api/sections',
  //   update: '/editor/update',
  //   pdfGeneration: '/editor/generate_pdf'
  // },
  Attributes:{
    getAttribute: '/onboard/getAttributes',
    saveAttribute:'/onboard/saveandupdateAttributes',
    deleteAttribute:'/onboard/deleteAttribute'
  },
  Login:{
    login:'/login/login',
    logout:'/login/logout'
  },
  Client:{
    getClient:'/onboard/getclient',
    getIndustryType:'/onboard/getdropdown',
    saveClient:'/onboard/saveandupdateclient'
  },
  Assessment:{
    getAssessment:'/onboard/getAssessment',
    saveAssessment:'/onboard/saveandupdateassessment',
    assessmentType:'/onboard/getdropdown',
    getProvider:'/onboard/getdropdown',
    getAssessStatus:'/onboard/getdropdown',
  },
  ErrorLog:{
    errorLog:'/login/error'
  },
  Approvals:{
    getApprovals:'/login/getapprovals'
  },
  Notification:{
    getNotification:'/login/getAllNotification',
    updateDarkTheme:'/login/changetheme'
  },
  Workflow:{
    getSession:'/workflow/fetchSessionsWorkflow',
    getActionItems:'/workflow/fetchActionItemsWorkflow',
    getWorkflowAttributes:'/workflow/fetchAttributesWorkflow',
    getScope:'/workflow/getScope'
  },
  Report:{
    getAssessmentReport:'/report/getAssessmentReport',
    getReportData:'/report/getReportData',
    saveReportData:'/report/saveAndupdateTemplate',
    deleteSection:'/report/deleteSection',
    reportSortSave:'/report/reportSortSaveApi',
    regenerateReportContent:'/report/regenerateReportContent',
    generateassessmentreport:'/report/generateassessmentreport'
  },
};

export const getBaseUrl = () => BASE_URLS[CURRENT_ENVIRONMENT];

export const getEndpoint = (moduleName, endpointName) => {
  const baseUrl = getBaseUrl();
  const moduleEndpoints = ENDPOINTS[moduleName];

  if (!moduleEndpoints) {
    throw new Error(`Module "${moduleName}" not found`);
  }

  const endpoint = moduleEndpoints[endpointName];

  if (!endpoint) {
    throw new Error(`Endpoint "${endpointName}" not found in module "${moduleName}"`);
  }

  return `${baseUrl}${endpoint}`;
};

export default getEndpoint;