import React, { useState, useEffect } from 'react';
import { checkSecurityHubStatus, checkTrustedAdvisorStatus } from '../service/QuestionnaireApi';
import Loader from './Loader';
import { getRegions } from '../service/AssesmentApi';
import { regionsModel } from '../interface/questionnaireInterface';
const Select = require('react-select').default;

interface Option {
    value: string;
    label: string;
}

interface WorkloadDetails {
    workLoadName: string;
    reviewOwnerName: string;
    workLoadDescription: string;
    environment: string;
    architecturalDesign: string;
    industryType: string;
    awsRegions:string;
    isAwsTrustedAdvisorActivated: boolean;
    isAwsSecurityHubActivated: boolean;
    lenses: string[];
}

interface PopupDataModel {
    hidePopup: boolean,
    type: string
}

interface ValidationMsgModel {
    workLoadName: string,
    reviewOwnerName: string,
    workLoadDescription: string,
    architecturalDesign: string
}

interface CreateWorkloadProps {
    setWorkLoadDetails: React.Dispatch<React.SetStateAction<WorkloadDetails>>;
    workLoadDetails: WorkloadDetails;
    workloads: WorkloadDetails;
    setWorkload: React.Dispatch<React.SetStateAction<WorkloadDetails>>;
    handleSave: () => void;
    projectDetails: any;
    setSelectedPage: (page: number) => void;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setApiError: any
}


const initialPopupData = {
    hidePopup: true,
    type: ""
}

const initialValidationMsg = {
    workLoadName: "",
    reviewOwnerName: "",
    workLoadDescription: "",
    architecturalDesign: ""
}



const CreateWorkload: React.FC<CreateWorkloadProps> = (props) => {
    const { setWorkLoadDetails, setWorkload, workLoadDetails, workloads, handleSave, projectDetails, setSelectedPage, setIsLoading, setApiError } = props;
    //Initializing the state varoables
    const [popupData, setPopupData] = useState<PopupDataModel>(initialPopupData)
    const [validationMsg, setValidationMsg] = useState<ValidationMsgModel>(initialValidationMsg)
    const [isDisable, setIsDisable] = useState<boolean>(true)
    const [regions, setRegions] = useState<Option[]>([]);
    const reviewOwnerRegex = /^([a-zA-Z\s]+|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const regexPattern = /^[a-zA-Z]([a-zA-Z0-9-_\. ]{0,15})*$/;
    const webUrlRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/;


    const lensesDropdown: Option[] = [
        { value: 'wellarchitected', label: 'Well-Architected' },
        { value: 'serverless', label: 'Serverless' },
        { value: 'saas', label: 'SaaS' },
    ];

    const industryTypeDropdown = [
        { value: 'Agriculture', label: 'Agriculture' },
        { value: 'Automotive', label: 'Automotive' },
        { value: 'Defense', label: 'Defense' },
        { value: 'Design_And_Engineering', label: 'Design And Engineering' },
        { value: 'Digital_Advertising', label: 'Digital Advertising' },
        { value: 'Education', label: 'Education' },
        { value: 'Environmental_Protection', label: 'Environmental Protection' },
        { value: 'Financial_Services', label: 'Financial Services' },
        { value: 'Gaming', label: 'Gaming' },
        { value: 'General_Public_Services', label: 'General Public Services' },
        { value: 'Healthcare', label: 'Healthcare' },
        { value: 'Hospitality', label: 'Hospitality' },
        { value: 'InfoTech', label: 'InfoTech' },
        { value: 'Justice_And_Public_Safety', label: 'Justice And Public Safety' },
        { value: 'Life_Sciences', label: 'Life Sciences' },
        { value: 'Manufacturing', label: 'Manufacturing' },
        { value: 'Media_Entertainment', label: 'Media Entertainment' },
        { value: 'Mining_Resources', label: 'Mining Resources' },
        { value: 'Oil_Gas', label: 'Oil & Gas' },
        { value: 'Power_Utilities', label: 'Power Utilities' },
        { value: 'Professional_Services', label: 'Professional Services' },
        { value: 'Real_Estate_Construction', label: 'Real Estate Construction' },
        { value: 'Retail_Wholesale', label: 'Retail & Wholesale' },
        { value: 'Social_Protection', label: 'Social Protection' },
        { value: 'Telecommunications', label: 'Telecommunications' },
        { value: 'Travel_Transportation_Logistics', label: 'Travel, Transportation & Logistics' },
        { value: 'Other', label: 'Other' }
    ];

    const [industryTypes] = useState<Option[]>(industryTypeDropdown);
    const [lenses] = useState<Option[]>(lensesDropdown);

    useEffect(() => {
        if (!workloads.lenses || workloads.lenses.length === 0) {
            setWorkload((prevDetails) => ({
                ...prevDetails,
                lenses: ['wellarchitected']
            }));
        }
    }, []);

    useEffect(()=>{
        fetchRegions()
    },[])
    useEffect(() => {
        setIsDisable(![workloads?.workLoadName?.trim()?.length !== 0, workloads?.reviewOwnerName?.trim()?.length !== 0, workloads?.environment?.length !== 0,workloads?.awsRegions?.length!==0,
        workloads?.isAwsTrustedAdvisorActivated, workloads?.lenses?.length !== 0, validationMsg?.reviewOwnerName?.length === 0, validationMsg?.workLoadName?.length === 0].every(Boolean))
    }, [workloads])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setWorkload((prevDetails) => ({ ...prevDetails, [name]: value }));
        setValidationMsg(prev => ({ ...prev, [name]: "" }));

        if (value.trim() === "") {
            setValidationMsg(prev => ({ ...prev, [name]: "This field is required" }));
        } else if ((name === "workLoadName") && !regexPattern.test(value)) {
            setValidationMsg(prev => ({ ...prev, [name]: "Enter a valid name" }));
        } else if ((name === "reviewOwnerName") && !reviewOwnerRegex.test(value)) {
            setValidationMsg(prev => ({ ...prev, [name]: "Enter a valid name" }));
        } else if (name === "architecturalDesign" && !webUrlRegex.test(value.trim())) {
            setValidationMsg(prev => ({ ...prev, [name]: "Enter a valid url" }));
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWorkload((prevDetails) => ({
            ...prevDetails,
            environment: event.target.value
        }));
    };

    const handleCheckboxChange = async (id: string, event: any) => {
        const { name, checked } = event.target;
        setWorkload((prevDetails) => ({
            ...prevDetails,
            [name]: checked
        }));
        if (checked) {
            const request = {
                organizationId: projectDetails?.organizationId,
                verticalId: projectDetails?.verticalId,
                projectId: projectDetails?.projectId,
                connectorId: projectDetails?.connectorId
            }


            let response: any
            try {
                setIsLoading(true)
                if (id === "Trusted Advisor") {
                    response = await checkTrustedAdvisorStatus(request)
                    setIsLoading(false)
                    setPopupData({ hidePopup: response.trustedAdvisorIsEnabled, type: "Trusted Advisor" })
                    setWorkload((prevDetails) => ({
                        ...prevDetails,
                        [name]: response.trustedAdvisorIsEnabled
                    }));

                } else {
                    response = await checkSecurityHubStatus(request)
                    setIsLoading(false)
                    setPopupData({ hidePopup: response.securityHubIsEnabled, type: "Security Hub" })
                    setWorkload((prevDetails) => ({
                        ...prevDetails,
                        [name]: response.securityHubIsEnabled
                    }));
                }
            }
            catch (error) {
                if (id === "Trusted Advisor") {
                    setPopupData({ hidePopup: false, type: "Trusted Advisor" })
                    setWorkload((prevDetails) => ({
                        ...prevDetails,
                        [name]: false
                    }));

                } else {
                    setPopupData({ hidePopup: false, type: "Security Hub" })
                    setWorkload((prevDetails) => ({
                        ...prevDetails,
                        [name]: false
                    }));
                }
                setIsLoading(false)
                // console.log("Api Failed")
            }
        }
    };

    const fetchRegions = async () => {
        try {
          const response = await getRegions();
    
          if (response.status === 200) {
            const formattedRegions = response.data.map((region: any) => ({
                value: region.region_name,
                label: region.region_name

            }));
            setRegions(formattedRegions);
          }
        } catch (error) {
          console.error('Error fetching regions:', error);
        }
      };

    const handleMultiSelectChange = (selectedOptions: Option[], actionMeta: any) => {
        if (actionMeta.action === 'remove-value' && actionMeta.removedValue.value === 'wellarchitected') {
            return;
        }
        setWorkload((prevDetails) => ({
            ...prevDetails,
            [actionMeta.name]: selectedOptions.map((option) => option.value)
        }));
    };

    const handleSingleSelectChange = (selectedOption: Option | null, actionMeta: any) => {
        if (selectedOption) {
            if(actionMeta.name === "awsRegions"){
                setWorkload((prevDetails) => ({
                    ...prevDetails,
                    awsRegions: selectedOption.value
                }));    
            }
            else{
                setWorkload((prevDetails) => ({
                    ...prevDetails,
                    [actionMeta.name]: selectedOption.value
                }));            }
        }
    };

    const FormComponent = () => {
        return (
            <div className="modal fade show" style={{ display: 'block', position: "absolute", top: "20px" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3">
                        <div className="modal-header pb-0 border-0"><button type="button" className="btn-close cursor-pointer" aria-label="Close" onClick={() => {
                            setPopupData({ ...popupData, hidePopup: true })
                        }} /></div>
                        <div className="modal-body">
                            <div className="w-100 text-center mb-3"><img src="images/alert-circle.svg" alt="delete" /></div>
                            <p className="font-semibold font-24 text-center mb-2">{popupData?.type}</p>
                            <p className="font-semibold font-16 text-center mb-5 color-grey-v1">{popupData?.type === "Trusted Advisor" ? `Activate Trusted Advisor by subscribing to the AWS
                                Business Support Plan to receive tailored recommendations.`: `Enabling Security Hub is a prerequisite for receiving security recommendations. Without this activation, no security suggestions will be provided.`}</p>
                            <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100"><button type="button" className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold " onClick={() => {
                                setPopupData({ ...popupData, hidePopup: true })
                            }}>Understood</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    return (
        <>
            <div className="col-lg-8 col-md-12 col-12">
                <div className="ps-lg-4">
                    <h3 className="font-18 font-bold mt-3 mb-5">Create Workload</h3>
                    <div className="row">
                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="workLoadName">
                                Name<span className="color-red ms-1">*</span>
                                <img src="images/info.svg" data-bs-toggle="tooltip" data-bs-placement="right" title="A unique identifier for the workload." className="ms-1" alt="Work Load Name" />
                            </label>
                            <input
                                type="text"
                                id="workLoadName"
                                name="workLoadName"
                                className="form-control custom-form"
                                placeholder="Name"
                                value={workloads.workLoadName}
                                onChange={handleChange}
                            />
                            <span className='color-red'>{validationMsg.workLoadName}</span>
                        </div>

                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="reviewOwnerName">
                                Review Owner<span className="color-red ms-1">*</span>
                                <img src="images/info.svg" data-bs-toggle="tooltip" data-bs-placement="right" title="The name, email ID, or identifier for the primary individual or group that owns the review process." className="ms-1" alt="Review Owner Name" />
                            </label>
                            <input
                                type="text"
                                id="reviewOwnerName"
                                name="reviewOwnerName"
                                className="form-control custom-form"
                                placeholder="Review Owner"
                                value={workloads.reviewOwnerName}
                                onChange={handleChange}
                            />
                            <span className='color-red'>{validationMsg.reviewOwnerName}</span>
                        </div>

                        <div className="col-md-12 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="workLoadDescription">
                                Description<span
                                    className="color-red ms-1">*</span><img src="images/info.svg" data-bs-toggle="tooltip"
                                        data-bs-placement="right" title="A brief description of the workload to document its scope and intended purpose." className="ms-1" alt="Description" />
                            </label>
                            <textarea
                                id="workLoadDescription"
                                name="workLoadDescription"
                                className="border rounded-1 ps-4 pe-5 textarea-height custom-form"
                                style={{ height: '150px' }}
                                placeholder='Enter Description'
                                value={workloads.workLoadDescription}
                                onChange={handleChange}
                            />
                            <span className='color-red'>{validationMsg.workLoadDescription}</span>
                        </div>




                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="lenses">
                                Region<span className="color-red">*</span>
                            </label>
                            <Select
                                name="awsRegions"
                                options={regions}
                                className="basic-single custom-select "
                                classNamePrefix="select"
                                value={regions?.find(option => 
                                {
                                    return option?.value === workloads?.awsRegions
                                }
                                )
                                }
                                onChange={handleSingleSelectChange}
                            />
                        </div>

                        <div className="mb-4">
                            <p className="font-16 font-medium">
                                Environment<span
                                    className="color-red ms-1">*</span><img src="images/info.svg" data-bs-toggle="tooltip"
                                        data-bs-placement="right" title="The environment in which your workload runs." className="ms-1" alt="Environment" />
                            </p>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input theme-radio label-bold"
                                    type="radio"
                                    name="environment"
                                    id="PRODUCTION"
                                    value="PRODUCTION"
                                    checked={workloads.environment === "PRODUCTION"}
                                    onChange={handleRadioChange}
                                />
                                <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor="PRODUCTION">
                                    Production
                                </label>
                            </div>
                            <div className="form-check form-check-inline ms-3">
                                <input
                                    className="form-check-input theme-radio label-bold"
                                    type="radio"
                                    name="environment"
                                    id="PREPRODUCTION"
                                    value="PREPRODUCTION"
                                    checked={workloads.environment === "PREPRODUCTION"}
                                    onChange={handleRadioChange}
                                />
                                <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor="PREPRODUCTION">
                                    Pre-Production
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="architecturalDesign">
                                Architectural design<img src="images/info.svg" data-bs-toggle="tooltip"
                                    data-bs-placement="right" title="A link to your architectural design." className="ms-1" alt="Architectural design" />
                            </label>
                            <input
                                type="text"
                                id="architecturalDesign"
                                name="architecturalDesign"
                                className="form-control custom-form"
                                placeholder="Enter URL"
                                value={workloads.architecturalDesign}
                                onChange={handleChange}
                            />
                            <span className='color-red'>{validationMsg.architecturalDesign}</span>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="form-label font-14 font-semibold" htmlFor="industryType">
                                Industry Type<img src="images/info.svg" data-bs-toggle="tooltip"
                                    data-bs-placement="right" title="The industry that your workload is associated with AWS Trusted Advisor." className="ms-1" alt="Industry Type" />
                            </label>
                            <Select
                                name="industryType"
                                options={industryTypes}
                                className="basic-single custom-select "
                                classNamePrefix="select"
                                value={industryTypes.find(option => option.value === workloads.industryType)}
                                onChange={handleSingleSelectChange}
                            />
                        </div>
                        <div className="col-md-6 mb-5">
                            <p className="form-label font-14 font-semibold">
                                AWS Trusted Advisor<span
                                    className="color-red ms-1">*</span><img src="images/info.svg" data-bs-toggle="tooltip"
                                        data-bs-placement="right" title="Trusted Advisor uses information from your AWS Regions and account IDs entered above to aid workload reviews, providing you automated context for supported questions." className="ms-1" alt="Environment" />
                            </p>
                            <div className="check-card">
                                <span className="form-check align-items-center">
                                    <input
                                        className="theme-check form-check-input label-bold"
                                        type="checkbox"
                                        name="isAwsTrustedAdvisorActivated"
                                        id="isAwsTrustedAdvisorActivated"
                                        checked={workloads.isAwsTrustedAdvisorActivated}
                                        onClick={(event: any) => handleCheckboxChange("Trusted Advisor", event)}
                                    />
                                    <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor="isAwsTrustedAdvisorActivated">
                                        Activate Trusted Advisor
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <p className="form-label font-14 font-semibold">
                                AWS Security Hub<img src="images/info.svg" data-bs-toggle="tooltip"
                                    data-bs-placement="right" title="Enabling Security Hub is a prerequisite for receiving security recommendations. Without this activation, no security suggestions will be provided." className="ms-1" alt="Architectural design" />
                            </p>
                            <div className="check-card">
                                <span className="form-check align-items-center">
                                    <input
                                        className="theme-check form-check-input label-bold"
                                        type="checkbox"
                                        name="isAwsSecurityHubActivated"
                                        id="isAwsSecurityHubActivated"
                                        checked={workloads.isAwsSecurityHubActivated}
                                        onChange={(event: any) => { handleCheckboxChange("Security Hub", event) }}
                                    />
                                    <label className="form-check-label mb-2 ms-2 font-14 font-regular" htmlFor="isAwsSecurityHubActivated">
                                        Activate Security Hub
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="font-18 font-bold mt-3 mb-5 border-bottom pb-3 ps-lg-4">
                Apply Lenses
            </h3>
            <div className="row">
                <div className="col-lg-8 col-md-12 col-12 ps-lg-4">
                    <div className="col-md-6 mb-5">
                        <label className="form-label font-14 font-semibold" htmlFor="lenses">
                            Lens Alias Name<span className="color-red">*</span>
                        </label>
                        <Select
                            isMulti
                            name="lenses"
                            options={lenses}
                            className="basic-multi-select custom-select"
                            classNamePrefix="select"
                            value={lenses.filter(option => workloads.lenses?.includes(option.value))}
                            onChange={handleMultiSelectChange}
                            defaultValue={lenses.find(option => option.value === 'wellarchitected')}
                        />
                    </div>
                    <div className="d-md-flex justify-content-between mb-5 ">
                        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-start mb-2 mb-md-0">
                            <button
                                type="button"
                                className="btn btn-secondary theme-secondary-btn font-14 font-semibold "
                                onClick={() => setSelectedPage(1)}
                            >
                                Previous
                            </button>
                        </div>
                        <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end">
                            <button
                                type="button"
                                className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
                                onClick={handleSave}
                                disabled={isDisable}
                            >
                                Save
                            </button>
                            <a className="theme-link-btn cursor-pointer font-14 font-semibold text-center order-md-first"
                                onClick={() => {
                                    setWorkload((prevState: any) => ({
                                        ...prevState,
                                        workLoadName: "",
                                        reviewOwnerName: "",
                                        workLoadDescription: "",
                                        environment: "",
                                        architecturalDesign: "",
                                        industryType: "",
                                        isAwsTrustedAdvisorActivated: "",
                                        isAwsSecurityHubActivated: "",
                                        lenses: "",
                                        awsRegions:""
                                    }));
                                }}
                            >

                                Clear
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {popupData?.hidePopup ? <></> :
                <FormComponent />
            }
            {popupData?.hidePopup ? <></> :
                <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default CreateWorkload;