//SQ_2.27 :Import statement
import React, { useState } from 'react';
import Select from 'react-select';
import { handleDeleteAttribute, handleSaveApi } from '../../Service/api';


const PreProcessComponent = (props) => {
    console.log(props, "preprocess");
    //SQ_2.29 :state declaration
    let { data, refreshAttributes, assessmentTypes } = props;
    const [editMode, setEditMode] = useState(false);
    const [editedData, setEditedData] = useState({ assessment_types: [], checklist: [] });
    const [newAttributeData, setNewAttributeData] = useState({ create: false, checklistEnabled: false, assessment_types: [], checklist: [] });

    const userId = localStorage.getItem('userId');
    // ``` ### handleEdit

    // Enables edit mode for a specific attribute.

    // Parameters:
    // -----------
    // attribute : object
    //     The attribute object to be edited.

    // Details:
    // --------
    // Sets the edit mode to true and updates the state with the attribute data to be edited.

    // Formula:
    // --------
    // setEditMode(true)
    // setEditedData(attribute)

    //SQ_3.1- 3.4: Handles edit
    const handleEdit = (attribute) => {
        setEditMode(true);
        setEditedData(attribute);
    };

    //     ``` ### handleOnChange

    // Handles changes in attribute input fields.

    //     Parameters:
    //     -----------
    //     e : object
    //         The event object for the input change.

    //     Details:
    //     --------
    //     Retrieves the input's name and value from the event. If edit mode is enabled, updates the editedData state with the new value. Otherwise, updates the newAttributeData state.

    //     Formula:
    //     --------
    //     const { name, value } = e.target
    //     if editMode:set previous data with new value else set new data with new value
    //     ```
    //SQ_4.1 -4.5: Handle field update
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (editMode) {
            setEditedData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setNewAttributeData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    //     ```### handleChecklistChange

    // Handles changes in checklist input fields for a specific item.

    //     Parameters:
    //     -----------
    //     e : object
    //         The event object for the input change.
    //     idx : number
    //         The index of the checklist item to update.

    //     Details:
    //     --------
    //     Retrieves the input's value from the event. Updates the specific checklist item in the editedData state based on the provided index.

    //     Formula:
    //     --------
    //     const { value } = e.target
    //     set edited data with new value by checking index of the item in checklist array and updating the value
    //     ```
    //SQ_11.63-11.67
    const handleChecklistChange = (e, idx) => {
        const { value } = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            checklist: prevData.checklist.map((item, index) => (index === idx ? value : item))
        }));
    };

    //     ```### addChecklistItem

    // Adds a new empty item to the checklist in the edited attribute data.

    //     Details:
    //     --------
    //     Adds an empty string to the checklist array in the editedData state.

    //     Formula:
    //     --------
    //    set edited data with checklist array with empty string or empty string if checklist is empty
    //    ```
    //SQ_11.68-11.70
    const addChecklistItem = (e) => {
        console.log(e, "Callee here")
        setEditedData((prevData) => ({
            ...prevData,
            checklist: (prevData.checklist || []).concat('')
        }));
    };

    //   ```  ### handleSave

    // Saves the attribute data, either creating a new attribute or updating an existing one.

    //     Parameters:
    //     -----------
    //     dataToSave : object
    //         The attribute data to save.

    //     Details:
    //     --------
    //     Constructs a payload for the API call, including user ID, process type, attribute details, and checklist. If in edit mode, adds additional information for updating the attribute. Makes an API call using handleSaveApi to save the attribute data. If successful, resets the edit mode, new attribute data, and refreshes the attribute list.

    //     Formula:
    //     --------
    //     commonPayload = { user_id, process, attribute_name, checklist, assessment_types, attribute_id, attribute_name }

    //    payload = commonPayload + (editMode ? { attribute_id: editedData.attribute_id } : {})

    //     API Call:
    //     --------
    //     response = await handleSaveApi(payload)

    //     If response.status == 200:
    //        set edited data to empty object and new data to empty object and set edit mode to false
    //        refresh attribute list

    //SQ_5.1 - 5.19: Handles save
    const handleSave = async (dataToSave) => {

        console.log(dataToSave, "save/edit params");
        // Filter out empty strings from the checklist array
        const filteredChecklist = dataToSave.checklist.filter(item => item.trim() !== "");
        console.log(filteredChecklist,"filtered checklist")

        const commonPayload = {
            user_id: userId,
            process: "Pre-Process",
            attribute_name: dataToSave.attribute_name,
            checklist: filteredChecklist,
            attribute_type: dataToSave.attribute_type || "",  // Modify this according to your data structure
            attribute_description: dataToSave.attribute_description,

            assessment_types_id: dataToSave.assessment_types.map(type => type.assessment_id),
        };

        const payload = editMode
            ? { ...commonPayload, create: "False", attribute_id: dataToSave.attribute_id, checklist_id: dataToSave.checklist_id, is_default: dataToSave.is_default, }
            : { ...commonPayload, create: "True", attribute_id: "NULL", checklist_id: "NULL", is_default: "False" };

        try {
            if (editMode) {
                payload.create = "False";
                payload.description = dataToSave.description;
            } else {
                payload.create = "True";
                payload.checklist = filteredChecklist || [];
            }
            // await api.post('/onboard/saveandupdateAttributes', payload);
            const response = await handleSaveApi(payload)
            console.log(response, "response");

            if (response.status === 200) {
                setEditMode(false);
                setNewAttributeData({ create: false, checklistEnabled: false, assessment_types: [], checklist: [] });
                refreshAttributes();
            }
        } catch (error) {
            console.error("Error saving attribute", error);
            const errorData = {
                errorName: error.name,
                errorServiceName: "PreProcessComponent",
                errorFunctionName: "handleSave",
                errorSource: "Frontend",
                errorDescription: error.message,
            };
            // await api.post('/logError', errorData);
        }
    };

    // ### `handleDelete`

    // Deletes an attribute based on its ID and name.

    //     Parameters:
    //     -----------
    //     attributeId : string
    //         The ID of the attribute to delete.
    //     attributeName : string
    //         The name of the attribute to delete.

    //     Details:
    //     --------
    //     Constructs a payload with user ID, attribute ID, and attribute name. Makes an API call using `handleDeleteAttribute` to delete the attribute. If successful, refreshes the attribute list.

    //     Formula:
    //     --------
    //     payload = {
    //         user_id: userId,
    //         attribute_id: attributeId,
    //         attribute_name: attributeName
    //     }

    //     API Call:
    //     --------
    //     response = await handleDeleteAttribute(payload)

    //     If response.status == 200:
    //         refreshAttributes()

    //SQ_7.2- 7.17: Delete attribute handling
    const handleDelete = async (attributeId, attributeName) => {
        const payload = {
            user_id: userId,
            attribute_id: attributeId,
            attribute_name: attributeName,
        };

        try {
            const response = await handleDeleteAttribute(payload)
            if (response.status == 200) {
                refreshAttributes()
            }
        } catch (error) {
            console.error("Error deleting attribute", error);
            const errorData = {
                errorName: error.name,
                errorServiceName: "PreProcessComponent",
                errorFunctionName: "handleDelete",
                errorSource: "Frontend",
                errorDescription: error.message,
            };
        }
    };

    //     ### `handleAddAttribute`

    // Initializes new attribute data for adding a new attribute.

    //     Parameters:
    //     -----------
    //     fieldType : string
    //         The type of the new attribute.

    //     Details:
    //     --------
    //     Sets the state for new attribute data, including its creation status, name, checklist, assessment types, and attribute type.

    //     Formula:
    //     --------
    //     setNewAttributeData with the initial values.

    //SQ_11.33-11.40
    const handleAddAttribute = (fieldType) => {
        setNewAttributeData({
            create: true,
            attribute_name: '',
            checklist: [],
            checklistEnabled: false,
            assessment_types: [],
            attribute_type: fieldType
        });
    };

    //     ### `handleChecklistNewAttributeChange`

    // Handles changes in checklist input fields for a new attribute.

    //     Parameters:
    //     -----------
    //     e : object
    //         The event object for the input change.
    //     idx : number
    //         The index of the checklist item to update.

    //     Details:
    //     --------
    //     Retrieves the input's value from the event. Updates the specific checklist item in the `newAttributeData` state based on the provided index.

    //     Formula:
    //     --------
    //     const { value } = e.target
    //    set newAttributeData with the updated checklist item.

    //SQ_11.41-11.43
    const handleChecklistNewAttributeChange = (e, idx) => {
        const { value } = e.target;
        setNewAttributeData((prevData) => ({
            ...prevData,
            checklist: prevData.checklist.map((item, index) => (index === idx ? value : item))
        }));
    };

    //     ### `addNewChecklistItem`

    // Adds a new empty item to the checklist in the new attribute data.

    //     Details:
    //     --------
    //     Adds an empty string to the checklist array in the `newAttributeData` state.

    //     Formula:
    //     --------
    //    set newAttributeData with the updated checklist array.
    //SQ_11.44-11.45
    const addNewChecklistItem = () => {
        setNewAttributeData((prevData) => ({
            ...prevData,
            checklist: [...prevData.checklist, '']
        }));
    };

    // Toggles the checklist enabled state for a new attribute.

    // Parameters:
    // -----------
    // e : object
    //     The event object for the toggle change.

    // Details:
    // --------
    // Updates the `checklistEnabled` state in `newAttributeData` based on the toggle's checked status.

    // Formula:
    // --------
    // set newAttributeData with the updated checklistEnabled state.

    //SQ_11.46-11.47
    const toggleChecklist = (e) => {
        setNewAttributeData((prevData) => ({
            ...prevData,
            checklistEnabled: e.target.checked
        }));
    };


    //### `styles`

    // Defines custom styles for the `Select` component used for multi-select options.

    //     Details:
    //     --------
    //     Customizes the styles for multi-value labels, removing the delete icon for fixed values, and applying different background colors for fixed values.

    //     Formula:
    //     --------
    //     styles = {
    //         multiValue: (base, state) => {
    //             return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base
    //         },
    //         multiValueLabel: (base, state) => {
    //             return state.data.isFixed
    //                 ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
    //                 : base
    //         },
    //         multiValueRemove: (base, state) => {
    //             return state.data.isFixed ? { ...base, display: 'none' } : base
    //         }
    //     }

    //SQ_11.48-11.57
    const styles = {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed
                ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                : base;
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
    };
    //     ### `orderOptions`

    // Orders the options for the `Select` component, placing fixed values at the start.

    //     Parameters:
    //     -----------
    //     values : array
    //         The current values of the multi-select options.

    //     Returns:
    //     --------
    //     array
    //         An array of values ordered with fixed values first.

    //     Formula:
    //     --------
    //     orderOptions = values => {
    //         return values
    //             .filter(v => v.isFixed)
    //             .concat(values.filter(v => !v.isFixed))
    //     }
    const orderOptions = (values) => {
        return values
            .filter((v) => v.isFixed)
            .concat(values.filter((v) => !v.isFixed));
    };

    //     ### `handleSelectChange`

    // Handles changes in the multi-select options for assessment types.

    //     Parameters:
    //     -----------
    //     newValue : array
    //         The new value from the Select component.
    //     actionMeta : object
    //         Metadata about the action that triggered the change.
    //     isNewAttribute : boolean
    //         Flag to determine if the change is for a new attribute.

    //     Details:
    //     --------
    //     Prevents removal of fixed values. Clears non-fixed values if the action is a clear. Updates either `newAttributeData` or `editedData` based on whether the change is for a new attribute or an edited existing attribute.

    //     Formula:
    //     --------
    //     if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
    //         if (actionMeta.removedValue && actionMeta.removedValue.isFixed) {
    //             return
    //         }
    //     } else if (actionMeta.action === 'clear') {
    //         newValue = assessmentTypes.filter(v => v.isFixed)
    //     }

    //     if (isNewAttribute) {
    //         setNewAttributeData(prevData => ({ ...prevData, assessment_types: orderOptions(newValue) }))
    //     } else {
    //         setEditedData(prevData => ({ ...prevData, assessment_types: orderOptions(newValue) }))
    //     }

    //SQ_11.58-11.62
    const handleSelectChange = (newValue, actionMeta, isNewAttribute) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue && actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                newValue = assessmentTypes.filter((v) => v.isFixed);
                break;
            default:
                break;
        }

        if (isNewAttribute) {
            setNewAttributeData({ ...newAttributeData, assessment_types: orderOptions(newValue) });
        } else {
            setEditedData({ ...editedData, assessment_types: orderOptions(newValue) });
        }
    };

    /***    ### `renderAttributes`

     Renders the attributes, including handling edit and delete actions for attributes.

         Returns:
         --------
     JSX Elements:
             Rendered attribute cards and editing interfaces.

         Details:
       --------
         Maps through `data` to create a card for each attribute, displaying its details and handling edit, delete, and save actions. If no attributes are found and no new attribute is being created, displays a message indicating no attributes are available.

         Formula:
         --------
         if (data.length === 0 && !newAttributeData.create) {
             return "Oops! No Attributes Found" JSX
         }
         return data.map((attribute, index) and render attribute card JSX
    */

    //SQ_2.30-2.40: Render the cards
    const renderAttributes = () => {

        if (data.length === 0 && !newAttributeData.create) {
            return (
                <div className="row mt-4">
                    <div className="d-grid justify-content-center text-center my-5 pt-5">
                        <img src="images/norecord-img.svg" alt="norecord-img" />
                        <p className="font-semibold font-30 mt-5 mb-2">Oops!</p>
                        <p className="font-regular font-16">No Attributes Found</p>
                    </div>
                </div>
            );
        }
        return data.map((attribute, index) => (
            <div key={attribute.attribute_id} className={`card p-3 custom-card mb-3 ${editMode && editedData.attribute_id === attribute.attribute_id ? 'edit-card' : ''}`}>
                <div className="d-flex justify-content-between align-items-center">
                    <span className="font-14 font-medium"><span className="me-2">{index + 1}.</span>{attribute.attribute_name}</span>
                    <div className="btn-container d-flex gap-3">
                        {!editMode && (
                            <>
                                <button className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center" onClick={() => handleEdit(attribute)}>
                                    <img src="images/edit.svg" alt="edit-icon" />
                                </button>
                                {/* <button className="border-0 p-0 action-icons delete-icon rounded-circle d-flex justify-content-center align-items-center" onClick={() => handleDelete(attribute.attribute_id, attribute.attribute_name)} hidden={attribute.is_default} >
                                    <img src="images/cancel-red.svg" alt="delete" />
                                </button> */}
                                {attribute.is_default ? false : (
                                    <button
                                        className="border-0 p-0 action-icons delete-icon rounded-circle d-flex justify-content-center align-items-center"
                                        onClick={() => handleDelete(attribute.attribute_id, attribute.attribute_name)}
                                    >
                                        <img src="images/cancel-red.svg" alt="delete" />
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {attribute.attribute_description && !editMode && (
                    <div className="purpose-container ps-3 mt-2">
                        <h3 className="font-14 font-medium">Purpose</h3>
                        <p className="font-14 font-regular primary-color">{attribute.attribute_description}</p>
                    </div>
                )}
                {attribute.checklist && !editMode && (
                    <div className="checklist-container ps-3 mt-2">
                        <h3 className="font-14 font-medium">Checklist</h3>
                        <ul className="mb-0">
                            {attribute.checklist.map((item, idx) => (
                                // <li key={idx} className="font-14">{item}</li>
                                <li key={idx} className="font-14">{item}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {attribute.assessment_types && !editMode && (
                    <>
                        <div className="divider border-bottom my-3 mx-3"></div>
                        <div className="tags-container ps-3 mt-2 pb-3">
                            <h3 className="font-14 font-medium">Applies to</h3>
                            <div className="d-flex gap-2 mt-2">
                                {attribute.assessment_types.map((assessment) => (
                                    <span
                                        key={assessment.assessment_id}
                                        className="tags rounded-pill px-3 py-1 font-12 font-medium d-flex align-items-center"
                                    >
                                        {assessment.assessment_name}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </>
                )}
                {editMode && editedData.attribute_id === attribute.attribute_id && (
                    <>
                        <div className="col">
                            <div className="row">
                                <div className="col-md-9">
                                    <label className="font-14 font-medium">Attribute Name</label>
                                    <input type="text" className="form-control w-100 mb-4 mt-2" value={editedData.attribute_name || ''} name="attribute_name" onChange={handleOnChange} />
                                </div>
                                <div className="col-md-3">
                                    <label className="font-14 font-medium">Applies to</label>
                                    <div className="dropdown mt-2">
                                        <Select
                                            value={editedData.assessment_types}
                                            isMulti
                                            styles={styles}
                                            isClearable={editedData.assessment_types.length > 0 && editedData.assessment_types.some((v) => !v.isFixed)}
                                            name="assessment_types"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(newValue, actionMeta) => handleSelectChange(newValue, actionMeta, false)}
                                            options={assessmentTypes}
                                            getOptionLabel={(option) => option.assessment_name}
                                            getOptionValue={(option) => option.assessment_id}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label className="mb-2">Purpose</label>
                                <textarea className="form-control resize-none custom-textarea" name="attribute_description" value={editedData.attribute_description || ''} onChange={handleOnChange}></textarea>
                            </div>
                            <div className="form-group mb-4">
                                {editedData.checklist && editedData.checklist.length > 0 ? (
                                    editedData.checklist.map((item, idx) => (
                                        <label key={idx} className="d-flex gap-2 align-items-center w-100 mb-3 bullet-point-label">
                                            {/* <input type="checkbox" className="custom-checkbox" /> */}
                                            <input type="text" className="form-control w-100" value={item} onChange={(e) => handleChecklistChange(e, idx)} />
                                        </label>
                                    ))
                                ) : (
                                    <p>No checklist items available.</p>
                                )}
                            </div>
                            <div className="dropdown mb-3">
                                <button className="d-flex gap-2 align-items-center outlined-button px-3" onClick={((e) => {
                                    addChecklistItem(e)
                                })}>
                                    <img src="images/plus.svg" alt="plus-icon" />
                                    <span>Add Checklist</span>
                                    {/* <img src="images/caret-down.svg" alt="caret-icon" /> */}
                                </button>

                            </div>
                            <div className="d-flex border-top align-items-center pt-5 justify-content-between">
                                {/* <label className="d-flex gap-2 align-items-center">
                                    <input type="checkbox" className="custom-checkbox" />
                                    Checklist
                                </label> */}
                                <div className="d-flex gap-2" style={{ paddingLeft: '82%' }}>
                                    <button className="btn btn-light secondary-button" onClick={() => setEditMode(false)}>Cancel</button>
                                    <button className="btn primary-button" onClick={() => handleSave(editedData)} disabled={!isFormValid(editedData)}>Save</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        ));
    };

    const isFormValid = (data) => {
        // Check if attribute_name is not empty
        if (!data.attribute_name || data.attribute_name.trim() === "") {
            return false;
        }

        // Check if attribute_description is not empty
        if (!data.attribute_description || data.attribute_description.trim() === "") {
            return false;
        }

        // Check if at least one assessment type is selected
        if (!data.assessment_types || data.assessment_types.length === 0) {
            return false;
        }

        // If all checks pass, the form is valid
        return true;
    };

    return (
        <div id="pre" className="tab-pane fade show active tab-section mt-4" role="tabpanel">
            {renderAttributes()}
            {newAttributeData.create && (
                <div className="card p-3 custom-card mb-3 edit-card d-flex gap-2 flex-row">
                    <div className="col">
                        <div className="row">
                            <div className="d-flex justify-content-between align-items-center">
                                <label className="font-14 font-medium"><span className="me-2">1.</span>Attribute Name</label>
                                <div className="btn-container d-flex gap-3">
                                    <button className="border-0 p-0 action-icons delete-icon rounded-circle d-flex justify-content-center align-items-center" onClick={() => setNewAttributeData({ create: false, checklistEnabled: false, assessment_types: [], checklist: [] })}>
                                        <img src="images/cancel-red.svg" alt="delete" />
                                    </button>
                                </div>
                            </div>
                            <div className="ps-3">
                                <input type="text" className="form-control w-100 mb-4 mt-2" value={newAttributeData.attribute_name || ''} name="attribute_name" onChange={handleOnChange} />
                                <div className="col-md-3">
                                    <label className="font-14 font-medium">Applies to</label>
                                    <div className="dropdown mt-2">
                                        <Select
                                            value={newAttributeData.assessment_types}
                                            isMulti
                                            styles={styles}
                                            isClearable={newAttributeData.assessment_types.length > 0 && newAttributeData.assessment_types.some((v) => !v.isFixed)}
                                            name="assessment_types"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(newValue, actionMeta) => handleSelectChange(newValue, actionMeta, true)}
                                            options={assessmentTypes}
                                            getOptionLabel={(option) => option.assessment_name}
                                            getOptionValue={(option) => option.assessment_id}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label className="mb-2">Purpose</label>
                                <textarea className="form-control resize-none custom-textarea" name="attribute_description" value={newAttributeData.attribute_description || ''} onChange={handleOnChange}></textarea>
                            </div>
                            <div className="form-check mb-4">
                                <input className="form-check-input" type="checkbox" id="checklistCheck" checked={newAttributeData.checklistEnabled} onChange={toggleChecklist} />
                                <label className="form-check-label" htmlFor="checklistCheck" style={{ paddingRight: '89%' }}>
                                    Enable Checklist
                                </label>
                            </div>
                            {newAttributeData.checklistEnabled && (
                                <div>
                                    {newAttributeData.checklist.map((item, idx) => (
                                        <div className="form-group mb-4" key={idx}>
                                            <label className="d-flex align-items-center gap-2 w-100 mb-3  bullet-point-label">
                                                {/* <input type="checkbox" className="custom-checkbox" /> */}
                                                
                                                <input type="text" className="form-control w-100" value={item} onChange={(e) => handleChecklistNewAttributeChange(e, idx)} />
                                            </label>
                                        </div>
                                    ))}
                                    <div className="dropdown mb-3">
                                        <button className="d-flex gap-2 align-items-center outlined-button px-3" onClick={((e) => {
                                            addNewChecklistItem(e)
                                        })}>
                                            <img src="images/plus.svg" alt="plus-icon" />
                                            <span>Add Checklist</span>
                                            {/* <img src="images/caret-down.svg" alt="caret-icon" /> */}
                                        </button>
                                        {/* <ul className="dropdown-menu button-dropdown">
                                            <li className="dropdown-item cursor-pointer" onClick={addNewChecklistItem}>Text</li>
                                            <li className="dropdown-item cursor-pointer" onClick={addNewChecklistItem}>File Upload</li>
                                        </ul> */}
                                    </div>
                                </div>
                            )}
                            <div className="d-flex border-top align-items-center pt-5 justify-content-between" style={{ paddingLeft: '85%' }}>
                                <div className="d-flex gap-2">
                                    <button className="btn btn-light secondary-button" onClick={() => setNewAttributeData({ create: false, checklistEnabled: false, assessment_types: [], checklist: [] })}>Cancel</button>
                                    <button className="btn primary-button" onClick={() => handleSave(newAttributeData)} disabled={!isFormValid(newAttributeData)}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="dropdown mb-3">
                <button className="d-flex gap-2 align-items-center outlined-button px-3" data-bs-toggle="dropdown">
                    <img src="images/plus.svg" alt="plus-icon" />
                    <span>Add Attributes</span>
                    <img src="images/caret-down.svg" alt="caret-icon" />
                </button>
                <ul className="dropdown-menu button-dropdown">
                    <li className="dropdown-item cursor-pointer" onClick={(() => { handleAddAttribute('text') })}>Text</li>
                    <li className="dropdown-item cursor-pointer" onClick={(() => { handleAddAttribute('file-upload') })}>File Upload</li>
                </ul>
            </div>
        </div>
    );
};

export default PreProcessComponent;