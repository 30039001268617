
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PopupComponent from './PopupComponent';
import { generateReportApi, postErrorLog, reportStatusApi } from '../../Service/api';
import { useOrgContext } from '../Context/Context';

function WorkflowNav(props) {
    const {deviation, setDeviation} = useOrgContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [popupCase, setPopupCase] = useState('');
  const navigate = useNavigate();
  const userId=localStorage.getItem('userId');

  
 let { assessmentName ,scopeData, reportId}=props
  // SQ_NO_04 - SQ_NO_10: Handle Generate Report button click
  const handleGenerateReport = async () => {
    setIsPopupOpen(true);
    setIsGenerating(true);
    setPopupCase('GeneratingReport');

    try {
      const payload = {
        user_id: userId,
        report_id: reportId,
        type: "Initial",
        template: "",
        action_item_id: [],
        title: "",
      };

    //   await generateReportApi(payload);
    //   await reportStatus();
    } catch (error) {
      handleError(error, 'handleGenerateReport');
    }
  };


  // SQ_NO_04.2: Report status function
  const reportStatus = async () => {
    try {
    //   const response = await reportStatusApi({ report_id: reportId });
    //   setIsGenerating(response.data.body.isGenerating);

    //   if (response.data.body.isGenerating) {
    //     setTimeout(reportStatus, 120000); // 2 minutes
    //   } else {
    //     navigate('/Report');
    //   }
    } catch (error) {
      handleError(error, 'reportStatus');
    }
  };

  // SQ_NO_05: Handle Scope button click
  const handleScopeClick = () => {
    setIsPopupOpen(true);
    setPopupCase('Scope');
  };

  // SQ_NO_06: Check for scope deviation
  useEffect(() => {
    if (scopeData.deviation) {
      setDeviation(scopeData.deviation);
    }
  }, [scopeData.deviation, setDeviation]);

      // SQ_NO_2.2 - Handle Error
      const handleError = (error, functionName) => {
        const errorData = {
            user_id: userId,
            type: "error",
            error_module_name: functionName,
            error_description: error?.message + " ," + error?.response?.data?.body?.message
        };
        postErrorLog(errorData);
    };

  return (
    <>
      <div className="row">
        <div className="col-md-12 my-3">
          <div className="d-flex justify-content-between flex-md-row flex-column align-items-center">
            <h1 className="font-24 font-semibold black-color page-title mb-0">
              <a href="#" onClick={() => navigate('/AssessmentsGrid')}>
                <img
                  src="images/back-arrow.svg"
                  alt="back-arrow"
                  className="me-2"
                />
              </a>
              {assessmentName}
            </h1>
            <div className="d-flex gap-2">
              <button
                className="d-flex align-items-center gap-1 align-items-center font-14 outlined-button px-3"
                onClick={handleScopeClick}
              >
                <img src="images/scope-icon.svg" alt="add-icon" />
                Scope
              </button>
              <button className="btn primary-button" onClick={handleGenerateReport}>
                <img
                  src="images/file-icon-white.svg"
                  alt="file-icon"
                  className="mb-1 me-1"
                />
                Generate Report
              </button>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <PopupComponent
          case={popupCase}
          isGenerating={isGenerating}
          onClose={() => setIsPopupOpen(false)}
          scopeData={scopeData}
        />
      )}
      {scopeData.deviation && <span className="deviation-message">{scopeData.deviation}</span>}
    </>
  );
}

export default WorkflowNav;