// Components/FlowComponents.jsx
import React, { useState, useRef, useEffect } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import SunEditor's CSS


const FlowComponents = ({ sectionName, title, content = [], updateSectionContent }) => {
  const [componentName, setComponentName] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [nameError, setNameError] = useState('');
  const [fileError, setFileError] = useState('');
  const [flowData, setFlowData] = useState(content);
  const fileInputRef = useRef(null);



  const handleComponentNameChange = (event) => {
    setComponentName(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileName('');
    fileInputRef.current.value = '';
  };

  const handleAdd = () => {
    if (!componentName.trim()) {
      setNameError('Please enter a Flow Name');
    } else {
      setNameError('');
    }

    if (!file) {
      setFileError('Please select a file to upload');
    } else {
      setFileError('');
    }

    if (componentName.trim() && file) {
      const newComponent = {
        id: flowData.length + 1,
        flowName: componentName,
        link: URL.createObjectURL(file),
        generatedDescription: '', // Initialize with an empty description
      };
      const updatedFlowData = [newComponent, ...flowData];
      setFlowData(updatedFlowData);
      updateSectionContent(updatedFlowData);

      setComponentName('');
      handleRemoveFile();
    }
  };

  const handleDescriptionChange = (id, newDescription, flow) => {
    
    // log("xxx", flow)

    const updatedFlowData = flowData.map(flow =>
      flow.id === id ? { ...flow, generatedDescription: newDescription } : flow
    );
    // console.log("testing flow component", content);

    setFlowData(updatedFlowData);
    updateSectionContent(updatedFlowData);
  };
  // const handleDescriptionChange = (id, newDescription) => {
  //   const updatedFlowData = flowData.map(flow => {
  //     console.log("Checking flow ID:", flow.id, ", current ID:", id);
  //     if (flow.id === id) {
  //       console.log("Updating flow:", { ...flow, generatedDescription: newDescription });
  //       return { ...flow, generatedDescription: newDescription };
  //     }
  //     // return flow;
  //   });

  //   console.log("Updated Flow Data:", updatedFlowData);

  //   setFlowData(updatedFlowData);
  //   updateSectionContent(id, "FlowComponent", newDescription);
  // };
  const renderFlowData = () => {



    return content.map((flowComponent) => (
      <div className="border rounded p-4 mb-4" key={flowComponent.id}>
        <div className="flex justify-between items-center font-semibold">
          <div>{flowComponent.flowName}</div>
        </div>
        <div className="mt-4">
          <img
            src={flowComponent.link}
            alt={`${sectionName} Diagram`}
            className="w-full"
          />
        </div>
        <div className="mt-4">
          <TextEditorTemplate
            initialContent={flowComponent.generatedDescription}
            onContentChange={(updatedContent) => {
              // console.log("Flowcomponent is:", flowComponent);
              handleDescriptionChange(flowComponent.id, updatedContent, flowComponent)
            }}
          />
        </div>
      </div>
    ));
  };

  return (
    <div className="p-4 space-y-5">
      <div className="bg-yellow-100 p-4 rounded-lg">
        <p className="text-sm font-medium">
          Once the {title} diagram is uploaded, you can edit the description below.
        </p>
        <div className="h-1 bg-yellow-500 mt-4"></div>
      </div>

      <div>
        <h3 className="text-xl font-semibold mb-4">{title} Diagram</h3>
        <div className="border-b pb-4 mb-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-semibold mb-2">
                {title} Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={componentName}
                onChange={handleComponentNameChange}
                placeholder={`${title} Name`}
                maxLength={40}
              />
              {nameError && <div className="text-red-500 text-sm mt-1">{nameError}</div>}
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2">
                Upload {title} <span className="text-red-500">*</span>
              </label>
              <div className="relative flex items-center">
                <input
                  type="file"
                  className="absolute opacity-0 w-full cursor-pointer"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  accept=".png,.jpg,.jpeg"
                />
                <div className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer">
                  Upload
                </div>
                {fileName && (
                  <>
                    <span className="ml-4 text-gray-600">{fileName}</span>
                    <button
                      className="ml-2 bg-red-500 text-white py-1 px-2 rounded"
                      onClick={handleRemoveFile}
                    >
                      Remove
                    </button>
                  </>
                )}
              </div>
              {fileError && <div className="text-red-500 text-sm mt-1">{fileError}</div>}
            </div>
            <div className="flex items-end">
              <button
                className="bg-green-500 text-white py-2 px-4 rounded w-full"
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        {flowData.length > 0 && (
          <div className="space-y-4">
            {renderFlowData()}
          </div>
        )}
      </div>
    </div>
  );
};

// Import the TextEditorTemplate component
const TextEditorTemplate = ({ initialContent, onContentChange }) => {
  const editorRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(initialContent);

  const handleEditorChange = (content) => {
    setContent(content);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    if (onContentChange) {
      onContentChange(content);
    }
  };

  return (
    <div className="mx-auto p-2 border border-gray-300 rounded shadow-sm">
      {isEditing ? (
        <>
          <SunEditor
            ref={editorRef}
            setContents={content}
            onChange={handleEditorChange}
            setOptions={{
              height: 400,
              width: '100%',
              buttonList: [
                ['bold', 'italic', 'underline'],
                ['font', 'fontSize'],
                ['fontColor', 'hiliteColor'],
                ['align', 'list', 'indent', 'outdent'],
                ['table', 'link', 'image'],
              ],
            }}
            className="border-2 border-gray-300 rounded mb-4"
          />
          <button
            onClick={handleSaveClick}
            className=" mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
            Save
          </button>
        </>
      ) : (
        <>
          <div className="prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: content }}></div>
          <button
            onClick={handleEditClick}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
            Edit
          </button>
        </>
      )}
    </div>
  );
};

export default FlowComponents;