import { useEffect, useState } from "react";
import { getSasToken } from "../../service/ReportApi";
import React from "react";
import { Architecture, Attribute, BusinessFlowType, ConclusionDetail, CostItem, CurrentCostItem, DataOpsRecommendation, DataWarehouseETLRecommendation, DatawarehouseModule, DatawarehouseSubModule, EntityDetail, EnvironmentDetail, futurestateArchitecture, MigrationPhase, MlRecommendation, ProcessTimeline, ReportingFrameworkRecommendation, SWOTDataItem } from "../../interface/DatabricksDownloadReportInterface";
import moment from "moment";

const DatabricksDownloadReport = ({ props, selectedOption, selectedCategory }: any) => {

    const reportData = props

    const migrationPlanData = reportData.migrationPlan

    const [minDateAndRoundValue, setMinDateAndRoundValue] = useState<any>({ minDate: new Date(), weekNumber: 1 });
    const [weeks, setWeeks] = useState<string[]>([]);

    useEffect(() => {
        const weekData = updateWeeks();
        setMinDateAndRoundValue(weekData);
    }, [migrationPlanData]);

    const extractDatesFromProcesses = (data: any) => {
        let datesArray: string[] = [];

        data?.forEach((phase: any) => {
            phase.processTimeLine?.forEach((process: any) => {
                let startDate = moment(process.startWeek);
                let endDate = moment(process.endWeek);
                datesArray.push(startDate.format("YYYY-MM-DD"));
                datesArray.push(endDate.format("YYYY-MM-DD"));
            });
        });
        return datesArray;
    };

    const updateWeeks = () => {
        const allDates = extractDatesFromProcesses(migrationPlanData);

        const uniqueDates = Array.from(new Set(allDates));
        const dateObjects: Date[] = uniqueDates?.map((date) => new Date(date));

        let minDate = new Date(dateObjects[0]);
        let maxDate = new Date(dateObjects[0]);

        dateObjects.forEach((date) => {
            if (date < minDate) minDate = new Date(date);
            if (date > maxDate) maxDate = new Date(date);
        });

        let weeks: string[] = [];
        let currentDate: Date = new Date(minDate);
        const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7; // Milliseconds in a week
        // const weeksBetween = Math.ceil((maxDate.getTime() - minDate.getTime()) / millisecondsPerWeek);
        const weeksBetween = getWeekIndex(maxDate, minDate);

        let roundedIncrement;

        if (weeksBetween % 4 != 0) {
            roundedIncrement = Math.ceil(weeksBetween / 4)
        } else {
            roundedIncrement = Math.round(weeksBetween / 4);
        }

        let weekNumber: number
        if (roundedIncrement == 0) {
            roundedIncrement = 1
            weekNumber = roundedIncrement
        } else {
            weekNumber = roundedIncrement
        }

        for (let i = 1; i <= 4; i++) {
            weeks.push(`Week ${weekNumber}`);
            currentDate.setDate(currentDate.getDate() + 7);
            weekNumber += roundedIncrement;
        }

        setWeeks(weeks);
        return { "minDate": minDate, "weekNumber": roundedIncrement };
    };

    const getWeekIndex = (date: Date, minDate: Date): number => {
        const startOfWeek = new Date(minDate);
        const targetDate = new Date(date);

        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        targetDate.setDate(targetDate.getDate() - targetDate.getDay());

        const weeksDiff = Math.round((targetDate.getTime() - startOfWeek.getTime()) / (7 * 24 * 60 * 60 * 1000));

        return weeksDiff >= 0 ? weeksDiff + 1 : 0;
    };

    const getWeekRange = (startDate: Date, endDate: Date, process: any, minDate: Date, weekNumber: number): any => {
        const startWeekIndex = getWeekIndex(startDate, minDate);
        const endWeekIndex = getWeekIndex(endDate, minDate);

        if (startWeekIndex !== -1 && endWeekIndex !== -1) {
            const totalBlocks = 4;
            const weeksPerBlock = weekNumber;

            const startBlock = Math.floor((startWeekIndex - 1) / weeksPerBlock);
            const endBlock = Math.floor((endWeekIndex - 1) / weeksPerBlock);

            const marginLeft = (startBlock / totalBlocks) * 100;
            const marginRight = ((totalBlocks - endBlock - 1) / totalBlocks) * 100;

            if (startWeekIndex === endWeekIndex) {
                return { "weekRange": `Week ${startWeekIndex}`, "marginLeft": `${marginLeft}%`, "marginRight": `${marginRight}%` };
            } else {
                return { "weekRange": `Week ${startWeekIndex} - Week ${endWeekIndex}`, "marginLeft": `${marginLeft}%`, "marginRight": `${marginRight}%` };
            }
        } else {
            return {};
        }
    };

    const handleBindingWeekRange = (process: any, index: any) => {
        const startDate = new Date(process.startWeek);
        const endDate = new Date(process.endWeek);

        const weekData = getWeekRange(startDate, endDate, process, minDateAndRoundValue.minDate, minDateAndRoundValue.weekNumber);


        return (
            <td colSpan={4} style={{ width: '58%', borderRight: '1px solid #E7E7E7', padding: '1%', textAlign: 'left', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', fontWeight: 600, color: '#242424', lineHeight: '1.5' }}>
                <div style={{ borderLeft: '4px solid #0F7FAF', borderRadius: '5px', backgroundColor: '#F1FBFF', padding: '10px 16px', marginLeft: weekData.marginLeft, marginRight: weekData.marginRight, }}>
                    <span>
                        {weekData.weekRange}
                    </span>
                </div>
            </td>
        );
    }

    const getEntityCount = (objectName: string): number => {
        const dataObject = reportData.environmentDetails.find((item: EnvironmentDetail) => item.objectName === objectName);
        return dataObject?.entityDetails.reduce((acc: any, detail: any) => acc + detail.entityCount, 0) || 0;
    };

    const ExecutiveSummary = () => {
        const modules: DatawarehouseModule[] = reportData?.dataWarehouseArchitecture?.modules || [];

        const executiveSummary = reportData?.summary?.flat()?.find((item: any) => item.attributeName === "Executive Summary")?.attributeValue || "";

        const flattenSubModules = (subModules: Record<string, DatawarehouseSubModule[]>) => {
            return Object.values(subModules).flat();
        };

        const renderAssessmentTable = (subModules: DatawarehouseSubModule[], title: string) => (
            <>
                <p style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '28px', marginBottom: '15px' }}>
                    {title}
                </p>
                <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '0%' }}>
                    <thead style={{ backgroundColor: '#F0F0F0' }}>
                        <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                            <th style={{ width: '25%', textAlign: 'left', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7' }}>
                                Assessment
                            </th>
                            <th style={{ width: '20%', textAlign: 'left', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7' }}>
                                Rating
                            </th>
                            <th style={{ width: '15%', textAlign: 'center', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7' }}>
                                Risk
                            </th>
                            <th style={{ width: '40%', textAlign: 'left', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7' }}>
                                Description
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {subModules.map((assessment) => (
                            <tr key={assessment.architectureSubModuleId} style={{ borderBottom: '1px solid #E7E7E7' }}>
                                <td style={{ width: '25%', padding: '2%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5 }}>
                                    {assessment.assessmentName}
                                </td>
                                <td style={{ width: '20%', padding: '2%', textAlign: 'left', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5 }}>
                                    {[...Array(5)].map((_, i) => (
                                        <img
                                            key={i}
                                            src={i < assessment.rating ? "./Images/star-blue.svg" : "./Images/star-gray.svg"}
                                            alt="star"
                                            style={{ height: '16px', width: '14px', objectFit: 'fill' }}
                                        />
                                    ))}
                                </td>
                                <td style={{
                                    width: '15%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'center', verticalAlign: 'top', fontSize: '13px', fontFamily: 'sans-serif', fontWeight: 400, color: assessment.risk === 'High' ? '#B71C1C' : (assessment.risk === 'Medium' ? '#E8B613' : '#199500'), lineHeight: 1.5
                                }}>
                                    {assessment.risk}
                                </td>
                                <td style={{ width: '40%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'left', verticalAlign: 'top', fontSize: '13px', fontFamily: 'sans-serif', fontWeight: 400, color: '#252525', lineHeight: 1.5 }}>
                                    {assessment.description}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );

        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ position: 'relative' }}>
                                <div style={{ width: '99%', margin: '0% 0%', pageBreakInside: 'avoid' }}  >
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Executive Summary
                                        </h5>
                                    </div>
                                    <div style={{ width: '88%', float: 'left', margin: '5% 0% 0% 0%', padding: '0% 6%' }} >
                                        <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '14px', margin: '0% 0% 2% 0%', lineHeight: '20px' }} className="new-page">
                                            {executiveSummary}
                                        </p>
                                        {modules.map((module) => (
                                            <div key={module.architectureModuleId} className="new-page">
                                                {renderAssessmentTable(flattenSubModules(module.subModules), module.moduleName)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const BusinessOverview = () => {
        const businessOverview = reportData?.summary?.flatMap((s: any) => s)
            .find((item: any) => item.attributeName === "Business Overview")?.attributeValue || "";

        const businessModel = reportData?.summary?.flatMap((s: any) => s)
            .find((item: any) => item.attributeName === "Business Model")?.attributeValue || "";

        const aiMlStandpoint = reportData?.summary?.flatMap((s: any) => s)
            .find((item: any) => item.attributeName === "AI/ML Business Standpoint")?.attributeValue || "";

        return (
            <div style={{ width: "100%", float: "left", margin: "0% 0%", pageBreakInside: "avoid" }}>
                {/* Business Overview Header */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: "#F5F5F5", pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                    <h3 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                        Business Overview
                    </h3>
                </div>

                {/* Content */}
                <div style={{ width: '88%', float: 'left', margin: '2% 0%', padding: '0% 6%' }}>
                    <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                        Business Model:
                    </h3>
                    <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '1% 0% 2% 0%', lineHeight: '24px' }}>
                        {businessModel}
                    </p>

                    <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 600, fontSize: '13px', margin: '4% 0% 0% 0%', lineHeight: '24px', marginBottom: '0px' }}>
                        AI/ML Business Standpoint:
                    </p>
                    <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '0.5% 0% 2% 0%', lineHeight: '24px' }}>
                        {aiMlStandpoint}
                    </p>
                </div>
            </div>
        );
    };

    const BusinessFlow = () => {
        const [sasToken, setSasToken] = useState<string>('');

        const businessFlows: BusinessFlowType[] =
            reportData?.businessFlow?.map((flow: Attribute[]) => ({
                name: flow.find((attr) => attr.attributeName === "Business Flow Name")?.attributeValue || '',
                bloburl: flow.find((attr) => attr.attributeName === "Business Flow URL")?.attributeValue || '',
                description: flow.find((attr) => attr.attributeName === "Business Flow Description")?.attributeValue || '',
            })) || [];

        useEffect(() => {
            const fetchSasToken = async () => {
                try {
                    const sas = await getSasToken('r');
                    setSasToken(sas?.data);
                } catch (error) {
                }
            };

            fetchSasToken();
        }, [getSasToken]);

        const generateImageUrl = (blobUrl: string): string => {
            if (!blobUrl || !sasToken) return '';
            return `${blobUrl}?${sasToken}`;
        };

        if (businessFlows.length) {
            return (
                <>
                    {businessFlows.map((flow, index) => (
                        <div key={index} style={{ width: "100%", float: "left", margin: "0% 0%", pageBreakInside: "avoid" }}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div style={{ width: "100%", float: "left", margin: "0% 0%", pageBreakInside: "avoid" }}>
                                                <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: "#F5F5F5", pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                                                    <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                                        Business Flow
                                                    </h5>
                                                </div>
                                                <div style={{ width: '88%', float: 'left', margin: '1.5% 0%', padding: '0% 6%' }}>
                                                    <img
                                                        style={{ textAlign: 'center', width: '100%', objectFit: 'fill', margin: '3%' }}
                                                        src={generateImageUrl(flow.bloburl)}
                                                        width="495"
                                                        height="354"
                                                        alt={`Business Flow ${index + 1}`}
                                                    />
                                                    <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                        {flow.name}
                                                    </h3>
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: flow.description }}
                                                        style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '3% 0% 1% 0%', lineHeight: '24px' }}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                </>
            );
        } else {
            return (
                <div style={{ width: "100%", float: "left", margin: "0% 0%", pageBreakInside: "avoid" }}>
                    <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: "#F5F5F5", pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                            Business Flow
                        </h5>
                    </div>
                </div>
            );
        }
    };

    const CurrentDataEnvironmentInventory = () => {
        return (
            <div>
                {/* Static Header */}
                <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                    <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                        Current Data Environment Inventory
                    </h5>
                </div>

                {/* Static Sections */}
                <div style={{ width: '88%', marginTop: '40px', padding: '0% 6%' }}>
                    {reportData.environmentDetails.map((item: EnvironmentDetail, index: number) => (
                        <div key={index} style={{ float: 'left', pageBreakInside: 'avoid', width: '100%' }}>
                            <div style={{ width: '100%', float: 'left', margin: '16px 0 16px 0px' }}>
                                <img style={{ float: 'left', height: '15px', width: '15px' }} src={`./Images/${item.objectName.toLowerCase()}.svg`} alt={item.objectName} />
                                <h5 style={{ float: 'left', fontWeight: 600, fontSize: '14px', fontFamily: 'sans-serif', margin: '1.7px 0 0 7px' }}>
                                    {item.objectName}
                                </h5>
                                {/* If no objectType is available, display the total count next to the objectName */}
                                {!item.entityDetails.some(detail => detail.objectType) && (
                                    <span style={{ float: 'left', marginLeft: '60px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: 600, color: '#000000' }}>
                                        {getEntityCount(item.objectName)}
                                    </span>
                                )}
                            </div>
                            {item.entityDetails.map((entityDetail, detailIndex) => (
                                entityDetail.objectType ? (
                                    <div key={detailIndex} style={{ width: '31.33%', float: 'left', padding: '0 1.5% 2% 0%' }}>
                                        <div style={{ backgroundColor: '#FFFFFF', borderRadius: '0.5rem', padding: '0.5rem 1rem', overflow: 'hidden', border: '1px solid #E8E8E8' }}>
                                            <span style={{ float: 'left', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: 600, verticalAlign: 'middle', color: '#414448', lineHeight: '38px' }}>
                                                {entityDetail.objectType}
                                            </span>
                                            <span style={{ fontFamily: 'sans-serif', float: 'right', fontSize: '14px', fontWeight: 600, verticalAlign: 'middle', lineHeight: '39px', color: '#000000' }}>
                                                {entityDetail.entityCount}
                                            </span>
                                        </div>
                                    </div>
                                ) : null
                            ))}
                        </div>
                    ))}
                    <div style={{ clear: 'both' }} />
                </div>
            </div>
        );
    };

    const CurrentStateArchitecture = () => {
        const [sasToken, setSasToken] = useState<string>('');

        const architectures: Architecture[] =
            reportData?.currentStateArchitecture?.map((arch: Attribute[]) => ({
                name: arch.find(attr => attr.attributeName === "Current State Architecture Name")?.attributeValue || '',
                bloburl: arch.find(attr => attr.attributeName === "Current State Architecture URL")?.attributeValue || '',
                description: arch.find(attr => attr.attributeName === "Current State Architecture Description")?.attributeValue || '',
            })) || [];

        useEffect(() => {
            const fetchSasToken = async () => {
                try {
                    const sas = await getSasToken('r');
                    setSasToken(sas?.data);
                } catch (error) {
                }
            };

            fetchSasToken();
        }, [getSasToken]);

        const generateImageUrl = (blobUrl: string): string => {
            if (!blobUrl || !sasToken) return '';
            return `${blobUrl}?${sasToken}`;
        };

        if (architectures.length) {
            return (
                <>
                    {architectures.map((arch, index) => (
                        <div key={index}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ position: 'relative' }}>
                                            <div style={{ width: '100%', float: 'left', margin: '0% 0%', pageBreakInside: 'avoid' }}>
                                                <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                                                    <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                                        Current State Architecture
                                                    </h5>
                                                </div>
                                                <div style={{ width: '88%', float: 'left', margin: '1.5% 0%', padding: '0% 6%' }}>
                                                    <img
                                                        style={{ textAlign: 'center', width: '100%', objectFit: 'fill', margin: '3%' }}
                                                        src={generateImageUrl(arch.bloburl)}
                                                        width="495"
                                                        height="354"
                                                        alt={`Current State Architecture ${index + 1}`}
                                                    />
                                                    <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                        {arch.name || "Current State Architecture"}
                                                    </h3>
                                                    <div dangerouslySetInnerHTML={{ __html: arch.description }} style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '2% 0% 2% 0%', lineHeight: '24px' }} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                </>
            );
        } else {
            return (
                <div style={{ width: "100%", float: "left", margin: "0% 0%", pageBreakInside: "avoid" }}>
                    <div style={{ width: '98%', justifyContent: 'center', padding: '2% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                            Current State Architecture
                        </h5>
                    </div>
                </div>
            );
        }
    };

    const CurrentInfrastructureCost = () => {
        const awsCosts = reportData.currentCost?.AWS?.current || [];
        const azureCosts = reportData.currentCost?.AZURE || [];
        const inventoryCosts = reportData.currentCost?.INVENTORY || [];

        const parseCost = (cost: string): number => {
            const costWithoutFormat = cost.replace(/[\$,]/g, '');
            return parseFloat(costWithoutFormat) || 0;
        };

        const calculateTotal = (costs: CurrentCostItem[]): { totalMonthlyCost: number; totalYearlyCost: number } => {
            const totalMonthlyCost = costs.reduce((sum, item) => sum + parseCost(item.monthlyCost), 0);
            const totalYearlyCost = costs.reduce((sum, item) => sum + parseCost(item.yearlyCost), 0);
            return { totalMonthlyCost, totalYearlyCost };
        };

        const renderCostTable = (costs: CurrentCostItem[], title: string) => (
            <>
                <p style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '35px', marginBottom: '15px' }}>
                    {title}
                </p>
                <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '0%' }}>
                    <thead style={{ backgroundColor: '#F0F0F0' }}>
                        <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                            <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7' }}>
                                Service/Software
                            </th>
                            <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7', paddingRight: '40px' }}>
                                Monthly Cost
                            </th>
                            <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7', paddingRight: '40px' }}>
                                Yearly Cost
                            </th>
                            <th style={{ width: '30%', textAlign: 'left', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7' }}>
                                Comments
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {costs.map((item, index) => (
                            <tr key={index} style={{ borderBottom: '1px solid #E7E7E7' }}>
                                <td style={{ width: '18%', padding: '2%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5 }}>
                                    {item.softwareName}
                                </td>
                                <td style={{ width: '15%', padding: '2%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5, paddingRight: '40px' }}>
                                    {item.monthlyCost}
                                </td>
                                <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'right', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', fontWeight: 400, color: '#252525', lineHeight: 1.5, paddingRight: '40px' }}>
                                    {item.yearlyCost}
                                </td>
                                <td style={{ width: '30%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'left', verticalAlign: 'top', fontFamily: 'sans-serif', fontWeight: 400, color: '#252525', lineHeight: 1.5, fontSize: '12px' }}>
                                    {item.comments}
                                </td>
                            </tr>
                        ))}
                        <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                            <td style={{ width: '18%', padding: '2%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5 }}>
                                Total
                            </td>
                            <td style={{ width: '15%', padding: '2%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5, paddingRight: '40px', fontWeight: 600 }}>
                                $ {calculateTotal(costs).totalMonthlyCost.toFixed(2)}
                            </td>
                            <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'right', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', fontWeight: 600, color: '#252525', lineHeight: 1.5, paddingRight: '40px' }}>
                                $ {calculateTotal(costs).totalYearlyCost.toFixed(2)}
                            </td>
                            <td style={{ width: '30%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'left', verticalAlign: 'top', fontFamily: 'sans-serif', fontWeight: 400, color: '#252525', lineHeight: 1.5, fontSize: '12px' }}></td>
                        </tr>
                    </tbody>
                </table>
            </>
        );

        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ position: 'relative' }}>
                                <div style={{ width: '100%', margin: '0% 0%', pageBreakInside: 'avoid', height: '99vh' }}>
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Current Infrastructure Cost
                                        </h5>
                                    </div>
                                    <div style={{ width: '88%', float: 'left', margin: '1% 0%', padding: '0 6%' }}>
                                        {renderCostTable(awsCosts, "Current AWS Cost")}
                                        {renderCostTable(azureCosts, "Current Azure Cost")}
                                        {renderCostTable(inventoryCosts, "Current Inventory Cost")}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const DataWarehouseETLRecommendations = () => {
        const recommendations: DataWarehouseETLRecommendation[] = reportData?.dataWarehouseETLStrategicRecommendations || [];

        if (recommendations.length === 0) {
            return null;
        }

        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ position: 'relative' }}>
                                <div style={{ width: '100%', float: 'left', margin: '0% 0%', pageBreakInside: 'avoid' }}>
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Data Warehouse & ETL Strategic Recommendations
                                        </h5>
                                    </div>
                                    <div style={{ width: '88%', float: 'left', margin: '0% 0%', padding: '0% 6%' }}>
                                        {recommendations.map((recommendation, index) => (
                                            <React.Fragment key={index}>
                                                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: index === 0 ? '60px' : '40px', marginBottom: '0px' }}>
                                                    {recommendation.title}
                                                </h3>

                                                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                    Challenges:
                                                </h3>
                                                <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '1.5% 0% 1% 0%', lineHeight: '24px' }}>
                                                    {recommendation.challenges}
                                                </p>

                                                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                    Recommendations:
                                                </h3>
                                                <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '1.5% 0% 1% 0%', lineHeight: '24px' }}>
                                                    {recommendation.recommendation}
                                                </p>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const ReportingFrameworkRecommendations = () => {
        const recommendations: ReportingFrameworkRecommendation[] = reportData?.reportingFrameworkRecommendations || [];

        const renderSection = (data: ReportingFrameworkRecommendation, index: number) => (
            <React.Fragment key={index}>
                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: index === 0 ? '50px' : '40px', marginBottom: '0px' }}>
                    {data.title}
                </h3>
                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                    Challenges:
                </h3>
                <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '1.5% 0% 1% 0%', lineHeight: '24px' }}>
                    {data.challenges}
                </p>
                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                    Recommendations:
                </h3>
                <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '1.5% 0% 1% 0%', lineHeight: '24px' }}>
                    {data.recommendation}
                </p>
            </React.Fragment>
        );

        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ position: 'relative' }}>
                                <div style={{ width: '100%', margin: '0% 0%', pageBreakInside: 'avoid' }}>
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Reporting Framework Recommendations
                                        </h5>
                                    </div>
                                    <div style={{ width: '88%', float: 'left', margin: '0% 0%', padding: '0% 6%' }}>
                                        {recommendations.map((data, index) => renderSection(data, index))}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const DataOpsStrategicRecommendations = () => {
        const recommendations: DataOpsRecommendation[] = reportData?.dataOpsStrategicRecommendations
            ? reportData.dataOpsStrategicRecommendations.map((data: any) => ({
                title: data.title,
                challenges: data.challenges,
                recommendations: data.recommendation,
            }))
            : [];

        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ position: 'relative' }}>
                                <div style={{ width: '100%', float: 'left', margin: '0% 0%', pageBreakInside: 'avoid' }}>
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            DataOps Strategic Recommendations
                                        </h5>
                                    </div>
                                    <div style={{ width: '88%', float: 'left', margin: '0% 0%', padding: '0% 6%' }}>
                                        {recommendations.map((data, index) => (
                                            <React.Fragment key={index}>
                                                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                    {data.title}
                                                </h3>
                                                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                    Challenges:
                                                </h3>
                                                <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '3% 0% 1% 0%', lineHeight: '24px' }}>
                                                    {data.challenges}
                                                </p>
                                                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                    Recommendations:
                                                </h3>
                                                <div dangerouslySetInnerHTML={{ __html: data.recommendations }} style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '3% 0% 1% 0%', lineHeight: '24px' }} />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const MlRecommendations = () => {
        const recommendations: MlRecommendation[] = reportData?.mlRecommendations || [];

        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ position: 'relative' }}>
                                <div style={{ width: '100%', float: 'left', margin: '0% 0%', pageBreakInside: 'avoid' }}>
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            ML Strategic Recommendations
                                        </h5>
                                    </div>
                                    <div style={{ width: '88%', float: 'left', margin: '0% 0%', padding: '0% 6%' }}>
                                        {recommendations.map((data, index) => (
                                            <React.Fragment key={index}>
                                                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                    {data.title}
                                                </h3>
                                                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                    Challenges:
                                                </h3>
                                                <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '3% 0% 1% 0%', lineHeight: '24px' }}>
                                                    {data.challenges}
                                                </p>
                                                <h3 style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '32px', marginBottom: '0px' }}>
                                                    Recommendations:
                                                </h3>
                                                <p style={{ fontFamily: 'sans-serif', color: '#000', fontWeight: 500, fontSize: '13px', margin: '3% 0% 1% 0%', lineHeight: '24px' }}>
                                                    {data.recommendation}
                                                </p>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const FutureStateArchitecture = () => {
        const [sasToken, setSasToken] = useState<string>('');

        const architectures: futurestateArchitecture[] = reportData?.futureStateArchitecture
            ? reportData.futureStateArchitecture.map((architectureArray: Attribute[]) => ({
                name: architectureArray?.find(attr => attr.attributeName === "Architecture Name")?.attributeValue || '',
                blobUrl: architectureArray?.find(attr => attr.attributeName === "Architecture Blob URL")?.attributeValue || '',
            }))
            : [];

        useEffect(() => {
            const fetchSasToken = async () => {
                try {
                    const sas = await getSasToken('r');
                    setSasToken(sas?.data);
                } catch (error) {

                }
            };

            fetchSasToken();
        }, [getSasToken]);

        const generateImageUrl = (blobUrl: string): string => {
            if (!blobUrl || !sasToken) return '';
            return `${blobUrl}?${sasToken}`;
        };

        const renderArchitectures = () => {
            return architectures.map((architecture, index) => (
                <div key={index} style={{ width: "100%", float: "left", margin: "0", pageBreakInside: "avoid" }}>
                    <div style={{ width: '100%', justifyContent: 'center', padding: "3% 1%", backgroundColor: "#F5F5F5", pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0', textAlign: 'center', wordSpacing: '4px' }}>
                            Future State Architecture
                        </h5>
                    </div>
                    <div style={{ width: '88%', float: 'left', margin: '0% 0%', padding: '0% 6%' }}>
                        <h3 style={{ width: '100%', float: 'left', fontFamily: 'sans-serif', color: '#000', fontSize: '14px', fontWeight: 600, marginTop: '7%' }}>
                            {architecture.name}
                        </h3>
                        <img src={generateImageUrl(architecture.blobUrl)} alt={`Future State Architecture ${index + 1}`} style={{ textAlign: 'center', width: '100%', objectFit: 'fill', margin: '3%' }} />
                    </div>
                </div>
            ));
        };


        return (
            <>
                {architectures.length ? (
                    <div>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ position: 'relative' }}>
                                        {renderArchitectures()}
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                ) : (
                    <div style={{ width: "100%", float: "left", margin: "0", pageBreakInside: "avoid" }}>
                        <div style={{ width: '100%', justifyContent: 'center', padding: '3% 1%', backgroundColor: "rgb(245, 245, 245)", pageBreakBefore: 'always' }}>
                            <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '23px', fontWeight: 600, margin: '0', textAlign: 'center', wordSpacing: '4px' }}>
                                Future State Architecture
                            </h5>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const SWOT = () => {
        const [swotData, setSwotData] = useState({
            strengths: [] as string[],
            weaknesses: [] as string[],
            opportunities: [] as string[],
            threats: [] as string[],
        });

        useEffect(() => {
            const extractedSwotData = {
                strengths: [] as string[],
                weaknesses: [] as string[],
                opportunities: [] as string[],
                threats: [] as string[],
            };

            if (reportData.SWOT && reportData.SWOT.length > 0) {
                reportData.SWOT[0].forEach((item: any) => {
                    switch (item.attributeName) {
                        case 'Strengths':
                            extractedSwotData.strengths.push(item.attributeValue);
                            break;
                        case 'Weaknesses':
                            extractedSwotData.weaknesses.push(item.attributeValue);
                            break;
                        case 'Opportunities':
                            extractedSwotData.opportunities.push(item.attributeValue);
                            break;
                        case 'Threats':
                            extractedSwotData.threats.push(item.attributeValue);
                            break;
                        default:
                            break;
                    }
                });
            }

            setSwotData(extractedSwotData);
        }, [reportData]);

        const renderList = (items: string[]) => (
            <ul style={{ paddingLeft: '22px', margin: '1.5% 0 0 0' }}>
                {items.map((item, index) => (
                    <li
                        key={index}
                        style={{
                            fontFamily: 'sans-serif',
                            color: '#000',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            marginTop: '8px',
                        }}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        );

        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ position: 'relative' }}>
                                <div style={{ width: '100%', margin: '0% 0%', pageBreakInside: 'avoid' }}>
                                    <div
                                        style={{
                                            width: '98%',
                                            justifyContent: 'center',
                                            padding: '3% 1%',
                                            backgroundColor: '#F5F5F5',
                                            pageBreakBefore: 'always',
                                            pageBreakAfter: 'avoid',
                                        }}
                                    >
                                        <h5
                                            style={{
                                                color: '#050505',
                                                fontFamily: 'sans-serif',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                margin: '0px',
                                                textAlign: 'center',
                                                wordSpacing: '4px',
                                            }}
                                        >
                                            SWOT
                                        </h5>
                                    </div>
                                    <div style={{ width: '88%', float: 'left', margin: '3% 0% 0% 0%', padding: '0% 6%' }}>
                                        <div style={{ width: '45%', float: 'left', paddingRight: '20px' }}>
                                            <h3
                                                style={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    fontFamily: 'sans-serif',
                                                    marginTop: '32px',
                                                    marginBottom: '18px',
                                                }}
                                            >
                                                Strength
                                            </h3>
                                            {renderList(swotData.strengths)}
                                        </div>
                                        <div style={{ width: '45%', float: 'left', paddingLeft: '20px' }}>
                                            <h3
                                                style={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    fontFamily: 'sans-serif',
                                                    marginTop: '32px',
                                                    marginBottom: '18px',
                                                }}
                                            >
                                                Weakness
                                            </h3>
                                            {renderList(swotData.weaknesses)}
                                        </div>
                                        <div
                                            style={{
                                                width: '95%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                paddingTop: '32px',
                                            }}
                                        >
                                            <img style={{ height: '180px', width: '180px' }} src="./Images/swot.svg" alt="swot" />
                                        </div>
                                        <div style={{ width: '45%', float: 'left', paddingRight: '20px' }}>
                                            <h3
                                                style={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    fontFamily: 'sans-serif',
                                                    marginTop: '32px',
                                                    marginBottom: '18px',
                                                }}
                                            >
                                                Opportunity
                                            </h3>
                                            {renderList(swotData.opportunities)}
                                        </div>
                                        <div style={{ width: '45%', float: 'left', paddingLeft: '20px' }}>
                                            <h3
                                                style={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    fontFamily: 'sans-serif',
                                                    marginTop: '32px',
                                                    marginBottom: '18px',
                                                }}
                                            >
                                                Threats
                                            </h3>
                                            {renderList(swotData.threats)}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const ProposedCostAnalysis = () => {
        const awsCosts = reportData?.futureCost?.AWS || {};
        const inventoryCosts = reportData?.futureCost?.INVENTORY || [];

        const parseCost = (cost: string): number => {
            const costWithoutFormat = cost.replace(/[\$,]/g, '');
            return parseFloat(costWithoutFormat) || 0;
        };

        const calculateTotal = (costs: CostItem[]): { totalMonthlyCost: number; totalYearlyCost: number } => {
            const totalMonthlyCost = costs.reduce((sum, item) => sum + parseCost(item.monthlyCost), 0);
            const totalYearlyCost = costs.reduce((sum, item) => sum + parseCost(item.yearlyCost), 0);
            return { totalMonthlyCost, totalYearlyCost };
        };

        const renderCostTable = (costs: CostItem[], title: string) => (
            <>
                <p style={{ color: '#000000', fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', marginTop: '28px', marginBottom: '15px' }}>
                    {title}
                </p>
                <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '0%' }}>
                    <thead style={{ backgroundColor: '#F0F0F0' }}>
                        <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                            <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7' }}>
                                Service/Software
                            </th>
                            <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7', paddingRight: '40px' }}>
                                Monthly Cost
                            </th>
                            <th style={{ width: '15%', textAlign: 'right', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7', paddingRight: '40px' }}>
                                Yearly Cost
                            </th>
                            <th style={{ width: '30%', textAlign: 'left', color: '#454545', fontSize: '12px', fontFamily: 'sans-serif', padding: '2%', border: '1px solid #E7E7E7' }}>
                                Comments
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {costs.map((item, index) => (
                            <tr key={index} style={{ borderBottom: '1px solid #E7E7E7' }}>
                                <td style={{ width: '18%', padding: '2%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5 }}>
                                    {item.softwareName}
                                </td>
                                <td style={{ width: '15%', padding: '2%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5, paddingRight: '40px' }}>
                                    {item.monthlyCost}
                                </td>
                                <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'right', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', fontWeight: 400, color: '#252525', lineHeight: 1.5, paddingRight: '40px' }}>
                                    {item.yearlyCost}
                                </td>
                                <td style={{ width: '30%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'left', verticalAlign: 'top', fontFamily: 'sans-serif', fontWeight: 400, color: '#252525', lineHeight: 1.5, fontSize: '12px' }}>
                                    {item.comments}
                                </td>
                            </tr>
                        ))}
                        <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                            <td style={{ width: '18%', padding: '2%', border: '1px solid #E7E7E7', textAlign: 'left', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5 }}>
                                Total
                            </td>
                            <td style={{ width: '15%', padding: '2%', textAlign: 'right', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', color: '#252525', lineHeight: 1.5, paddingRight: '40px', fontWeight: 600 }}>
                                $ {calculateTotal(costs).totalMonthlyCost.toFixed(2)}
                            </td>
                            <td style={{ width: '15%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'right', verticalAlign: 'top', fontSize: '14px', fontFamily: 'sans-serif', fontWeight: 600, color: '#252525', lineHeight: 1.5, paddingRight: '40px' }}>
                                $ {calculateTotal(costs).totalYearlyCost.toFixed(2)}
                            </td>
                            <td style={{ width: '30%', borderRight: '1px solid #E7E7E7', padding: '2%', textAlign: 'left', verticalAlign: 'top', fontFamily: 'sans-serif', fontWeight: 400, color: '#252525', lineHeight: 1.5, fontSize: '12px' }}>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        );

        return (
            <div>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ position: 'relative' }}>
                                <div style={{ width: '100%', margin: '0% 0%', pageBreakInside: 'avoid', height: '99vh' }}>
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '3% 1%', backgroundColor: '#F5F5F5', pageBreakBefore: 'always', pageBreakAfter: 'avoid' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Proposed Cost Analysis
                                        </h5>
                                    </div>
                                    <div style={{ width: '88%', float: 'left', margin: '1% 0%', padding: '0 6%' }}>
                                        {Object.keys(awsCosts).map((approach) => renderCostTable(awsCosts[approach], `Future AWS Cost (${approach})`))}
                                        {renderCostTable(inventoryCosts, "Future Inventory Cost")}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const Roadmap = () => {
        return (
            <>
                <div style={{ width: "100%", float: "left", margin: "0% 0%", pageBreakInside: "avoid" }} >
                    {/*AWS Well-Architected Framework - Findings Header starts here */}
                    <div style={{ width: "100%", justifyContent: "center", padding: "3% 1%", backgroundColor: "rgb(245, 245, 245)", marginLeft: "-15px", marginTop: "-15px" }}>
                        <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '23px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                            Roadmap</h5>
                    </div>
                    {/*AWS Well-Architected Framework - Findings Header  ends here */}
                    <div style={{ width: '96%', float: 'left', padding: '16px' }}>
                        {/* <h5 style="color: #000; font-family: Arial ; font-size: 13px; font-weight: bold; margin-top:0px;">Migration Strategy Table</h5> */}
                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '16px' }}>
                            <thead style={{ backgroundColor: '#F0F0F0' }}>
                                {<tr style={{ borderBottom: '1px solid #E7E7E7' }}>
                                    <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '15px', fontFamily: '"Roboto"', padding: '1%' }}>Phase
                                    </th>
                                    <th style={{ width: '18%', textAlign: 'left', color: '#454545', fontSize: '15px', fontFamily: '"Roboto"', padding: '1%' }}>Process
                                    </th>
                                    {weeks?.map((week, index, processWeek) => (
                                        <th key={index} style={{ width: '13%', textAlign: 'left', color: '#454545', fontSize: '15px', fontFamily: '"Roboto"', padding: '1%', whiteSpace: 'nowrap', borderRight: processWeek.length - 1 === index ? '1px solid #E7E7E7' : '' }}>{week}</th>
                                    ))}
                                </tr>}
                            </thead>
                            <tbody>
                                {migrationPlanData && migrationPlanData?.map((phaseData: any, phaseIndex: number) => (
                                    phaseData?.processTimeLine?.map((process: any, processIndex: number) => (
                                        <tr key={`${phaseIndex}-${processIndex}`} style={{ borderBottom: '1px solid #E7E7E7' }}>
                                            {processIndex === 0 && (
                                                <td rowSpan={phaseData.processTimeLine.length} style={{ width: '18%', padding: '1%', textAlign: 'left', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                                    {phaseData.phaseName}
                                                </td>
                                            )}

                                            <td style={{ width: '18%', padding: '1%', textAlign: 'left', border: '1px solid #E7E7E7', verticalAlign: 'top', fontSize: '12px', fontFamily: '"Roboto"', color: '#242424', lineHeight: '1.5' }}>
                                                {process.processName}
                                            </td>

                                            {/* Assuming handleBindingWeekRange is a function that returns a valid JSX component or HTML element */}
                                            {handleBindingWeekRange(process, processIndex)}
                                        </tr>
                                    ))
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }

    const Conclusion = () => {
        const conclusion = reportData?.conclusion ? reportData.conclusion.map((data: ConclusionDetail[]) => ({
            description: data.find(attr => attr.attributeName.includes("Conclusion Description"))?.attributeValue,
        })) : [];

        const removeHtmlTags = (text: string): string => {
            const regex = /<[^>]*>|"/g;
            return text?.replace(regex, '');
        };

        return (
            <div style={{ width: "100%", float: "left", margin: "0% 0%", pageBreakInside: "avoid" }} >
                <div style={{ width: "100%", justifyContent: "center", padding: "3% 1%", backgroundColor: "rgb(245, 245, 245)", marginLeft: "-15px", marginTop: "-15px" }}>
                    <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '23px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px', pageBreakBefore: 'always' }}>
                        Conclusion
                    </h5>
                </div>
                <div style={{ width: '94%', float: 'left', margin: '1% 3%' }}>
                    <p style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '16px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                        {conclusion[0]?.description ? <div dangerouslySetInnerHTML={{ __html: removeHtmlTags(conclusion[0]?.description) }} /> : null}
                    </p>
                </div>
            </div>
        );
    };


    const shouldRenderSection = (selectedOption: string) => {
        return selectedOption === 'all' || selectedCategory.includes(selectedOption);
    };

    return (

        <>
            <div style={{ width: '100%' }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                {/*Reports page starts here */}
                                <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                    {/*Reports Header starts here */}
                                    <div style={{ width: '98%', justifyContent: 'center', padding: '1.5% 1%', backgroundColor: '#F5F5F5' }}>
                                        <h5 style={{ color: '#050505', fontFamily: 'Arial', fontSize: '20px', fontWeight: 600, margin: '0px', textAlign: 'center', wordSpacing: '4px' }}>
                                            Reports</h5>
                                    </div>
                                    {/*Reports Header  ends here */}
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%' }}>
                                        {/* Main header style */}
                                        {/*page header style */}
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Executive Summary
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Business Overview
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Business Flow
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Current Data Environment Inventory
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Current State Architecture
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Current Infrastructure Cost
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Data Warehouse & ETL Strategic Recommendations
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Reporting Framework Recommendations
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                DataOps Strategic Recommendations
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Future State Architecture
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                SWOT
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Proposed Cost Analysis
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Roadmap
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                        <div style={{ width: '100%', float: 'left' }}>
                                            <label style={{ fontFamily: 'Arial', color: '#2E2E2E', fontWeight: 600, fontSize: '13px', margin: '1% 0%', float: 'left' }}>
                                                Conclusion
                                            </label>
                                            <label style={{ fontFamily: 'Arial', color: '#000', fontWeight: 500, fontSize: '11px', margin: '1% 0%', float: 'right' }}>

                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/*Reports page ends here */}
                                {/* Executive Summary */}
                                {shouldRenderSection('Executive Summary') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {ExecutiveSummary()}
                                    </div>
                                )}

                                {/* Business Overview */}
                                {shouldRenderSection('Business Overview') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {BusinessOverview()}
                                    </div>
                                )}

                                {/* Business Flow */}
                                {shouldRenderSection('Business Flow') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {BusinessFlow()}
                                    </div>
                                )}
                                {/* Current Data Environment Inventory */}
                                {shouldRenderSection('Current Data Environment Inventory') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {CurrentDataEnvironmentInventory()}
                                    </div>
                                )}

                                {/* Current State Architecture */}
                                {shouldRenderSection('Current State Architecture') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {CurrentStateArchitecture()}
                                    </div>
                                )}

                                {/* Current Infrastructure Cost */}
                                {shouldRenderSection('Current Infrastructure Cost') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {CurrentInfrastructureCost()}
                                    </div>
                                )}

                                {/* Data Warehouse & ETL Strategic Recommendations */}
                                {shouldRenderSection('Data Warehouse & ETL Strategic Recommendations') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {DataWarehouseETLRecommendations()}
                                    </div>
                                )}

                                {/* Reporting Framework Recommendations */}
                                {shouldRenderSection('Reporting Framework Recommendations') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {ReportingFrameworkRecommendations()}
                                    </div>
                                )}

                                {/* DataOps Strategic Recommendations */}
                                {shouldRenderSection('DataOps Strategic Recommendations') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {DataOpsStrategicRecommendations()}
                                    </div>
                                )}


                                {/* ML Recommendations */}
                                {shouldRenderSection('ML Recommendations') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {MlRecommendations()}
                                    </div>
                                )}

                                {/* Future State Architecture */}
                                {shouldRenderSection('Future State Architecture') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {FutureStateArchitecture()}
                                    </div>
                                )}

                                {/* SWOT */}
                                {shouldRenderSection('SWOT') && (
                                    <div style={{ width: '100%', float: 'left', margin: '25% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {SWOT()}
                                    </div>
                                )}

                                {/* Proposed Cost Analysis */}
                                {shouldRenderSection('Proposed Cost Analysis') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {ProposedCostAnalysis()}
                                    </div>
                                )}

                                {/* Roadmap */}
                                {shouldRenderSection('Roadmap') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {Roadmap()}
                                    </div>
                                )}

                                {/* Conclusion */}
                                {shouldRenderSection('Conclusion') && (
                                    <div style={{ width: '100%', float: 'left', margin: '1% 0%', pageBreakInside: 'avoid' }} className="new-page">
                                        {Conclusion()}
                                    </div>
                                )}
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>
        </>
    )

}


export default DatabricksDownloadReport