import { jwtDecode } from "jwt-decode";
import { encryptStorage } from "../../constant/constant";
import { getConnectorsData } from "../../helpers/encryption";
import { getSasToken } from "../../service/ReportApi";
import { useRef, useState } from "react";
import { read, utils } from "xlsx";

export default function TemplateBasedConnectorForm(props: any) {
  const { activeConnector, verticalName, setExcelTemplateData, validateConnectorInput, setConnectorFormData, resetValidationMessages,setShowConnectorForm } = props;
  const attachment = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<any>();
  const [validationMessage, setValidationMessage] = useState<string>("");

  const DatabricksInventoryTemplate = [
    "Object",
    "ObjectType",
    "ObjectName",
    "SourceObjectType",
    "SourceObjectPath",
    "EntityCount",
  ];

  const attachmentRemover = () => {
    if (attachment?.current) {
      attachment.current.files = null;
      attachment.current.value = "";
    }
  };

  const removeFile = () => {
    setFileName("");
    setValidationMessage("");
    attachmentRemover();
  };

  const readFile = (event: any) => {
    let array: any = [];
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
      const typeArr = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      let type = typeArr.includes(file.type.split("/")[1]);
      if (type) {
        setValidationMessage("");
        let files = file;
        let reader = new FileReader();
        reader.onload = (e) => {
          const wb = read(e?.target?.result);
          const sheets = wb.SheetNames;
          const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          if (rows.length >= 1) {
            setFileName(event.target.files[0]?.name);
            setValidationMessage("");
            let columnTitle = utils.sheet_to_json(wb.Sheets[sheets[0]], {
              header: 1,
            })[0];
            if (
              JSON.stringify(
                activeConnector === "Inventory" && verticalName === "Databricks"
                  ? DatabricksInventoryTemplate
                  : ""
              ) === JSON.stringify(columnTitle)
            ) {
              rows.forEach((data: any) => {
                const requiredFields = ["Object"];
                const isValid = requiredFields.every((field) => {
                  const value = data[field];
                  return (
                    (value &&
                      (typeof value === "string" && value?.trim() !== "")) ||
                    typeof value === "number"
                  );
                });

                if (!isValid) {
                  attachmentRemover();
                  setFileName("");
                  setValidationMessage("Please upload the correct file");
                  return;
                }

                let obj = {
                  object: data["Object"],
                  objectType: data["ObjectType"],
                  objectName: data["ObjectName"],
                  sourceObjectType: data["SourceObjectType"],
                  sourceObjectPath: data["SourceObjectPath"],
                  entityCount: data["EntityCount"],
                };
                array.push(obj);
              });

              setExcelTemplateData(array);
            } else {
              attachmentRemover();
              setFileName("");
              setValidationMessage("Please upload a valid XLSX file");
            }
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const downloadTemplate = async () => {
    try {
      let value = encryptStorage.getItem("jwt");
      let parsedJtk: any = "";
      let storageAccountName: any, containerName: any;
      if (value) {
        parsedJtk = jwtDecode(value);
        storageAccountName = getConnectorsData({
          key: parsedJtk.ek,
          encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME!,
        });
        containerName = getConnectorsData({
          key: parsedJtk.ek,
          encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER!,
        });
      }
      const sas = await getSasToken("r");
      if (activeConnector === "Solarwinds") {
        window.open(
          `SolarWinds Infra Template.xlsx` +
            `?${sas.data}`
        );
      } else if (activeConnector === "Inventory") {
        switch (verticalName) {
          case "MDPW":
            window.open(
              `AWS MDPW Inventory Template.xlsx` +
                `?${sas.data}`
            );
            break;
          case "VMware":
            window.open(
              `Infrastructure VMware Inventory Template.xlsx` +
                `?${sas.data}`
            );
            break;
        }
      } else if (activeConnector === "Azure") {
        window.open(
          `Azure Template - Infra connector.xlsx` +
            `?${sas.data}`
        );
      } else {
        window.open(
          `AWS template - Infra connector.xlsx` +
            `?${sas.data}`
        );
      }
    } catch (error) {
      console.error("Error occurred while downloading the template.", error);
    }
  };

  return (
    <>
      <p className="font-14 font-bold color-black mb-4">Step 1</p>
      <div className="d-flex mb-3">
        <span
          onClick={() => {
            downloadTemplate();
          }}
        >
          <img
            src="images/download.svg"
            alt="download"
            title="download"
            className="me-2"
          />
          <span className="link-blue font-12 font-semibold link-blue cursor-pointer">
            Download Template
          </span>
        </span>
      </div>
      <p className="form-label font-14 font-regular color-black mb-4">
        Download the template
      </p>
      <p className="font-14 font-bold color-black mb-4">Step 2</p>
      <p className="form-label font-14 font-regular color-black mb-4">
        Enter valid details on the template
      </p>
      <p className="font-14 font-bold color-black mb-3">Step 3</p>
      <div className="d-flex mb-3 cursor-pointer">
        <span className="upload-btn-wrapper me-2 d-block">
          <img src="images/upload.svg" alt="browse" className="me-2" />
          <span className="link-blue font-12 font-semibold link-blue">
            Upload Template
          </span>
          <input
            type="file"
            name="myfile"
            ref={attachment}
            disabled={fileName !== "" ? true : false}
            className="cursor-pointer"
            onChange={(e) => {
              readFile(e);
            }}
          />
        </span>
      </div>
      <div>
        <span className="font-14 font-medium color-grey mt-1 d-block">
          {fileName}
          <span className="cursor-pointer ms-3" title="Cancel">
            {fileName ? (
              <img
                src="images/upload-close.svg"
                alt="upload-close"
                onClick={() => {
                  removeFile();
                }}
              />
            ) : (
              <></>
            )}
          </span>
        </span>
        <p className="mt-2 mb-0 color-red font-14 font-regular">
          {validationMessage}
        </p>
      </div>
      <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
          <button
            type="button"
            className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
            disabled= {!file ? true : false}
            onClick={() => {validateConnectorInput(file)}}
          >
            Save
          </button>
          <a
            className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
            onClick={() => {
              setConnectorFormData({});
              resetValidationMessages(activeConnector);
              setShowConnectorForm(true);
            }}
          >
            Cancel
          </a>
        </div>
    </>
  );
}