import React from 'react';
import { SavingToastProps } from '../interface/DatabricksReportModel';

const SavingToast: React.FC<SavingToastProps> = ({ text }) => {
  return (
    <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
      <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
        <div className="toast-body d-flex align-items-center ">
          <div className="d-block me-3">
            <img
              src="images/loading.gif"
              alt="toast-success"
              style={{ height: "15px", width: "15px" }}
            />
          </div>
          <div className="d-block">
            <span className="font-medium font-16 color-grey">
              {text}...
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingToast;