import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { saveReportContentApi, postErrorLog } from "../../../Service/api";

const MultiRecordTableTemplate = ({ reportData, getReportData }) => {
  const initialContent = reportData.body.content;

  const [content, setContent] = useState(initialContent);
  const [newRow, setNewRow] = useState({
    service: "",
    monthlyCost: "",
    yearlyCost: "",
    comments: "",
  });
  const [editState, setEditState] = useState({
    tableIndex: null,
    rowIndex: null,
    rowData: {},
  });
  const [approachName, setApproachName] = useState("");
  const [url, setUrl] = useState("");
  const [formError, setFormError] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [approachNames, setApproachNames] = useState([]);

  useEffect(() => {
    console.log("logging: Initial content loaded", content);
    const existingApproachNames = content.map((item) =>
      item.table_title.toLowerCase().trim()
    );
    setApproachNames(existingApproachNames);
  }, [content]);

  const validateInput = (field, value, tableIndex = null, rowIndex = null) => {
    console.log("debug: Validating input", {
      field,
      value,
      tableIndex,
      rowIndex,
    });
    let errors = { ...validationErrors };

    if (field === "service") {
      if (value.trim() && tableIndex !== null) {
        const isDuplicate = content[tableIndex].rows.some(
          (row, index) =>
            index !== rowIndex &&
            row[0].toLowerCase().trim() === value.trim().toLowerCase() &&
            row[0] !== "Total"
        );
        if (isDuplicate) {
          errors.service = "Service name must be unique within this table";
        } else {
          delete errors.service;
        }
      } else {
        delete errors.service;
      }
    } else if (field === "monthlyCost" || field === "yearlyCost") {
      if (value !== "" && (isNaN(value) || parseFloat(value) < 0)) {
        errors[field] = `Invalid ${
          field === "monthlyCost" ? "monthly" : "yearly"
        } cost`;
      } else {
        delete errors[field];
      }
    } else if (field === "approachName") {
      if (value.trim() && approachNames.includes(value.trim().toLowerCase())) {
        errors.approachName = "Approach Name must be unique";
      } else {
        delete errors.approachName;
      }
    } else if (field === "url") {
      if (
        value.trim() &&
        !value.startsWith("https://calculator.aws/#/estimate?id=")
      ) {
        errors.url = "Invalid AWS calculator URL";
      } else {
        delete errors.url;
      }
    }

    console.log("debug: Validation result", errors);
    setValidationErrors(errors);
  };

  const handleInputChange = (field, value, tableIndex = null) => {
    console.log("logging: Input change", { field, value, tableIndex });
    if (field === "approachName") {
      setApproachName(value);
      validateInput("approachName", value);
    } else if (field === "url") {
      setUrl(value);
      validateInput("url", value);
    } else {
      if (editState.rowIndex !== null && editState.tableIndex !== null) {
        setEditState((prevState) => ({
          ...prevState,
          rowData: { ...prevState.rowData, [field]: value },
        }));
        validateInput(field, value, editState.tableIndex, editState.rowIndex);
      } else {
        setNewRow((prevState) => ({ ...prevState, [field]: value }));
        validateInput(field, value, tableIndex);
      }
    }
    setFormError("");
  };

  const isRowValid = (row) => {
    return (
      row.service.trim() !== "" &&
      row.monthlyCost !== "" &&
      row.yearlyCost !== "" &&
      !isNaN(row.monthlyCost) &&
      !isNaN(row.yearlyCost) &&
      parseFloat(row.monthlyCost) >= 0 &&
      parseFloat(row.yearlyCost) >= 0
    );
  };

  const callSaveApi = async (updatedContent) => {
    const payload = {
      report_id: reportData.report_data_id,
      message: {
        ...reportData,
        body: { ...reportData.body, content: updatedContent },
      },
    };

    try {
      console.log("debug: Calling saveReportContentApi with payload", payload);
      const response = await saveReportContentApi(payload);
      console.log("logging: Save API response", response);

      if (response.status === 200) {
        console.log("logging: Save successful");
        setContent(updatedContent);
        getReportData(); // Refresh data by calling the passed prop function
      }
    } catch (error) {
      console.error("error: Save API call failed", error);
      await postErrorLog({ error });
      setFormError("Failed to save data. Please try again!");
    }
  };

  const addRow = (tableIndex) => {
    console.log("logging: Adding new row", { tableIndex, newRow });
    if (!isRowValid(newRow)) {
      console.error("error: Cannot add row. Invalid input");
      return;
    }

    // Check if the fourth element (last column) of the newRow is empty and fill it with _
    if (newRow.comments === "") {
      console.log("info: Last column is empty, filling it with underscore _");
      newRow.comments = "_";
    }

    // if there are no floating values then add two decimal places to the values
    if (newRow.monthlyCost && !String(newRow.monthlyCost).includes(".")) {
      newRow.monthlyCost = parseFloat(newRow.monthlyCost).toFixed(2);
    }
    if (newRow.yearlyCost && !String(newRow.yearlyCost).includes(".")) {
      newRow.yearlyCost = parseFloat(newRow.yearlyCost).toFixed(2);
    }

    const updatedContent = [...content];
    updatedContent[tableIndex].rows.splice(-1, 0, [
      newRow.service,
      parseFloat(newRow.monthlyCost).toFixed(2),
      parseFloat(newRow.yearlyCost).toFixed(2),
      newRow.comments,
    ]);
    recalculateTotals(updatedContent, tableIndex);
    setNewRow({ service: "", monthlyCost: "", yearlyCost: "", comments: "" });
    setValidationErrors({});

    callSaveApi(updatedContent);
  };

  const startEditing = (tableIndex, rowIndex, rowData) => {
    console.log("logging: Start editing row", {
      tableIndex,
      rowIndex,
      rowData,
    });
    if (rowData[0] === "Total") return;
    setEditState({
      tableIndex,
      rowIndex,
      rowData: {
        service: rowData[0],
        monthlyCost: rowData[1],
        yearlyCost: rowData[2],
        comments: rowData[3],
      },
    });
    setValidationErrors({});
  };

  const saveEdit = (tableIndex) => {
    console.log("logging: Saving edited row", { tableIndex, editState });
    if (!isRowValid(editState.rowData)) {
      console.error("error: Cannot save edit. Invalid input");
      return;
    }
    const updatedContent = [...content];
    const editedRowData = [
      editState.rowData.service,
      parseFloat(editState.rowData.monthlyCost).toFixed(2),
      parseFloat(editState.rowData.yearlyCost).toFixed(2),
      editState.rowData.comments,
    ];
    updatedContent[tableIndex].rows[editState.rowIndex] = editedRowData;
    recalculateTotals(updatedContent, tableIndex);
    setEditState({ tableIndex: null, rowIndex: null, rowData: {} });
    setValidationErrors({});

    callSaveApi(updatedContent);
  };

  const cancelEdit = () => {
    setEditState({ tableIndex: null, rowIndex: null, rowData: {} });
    setValidationErrors({});
  };

  const recalculateTotals = (updatedContent, tableIndex) => {
    console.log("debug: Recalculating totals", { tableIndex });
    const totalRowIndex = updatedContent[tableIndex].rows.length - 1;
    const totalRow = updatedContent[tableIndex].rows[totalRowIndex];
    let monthlyTotal = 0;
    let yearlyTotal = 0;
    updatedContent[tableIndex].rows.forEach((row, index) => {
      if (index !== totalRowIndex) {
        monthlyTotal += parseFloat(row[1]) || 0;
        yearlyTotal += parseFloat(row[2]) || 0;
      }
    });
    totalRow[1] = monthlyTotal.toFixed(2);
    totalRow[2] = yearlyTotal.toFixed(2);
    console.log("debug: Totals recalculated", { monthlyTotal, yearlyTotal });
  };

  const addTableFromUrl = async () => {
    console.log("logging: Adding table from URL", { approachName, url });
    if (
      Object.keys(validationErrors).length > 0 ||
      !approachName.trim() ||
      !url.trim()
    ) {
      console.error("error: Cannot add table. Invalid input or empty fields");
      return;
    }
    setIsLoading(true);
    try {
      // Simulating API call
      const response = await fetch(url);
      const scrapedData = await response.json();

      const newTable = {
        table_title: approachName,
        columns: [
          "Service/Software",
          "Monthly Cost",
          "Yearly Cost",
          "Comments",
        ],
        rows: [...scrapedData.rows, ["Total", "0", "0", ""]],
      };

      const updatedContent = [...content, newTable];
      setContent(updatedContent);
      setApproachNames([...approachNames, approachName.toLowerCase().trim()]);
      setApproachName("");
      setUrl("");
      setFormError("");
      console.log("logging: New table added successfully", newTable);

      callSaveApi(updatedContent);
    } catch (error) {
      console.error("error: Failed to fetch data from URL", error);
      setFormError("Error fetching data from URL");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTable = (tableIndex) => {
    console.log("logging: Deleting table", { tableIndex });
    const updatedContent = content.filter((_, index) => index !== tableIndex);
    setContent(updatedContent);
    const updatedApproachNames = updatedContent.map((item) =>
      item.table_title.toLowerCase().trim()
    );
    setApproachNames(updatedApproachNames);
    console.log("logging: Table deleted successfully");

    callSaveApi(updatedContent);
  };

  return (
    <div className="d-flex col-md-9">
      <div className="bg-white rounded-2 w-100 px-3">
        <h2 className="font-24 font-semibold heading-txt-color border-bottom py-3 mb-4">
          Future State Infrastructure
        </h2>

        {/* Table Insertion Form */}
        <div className="row mb-3">
          <div className="col-md-4">
            <label
              className="form-label text-dark font-14 font-medium"
              htmlFor="approachName"
            >
              Approach Name<span className="input-improtent-section">*</span>
            </label>
            <input
              type="text"
              className="form-control font-14 w-100 mt-2"
              placeholder="Enter Approach Name"
              value={approachName}
              onChange={(e) =>
                handleInputChange("approachName", e.target.value)
              }
              disabled={isLoading}
            />
            {validationErrors.approachName && (
              <div className="text-danger">{validationErrors.approachName}</div>
            )}
          </div>

          <div className="col-md-4">
            <label
              className="form-label text-dark font-14 font-medium"
              htmlFor="url"
            >
              URL<span className="input-improtent-section">*</span>
            </label>
            <input
              type="text"
              className="form-control font-14 w-100 mt-2"
              placeholder="Enter URL"
              value={url}
              onChange={(e) => handleInputChange("url", e.target.value)}
              disabled={isLoading}
            />
            {validationErrors.url && (
              <div className="text-danger">{validationErrors.url}</div>
            )}
          </div>

          <div className="col-md-4 align-items-middle mt-auto">
            <button
              className="btn primary-button focusable"
              onClick={addTableFromUrl}
              disabled={
                isLoading ||
                !approachName.trim() ||
                !url.trim() ||
                Object.keys(validationErrors).length > 0
              }
            >
              {isLoading ? "Loading..." : "Add"}
            </button>
          </div>
        </div>

        {formError && (
          <div className="row mb-5">
            <div className="text-danger col-md-12">{formError}</div>
          </div>
        )}

        {/* Existing Tables */}
        {content.map((table, tableIndex) => (
          <div key={tableIndex} className="col-md-12 mb-4">
            <div className="card p-3 custom-card mb-3">
              <div className="d-flex justify-content-between align-items-center accordion-btn-parent mb-4">
                <h2 className="accordion-header font-14 font-semibold mb-0">
                  {table.table_title}
                </h2>
                <div className="btn-container d-flex gap-3">
                  <button
                    className="border-0 p-0 action-icons delete-icon rounded-circle d-flex justify-content-center align-items-center"
                    onClick={() => deleteTable(tableIndex)}
                  >
                    <img src="images/cancel-red.svg" alt="delete" />
                  </button>
                </div>
              </div>
              <div className="table-responsive bg-white zp-table custom-spacing">
                <table className="table m-0 align-middle">
                  <thead>
                    <tr className="alter">
                      {table.columns.map((col, index) => (
                        <th key={index} className={index > 0 ? "text-end" : ""}>
                          {col}
                        </th>
                      ))}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* New Row Input */}
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Service/Software"
                          value={newRow.service}
                          onChange={(e) =>
                            handleInputChange(
                              "service",
                              e.target.value,
                              tableIndex
                            )
                          }
                          disabled={editState.tableIndex !== null}
                        />
                      </td>
                      <td className="text-end">
                        <div className="input-group mb-3">
                          <span className="input-group-text">$</span>
                          <NumericFormat
                            value={newRow.monthlyCost}
                            onValueChange={(values) =>
                              handleInputChange(
                                "monthlyCost",
                                values.value,
                                tableIndex
                              )
                            }
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            allowNegative={false}
                            className="form-control font-14"
                            placeholder="0.00"
                            disabled={editState.tableIndex !== null}
                          />
                        </div>
                      </td>
                      <td className="text-end">
                        <div className="input-group mb-3">
                          <span className="input-group-text">$</span>
                          <NumericFormat
                            value={newRow.yearlyCost}
                            onValueChange={(values) =>
                              handleInputChange(
                                "yearlyCost",
                                values.value,
                                tableIndex
                              )
                            }
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            allowNegative={false}
                            className="form-control font-14"
                            placeholder="0.00"
                            disabled={editState.tableIndex !== null}
                          />
                        </div>
                      </td>
                      <td className="comments-section">
                        <textarea
                          className="form-control"
                          placeholder="Enter Comments"
                          value={newRow.comments}
                          onChange={(e) =>
                            handleInputChange(
                              "comments",
                              e.target.value,
                              tableIndex
                            )
                          }
                          disabled={editState.tableIndex !== null}
                        />
                      </td>
                      <td className="text-center">
                        <button
                          className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center"
                          onClick={() => addRow(tableIndex)}
                          disabled={
                            !isRowValid(newRow) ||
                            Object.keys(validationErrors).length > 0 ||
                            editState.tableIndex !== null
                          }
                        >
                          <img src="images/add-row.svg" alt="Add" />
                        </button>
                      </td>
                    </tr>
                    {/* Existing Rows */}
                    {table.rows.map((row, rowIndex) => {
                      const isTotalRow = row[0] === "Total";
                      const isEditing =
                        editState.tableIndex === tableIndex &&
                        editState.rowIndex === rowIndex;
                      return (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td
                              key={cellIndex}
                              className={`${
                                cellIndex > 0 && cellIndex < 3 ? "text-end" : ""
                              } ${isTotalRow ? "fw-bold" : ""}`}
                            >
                              {isTotalRow ? (
                                cellIndex > 0 && cellIndex < 3 ? (
                                  <span className="d-block fw-bold">
                                    ${cell}
                                  </span>
                                ) : (
                                  <strong>{cell}</strong>
                                )
                              ) : isEditing ? (
                                cellIndex === 1 || cellIndex === 2 ? (
                                  <div className="input-group mb-3">
                                    <span className="input-group-text">$</span>
                                    <NumericFormat
                                      value={
                                        editState.rowData[
                                          cellIndex === 1
                                            ? "monthlyCost"
                                            : "yearlyCost"
                                        ]
                                      }
                                      onValueChange={(values) =>
                                        handleInputChange(
                                          cellIndex === 1
                                            ? "monthlyCost"
                                            : "yearlyCost",
                                          values.value,
                                          tableIndex
                                        )
                                      }
                                      thousandSeparator
                                      decimalScale={2}
                                      fixedDecimalScale
                                      allowNegative={false}
                                      className="form-control font-14"
                                      placeholder="0.00"
                                    />
                                  </div>
                                ) : cellIndex === 0 ? (
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={editState.rowData.service}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "service",
                                        e.target.value,
                                        tableIndex
                                      )
                                    }
                                  />
                                ) : (
                                  <textarea
                                    className="form-control"
                                    value={editState.rowData.comments}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "comments",
                                        e.target.value,
                                        tableIndex
                                      )
                                    }
                                  />
                                )
                              ) : cellIndex > 0 && cellIndex < 3 ? (
                                <span className="d-block">${cell}</span>
                              ) : (
                                cell
                              )}
                              {isEditing &&
                                cellIndex < 3 &&
                                validationErrors[
                                  cellIndex === 0
                                    ? "service"
                                    : cellIndex === 1
                                    ? "monthlyCost"
                                    : "yearlyCost"
                                ] && (
                                  <div className="text-danger">
                                    {
                                      validationErrors[
                                        cellIndex === 0
                                          ? "service"
                                          : cellIndex === 1
                                          ? "monthlyCost"
                                          : "yearlyCost"
                                      ]
                                    }
                                  </div>
                                )}
                            </td>
                          ))}
                          {!isTotalRow && (
                            <td className="text-center">
                              <div className="d-flex gap-3 justify-content-center">
                                {isEditing ? (
                                  <>
                                    <button
                                      className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center"
                                      onClick={() => saveEdit(tableIndex)}
                                      disabled={
                                        !isRowValid(editState.rowData) ||
                                        Object.keys(validationErrors).length > 0
                                      }
                                    >
                                      <img
                                        src="images/add-row.svg"
                                        alt="Save"
                                      />
                                    </button>
                                    <button
                                      className="border-0 p-0 action-icons delete-icon rounded-circle d-flex justify-content-center align-items-center"
                                      onClick={cancelEdit}
                                    >
                                      <img
                                        src="images/cancel-red.svg"
                                        alt="Cancel"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center"
                                      onClick={() =>
                                        startEditing(tableIndex, rowIndex, row)
                                      }
                                    >
                                      <img src="images/edit.svg" alt="Edit" />
                                    </button>
                                    <button
                                      className="border-0 p-0 action-icons delete-icon rounded-circle d-flex justify-content-center align-items-center"
                                      onClick={() => {
                                        console.log("logging: Deleting row", {
                                          tableIndex,
                                          rowIndex,
                                        });
                                        const updatedContent = [...content];
                                        updatedContent[tableIndex].rows.splice(
                                          rowIndex,
                                          1
                                        );
                                        recalculateTotals(
                                          updatedContent,
                                          tableIndex
                                        );
                                        setContent(updatedContent);
                                        console.log(
                                          "logging: Row deleted successfully"
                                        );

                                        callSaveApi(updatedContent);
                                      }}
                                    >
                                      <img
                                        src="images/cancel-red.svg"
                                        alt="Delete"
                                      />
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiRecordTableTemplate;
