import { useNavigate } from "react-router-dom"
import { useOrgContext } from "../Context/Context";

function SessionTimeOutPopup({ onClose }) {
    const navigate=useNavigate()
     const { sessionTimeoutPopup, setSessionTimeoutPopup } = useOrgContext();

     
    
    return (
        <>
        {sessionTimeoutPopup && (
              <div
              className="modal fade show modal-lg-only loader-popup-container configure-limit-container"
              id="timeout"
              //   tabIndex={-1}
              //   aria-labelledby="loaderModalLabel"
              //   aria-hidden="true"
              style={{ display: 'block', backdropFilter: 'blur(5px)', backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: '10000' }}
          >

              <div className="modal-dialog">
                  <div className="modal-content vh-75 overflow-hidden radius-6 border-0">
                      <div className="modal-header border-0 p-4">
                          <img src="images/timeout.svg" alt="" className="me-3" />
                          <h4 className="mb-0 font-18 font-semibold text-grey-v1" style={{paddingRight:'59%'}}>
                              Session Timeout
                          </h4>                         
                      </div>
                      <div className="p-4 pt-1">
                          <p className="font-14 text-color w-75 ">
                              Your Session has been logged out due to inactivity, Please login again
                              to access the application.
                          </p>
                          <div className="col-md-12">
                              <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                                  <button className="btn primary-button py-2 px-4" onClick={(()=>{
                                      navigate('/')
                                      localStorage.clear()
                                      sessionStorage.clear()
                                      setSessionTimeoutPopup(false)
                                      
                                  }
                                      )}> OK</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

        )}
          

        </>
    )
}
export default SessionTimeOutPopup