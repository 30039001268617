
// import React, { useEffect, useState } from "react";
// import PopupComponent from "./PopupComponent";
// import { useNavigate } from "react-router-dom";

// export default function WorkflowPreprocess(props) {

//     let { preProcess, assessmentId, workflowId, sessionId,getActionItem } = props
//     const [isPopupOpen, setIsPopupOpen] = useState(false);
//     const [popupCase, setPopupCase] = useState("");
//     const [editedAttributeData, setEditedAttributeData] = useState([]);
//     const [reason, setReason] = useState("");
//     const [editModeId, setEditModeId] = useState(null);
//     const userId = localStorage.getItem('userId');
//     const navigate=useNavigate()

//     console.log(preProcess, "Preprocess");
//     console.log(editedAttributeData, "editedPreprocess");

//     // Update state when preProcess prop changes
//     useEffect(() => {
//         if (preProcess && preProcess.length > 0) {
//             setEditedAttributeData([...preProcess]); // Create a new copy
//         }
//     }, [preProcess]);

//     // Handle edit button click
//     const handleEdit = (attributeId) => {
//         setEditModeId(attributeId);
//     };

//     // Handle checkbox state changes
//     const handleCheck = (attributeId, checklistId, isChecked) => {
//         const updatedData = editedAttributeData.map((attribute) =>
//             attribute.attribute_id === attributeId
//                 ? {
//                     ...attribute,
//                     checklist: attribute.checklist.map((checklistItem) =>
//                         checklistItem.checklist_id === checklistId
//                             ? { ...checklistItem, is_checked: isChecked }
//                             : checklistItem
//                     ),
//                 }
//                 : attribute
//         );
//         setEditedAttributeData(updatedData);
//     };

//     // Prepare to skip a checklist item
//     const handleSkip = () => {
//         setPopupCase("SkipChecklist");
//         setIsPopupOpen(true);
//     };

//     const handleSkipConfirm = (attributeId, checklistId) => {
//         const updatedData = editedAttributeData.map((attribute) =>
//             attribute.attribute_id === attributeId
//                 ? {
//                     ...attribute,
//                     checklist: attribute.checklist.map((checklistItem) =>
//                         checklistItem.checklist_id === checklistId
//                             ? { ...checklistItem, is_skip: true, is_checked: false, reason: reason }
//                             : checklistItem
//                     ),
//                 }
//                 : attribute
//         );
//         setEditedAttributeData(updatedData);
//         setIsPopupOpen(false);
//     };

//     // Handle adding/viewing comments
//     const handleComments = (reason) => {
//         setPopupCase("comments");
//         setIsPopupOpen(true);
//         setReason(reason);
//     };

//     // Resume a skipped checklist item
//     const handlePlay = (attributeId, checklistId) => {
//         const updatedData = editedAttributeData.map((attribute) =>
//             attribute.attribute_id === attributeId
//                 ? {
//                     ...attribute,
//                     checklist: attribute.checklist.map((checklistItem) =>
//                         checklistItem.checklist_id === checklistId
//                             ? { ...checklistItem, is_skip: false }
//                             : checklistItem
//                     ),
//                 }
//                 : attribute
//         );
//         setEditedAttributeData(updatedData);
//     };

//     // Handle file upload for an attribute
//     const handleFileUpload = (attributeId, file) => {
//         console.log(file,"file");
        
//         const validFileTypes = [".jpeg", ".png", ".jpg", ".pdf", ".doc", ".docx", ".xlsx", ".pptx", ".txt"];
//         const fileType = file.name.split(".").pop();
    
//         if (!validFileTypes.includes(`.${fileType}`)) {
//           console.error("Invalid file type");
//           return;
//         }
    
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           const base64String = reader.result.split(",")[1];
    
//           const updatedData = editedAttributeData.map((attribute) =>
//             attribute.attribute_id === attributeId
//               ? { ...attribute, file_name: file.name, file_size: `${(file.size / 1024).toFixed(2)} KB`, file_data: base64String }
//               : attribute
//           );
    
//           setEditedAttributeData(updatedData);
//         };
    
//         reader.readAsDataURL(file);
//       };

//     // Handle save action item
//     const saveActionItem = async (attributeId) => {
        
//         try {
//             console.log(editedAttributeData);
            
//             const attribute = editedAttributeData.find((attr) => attr.attribute_id === attributeId);

//             const payload = {
//                 user_id: 123, // Replace with actual user ID
//                 assessment_id: 456, // Replace with actual assessment ID
//                 workflow_id: 789, // Replace with actual workflow ID
//                 session_id: 101112, // Replace with actual session ID
//                 action_items: {
//                     action_item_id: attribute.attribute_id,
//                     action_item_name: attribute.attribute_name,
//                     file_name: attribute.file_name,
//                     file_size: attribute.file_size,
//                     checklist: attribute.checklist,
//                 },
//             };
//             console.log(payload,"payload");
            

//             //   const response = await axios.post("/workflow/saveActionItem", payload);

//             //   if (response.status === 200) {
//             //     // Call getActionItems to refresh action items
//             //     // if isAutomated === true, Call orchestrationAgentApi with payload
//             //   }
//             setEditModeId(null);
//             getActionItem()
//         } catch (error) {
//             console.error("Error saving action item", error);
//             // Handle error (e.g., calling handleError)
//         }
//     };

//     // Render the preprocess data
//     const bindPreprocess = () => {
//         return editedAttributeData.map((attribute, idx) => (
//             <div className="card p-4 custom-card mb-3" key={idx}>
//                 <div className="d-flex justify-content-between align-items-center">
//                     <h3 className="font-16 font-semibold">{attribute.attribute_name}</h3>

//                     <button className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center"
//                         onClick={() => handleEdit(attribute.attribute_id)}>
//                         {/* {editModeId === attribute.attribute_id ? "Editing" : "Edit"} */}
//                         <img src="images/edit.svg" alt="edit-icon" />
//                     </button>


//                 </div>
//                 <div className="checklist-container mt-2">
//                     <div className="pb-4">
//                         {!attribute.file_name ? (
//                             <div class="d-flex gap-3">
//                                 <button
//                                     className="btn file-upload-chat-area link-color"
//                                     onClick={() => document.getElementById(`file-input-${attribute.attribute_id}`).click()}
//                                     disabled={!editModeId || editModeId !== attribute.attribute_id}
//                                 >
//                                     <img src="images/uplode-icon.svg" alt="" /> Upload
//                                     <input
//                                         type="file"
//                                         id={`file-input-${attribute.attribute_id}`}
//                                         style={{ display: "none" }}
//                                         onChange={(e) => handleFileUpload(attribute.attribute_id, e.target.files[0])}
//                                         disabled={!editModeId || editModeId !== attribute.attribute_id}
//                                     />
//                                 </button>
//                                 {/* Condition to display Open Workbench */}
//                                 {(attribute.attribute_name === "Business Flow" || attribute.attribute_name === "Architecture") && editModeId === attribute.attribute_id && (
//                                     <button className="btn outlined-button"
//                                     onClick={() => {
//                                         navigate('/ArchitectureWorkbench', {
//                                             state: {
//                                                 attribute_name: attribute.attribute_name,
//                                                 assessmentId: assessmentId,
//                                                 workflowId: workflowId,
//                                                 attribute_id: attribute.attribute_id
//                                             }
//                                         });
//                                     }}
//                                     >
//                                         Open Workbench
//                                     </button>
//                                 )}
//                             </div>
//                         ) : (
//                             <div className="uploaded-file d-inline-flex p-3 rounded-2">
//                                 <img className="pe-3" src="images/ppt.svg" alt="" />
//                                 <div className="d-flex flex-column">
//                                     <span className="font-14 font-medium">{attribute.file_name}</span>
//                                     <span className="font-12">{attribute.file_size}</span>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                     <h3 className="font-16 font-semibold">Checklist</h3>
//                     <ul className="ps-0 mb-0">
//                         {attribute.checklist.map((checklistItem, idx) => (
//                             <li
//                                 className={`d-flex justify-content-between align-items-center font-14 custom-list ${checklistItem.is_skip && !checklistItem.is_checked =="true" ? "text-decoration-line-through grey-v2" : ""
//                                     }`}
//                                 key={idx}
//                             >
//                                 <div className="d-flex gap-2 align-items-center">
//                                     <input
//                                         type="checkbox"
//                                         className="custom-checkbox checkbox-theme"
//                                         checked={checklistItem.is_checked}
//                                         onChange={(e) =>
//                                             handleCheck(attribute.attribute_id, checklistItem.checklist_id, e.target.checked)
//                                         }
//                                         disabled={!editModeId || editModeId !== attribute.attribute_id}
//                                     />
//                                     <span>{checklistItem.checklist_item}</span>
//                                 </div>
//                                 {editModeId === attribute.attribute_id && (
//                                     <div className="d-flex justify-content-end gap-3">
//                                         {checklistItem.is_skip ? (
//                                             <>
//                                                 <button
//                                                     className="border-0 p-0 action-icons comment-icon rounded-circle d-flex justify-content-center align-items-center"
//                                                     onClick={() => handleComments(checklistItem.reason)}
//                                                 >
//                                                     <img src="images/comments.svg" alt="comments" />
//                                                 </button>
//                                                 <button
//                                                     className="border-0 p-0 action-icons active-line-icon rounded-circle d-flex justify-content-center align-items-center"
//                                                     onClick={() => handlePlay(attribute.attribute_id, checklistItem.checklist_id)}
//                                                 >
//                                                     <img src="images/active-line-item.svg" alt="play" />
//                                                 </button>
//                                             </>
//                                         ) : (
//                                             <button
//                                                 className="border-0 p-0 action-icons skip-icon rounded-circle d-flex justify-content-center align-items-center"
//                                                 onClick={() => handleSkip(attribute.attribute_id, checklistItem.checklist_id)}
//                                             >
//                                                 <img src="images/skip_assessment.svg" alt="skip-icon" />
//                                             </button>
//                                         )}
//                                     </div>
//                                 )}
//                             </li>
//                         ))}
//                     </ul>
//                     {editModeId === attribute.attribute_id && (
//                         <div className="d-flex justify-content-end mt-5 gap-2">
//                             <button className="btn btn-light secondary-button" onClick={handleCancel}>
//                                 Cancel
//                             </button>
//                             <button className="btn primary-button" onClick={() => saveActionItem(attribute.attribute_id)}>
//                                 Save
//                             </button>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         ));
//     };

//     // Handle cancel button click
//     const handleCancel = () => {
//         setEditModeId(null);
//         setEditedAttributeData(preProcess);
//     };

//     return (
//         <div>
//             {bindPreprocess()}
//             {isPopupOpen &&
//                 <PopupComponent
//                     case={popupCase}
//                     reason={reason}
//                     onConfirm={handleSkipConfirm}
//                     onClose={() => setIsPopupOpen(false)}
//                     setReason={setReason}
//                 />}
//         </div>
//     );
// }


import React, { useEffect, useState } from 'react';
import PopupComponent from './PopupComponent';
import { useNavigate } from 'react-router-dom';

export default function WorkflowPreprocess(props) {
  const { preProcess, assessmentId, workflowId, sessionId, getActionItem,assessmentName } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupCase, setPopupCase] = useState('');
  const [editedAttributeData, setEditedAttributeData] = useState([]);
  const [reason, setReason] = useState('');
  const [editModeId, setEditModeId] = useState(null);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const [skipDetails, setSkipDetails] = useState(null);

  console.log(preProcess, 'Preprocess');
  console.log(editedAttributeData, 'editedPreprocess');

  // Update state when preProcess prop changes
  useEffect(() => {
    if (preProcess && preProcess.length > 0) {
      setEditedAttributeData([...preProcess]); // Create a new copy
    }
  }, [preProcess]);

  // Handle edit button click
  const handleEdit = (attributeId) => {
    setEditModeId(attributeId);
  };

  // Handle checkbox state changes
  const handleCheck = (attributeId, checklistId, isChecked) => {
    const updatedData = editedAttributeData.map((attribute) =>
      attribute.attribute_id === attributeId
        ? {
            ...attribute,
            checklist: attribute.checklist.map((checklistItem) =>
              checklistItem.checklist_id === checklistId
                ? { ...checklistItem, is_checked: isChecked }
                : checklistItem
            )
          }
        : attribute
    );
    setEditedAttributeData(updatedData);
  };

  // Prepare to skip a checklist item
  const handleSkip = (attributeId, checklistId) => {
    setPopupCase('SkipChecklist');
    setSkipDetails({ attributeId, checklistId });
    setIsPopupOpen(true);
  };

  // Confirm skip action
  const handleSkipConfirm = () => {
    const { attributeId, checklistId } = skipDetails;
    const updatedData = editedAttributeData.map((attribute) =>
      attribute.attribute_id === attributeId
        ? {
            ...attribute,
            checklist: attribute.checklist.map((checklistItem) =>
              checklistItem.checklist_id === checklistId
                ? { ...checklistItem, is_skip: true, is_checked: false, reason: reason }
                : checklistItem
            )
          }
        : attribute
    );
    setEditedAttributeData(updatedData);
    setIsPopupOpen(false);
    setSkipDetails(null);
    setReason('');
  };

  // Handle adding/viewing comments
  const handleComments = (reason) => {
    setPopupCase('comments');
    setReason(reason);
    setIsPopupOpen(true);
  };

  // Resume a skipped checklist item
  const handlePlay = (attributeId, checklistId) => {
    const updatedData = editedAttributeData.map((attribute) =>
      attribute.attribute_id === attributeId
        ? {
            ...attribute,
            checklist: attribute.checklist.map((checklistItem) =>
              checklistItem.checklist_id === checklistId
                ? { ...checklistItem, is_skip: false }
                : checklistItem
            )
          }
        : attribute
    );
    setEditedAttributeData(updatedData);
  };

  // Handle file upload for an attribute
  const handleFileUpload = (attributeId, file) => {
    const validFileTypes = ['.jpeg', '.png', '.jpg', '.pdf', '.doc', '.docx', '.xlsx', '.pptx', '.txt'];
    const fileType = file.name.split('.').pop();

    if (!validFileTypes.includes(`.${fileType}`)) {
      console.error('Invalid file type');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];

      const updatedData = editedAttributeData.map((attribute) =>
        attribute.attribute_id === attributeId
          ? {
              ...attribute,
              file_name: file.name,
              file_size: `${(file.size / 1024).toFixed(2)} KB`,
              file_data: base64String
            }
          : attribute
      );

      setEditedAttributeData(updatedData);
    };

    reader.readAsDataURL(file);
  };

  // Handle save action item
  const saveActionItem = async (attributeId) => {
    try {
      const attribute = editedAttributeData.find((attr) => attr.attribute_id === attributeId);

      const payload = {
        user_id: userId,
        assessment_id: assessmentId,
        workflow_id: workflowId,
        session_id: sessionId,
        action_item_id: attribute.attribute_id,
        action_item_name: attribute.attribute_name,
        checklist: attribute.checklist,
        data: {
          file: attribute.file_data || '',
          description: 'text' // Replace with actual description if available
        }
      };

      console.log(payload, 'payload');

      // Call the save API (currently commented out)
      // const response = await axios.post("/workflow/saveActionItem", payload);

      // if (response.status === 200) {
      //   // Call getActionItems to refresh action items
      //   // if isAutomated === true, Call orchestrationAgentApi with payload
      // }
      setEditModeId(null);
      getActionItem();
    } catch (error) {
      console.error('Error saving action item', error);
      // Handle error (e.g., calling handleError)
    }
  };

  // Determine file icon based on file extension
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'images/ppt.svg';
      case 'doc':
      case 'docx':
        return 'images/docx.svg';
      case 'xls':
      case 'xlsx':
        return 'images/xls.svg';
      case 'ppt':
      case 'pptx':
        return 'images/ppt.svg';
      default:
        return 'images/file-icon.svg';
    }
  };

  // Render the preprocess data
  const bindPreprocess = () => {
    return editedAttributeData.map((attribute, idx) => (
      <div className="card p-4 custom-card mb-3" key={idx}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font-16 font-semibold">{attribute.attribute_name}</h3>
          <button
            className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center"
            onClick={() => handleEdit(attribute.attribute_id)}
          >
            <img src="images/edit.svg" alt="edit-icon" />
          </button>
        </div>
        <div className="checklist-container mt-2">
          <div className="pb-4">
            {!attribute.file_name ? (
              <div className="d-flex gap-3">
                <button
                  className="btn file-upload-chat-area link-color"
                  onClick={() => document.getElementById(`file-input-${attribute.attribute_id}`).click()}
                  disabled={!editModeId || editModeId !== attribute.attribute_id}
                >
                  <img src="images/uplode-icon.svg" alt="" /> Upload
                  <input
                    type="file"
                    id={`file-input-${attribute.attribute_id}`}
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileUpload(attribute.attribute_id, e.target.files[0])}
                    disabled={!editModeId || editModeId !== attribute.attribute_id}
                  />
                </button>
                {/* Condition to display Open Workbench */}
                {(attribute.attribute_name === 'Architecture') &&
                  editModeId === attribute.attribute_id && (
                    <button
                      className="btn outlined-button"
                      onClick={() => {
                        navigate('/ArchitectureWorkbench', {
                          state: {
                            attribute_name: attribute.attribute_name,
                            assessmentId: assessmentId,
                            workflowId: workflowId,
                            attribute_id: attribute.attribute_id,
                            assessmentName: assessmentName,
                          }
                        });
                      }}
                    >
                      Open Workbench
                    </button>
                  )}
                   {(attribute.attribute_name === 'Business Flow') &&
                  editModeId === attribute.attribute_id && (
                    <button
                      className="btn outlined-button"
                      onClick={() => {
                        navigate('/Workbench', {
                          state: {
                            attribute_name: attribute.attribute_name,
                            assessmentId: assessmentId,
                            workflowId: workflowId,
                            attribute_id: attribute.attribute_id,
                            assessmentName: assessmentName,
                          }
                        });
                      }}
                    >
                      Open Workbench
                    </button>
                  )}
              </div>
            ) : (
              <div className="uploaded-file d-inline-flex p-3 rounded-2">
                <img className="pe-3" src={getFileIcon(attribute.file_name)} alt="" />
                <div className="d-flex flex-column">
                  <span className="font-14 font-medium">{attribute.file_name}</span>
                  <span className="font-12">{attribute.file_size}</span>
                </div>
              </div>
            )}
          </div>
          <h3 className="font-16 font-semibold">Checklist</h3>
          <ul className="ps-0 mb-0">
            {attribute.checklist.map((checklistItem, idx) => (
              <li
                className={`d-flex justify-content-between align-items-center font-14 custom-list ${
                  checklistItem.is_skip && !checklistItem.is_checked ? 'text-decoration-line-through grey-v2' : ''
                }`}
                key={idx}
              >
                <div className="d-flex gap-2 align-items-center">
                  <input
                    type="checkbox"
                    className="custom-checkbox checkbox-theme"
                    checked={checklistItem.is_checked}
                    onChange={(e) => handleCheck(attribute.attribute_id, checklistItem.checklist_id, e.target.checked)}
                    disabled={!editModeId || editModeId !== attribute.attribute_id}
                  />
                  <span>{checklistItem.checklist_item}</span>
                </div>
                {editModeId === attribute.attribute_id && (
                  <div className="d-flex justify-content-end gap-3">
                    {!checklistItem.is_skip && (
                      <button
                        className="border-0 p-0 action-icons skip-icon rounded-circle d-flex justify-content-center align-items-center"
                        onClick={() => handleSkip(attribute.attribute_id, checklistItem.checklist_id)}
                      >
                        <img src="images/skip_assessment.svg" alt="skip-icon" />
                      </button>
                    )}
                    {checklistItem.is_skip && (
                      <>
                        <button
                          className="border-0 p-0 action-icons comment-icon rounded-circle d-flex justify-content-center align-items-center"
                          onClick={() => handleComments(checklistItem.reason)}
                        >
                          <img src="images/comments.svg" alt="comments" />
                        </button>
                        <button
                          className="border-0 p-0 action-icons active-line-icon rounded-circle d-flex justify-content-center align-items-center"
                          onClick={() => handlePlay(attribute.attribute_id, checklistItem.checklist_id)}
                        >
                          <img src="images/active-line-item.svg" alt="play" />
                        </button>
                      </>
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
          {editModeId === attribute.attribute_id && (
            <div className="d-flex justify-content-end mt-5 gap-2">
              <button className="btn btn-light secondary-button" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn primary-button" onClick={() => saveActionItem(attribute.attribute_id)}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    ));
  };

  // Handle cancel button click
  const handleCancel = () => {
    setEditModeId(null);
    setEditedAttributeData(preProcess);
  };

  return (
    <div>
      {bindPreprocess()}
      {isPopupOpen && (
        <PopupComponent
          case={popupCase}
          reason={reason}
          onConfirm={handleSkipConfirm}
          onClose={() => setIsPopupOpen(false)}
          setReason={setReason}
        />
      )}
    </div>
  );
}