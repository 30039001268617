import { useNavigate } from "react-router-dom";
import BreadCrumbs from "./breadCrumb";
import { useEffect, useState } from "react";
import { getProjectReportData, postSummaryData, postTranscriptDatabricks } from "../service/ReportApi";
import FlowComponents from "./reportComponents/FlowComponents";
import Loader from "./Loader";
import CostEstimation from "./reportComponents/CostEstimation";
import MigrationPlan from "./MigrationPlan";
import ChallengesRecommendations from "./reportComponents/ChallengesRecommendations";
import FutureStateArchitecture from "./FutureStateArchitecture";
import SWOT from "./Swot";
import Conclusion from "./Conclusion";
import CurrentDataEnvironmentInventory from "./reportComponents/CurrentDataEnvironmentInventory";
import { fileDataPayloadModel, postSummaryModel, uploadTranscriptDatabricksModel, wafrFileInfoModel } from "../interface/WafrReportModel";
import UploadTranscriptDatabricks from "./reportComponents/UploadTranscriptDatabricks";
import ExecutiveSummary from "./reportComponents/ExecutiveSummary";
import ExecutiveSummaryRating from "./reportComponents/ExecutiveSummaryRating";
import { GenerateAiChallengesRecommendation, GenerateAiInventory, PostDatawarehouseArchitectureAi, PostInventoryCountDetails } from "../service/QuestionnaireApi";
import DownloadReportModal from "./reportComponents/DownloadReportPopup";


const DataBricksReport = ({ initialReportData }: any) => {

    const initialFileInfo: wafrFileInfoModel = {
        fileData: "",
        fileName: "",
        fileType: "",
        base64Transcript: ""
    }

    //PS_DatawarehouseArchitecture_01 - PS_DatawarehouseArchitecture_26
    const navigate = useNavigate()
    const [selectedNavBar, setSelectedNavBar] = useState<string>("uploadTranscript")
    const [reportData, setReportData] = useState<any>(initialReportData)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [execSummary, setExecSummary] = useState<string>("")
    const [businessOverview, setBusinessOverview] = useState<string>("")
    const [showToasterLoading, setShowToasterLoading] = useState(false);
    const [showToasterPopup, setShowToasterPopup] = useState(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [showSessionToasterPopup, setShowSessionToasterPopup] = useState(false);
    const [conclusionData, setConclusionData] = useState<any>(reportData.conclusion || []);
    const [hideToast, setHideToast] = useState(true);
    const [toastProp, setToastProp] = useState({
        toastType: '',
        toastHeaderMessage: '',
        toastBodyMessage: '',
    });
    const [isSummaryModified, setIsSummaryModified] = useState(false);
    const [isChallengesModified, setIsChallengesModified] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<string[]>([]);



    const breadCrumbs =
        [
            { title: "Organization", path: "/homepage", isActive: true },
            { title: initialReportData.organizationName, path: "/manageassessment", isActive: true, state: { organizationId: initialReportData.organizationId, organizationName: initialReportData.organizationName } },
            { title: initialReportData.verticalName, path: "", isActive: true },
            { title: "Report", path: "", isActive: false },
        ]

    //PS_DatawarehouseArchitecture_27 - PS_DatawarehouseArchitecture_43
    const BindMenus = () => {
        return reportData.menus?.map((data: any) => {
            return (
                <button
                    className={`nav-link text-start rounded-0 font-medium font-14 text-truncate menu-list ${selectedNavBar === data.menuName ? "active" : ""}`}
                    key={data.menuId}
                    id={data.menuName}
                    type="button"
                    role="tab"
                    onClick={() => { setSelectedNavBar((prev) => data.menuName) }}
                    title={data.menuName}
                >
                    {data.menuName}
                </button>
            );
        });
    };

    

    //PS_Report_Category_Selection_01, PS_Report_Category_Selection_02
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    //PS_Report_Category_Selection_17
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    //PS_Report_Category_Selection_09, 10 - Define a method handleOptionChange whether the user selects All or Specific Categories
    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'all') {
            setSelectedCategory(reportData.menus.map((menu: any) => menu.menuName));
        } else {
            setSelectedCategory([]);
        }
    };
    //PS_Report_Category_Selection_11,12
    const handleCategoryChange = (menuName: string) => {
        setSelectedCategory(prev => {
            if (prev.includes(menuName)) {
                return prev.filter(category => category !== menuName);
            } else {
                return [...prev, menuName];
            }
        });
    };
    //PS_Report_Category_Selection_13, 14
    const removeCategory = (category: string) => {
        setSelectedCategory(prev => prev.filter(c => c !== category));
    };
    //PS_Report_Category_Selection_15, 16
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    //PS_Report_Category_Selection_18
    const handleProceedDownload = async (option: string, category: string[]) => {
        //PS_Report_Category_Selection_19
        setSelectedOption(option)
        setSelectedCategory(category)
        setIsModalOpen(false);
        //PS_Report_Category_Selection_20
        let body = {
            organizationId: reportData.organizationId,
            projectId: reportData.projectId,
            projectName: reportData.projectName,
            verticalId: reportData.verticalId,
            verticalName: reportData.verticalName,
            isViewReport: true
        };
        //PS_Report_Category_Selection_21,22
        try {
            let response = await getProjectReportData(body);
            if (response.status === 200) {
                navigate("/previewpage", {
                    state: {
                        data: response.data,
                        selectedOption: selectedOption,
                        selectedCategory: selectedCategory
                    },
                });
            } else {
                // Handle error
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    const postTranscript = async (fileDataPayload: fileDataPayloadModel) => {
        let payload: uploadTranscriptDatabricksModel = {
            organizationId: reportData.organizationId,
            projectId: reportData.projectId,
            verticalId: reportData.verticalId,
            verticalName: reportData.verticalName,
            url: [],
            recommendations: [],
            isSummary: true,
            fileName: fileDataPayload.fileName,
            fileType: fileDataPayload.fileType,
            fileData: fileDataPayload.fileData
        };

        setShowToasterLoading(true);

        try {
            const response = await postTranscriptDatabricks(payload)
            if (response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true);
                await new Promise(resolve => setTimeout(resolve, 1000));
                setExecSummary(response?.data?.executiveSummary)
                setBusinessOverview(response?.data?.businessOverview)
                setConclusionData([])
                setReportData((prevData: any) => ({
                    ...prevData,
                    executiveSummary: response?.data?.executiveSummary,
                    businessOverview: response?.data?.businessOverview
                }));

                let connectorId = '';
                let connectorName = '';
                let noteBookConnectorId = reportData.connectors.find((c: { connectorName: string }) => c.connectorName === "Inventory")?.connectorId;
                if (reportData.isAWS) {
                    const awsConnector = reportData.connectors.find((c: { connectorName: string; }) => c.connectorName === "AWS");
                    connectorId = awsConnector?.connectorId || '';
                    connectorName = awsConnector?.connectorName || '';
                } else if (reportData.isAzure) {
                    const azureConnector = reportData.connectors.find((c: { connectorName: string; }) => c.connectorName === "Azure");
                    connectorId = azureConnector?.connectorId || '';
                    connectorName = azureConnector?.connectorName || '';
                }
                const datawarehousePayload = {
                    organizationId: reportData.organizationId,
                    verticalId: reportData.verticalId,
                    projectId: reportData.projectId,
                    businessOverview: response?.data?.businessOverview
                };

                const inventoryPayload = {
                    organizationId: reportData.organizationId,
                    verticalId: reportData.verticalId,
                    connectorId: connectorId,
                    ConnectorName: connectorName,
                    noteBookConnectorId:noteBookConnectorId,
                    projectId: reportData.projectId,
                    businessOverview: response?.data?.businessOverview
                };

                const challengesRecommendationPayload = {
                    OrganizationID: reportData.organizationId,
                    VerticalID: reportData.verticalId,
                    ProjectID: reportData.projectId,
                    VerticalName: reportData.verticalName,
                    BusinessOverview: response?.data?.businessOverview
                };

                // Fire off API calls without waiting for their responses
                PostDatawarehouseArchitectureAi(datawarehousePayload).catch(() => { });
                GenerateAiInventory(inventoryPayload).catch(() => { }); // Assuming same payload as PostDatawarehouseArchitectureAi
                GenerateAiChallengesRecommendation(challengesRecommendationPayload).catch(() => { });
            }
            else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false)
        } finally {
            setIsLoading(false);
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }


    }

    const regex = /"|[*]/g;


    const escapeJsonString = (str: string) => {
        return str.replace(/"/g, '\\"')
    }

    //PS_DatawarehouseArchitecture_70 - PS_DatawarehouseArchitecture_79
    const postSummary = async (key: string, value: string) => {
        value = value ? escapeJsonString(value?.trim().replace(regex, "")) : ""
        let payload: postSummaryModel = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            attributeName: key === "executiveSummary" ? "Executive Summary" : "Business Overview",
            attributeValue: value.trim(),
            attributeId: ""
        }
        setShowToasterLoading(true);

        try {
            const response = await postSummaryData(payload)
            if (response.status === 200) {
                setShowToasterPopup(true);
                setIsLoading(true);
                await new Promise(resolve => setTimeout(resolve, 2000));
                if (key === "executiveSummary")
                    setExecSummary(value?.trim())
                else
                    setBusinessOverview(value?.trim())
                setConclusionData([]);

            }
            else {
                setIsLoading(false);
                let toastData = {
                    toastType: 'error',
                    toastHeaderMessage: 'Error',
                    toastBodyMessage: 'API failed'
                }
                setToastProp(toastData)
                setHideToast(false);
            }
        }
        catch (error) {
            setShowToasterFailedPopup(true);
            setIsLoading(false);
        } finally {
            setIsLoading(false)
            setShowToasterLoading(false);
            setTimeout(() => {
                setShowToasterPopup(false);
                setShowToasterFailedPopup(false);
            }, 4000);
        }
    }

    return (
        <div>
            {/* top header starts here */}

            {/* top header ends here */}
            <div className="container-fluid" style={{ marginTop: "105px" }}>
                <div className="row justify-content-center">
                    <div className="px-4">
                        {/* breadcrumb starts here */}
                        <BreadCrumbs data={breadCrumbs}></BreadCrumbs>
                        {/* breadcrumb ends here */}
                        {/* heading content starts here */}
                        <div className="d-block d-md-flex flex-wrap justify-content-between align-items-center  mb-4">
                            <div className="d-flex align-items-center mb-3 mb-md-0">
                                <a href="#"><img src="images/backarrow.svg" alt="back-arrow" title="Back" onClick={(event: any) => {
                                    event.preventDefault();
                                    navigate("/manageassessment", { state: { organizationId: initialReportData.organizationId, organizationName: initialReportData.organizationName } })
                                }} /></a>
                                <h2 className="font-22 font-semibold color-black mb-0 ms-3 ">
                                    Databricks Assessment
                                </h2>
                            </div>
                            <div className="d-grid gap-3 d-md-inline-flex justify-content-md-end">
                                <button type="button" className="btn btn-dark theme-primary-btn-md d-flex gap-2 align-items-center border-0 font-14 font-semibold"
                                    onClick={handleOpenModal}
                                >
                                    <img src="images/dwnld-icon-white.svg" alt="download-icon" />Download Report
                                </button>
                            </div>
                        </div>
                        {/* heading content ends here */}
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-3 col-xl-2">
                                <div className="row">
                                    <div className="d-lg-flex d-none pe-0 align-items-start v-tab-border left-nav-brd">
                                        <div className="nav flex-column nav-pills custom-v-pills w-100" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <p className="font-16 font-semibold mb-lg-2 mb-2 ps-0 ps-lg-3">Prerequisite</p>
                                            <button className={`nav-link text-start rounded-0 font-medium font-14 ${selectedNavBar === "uploadTranscript" ? "active" : ""}`} data-bs-toggle="pill" id="uploadTranscript" type="button" role="tab" aria-selected="true" onClick={(event: any) => { setSelectedNavBar(event.target.id) }}>Upload Transcript</button>                                            <p className="font-16 font-semibold my-lg-2  my-2 ps-0 ps-lg-3">Report</p>
                                            {BindMenus()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-9 col-xl-10 Nav-page-scrl">
                                <div className="row">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        {selectedNavBar === "uploadTranscript" &&
                                            <UploadTranscriptDatabricks
                                                postTranscript={postTranscript}
                                                showToasterLoading={showToasterLoading}
                                                showToasterPopup={showToasterPopup}
                                                showToasterFailedPopup={showToasterFailedPopup}
                                                verticalName={"Databricks"} />
                                        }

                                        {selectedNavBar === "Executive Summary" &&
                                            <ExecutiveSummaryRating
                                                verticalName="Databricks"
                                                summaryName="Executive Summary"
                                                summary={execSummary}
                                                postSummary={postSummary.bind(null, "executiveSummary")}
                                                reportData={reportData}
                                                showToasterLoading={showToasterLoading}
                                                showToasterPopup={showToasterPopup}
                                                showToasterFailedPopup={showToasterFailedPopup}
                                                hideToast={hideToast}
                                                toastProp={toastProp}
                                                showSessionToasterPopup={showSessionToasterPopup}
                                                setIsLoading={setIsLoading}
                                            />}

                                        {selectedNavBar === "Business Overview" && <ExecutiveSummary summaryName={"Business Overview"}
                                            summary={businessOverview}
                                            postSummary={postSummary.bind(null, "businessOverview")}
                                            verticalName="Databricks" showToasterPopup={showToasterPopup}
                                            showToasterFailedPopup={showToasterFailedPopup}
                                            hideToast={hideToast}
                                            toastProp={toastProp}
                                             />}
                                        {selectedNavBar === "Business Flow" && (
                                            <FlowComponents type="Business Flow" title="Business Flow"
                                                state={{
                                                    "organizationId": reportData.organizationId,
                                                    "verticalId": reportData.verticalId,
                                                    "projectId": reportData.projectId,
                                                    "connectorId": reportData.connectors.find((c: any) => c.connectorName === "Business Flow")?.connectorId,
                                                    "connectorName": "Business Flow"
                                                }}
                                                setIsLoading={setIsLoading} />
                                        )}
                                        {selectedNavBar === "Current State Architecture" && (
                                            <FlowComponents type="Current State Architecture" title="Architecture"
                                                state={{
                                                    "organizationId": reportData.organizationId,
                                                    "verticalId": reportData.verticalId,
                                                    "projectId": reportData.projectId,
                                                    "connectorId": reportData.connectors.find((c: any) => c.connectorName === "Current State Architecture")?.connectorId,
                                                    "connectorName": "Current State Architecture"
                                                }}
                                                setIsLoading={setIsLoading} />
                                        )}
                                        {selectedNavBar === "Current Infrastructure Cost" && <CostEstimation
                                            type="current"
                                            state={{
                                                "organizationId": reportData.organizationId,
                                                "verticalId": reportData.verticalId,
                                                "projectId": reportData.projectId,
                                                "awsconnectorId": reportData.connectors.find((c: any) => c.connectorName === "AWS")?.connectorId,
                                                "azureconnectorId": reportData.connectors.find((c: any) => c.connectorName === "Azure")?.connectorId,
                                                "inventoryconnectorId": reportData.connectors.find((c: any) => c.connectorName === "Inventory")?.connectorId,
                                                "isAWS": reportData.isAWS,
                                                "isAzure": reportData.isAzure,
                                                "isInventory": reportData.isInventory
                                            }}
                                            setIsLoading={setIsLoading}
                                        />
                                        }
                                        {selectedNavBar === "Data Warehouse & ETL Strategic Recommendations" && <ChallengesRecommendations
                                            title="Data Warehouse & ETL Strategic Recommendations"
                                            state={{
                                                "organizationId": reportData.organizationId,
                                                "verticalId": reportData.verticalId,
                                                "projectId": reportData.projectId,
                                                "connectorId": reportData.connectors.find((c: any) => c.connectorName === "Data Warehouse & ETL Strategic Recommendations")?.connectorId,
                                                "connectorName": "Data Warehouse & ETL Strategic Recommendations",
                                            }}
                                            setIsLoading={setIsLoading}
                                            />}
                                        {selectedNavBar === "Reporting Framework Recommendations" && <ChallengesRecommendations
                                            title="Reporting Framework Recommendations"
                                            state={{
                                                "organizationId": reportData.organizationId,
                                                "verticalId": reportData.verticalId,
                                                "projectId": reportData.projectId,
                                                "connectorId": reportData.connectors.find((c: any) => c.connectorName === "Reporting Framework Recommendations")?.connectorId,
                                                "connectorName": "Reporting Framework Recommendations",
                                            }}
                                            setIsLoading={setIsLoading} />}
                                        {selectedNavBar === "DataOps Strategic Recommendations" && <ChallengesRecommendations
                                            title="DataOps Strategic Recommendations"
                                            state={{
                                                "organizationId": reportData.organizationId,
                                                "verticalId": reportData.verticalId,
                                                "projectId": reportData.projectId,
                                                "connectorId": reportData.connectors.find((c: any) => c.connectorName === "DataOps Strategic Recommendations")?.connectorId,
                                                "connectorName": "DataOps Strategic Recommendations",
                                            }}
                                            setIsLoading={setIsLoading}
                                            />}
                                        {selectedNavBar === "ML Recommendations" && <ChallengesRecommendations
                                            title="ML Recommendations"
                                            state={{
                                                "organizationId": reportData.organizationId,
                                                "verticalId": reportData.verticalId,
                                                "projectId": reportData.projectId,
                                                "connectorId": reportData.connectors.find((c: any) => c.connectorName === "ML Recommendations")?.connectorId,
                                                "connectorName": "ML Recommendations",
                                            }}
                                            setIsLoading={setIsLoading} 
                                             />}

                                        {selectedNavBar === "SWOT" &&
                                        <SWOT
                                        reportData={reportData}
                                        challengesData={{
                                            dataOpsStrategicRecommendations: reportData.dataOpsStrategicRecommendations || [],
                                            dataWarehouseETLStrategicRecommendations: reportData.dataWarehouseETLStrategicRecommendations || [],
                                            mlRecommendations: reportData.mlRecommendations || [],
                                            reportingFrameworkRecommendations: reportData.reportingFrameworkRecommendations || [],
                                        }}
                                        businessOverviewData={reportData?.summary ? reportData.summary?.flat()?.find((item:any) => item.attributeName === "Business Overview")?.attributeValue : ''}
                                        setIsLoading={setIsLoading}

                                    />
                                
                                            
                                        }
                                        {selectedNavBar === "Current Data Environment Inventory" && (
                                            <CurrentDataEnvironmentInventory
                                                reportData={reportData}
                                                setIsLoading={setIsLoading}
                                            />
                                        )}

                                        {selectedNavBar === "Proposed Cost Analysis" && <CostEstimation
                                            type="future"
                                            state={{
                                                "organizationId": reportData.organizationId,
                                                "verticalId": reportData.verticalId,
                                                "projectId": reportData.projectId,
                                                "AWSconnectorId": reportData.connectors.find((c: any) => c.connectorName === "AWS")?.connectorId ,
                                                "AzureconnectorId": reportData.connectors.find((c: any) => c.connectorName === "Azure")?.connectorId
                                            }}
                                            setIsLoading={setIsLoading}
                                            verticalName={reportData.verticalName}
                                        />
                                        }
                                        {selectedNavBar === "Roadmap" && <MigrationPlan
                                            state={{
                                                organizationId: initialReportData.organizationId,
                                                verticalId: initialReportData.verticalId,
                                                verticalName: initialReportData.verticalName,
                                                projectId: initialReportData.projectId,
                                                projectName: initialReportData.projectName,
                                            }}
                                            menuId={initialReportData.menus[6].menuId}
                                            setIsLoading={setIsLoading}
                                        ></MigrationPlan>}
                                        {selectedNavBar === "Future State Architecture" && (
                                            <FutureStateArchitecture
                                                reportData={reportData}
                                                setReportData={setReportData}
                                                setIsLoading={setIsLoading}
                                            />
                                        )}
                                        {selectedNavBar === "Conclusion" && (
                                            
                                            <Conclusion
                                                reportData={reportData}
                                                challengesData={{
                                                    dataOpsStrategicRecommendations: reportData.dataOpsStrategicRecommendations || [],
                                                    dataWarehouseETLStrategicRecommendations: reportData.dataWarehouseETLStrategicRecommendations || [],
                                                    mlRecommendations: reportData.mlRecommendations || [],
                                                    reportingFrameworkRecommendations: reportData.reportingFrameworkRecommendations || [],
                                                }}
                                                businessOverviewData={reportData?.summary ? reportData.summary?.flat()?.find((item:any) => item.attributeName === "Business Overview")?.attributeValue : ''}
                                                setIsLoading={setIsLoading}
                                                onConclusionChange={isSummaryModified}

                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="alert" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3">
                        <div className="modal-header pb-0 border-0">
                            <h5 className="font-semibold font-18 font-semibold d-flex justify-content-between align-items-center">
                                Alert</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div>
                                <p className="font-medium font-14">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione quae consequuntur aperiam tempora est. Harum!</p>
                                <div className="col-md-12 gap-2 d-grid d-md-flex justify-content-center mt-4 ">
                                    <button data-bs-toggle="modal" data-bs-target="#progress" data-bs-dismiss="modal" type="button" className="btn btn-secondary  theme-secondary-btn-sm border-0 font-semibold px-4">Review What's Left</button>
                                    <button data-bs-toggle="modal" data-bs-target="#progress" data-bs-dismiss="modal" type="button" className="btn btn-dark theme-primary-btn-sm border-0 font-semibold px-4">Proceed Anyway</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DownloadReportModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onProceed={handleProceedDownload}
                selectedOption={selectedOption}
                selectedCategory={selectedCategory}
                reportData={reportData}
                handleOptionChange={handleOptionChange}
                handleCategoryChange={handleCategoryChange}
                removeCategory={removeCategory}
                isDropdownOpen={isDropdownOpen}
                toggleDropdown={toggleDropdown}
            />
            <Loader isLoading={isLoading} />


        </div>
    )
}


export default DataBricksReport