import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { regenerateReportContentApi, saveReportContentApi, postErrorLog } from '../../../Service/api';

const TextEditorTemplate = ({ reportData }) => {
  console.log("TextEditorTemplate rendering with data:", reportData);

  const [sections, setSections] = useState([]);
  const [isEditing, setIsEditing] = useState([]);
  const [error, setError] = useState(null);
  const [isValid, setIsValid] = useState([]);

  useEffect(() => {
    console.log("TextEditorTemplate useEffect triggered");
    if (reportData && reportData.body && reportData.body.content) {
      const contentArray = Array.isArray(reportData.body.content) ? reportData.body.content : [reportData.body.content];
      setSections(contentArray);
      setIsEditing(contentArray.map(() => false));
      setIsValid(contentArray.map(section => !isEmptyContent(section.content)));
    } else {
      console.warn("reportData or its structure is undefined");
      setSections([]);
      setIsEditing([]);
      setIsValid([]);
    }
  }, [reportData]);

  const isEmptyContent = (content) => {
    // Remove HTML tags and trim whitespace
    const strippedContent = content.replace(/<[^>]*>?/gm, '').trim();
    return strippedContent === '';
  };

  const handleEditorChange = (content, index) => {
    console.log(`Editor content changed at index ${index}`);
    console.log(`New content: ${content}`);
    
    const updatedSections = [...sections];
    updatedSections[index].content = content;
    setSections(updatedSections);

    const isEmpty = isEmptyContent(content);
    console.log(`Is content empty? ${isEmpty}`);

    const validityArray = [...isValid];
    validityArray[index] = !isEmpty;
    setIsValid(validityArray);
  };

  const handleEdit = (index) => {
    console.log(`Editing initiated at index ${index}`);
    const editingState = sections.map((_, idx) => idx === index);
    setIsEditing(editingState);
  };

  const handleSave = async (index) => {
    if (!isValid[index]) {
      console.log(`Cannot save empty content at index ${index}`);
      return;
    }

    try {
      console.log(`Saving content at index ${index}`);
      const updatedData = {
        report_id: reportData.report_data_id,
        message: { 
          ...reportData, 
          body: { ...reportData.body, content: sections } 
        }
      };
      
      console.log("Saving updated data:", updatedData);
      
      const response = await saveReportContentApi(updatedData);
      console.log("Save response:", response);
      
      if (response.status === 200) {
        const editingState = [...isEditing];
        editingState[index] = false;
        setIsEditing(editingState);
      }
    } catch (errorResponse) {
      console.error(`Failed to save content at index ${index}`, errorResponse);
      setError({ sectionIndex: index, message: 'Failed to save content.' });
      await postErrorLog({ error: errorResponse });
    }
  };

  const handleRegenerate = async (index) => {
    // ... (keep the existing implementation)
  };

  const handleCancel = (index) => {
    console.log(`Edit cancelled at index ${index}`);
    const editingState = [...isEditing];
    editingState[index] = false;
    setIsEditing(editingState);
  };

  return (
    <div className="d-flex col-md-9">
      <div className="bg-white rounded-2 w-100 px-3">
        <h2 className="font-24 font-semibold heading-txt-color border-bottom py-3 mb-4">
          {reportData ? reportData.navTitle : 'Loading...'}
        </h2>
        
        {sections.length > 0 ? (
          sections.map((section, index) => (
            <div key={index} className="d-flex flex-column tab-container mb-5">
              <div className="d-flex justify-content-between flex-md-row flex-column align-items-center mb-3">
                <div className="d-flex">
                  <span className="font-18 font-semibold text-dark">{section.title || ''}</span>
                </div>
                <div className="d-flex">
                  {!isEditing[index] && (
                    <button
                      className="border-0 p-0 action-icons edit-icon rounded-circle d-flex justify-content-center align-items-center"
                      onClick={() => handleEdit(index)}
                      disabled={isEditing.some(editing => editing)}
                    >
                      <img src="../images/edit.svg" alt="edit-icon" />
                    </button>
                  )}
                </div>
              </div>

              <div>
                {isEditing[index] ? (
                  <ReactQuill
                    value={section.content}
                    onChange={(content) => handleEditorChange(content, index)}
                    className="mb-3"
                  />
                ) : (
                  <p className="lh-base mb-0 font-14 font-regular text-dark" dangerouslySetInnerHTML={{ __html: section.content }} />
                )}
              </div>

              {isEditing[index] && (
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <button 
                    className="d-flex gap-2 align-items-center outlined-button px-3 bg-transparent"
                    onClick={() => handleRegenerate(index)}
                  >
                    <img src="../images/regenerate.svg" alt="regenerate-icon" />
                    <span className="font-semibold font-14">Regenerate</span>
                  </button>
                  <div className="d-flex gap-2">
                    <button className="btn btn-light secondary-button" onClick={() => handleCancel(index)}>Cancel</button>
                    <button 
                      className="btn primary-button" 
                      onClick={() => handleSave(index)} 
                      disabled={!isValid[index]}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              {error && error.sectionIndex === index && (
                <div className="error-message mt-2">{error.message}</div>
              )}
            </div>
          ))
        ) : (
          <p>No content available</p>
        )}
      </div>
    </div>
  );
};

export default TextEditorTemplate;