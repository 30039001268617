

import React from 'react';

const Breadcrumbs = ({ assessmentName }) => {
  const breadcrumbs = [
    { name: 'Assessments', path: '/AssessmentsGrid' },
    { name: assessmentName, path: '/Workflow' },
    { name: 'Reports', path: '/Report' }
  ];

  return (
    <div className="col-md-12">
      <div className="d-flex justify-content-between flex-md-row">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb d-flex align-items-center mb-0">
            {breadcrumbs.map((breadcrumb, index) => (
              <span key={index} className="d-flex align-items-center">
                <li className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`}>
                  {index !== breadcrumbs.length - 1 ? (
                    // Using a styled <a> tag to remove the default hyperlink styling
                    <a 
                      href={breadcrumb.path} 
                      className="breadcrumb-item" 
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {breadcrumb.name}
                    </a>
                  ) : (
                    breadcrumb.name
                  )}
                </li>
                {index !== breadcrumbs.length - 1 && (
                  <span className="fill-icon mx-2">
                    <img
                      src="images/breadcrumb-arrow.svg"
                      alt="breadcrumb-arrow"
                      className="d-flex"
                    />
                  </span>
                )}
              </span>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumbs;