import React from 'react';
import TextEditorTemplate from './Templates/TextEditorTemplate';
import ChallengesRecommendationsTemplate from './Templates/ChallengesRecommendationsTemplate';
import MultiRecordTableTemplate from './Templates/MultiRecordTableTemplate';
import SingleRecordTableTemplate from './Templates/SingleRecordTableTemplate';

const ReportWorkbenchComponent = ({ templates, activeTemplateId, reportId, getReportData }) => {
  console.log("ReportWorkbenchComponent rendering", { templates, activeTemplateId, reportId });

  const activeTemplate = templates.find(t => t.report_data_id === activeTemplateId);

  console.log("Active template:", activeTemplate);

  if (!activeTemplate) {
    return <div>No template selected</div>;
  }

  // Validate the template structure
  if (!activeTemplate.body || !activeTemplate.body.templateId) {
    console.error("Invalid template structure:", activeTemplate);
    return <div>Invalid template structure</div>;
  }

  // Render appropriate template based on templateId
  const renderTemplate = () => {

    console.log("current template id", activeTemplate.body.templateId);
    

    switch (activeTemplate.body.templateId) {
      case 'texteditor':
        return <TextEditorTemplate reportData={activeTemplate} getReportData={getReportData} />;
      case 'challengesrecommendations':
        return <ChallengesRecommendationsTemplate reportData={activeTemplate} getReportData={getReportData} />;
      case 'tablecomponent':
        return <MultiRecordTableTemplate reportData={activeTemplate} getReportData={getReportData} />;
      case 'singlerecordtemplate':
        return <SingleRecordTableTemplate reportData={activeTemplate} getReportData={getReportData} />;
      default:
        return <div>Unknown template type: {activeTemplate.body.templateId}</div>;
    }
  };

  return (

      renderTemplate()
  );
}
export default ReportWorkbenchComponent;