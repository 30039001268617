
import axios from 'axios';
import { useOrgContext } from '../Components/Context/Context';
import { useEffect, useState } from 'react';


export default async function Client(endpoint, payload, method, options) {

	// const { setSessionTimeoutPopup } = useOrgContext();

	// const [count, setCount] = useState(0);
	

	// useEffect(() => {
	// 	let timer;
	// 	if (count >= 30) {
	// 		setSessionTimeoutPopup(true);
	// 	} else {
	// 		timer = setTimeout(() => {
	// 			setCount(prev => prev + 1);
	// 		}, 60000); // increment count every minute
	// 	}

	// 	return () => clearTimeout(timer); // cleanup timer on component unmount or re-render

	// }, [count]);

	// const resetCount = () => setCount(0);



	let jwtToken = localStorage.getItem('jwt'); // Retrieve the JWT from local storage

	// Perform Conditional Check Over here
	if (!jwtToken) {

		// Navigate to Login Page 
	}

	// Retreive the User id from the Local storage and Add Dynamicallly in hte Payload
	if (payload) {
		// retrerive from the local stroage and add Dynamically here

		// let userId=localStorage.setItem('user_Id','ad18ea57-9896-4de8-b3ed-cb18d01232b1')
		// let user_Id = localStorage.getItem('userId')

		// // let userId ="a2e1730f-7c47-417f-af9c-353990c32108"
		// payload["userId"] = user_Id
	}

	const config = {
		method: method,
		url: endpoint,
		headers: {
			'Content-Type': 'application/json',
			// 'Access-Control-Allow-Origin': "*", "Access-Control-Allow-Header": "*",
		},
	};;
	// console.log(config, "config");
	// Include the JWT in the headers if available
	if (jwtToken) {
		config.headers['Authorization'] = `Bearer ${jwtToken}`;
	}

	// Include the payload for POST or PUT requests
	if (method === 'POST' || method === 'PUT') {
		config.data = payload; // Axios uses 'data' for request body
	}

	try {
		//resetCount(); // Reset count whenever an API call is made.
		// console.log(config, "Client -- before", config.url)
		const response = await axios(config);
		// console.log(response, "Client -- after")

		return response; // Return the data from the response

	} catch (error) {
		console.error('Error in Client function:', error);


		throw error; // Rethrow or handle the error as necessary
	}
}
