// authConfig.js


export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URI
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    }
};

console.log(process.env.REACT_APP_REDIRECT_URI,"This is the redirect uri...")

console.log("Inside the Auth config")
console.log();


export const loginRequest = {
    scopes: ["User.Read"]

};

export const graphConfig = {
    graphEndpoint: process.env.REACT_APP_GRAPH_ENDPOINT
};




