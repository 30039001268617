import React, { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';
import { useNavigate } from 'react-router-dom';
import { postErrorLog, postLoginDetails } from '../../Service/api';
import Loader from '../Loader/Loader';

function Login({ isDarkMode, setIsDarkMode }) {
    const { instance } = useMsal();
    const navigate = useNavigate()
    const [isLogin, setIsLogin] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [photoBlob, setPhotoBlob] = useState(null);
    const [userID, setUserID] = useState("")
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        // Use a short timeout to ensure styles are applied
        const timer = setTimeout(() => {
            setShowLoader(false);
        }, 500); // Adjust this value if needed

        return () => clearTimeout(timer);
    }, []);

    function parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    const getProfilePhoto = async (userId, account) => {
        try {
            const request = {
                ...loginRequest,
                account: account
            };
            console.log(request , "For Profile Phottto")
            const tokenResponse = await instance.acquireTokenSilent(request);
            const response = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, {
                headers: {
                    Authorization: `Bearer ${tokenResponse.accessToken}`
                }
            });

            if (response.status === 404) {
                console.log('User does not have a profile photo');
                return null;
            }

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.status}`);
            }

            const photoBlob = await response.blob();
            return photoBlob;
        } catch (err) {
            console.error('Error fetching profile photo:', err);
            return null;
        }
    };

    async function fetchProfileImageAsBase64(accessToken) {
        const endpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";

        const response = await fetch(endpoint, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.ok) {
            const blob = await response.blob();
            return await convertBlobToBase64(blob);
        } else {
            console.error("Failed to fetch profile image:", response.status, response.statusText);
            return null;
        }
    }

    function convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(",")[1]); // Remove prefix to get pure Base64
            reader.onerror = reject;
            reader.readAsDataURL(blob); // Convert blob to data URL
        });
    }

    const handleLogin = async () => {
        console.log("triggered")
        setShowLoader(true);
        try {
            if (instance?.getActiveAccount()) {
                console.log("User is already logged in");

                const tokenResponse = await instance?.acquireTokenSilent(loginRequest);

                localStorage.setItem('userImage', await fetchProfileImageAsBase64(tokenResponse?.accessToken))

                navigate('/Client-onboarding');
                return;
            }

            const loginResponse = await instance.loginPopup(loginRequest);
            console.log(loginResponse, "loginResponse");

            setIsLogin(true);
            instance.setActiveAccount(loginResponse.account);

            const tokenResponse = await instance.acquireTokenSilent(loginRequest);

            localStorage.setItem('userImage', await fetchProfileImageAsBase64(tokenResponse?.accessToken))

            const jwtResponse = await postLoginDetails({
                username: loginResponse.account.name,
                email: loginResponse.account.username,
            });

            console.log(jwtResponse, "jwtResponse")
            if (jwtResponse.data.body.statusCode !== '200') {
                navigate('/')
            }
            const jwtToken = jwtResponse.data.body.token;
            const isadmin = jwtResponse.data.body.is_admin;
            const isDarkTheme = jwtResponse.data.body.is_theme_dark;

            if (!jwtToken) {
                throw new Error('JWT token is empty');
            }

            localStorage.setItem('jwt', jwtToken);
            localStorage.setItem('isAdmin', isadmin);
            localStorage.setItem('isDarkTheme', isDarkTheme)
            localStorage.setItem('darkTheme', isDarkTheme)

            localStorage.setItem('userName', loginResponse.account.name)

            setIsDarkMode(isDarkTheme)
            const decodedToken = parseJwt(jwtToken);

            const userId = decodedToken.user_id;

            setUserID(userId)
            setUserID(userId)

            localStorage.setItem('userId', userId);
            localStorage.setItem('emailId', loginResponse.account.username)
            navigate('/Client-onboarding');            

            const photo = await getProfilePhoto(userId, loginResponse.account);
            if (photo) {
                setPhotoBlob(photo);
            } else {
                console.log('No profile photo available, using default');
            }

        } catch (error) {
            setShowLoader(false);

            const errorData = {
                user_id: localStorage.getItem(('userId'),),
                type: "error",
                error_module_name: "handleLogin",
                error_description: error?.message + " ," + error?.response?.data?.body?.message

            };
            if(localStorage.getItem('userId')){
                postErrorLog(errorData);
            }
           
            localStorage.clear()
            sessionStorage.clear()
            navigate('/')
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <>
            {showLoader ?
                <Loader /> :
                <div className="container-fluid">
                    <div className="row vh-100 login-container align-items-center justify-content-center">
                        <div className="login-content text-center p-5">
                            <img src="images/logo-color.svg" alt="logo" className="mb-5" />
                            <p className="font-38 font-bold primary-color mb-3">
                                The simplest way to manage your workplace
                            </p>
                            <p className="font-22 light-grey-v1 font-regular mb-4 pb-2">
                                Experience intelligent assessment and personalized experiences through
                                our cutting-edge AI solutions.
                            </p>
                            <button className="btn primary-button font-16 font-semibold px-5"
                                onClick={handleLogin}>
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Login;