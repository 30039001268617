

import React from 'react';

const PopupComponent = (props) => {

    const { case: popupCase, onClose, scopeData,attributeData,selectedAttributeId,setSelectedAttributeId,onConfirm,reason,setReason } = props

    console.log(scopeData, "scope in popup");

    const renderContent = () => {
        switch (popupCase) {
            case 'GeneratingReport':
                return (
                    <div
                        className="modal fade show modal-lg-only loader-popup-container configure-limit-container"
                        // id="info"
                        // tabIndex={-1}
                        // aria-labelledby="loaderModalLabel"
                        // aria-hidden="true"
                        style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)", zIndex: '10000' }}

                    >
                        <div className="modal-dialog">
                            <div className="modal-content vh-75 overflow-hidden radius-6 border-0">
                                <div className="modal-header border-0 p-4">
                                    <img src="images/add-assessment-icon.svg" alt="" className="me-3" />
                                    <h4 className="mb-0 font-18 font-semibold black-color">Information</h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        // data-bs-dismiss="modal"
                                        // aria-label="Close"
                                        onClick={onClose}
                                    />
                                </div>
                                <div className="p-4 pt-1">
                                    <p className="font-14 text-color">
                                        Report generation in process , it could take approximately 5 minutes. We will notify you once done.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                );
            case 'Scope':
                return (
                    <div
                        className="modal fade show modal-lg-only loader-popup-container configure-limit-container"
                        // id="scope"
                        // tabIndex={-1}
                        // aria-labelledby="loaderModalLabel"
                        // aria-hidden="true"
                        style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)", zIndex: '10000' }}
                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content vh-75 overflow-hidden radius-6 border-0">
                                <div className="modal-header border-0 p-4">
                                    <img src="images/add-assessment-icon.svg" alt="" className="me-3" />
                                    <h4 className="mb-0 font-18 font-semibold black-color">Scope</h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={onClose}
                                    />
                                </div>
                                <div className="p-4 pt-1">
                                    <div className="row">
                                        <div className="col-md-12 mb-3 scope-data">
                                            {scopeData.inscope}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                );

            case 'SkipChecklist':
                return (
                    <div
                        className="modal fade show modal-lg-only loader-popup-container configure-limit-container"
                        // id="skip_checklist"
                        // tabIndex={-1}
                        // aria-labelledby="loaderModalLabel"
                        // aria-hidden="true"
                        style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)", zIndex: '10000' }}

                    >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content vh-75 overflow-hidden radius-6 border-0">
                                <div className="modal-header border-0 p-4">
                                    <img src="images/skip_checklist.svg" alt="" className="me-3" />
                                    <h4 className="mb-0 font-18 font-semibold black-color">
                                        Skip Checklist
                                    </h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={onClose}
                                    />
                                </div>
                                <div className="p-4 pt-1">
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label
                                                className="form-label text-dark font-14 font-medium"
                                                htmlFor="Website-type"
                                            >
                                                Reason for skipping the checklist{" "}
                                                <span className="input-improtent-section">*</span>
                                            </label>
                                            <textarea
                                                className="form-control w-100 mt-2 resize-none custom-textarea shadow-none"
                                                placeholder="Describe Reason"
                                                onChange={((e)=>{setReason(e.target.value)})}
                                                value={reason}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                                                <button className="btn outline-button py-2 px-4 font-semibold" onClick={onClose}>
                                                    Cancel
                                                </button>
                                                <button className="btn primary-button py-2 px-4 font-semibold" onClick={onConfirm}>
                                                    Skip
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            case 'Add Attribute':
                return (
                    <div
                        className="modal fade show modal-lg-only loader-popup-container configure-limit-container"
                        id="attribute"
                        tabIndex={-1}
                        aria-labelledby="loaderModalLabel"
                        aria-hidden="true"
                        style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)", zIndex: '10000' }}

                    >
                        <div className="modal-dialog">
                            <div className="modal-content vh-75 overflow-hidden radius-6 border-0">
                                <div className="modal-header border-0 p-4">
                                    <img src="images/add-assessment-icon.svg" alt="" className="me-3" />
                                    <h4 className="mb-0 font-18 font-semibold black-color">
                                        Add Attribute
                                    </h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={onClose}
                                    />
                                </div>
                                <div className="p-4 pt-1">
                                    <div className="col-md-12 mb-3">
                                        <label
                                            className="form-label text-dark font-14 font-medium"
                                            htmlFor="industry-type"
                                        >
                                            Attributes <span className="input-improtent-section">*</span>
                                        </label>
                                        <select
                                            className="form-select migrate-form-ui mb-4 mt-2"
                                            id="attribute"
                                            value={selectedAttributeId}
                                            onChange={((e)=>{setSelectedAttributeId(e.target.value)})}
                                        >
                                            <option selected="">Select attribute</option>
                                            {attributeData?.map((attribute) => (
                                            <option key={attribute.attribute_id} value={attribute.attribute_id}>
                                                {attribute.attribute_name}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                                            <button className="btn primary-button py-2 px-4"> OK</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )

            case 'unlockPopup':
                return (
                    <div
                        className="modal fade show modal-lg-only loader-popup-container configure-limit-container"
                        id="info"
                        tabIndex={-1}
                        aria-labelledby="loaderModalLabel"
                        aria-hidden="true"
                        style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)", zIndex: '10000' }}

                    >
                        <div className="modal-dialog">
                            <div className="modal-content vh-75 overflow-hidden radius-6 border-0">
                                <div className="modal-header border-0 p-4">
                                    <img src="images/add-assessment-icon.svg" alt="" className="me-3" />
                                    <h4 className="mb-0 font-18 font-semibold black-color">Information</h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="p-4 pt-1">
                                    <p className="font-14 text-color">
                                    Editing the Past Day can impact in the further days.
                                    Do you want to proceed?
                                    </p>
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                                            <button className="btn primary-button py-2 px-4"> Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )

                
            default:
                return null;
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                {renderContent()}
                {/* <button onClick={onClose}>Close</button> */}
            </div>
        </div>
    );
};

export default PopupComponent;