import replaceSpacesWithUnderscores from "../ReportGeneration/helper/modifyTitle";


export const TableComponent = (content) => {
  let modified_title = replaceSpacesWithUnderscores(content.table.title);
  return `
    <div class="new-page" id="${modified_title}" style="padding: 20px; margin-bottom: 20px; background-color: #fff;">
      <h2 style="color: #000; font-size: 24px; font-family: Arial; font-weight: 700; margin-bottom: 10px;">${content.table.title}</h2>
      <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
        <thead>
          <tr>
            ${content.table.columns.map(col => `
              <th style="padding: 10px; border-bottom: 2px solid #000; text-align: left; font-family: Arial; font-size: 16px; color: #000; font-weight: 700;">
                ${col}
              </th>`).join('')}
          </tr>
        </thead>
        <tbody>
          ${content.table.rows.map(row => `
            <tr>
              ${row.map(cell => `
                <td style="padding: 10px; border-bottom: 1px solid #ccc; text-align: left; font-family: Arial; font-size: 14px; color: #000;">
                  ${cell}
                </td>
              `).join('')}
            </tr>
          `).join('')}
        </tbody>
      </table>
    </div>
  `;
};