// SQ_0.3: Import statements
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AssessmentsPopup from "./AssessmentsPopup";
import Header from "../Header/Header";
import { fetchClientsDataApi, getAssessmentsData, getAssessmentTypeData, getAssessStatusData, postErrorLog } from "../../Service/api";
import Loader from "../Loader/Loader";
import SessionTimeOutPopup from "../Header/SessionTimeOutPopup";

function AssessmentsGrid() {


    //SQ_0.5: State declarations
    const [assessmentData, setAssessmentData] = useState([]);
    const [totalAssessmentCount, setTotalAssessmentCount] = useState('')
    const [editMode, setEditMode] = useState(false);
    const [filterData, setFilterData] = useState({
        assessment_type: '',
        domain: '',
        start_date: '',
        end_date: '',
        status: ''
    });
    const [sortColumnName, setSortColumnName] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [loadCount, setLoadCount] = useState(10);
    const [filteredAssessments, setFilteredAssessments] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedAssessment, setSelectedAssessment] = useState('')
    const [assessmentTypeData, setAssessmentTypeData] = useState([])
    const [clientsData, setClientsData] = useState([]);
    const [selectedClient, setSelectedClient] = useState("");
    const [showLoader, setShowLoader] = useState(false)
    const [assessmnetStatus, setAssessmnetStatus] = useState([])
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedClientName, setSelectedClientName] = useState('')
    const [initialLoad, setInitialLoad] = useState(true);
    const isAdmin = localStorage.getItem('isAdmin')
    const userId = localStorage.getItem('userId')
    const navigate= useNavigate();

    const location = useLocation();
    const { clientId } = location?.state || {};

    console.log(clientId, "clientId");
    console.log(selectedClient, "selectedClient");


     // Handle initial state from location.state
     useEffect(() => {
        const clientId = location.state?.clientId || "";
        const clientName = location.state?.clientName || "All";

        setSelectedClient(clientId);
        setSelectedClientName(clientName);

        if (initialLoad) {
            setInitialLoad(false);
            fetchData(filterData, clientId, "", "", undefined, "");
        }
    }, [location.state]);

    // Fetch data on client change or search term change
    useEffect(() => {
        if(!initialLoad) {
            fetchData(filterData, selectedClient, "", "", undefined, searchTerm);
        }
    }, [selectedClient, searchTerm]);

    // SQ_NO_2.0 and 13.33- Fetch Assessment Type Dropdown Data

    useEffect(() => {
        fetchAssessmentTypeData();
        getAllClientData();
        fetchAssessStatus()
    }, []);


    // Fetches and sets the assessment data based on provided filters, sorting, and load count.
    // Parameters:
    // -----------
    // filterData : object
    //     The filter data to apply to the assessment data fetch.

    // Details:
    // --------
    // Constructs a payload with user ID, client ID, filters, sorting preferences, and load count. Fetches assessment data using `getAssessmentsData` and updates the state with the fetched assessment data and total assessment count. Handles errors by logging them and ensures the loading state is properly managed.

    // Formula:
    // --------
    // payload = {
    //     user_id: userId,
    //     client_id: selectedClient,
    //     filters: filterData,
    //     sort: {
    //         columnName: sortColumnName,
    //         order: sortOrder
    //     },
    //     loadmore: loadCount
    // }

    // API Call:
    // --------
    // response = await getAssessmentsData(payload)

    // If response is successful:
    //     set assessmentData with response.data.body.data.assessment_details

    //SQ_1.0 to 1.14
    const fetchData = async (filterData, client_id="", sortBy, orderBy,newLoadCount,search_key ="") => {
        try {
            const payload = {

                user_id: userId,
                client_id: client_id,
                filters: filterData,
                sort: {
                    columnName: sortBy || 'status',
                    order: orderBy || 'ASC',
                },
                load: newLoadCount || loadCount,
                search_key: search_key || searchTerm    
            };
            if(!searchTerm){
                setShowLoader(true);
            }
            const response = await getAssessmentsData(payload);
            if(!searchTerm){
                setShowLoader(false)
            }
            if (response.status === 200) {
                const data = response.data.body.data.assessment_details;
                setAssessmentData(data);
                setFilteredAssessments(data);
                setTotalAssessmentCount(response.data.body.data.total_assessments);
            }
        } catch (error) {
            setShowLoader(false)
            handleError(error, "getAssessmentData")

        }
    };

    // Logs and handles errors that occur in API calls.

    // Parameters:
    // -----------
    // error : object
    //     The error object received from a failed API call.
    // functionName : string
    //     The name of the function where the error occurred.

    // Details:
    // --------
    // Constructs an error data object including user ID, error type, module name, and error description. Sends this data to `postErrorLog`.
    //SQ_1.0 -1.3: Handle posting error if any
    const handleError = (error, functionName) => {
        const errorData = {
            user_id: userId,
            type: "error",
            error_module_name: functionName,
            error_description: error?.message + " ," + error?.response?.data?.body?.message

        };
        postErrorLog(errorData);
    };

    //     ### `fetchAssessmentTypeData`
    // Fetches and sets the assessment type data.
    //     Details:
    //     --------
    //     Constructs a payload with user ID and dropdown type for assessment types. Fetches assessment type data using `getAssessmentTypeData` and updates the state with the fetched assessment type data. Handles errors by logging them.

    //     Formula:
    //     --------
    //     payload = {
    //         dropdown_type: 'assessment_type',
    //         user_id: userId
    //     }

    //     API Call:
    //     --------
    //     response = await getAssessmentTypeData(payload)

    //     If response is successful:
    //     set assessmentTypeData with response.data.body.data

    //SQ_2.0 -2.12
    const fetchAssessmentTypeData = async () => {
        try {
            const payload = {
                dropdown_type: 'assessment_type',
                // is_admin: false,
                user_id: userId
            };
            const response = await getAssessmentTypeData(payload);
            if (response.status === 200) {
                setAssessmentTypeData(response.data.body.data);
            }
        } catch (error) {
            handleError(error, "fetchAssessmentTypeData")
        }
    };

    //     ### `fetchAssessStatus`
    // Fetches and sets the assessment type data.
    //     Details:
    //     --------
    //     Constructs a payload with user ID and dropdown type for status types. Fetches status using `getAssessmentStatusData` and updates the state with the fetched data. Handles errors by logging them.

    //     Formula:
    //     --------
    //     payload = {
    //         dropdown_type: 'assessment_type',
    //         user_id: userId
    //     }

    //     API Call:
    //     --------
    //     response = await getAssessStatus(payload)

    //     If response is successful:
    //     set assessmentTypeData with response.data.body.data
    //SQ_14.0 -14.12
    const fetchAssessStatus = async () => {
        try {
            const payload = {
                dropdown_type: 'assessment_status',
                // is_admin: false,
                user_id: userId
            };
            const response = await getAssessStatusData(payload);
            if (response.status === 200) {
                setAssessmnetStatus(response.data.body.data);
            }
        } catch (error) {
            handleError(error, "fetchAssessStatus")
        }
    };

    //### `getAllClientData`

    // Fetches and sets the client data without any applied filters or search keys.

    //     Details:
    //     --------
    //     Constructs a payload with user ID and default filter and search settings. Fetches client data using `fetchClientsDataApi` and updates the state with the fetched client data. Handles errors by logging them.

    //     Formula:
    //     --------
    //     payload = {
    //         user_id: userId,
    //         filters: {
    //             industry_name: "",
    //             load: ""
    //         },
    //         search_key: searchTerm
    //     }

    //     API Call:
    //     --------
    //     response = await fetchClientsDataApi(payload)

    //     If response is successful:
    //         set clientsData with response.data.body.data.client_data

    //SQ_13.34- 13.47: client data for dropdown
    const getAllClientData = async () => {
        try {
            const payload =
            {
                user_id: userId,
                filters: {
                    industry_name: "",
                    load: ""

                },
                search_key: searchTerm,
                //load: loadCount
            };

            const response = await fetchClientsDataApi(payload);
            console.log(response, "responsess")
            setClientsData(response.data.body.data.client_data);
            // const response = clientResponse;
            // setClientsData(response.client_data);
            // setClientCount(parseInt(response.client_count));
        } catch (error) {

        }
    };

    // Handles changes in filter inputs and updates the filter data state.

    // Parameters:
    // -----------
    // e : object
    //     The event object for the filter input change.

    // Details:
    // --------
    // Retrieves the input's ID and value from the event, then updates the `filterData` state accordingly.

    // Formula:
    // --------
    // const { id, value } = e.target;
    // set filter data with the new value


    // SQ_NO_9.0 -9.3 - Handle Filter Change
    const handleFilterChange = (e) => {
        const { id, value } = e.target;
        setFilterData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    // Applies the current filter settings and fetches assessment data.

    // Details:
    // --------
    // Calls `fetchData` with the current filter data to refresh the assessment list based on the applied filters.

    // Formula:
    // --------
    // fetchData(filterData)

    // SQ_NO_10.0 - Handle Apply Filter
    const handleApply = () => {
        // This will trigger the useEffect for fetching assessment data
        fetchData(filterData, selectedClient, "", "", undefined, searchTerm);
        let type ="Apply"
        closeFilter(type)
    };   

    // Clears all filter inputs and resets the filter data state.

    // Details:
    // --------
    // Resets the filter data to an initial state with empty values.

    // Formula:
    // --------
    // set filterData to an initial state with empty values

    // SQ_NO_11.0 - Handle Clear Filter
    const handleClear = () => {
        const resetValue = {
            assessment_type: "",
            domain: "",
            status: "",
            start_date: "",
            end_date: ""
        }
        setFilterData(resetValue);
        fetchData(resetValue, selectedClient, "", "", undefined, searchTerm);
        let type ="Clear"
        closeFilter(type)
        //setSearchTerm('')

    };

    // Formats a date string into a human-readable format.

    // Parameters:
    // -----------
    // dateString : string
    //     The date string to format.

    // Returns:
    // --------
    // string
    //     A formatted date string (e.g., 'Jan 23, 2025').

    // Formula:
    // --------
    // const date = new Date(dateString);
    // const months = [
    //     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    //     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    // ];
    //get month name (e.g., 'Jan') and day of the month (e.g., 23) and full year (e.g., 2025)

    //SQ_13.52 - 13.55
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const month = months[date.getMonth()]; // Get month name (e.g., 'Jan')
        const day = date.getDate(); // Get day of the month (e.g., 23)
        const year = date.getFullYear(); // Get full year (e.g., 2025)
        return `${month} ${day}, ${year}`;
    };

    // Renders the assessment data or a message indicating no assessments were found.

    // Returns:
    // --------
    // JSX Elements:
    //     Rendered assessment table rows if `filteredAssessments` has data; Otherwise, an "Oops! No Assessments Found" message.

    // Details:
    // --------
    // Maps through `filteredAssessments` to create table rows for each assessment entry, including client name, last assessed date, assessment type, progress, status, and actions. Applies different actions based on the assessment status.

    // Formula:
    // --------
    // If filteredAssessments.length == 0:
    //     return "Oops! No Assessments Found" JSX

    // Else:
    //     filter assessments based on data

    // SQ_NO_12.0 - Bind Assessment Data
    const bindAssessmentData = () => {

        if (filteredAssessments.length === 0) {
            return (
                <div className="row mt-4" style={{ paddingLeft: '180%' }}>
                    <div className="d-grid justify-content-center text-center my-5 pt-5">
                        <img src="images/norecord-img.svg" alt="norecord-img" />
                        <p className="font-semibold font-30 mt-5 mb-2">Oops!</p>
                        <p className="font-regular font-16">No Assessments Found</p>
                    </div>
                </div>
            );
        }

        if (filteredAssessments && filteredAssessments.length > 0) {
            return filteredAssessments.map(assessment => (

                <tr key={assessment.assessment_id}>
                    <td>
                        <a className="text-decoration-none blue-text font-medium font-14" title={assessment.assessment_name}>
                            {assessment.assessment_name.length > 30
                                ? `${assessment.assessment_name.slice(0, 30)}...` // Slice to 40 characters and add ellipsis
                                : assessment.assessment_name}
                        </a>
                    </td>
                    <td>{assessment.client_name}</td>
                    <td>{formatDate(assessment.last_assessed)}</td>
                    <td>{assessment.assessment_type}</td>
                   <td> {assessment?.progress ? `${assessment.progress}%` : '0%'}</td>

                    <td className="text-center">
                        <span className={`table-status ${getStatusClass(assessment.status)}`}>
                            <span className={`status-indicator  status-${assessment.status.toLowerCase().replace(' ', '-')}`} />
                            {assessment.status}
                        </span>
                    </td>
                    <td>
                        <div className="dropdown d-flex justify-content-center">
                            <button
                                id="end-point-config"
                                className="btn kebab-btn d-flex justify-content-center py-0 px-1"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img src="images/kebab-horizontal-dropdown.svg" alt="kebab-icon" />
                            </button>


                            <ul className="dropdown-menu border-0 py-0 kebab-dropdown-menu">
                                {assessment.status === 'Not Started' && (
                                    <>
                                        <li className="font-14 font-medium color-black px-3 py-2" onClick={(()=>{navigate('/Workflow',{state:{assessmentId:assessment.assessment_id,status:"start", assessmentName:assessment.assessment_name}});})}>
                                            <img src="images/start-assessment.svg" alt="start-assessment-icon" className="me-2" />
                                            <label className="font-13 font-regular color-black">Start Assessment</label>
                                        </li>
                                        {isAdmin == "true" && (
                                            <li className="font-14 font-medium color-black px-3 py-2" onClick={(() => {
                                                setIsPopupOpen(true)
                                                setSelectedAssessment(assessment)
                                                setEditMode(true)
                                            })}>
                                                <img src="images/edit.svg" alt="edit-icon" className="me-2" />
                                                <label className="font-13 font-regular color-black">Edit</label>
                                            </li>
                                        )}
                                    </>

                                )}
                                {assessment.status === 'In Progress' && (
                                    <li className="font-14 font-medium color-black px-3 py-2" onClick={(()=>{navigate('/Workflow',{state:{assessmentId:assessment.assessment_id,status:"resume",assessmentName:assessment.assessment_name}});})}>
                                        <img src="images/resume-assessment.svg" alt="resume-assessment-icon" className="me-2" />
                                        <label className="font-13 font-regular color-black">Resume Assessment</label>
                                    </li>
                                )}
                                {assessment.status === 'Completed' && (
                                    <>
                                        <li className="font-14 font-medium color-black px-3 py-2" onClick={(()=>{navigate('/Workflow',{state:{assessmentId:assessment.assessment_id,status:"reassess", assessmentName:assessment.assessment_name}});})}>
                                            <img src="images/start-assessment.svg" alt="start-assessment-icon" className="me-2" />
                                            <label className="font-13 font-regular color-black">Reassess Assessment</label>
                                        </li>
                                        {isAdmin == "true" && (
                                            <li className="font-14 font-medium color-black px-3 py-2" onClick={(() => {
                                                setIsPopupOpen(true)
                                                setSelectedAssessment(assessment)
                                            })}>
                                                <img src="images/edit.svg" alt="edit-icon" className="me-2" />
                                                <label className="font-13 font-regular color-black">Edit</label>
                                            </li>
                                        )}
                                        <li className="font-14 font-medium color-black px-3 py-2" >
                                            <img src="images/dl-rep.svg" alt="delete" className="me-2"></img>
                                            <label className="font-13 font-regular color-black">Download Report</label>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </td>
                </tr>
            ));
        }
        return null;
    };

    // Returns a CSS class based on the assessment status.

    // Parameters:
    // -----------
    // status : string
    //     The status of the assessment.

    // Returns:
    // --------
    // string
    //     The CSS class corresponding to the status.

    // Formula:
    // --------
    // switch (status) {
    //     case 'Completed':
    //         return 'completed-color';
    //     case 'In Progress':
    //         return 'in-progress-color';
    //     case 'Not started':
    //         return 'not-started-color';
    // }
    const getStatusClass = (status) => {
        switch (status) {
            case 'Completed':
                return 'completed-color';
            case 'In Progress':
                return 'in-progress-color';
            case 'Not Started':
                return 'not-started-color';
        }
    };

    // Toggles the sort order and sets the column name to sort by.

    // Parameters:
    // -----------
    // columnName : string
    //     The name of the column to be sorted.

    // Details:
    // --------
    // Toggles the sort order between 'ASC' and 'DESC' and sets the sort column name to the provided column name.

    // Formula:
    // --------
    // sort order based on the current sort order and the column name.

    // SQ_NO_13.24- 13.26: Toggle Sort Order
    // const toggleSortOrder = (columnName) => {
    //     setSortOrder(prevOrder => prevOrder === 'ASC' ? 'DESC' : 'ASC');
    //     setSortColumnName(columnName);
    // };

    const toggleSortOrder = (columnName) => {
        let newSortOrder;

        if (sortColumnName === columnName) {
            // If the same column is clicked, toggle the sort order
            newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
        } else {
            // If a different column is clicked, set the default sort order (ASC)
            newSortOrder = 'ASC';
        }


        setSortOrder(newSortOrder);
        setSortColumnName(columnName);
        fetchData(filterData, selectedClient, columnName, newSortOrder);
    };


    // Handles input changes in the search field and triggers filtering of assessment data.

    // Parameters:
    // -----------
    // e : object
    //     The event object for the search input change.

    // Details:
    // --------
    // Updates the state with the new input value. Filters the `assessmentData` based on the new search term and updates the `filteredAssessments` state.

    // Formula:
    // --------
    // term = e.target.value
    // setSearchTerm(term)

    // search results display the filtered assessments based on the search term.

    //SQ_8.1 -8.5: Handles search functionality
    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
    };

    //SQ_5.42-5.43
    // Function to toggle filter dropdown
    const toggleFilter = () => {
        setIsFilterOpen((prev) => !prev);
       
    };
    //SQ_5.44-5.45
    // Function to close filter dropdown
    const closeFilter = (type) => {
        setIsFilterOpen(false);
        if (type == "Clear"){
            
        setFilterData({ 
            assessment_type: "",
            domain: "",
            status: "",
            start_date: "",
            end_date: ""
        });  
    }
        
    };

    const isFilterDataEmpty = () => {
        return Object.values(filterData).every(value => value === '');
    };

    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
    };

    const handleLoadMore = () => {
        // Store the new value in a local variable
        const newLoadCount = loadCount + 10;

        // Update the state
        setLoadCount(newLoadCount);
        fetchData(filterData, selectedClient, sortColumnName, sortOrder, newLoadCount);
    };

        // Client Dropdown Handlers
        const handleClientSelect = (client) => {
            setSelectedClient(client.client_id);
            setSelectedClientName(client.client_name);
        };
    
        const handleAllClientsSelect = () => {
            setSelectedClient("");
            setSelectedClientName("All");
        };

        const refreshAssessmentData = () => {
            fetchData(filterData, selectedClient, "", "", undefined, searchTerm);
        };

    



    return (
        <>
            {/* <Header /> */}
            {showLoader ? (
                <Loader />
            ) : (
                <>
                    {/* Parent Organization Grid starts here */}
                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-md-12"></div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12 mb-3">
                                <div className="d-flex justify-content-between flex-md-row flex-column">
                                    <span className="font-24 font-semibold black-color page-title">
                                        Assessments ({totalAssessmentCount})
                                    </span>

                                    <div className="d-flex gap-2">
                                        {/* Search */}
                                        <div className="input-group custom-search">
                                            <input
                                                type="text"
                                                className="form-control border-0 input-search"
                                                placeholder="Search"
                                                value={searchTerm}
                                                onChange={((e)=>handleSearch(e))}
                                            />
                                            <button className="input-group-text input-field border-0 bg-white migrate-form-ui" onClick={handleSearch}>
                                                <img src="images/search.svg" alt="search-icon" />
                                            </button>
                                        </div>
                                        {/* Search */}

                                        {/* Client dropdown */}
                                        <div className="dropdown client-dropdown">
                                            <button
                                                className="bg-white d-flex gap-3 align-items-center text-nowrap"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {selectedClient ? (
                                                    <>
                                                        <img
                                                            src={
                                                                clientsData.find((client) => client.client_id === selectedClient)?.client_logo ||
                                                                "images/client-logo-1.svg"
                                                            }
                                                            alt={`${selectedClientName}-icon`}
                                                            className="client-logo"
                                                            style={{
                                                                width: "32px",
                                                                height: "32px",
                                                                borderRadius: "50%",
                                                                objectFit: "cover",
                                                                border: "1px solid #f0f0f0" // optional subtle border
                                                            }}
                                                        />
                                                        <span className="font-14">{selectedClientName}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src="" alt="" className="client-logo" />
                                                        <span className="font-14">All</span>
                                                    </>
                                                )}
                                                <img src="images/caret-down.svg" alt="caret-down-icon" />
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end custom-box-shadow border-0"
                                                style={{ overflowY: 'auto', maxHeight: '270px', scrollbarWidth: 'thin' }}>
                                                <li className="dropdown-item" 
                                                //onClick={() => { setSelectedClient(""); fetchData(filterData, "") }}
                                                onClick={handleAllClientsSelect}
                                                >
                                                    All
                                                </li>
                                                {clientsData.map((client) => (
                                                    <li
                                                        key={client.client_id}
                                                        className="dropdown-item"
                                                        // onClick={() => {
                                                        //     setSelectedClient(client.client_id)
                                                        //     fetchData(filterData, client.client_id)

                                                        //     setSelectedClientName(client.client_name)

                                                        // }}
                                                        onClick={() => handleClientSelect(client)}
                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <img
                                                                src={client.client_logo || "images/client-logo-1.svg"}
                                                                alt={`${client.client_name}-icon`}
                                                                className="client-logo"
                                                                style={{
                                                                    width: "32px",
                                                                    height: "32px",
                                                                    borderRadius: "50%",
                                                                    objectFit: "cover",
                                                                    border: "1px solid #f0f0f0" // optional subtle border
                                                                }}
                                                            />
                                                            <span>{client.client_name}</span>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        {/* Client dropdown */}

                                        {/* Filter Section Starts */}
                                        <div className="position-relative"
                                       
                                                                            >
                                        <button
                                            type="button"
                                            className="filter-btn bg-white"
                                            // data-bs-toggle="dropdown"
                                            // id="dropdownMenuLink"
                                            data-bs-auto-close={(()=>{
                                                console.log("closeFilter")
                                                closeFilter("Clear")})}
                                            onClick={(()=>{toggleFilter()})}
                                            aria-expanded={isFilterOpen}
                                        >

                                            <img src="images/filter.svg" alt="filter-icon" />
                                        </button>
                                        <div
                                            className={`dropdown-menu filter-dropdown dropdown-menu-end border-0 ${isFilterOpen == true ? "show" : ""}`} // Add "show" class conditionally
                                            aria-labelledby="dropdownMenuLink"
                                        >
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <h5 className="font-16 font-semibold black-color mb-0">Filter</h5>
                                                <button type="button" className="btn-close" 
                                                onClick={(() => { closeFilter("Clear") })} 
                                                data-bs-dismiss="modal" aria-label="Close"
                                                ></button>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label font-14 font-medium" htmlFor="industry-type">
                                                        Domain
                                                    </label>
                                                    <select
                                                        className="form-select migrate-form-ui"
                                                        id="domain"
                                                        value={filterData.domain}
                                                        onChange={handleFilterChange}
                                                    >
                                                        <option value=''>Select</option>
                                                        <option value="Data Science">Data Science</option>
                                                        <option value="AI">AI</option>
                                                        <option value="App dev">App Dev</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label font-14 font-medium" htmlFor="employee-range">
                                                        Type
                                                    </label>
                                                    <select
                                                        className="form-select migrate-form-ui"
                                                        id="assessment_type"
                                                        value={filterData.assessment_type}
                                                        onChange={handleFilterChange}
                                                    >
                                                        <option value=''>Select type</option>
                                                        {assessmentTypeData?.map((type) => (
                                                            <option key={type.id} value={type.id}>
                                                                {type.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label font-14 font-medium" htmlFor="Status">
                                                        Status
                                                    </label>
                                                    <select className="form-select migrate-form-ui"
                                                        id="status"
                                                        value={filterData.status}
                                                        onChange={handleFilterChange}
                                                    >
                                                        <option value="">Select</option>
                                                        {assessmnetStatus?.map((status) => (
                                                            <option key={status.id} value={status.id}>
                                                                {status.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="row mt-3">
                                                    <h5 className="font-14 font-semibold black-color mb-2">Date Range</h5>
                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label font-14 font-medium" htmlFor="employee-range">
                                                            Start Date
                                                        </label>

                                                        <input
                                                            type="date"
                                                            className="form-control migrate-form-ui"
                                                            id="start_date"
                                                            onChange={handleFilterChange}
                                                            value={filterData.start_date ? new Date(filterData.start_date).toISOString().split('T')[0] : ''}
                                                            max={getCurrentDate()}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label className="form-label font-14 font-medium" htmlFor="employee-range">
                                                            End Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control migrate-form-ui"
                                                            id="end_date"
                                                            onChange={handleFilterChange}
                                                            value={filterData.end_date ? new Date(filterData.end_date).toISOString().split('T')[0] : ''}
                                                            min={filterData.start_date} // Ensure end date is after start date
                                                            max={getCurrentDate()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                                                        <button className="btn outline-button" onClick={handleClear}>
                                                            Clear
                                                        </button>
                                                        <button className="btn primary-button"
                                                            disabled={isFilterDataEmpty()}
                                                            onClick={
                                                                handleApply
                                                            }>
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        {/* Filter Section Ends */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-4">
                                <div className="table-responsive bg-white zp-table custom-spacing">
                                    <table className="table m-0 align-middle">
                                        <thead>
                                            <tr className="alter">
                                                <th >
                                                    Assessment Name
                                                    {filteredAssessments.length > 0 && (
                                                        <span className="cursor-pointer ms-1" onClick={() =>  toggleSortOrder('assessment_name')}>
                                                            <img
                                                                src="images/sort-down-arrow.svg"
                                                                alt="sort-arrow-down"
                                                                style={{
                                                                    transform: sortColumnName === "assessment_name" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </th>
                                                <th>
                                                    Client Name
                                                    {filteredAssessments.length > 0 && (
                                                        <span className="cursor-pointer ms-1"  onClick={() => toggleSortOrder('client_name')}>
                                                            <img
                                                                src="images/sort-down-arrow.svg"
                                                                alt="sort-arrow-down"
                                                                style={{
                                                                    transform: sortColumnName === "client_name" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </th>
                                                <th>
                                                    Last Assessed
                                                    {filteredAssessments.length > 0 && (
                                                        <span className="cursor-pointer ms-1"  onClick={() => filteredAssessments.length > 0 && toggleSortOrder('last_assessed_at')}>
                                                            <img
                                                                src="images/sort-down-arrow.svg"
                                                                alt="sort-arrow-down"
                                                                style={{
                                                                    transform: sortColumnName === "last_assessed_at" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </th>
                                                <th >
                                                    Type
                                                    {filteredAssessments.length > 0 && (
                                                        <span className="cursor-pointer ms-1" onClick={() => filteredAssessments.length > 0 && toggleSortOrder('type')}>
                                                            <img
                                                                src="images/sort-down-arrow.svg"
                                                                alt="sort-arrow-down"
                                                                style={{
                                                                    transform: sortColumnName === "type" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </th>
                                                <th >
                                                    Progress
                                                    {filteredAssessments.length > 0 && (
                                                        <span className="cursor-pointer ms-1" onClick={() =>  toggleSortOrder('progress')}>
                                                            <img
                                                                src="images/sort-down-arrow.svg"
                                                                alt="sort-arrow-down"
                                                                style={{
                                                                    transform: sortColumnName === "progress" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </th>
                                                <th className="text-center" >
                                                    Status
                                                    {filteredAssessments.length > 0 && (
                                                        <span className="cursor-pointer ms-1" onClick={() =>    toggleSortOrder('status')}>
                                                            <img
                                                                src="images/sort-down-arrow.svg"
                                                                alt="sort-arrow-down"
                                                                style={{
                                                                    transform: sortColumnName === "status" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>{bindAssessmentData()}</tbody>
                                    </table>
                                </div>
                            </div>

                            {isPopupOpen && (
                                <AssessmentsPopup
                                    isOpen={isPopupOpen}
                                    onClose={() => setIsPopupOpen(false)}
                                    assessmentEditData={selectedAssessment}
                                    editMode={editMode}
                                    setIsPopupOpen={setIsPopupOpen}
                                    refreshAssessmentData={refreshAssessmentData}
                                />
                            )}
                            <SessionTimeOutPopup />

                            {filteredAssessments.length > 0 && (
                                <div className="col-md-12">
                                    <span className="font-14 font-medium table-records-color">
                                        Showing <span>{filteredAssessments?.length}</span> of <span>{totalAssessmentCount}</span> records
                                    </span>
                                    {filteredAssessments.length < totalAssessmentCount && (
                                        <div className="text-center">
                                            <button
                                                className="btn primary-button text-center"
                                                onClick={() => {
                                                    handleLoadMore();
                                                }

                                                }

                                            >
                                                Load More
                                            </button>
                                        </div>
                                    )}
                                </div>

                            )}
                        </div>
                    </div>
                    {/* Parent Organization Grid ends here */}
                </>
            )}
        </>
    );
    }
export default AssessmentsGrid