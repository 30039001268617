import Client from "./client";
import getEndpoint from "./endpoints";

// export async function getSectionsApi() {
//   const endpoint = getEndpoint('Editor', 'sections');
// console.log(endpoint);

//   try {
//     const response = await Client(endpoint, null, 'GET');
// console.log("inside get sections api", response)
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching sections:', error);
//     throw error;
//   }
// }

// export async function updateSectionsApi(sections) {
//   const endpoint = getEndpoint('Editor', 'update');

//   try {

//     const payload = {
//       sections: sections
//     }

// console.log("inside update API", sections);

//     const response = await Client(endpoint, payload, 'POST');

//     return response.data;

//   } catch (error) {
//     console.error('Error updating sections:', error);
//     throw error;
//   }
// }

// export async function htmlToPdf(html) {
//   const endpoint = getEndpoint('Editor', 'pdfGeneration');
//   try {
//     const payload = {
//       html_content: html
//     };
//     const response = await Client(endpoint, payload, 'POST');
//     return response.data;
//   } catch (error) {
//     console.error('Error generating PDF:', error);
//     throw error;
//   }
// }

export async function fetchClientsDataApi(payload) {
  const endpoint = getEndpoint("Client", "getClient"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error getting clients data", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function fetchIndustryType(payload) {
  const endpoint = getEndpoint("Client", "getIndustryType"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error getting industry type", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function getAssessmentTypeData(payload) {
  const endpoint = getEndpoint("Assessment", "assessmentType"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error getting assessment type", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function getProviderApi(payload) {
  const endpoint = getEndpoint("Assessment", "getProvider"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error getting assessment type", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function postErrorLog(payload) {
  const endpoint = getEndpoint("ErrorLog", "errorLog"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error storing error logs", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function saveClientInfoApi(payload) {
  const endpoint = getEndpoint("Client", "saveClient"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error saving client data", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function getAttributesDataApi(payload) {
  const endpoint = getEndpoint("Attributes", "getAttribute"); // Corrected endpoint retrieval
  // console.log(endpoint,"endpoint");

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "attribute responses")
    return response;
  } catch (error) {
    console.error("Error getting  attributes data :", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function handleSaveApi(payload) {
  const endpoint = getEndpoint("Attributes", "saveAttribute"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error  saving attributes:", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function handleDeleteAttribute(payload) {
  const endpoint = getEndpoint("Attributes", "deleteAttribute"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error deleting attribute", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function postLoginDetails(payload) {
  const endpoint = getEndpoint("Login", "login"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error logging", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function logOutUser(payload) {
  const endpoint = getEndpoint("Login", "logout"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error logging out", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function getApprovalData(payload) {
  const endpoint = getEndpoint("Approvals", "getApprovals"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error getting approvals data:", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function saveAssessmentDetailsApi(payload) {
  const endpoint = getEndpoint("Assessment", "saveAssessment"); // Corrected endpoint retrieval

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error storing assessment data", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function getAssessmentsData(payload) {
  const endpoint = getEndpoint("Assessment", "getAssessment"); // Corrected endpoint retrieval
  // console.log(endpoint,"log endpoint");

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error getting assessment data:", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function getAssessStatusData(payload) {
  const endpoint = getEndpoint("Assessment", "getAssessStatus"); // Corrected endpoint retrieval
  // console.log(endpoint,"log endpoint");

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error getting assessment data:", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function getNotificationApi(payload) {
  const endpoint = getEndpoint("Notification", "getNotification"); // Corrected endpoint retrieval
  // console.log(endpoint,"log endpoint");
  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error getting notification data:", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

 
export async function updateDarkThemeApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAssessmentStatusApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getSessionDataApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getScopeApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getActionItemApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function generateReportApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
export async function reportStatusApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getWorkflowAttributesApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function addAttributeApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getLockStatusApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function orchestrationAgentApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function saveActionItemApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function handleQuerySubmitApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getChatHistoryApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getWorkbenchDataApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}


export async function saveWorkbenchContentApi(payload) {
	const endpoint = getEndpoint('Notification', 'updateDarkTheme'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	}catch (error) {
		console.error('Error getting notification data:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

/*-----------------------
A - Report apis Start
-----------------------*/

export async function fetchReportDataApi(payload) {
  const endpoint = getEndpoint("Report", "getAssessmentReport");
  // console.log(endpoint,"log endpoint");
  try {
    const response = await Client(endpoint,payload,"POST");

    console.log(response , "get responses")
    return response.data;
  } catch (error) {
    console.error("Error getting notification data:", error);
    throw error;
  }
}



export async function saveReportContentApi(payload) {
  const endpoint = getEndpoint("Report", "saveReportData"); // Corrected endpoint retrieval
  console.log(endpoint,"log endpoint");

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error saving report data:", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function deleteSectionApi(payload) {
  const endpoint = getEndpoint("Report", "deleteSection"); // Corrected endpoint retrieval
  // console.log(endpoint,"log endpoint");

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error deleting section:", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function reportSortSaveApi(payload) {
  const endpoint = getEndpoint("Report", "reportSortSave"); // Corrected endpoint retrieval
  console.log(endpoint, "report save endpoint");

  console.log(payload, "payload is");

  try {
    const response = await Client(endpoint, payload, "POST");
    console.log(response, "responses");
    // const response = true
    return response;
  } catch (error) {
    console.error("Error saving report sort:", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

export async function regenerateReportContentApi(payload) {
  const endpoint = getEndpoint("Report", "regenerateReportContent"); // Corrected endpoint retrieval
  // console.log(endpoint,"log endpoint");

  try {
    const response = await Client(endpoint, payload, "POST");
    // console.log(response , "responses")
    return response;
  } catch (error) {
    console.error("Error regenerating report content:", error);
    throw error; // Rethrow or handle the error as necessary
  }
}

/*-----------------------
A - Report apis End
-----------------------*/
